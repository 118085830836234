import Axios from '@common/apis/axios';
import { ActionCreatorFactory } from '@common/ActionCreator';
import config from '@assets/config';

export const ACTION_TYPE = {
  CUTOUT_LOGO: ActionCreatorFactory('logoCutModal', "CUTOUT_LOGO"),
};

export const cutOutLogo = (file, fileName) => (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.CUTOUT_LOGO.start, 
    payload: { 
      loading: true, 
      status: '', 
      results: null, 
      imgName: null, 
      error: null 
    } 
  });
  Axios.post(`${config.apiServer.backgroundCutUrl}segment/logo`, {}, { params: {
    img_url: file,
    input_type: 'url'
  }}).then((res) => {
    setTimeout(() => {
      dispatch({
        type: ACTION_TYPE.CUTOUT_LOGO.success, 
        payload: {
          loading: false, 
          status: 'success', 
          results: res.data, 
          imgName: fileName, 
          error: null 
        } 
      });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ 
      type: ACTION_TYPE.CUTOUT_LOGO.fail, 
      payload: { 
        loading: false, 
        status: 'error', 
        results: null, 
        imgName: null, 
        error: {message: 'ロゴの切り抜きに失敗しました。' }
      } 
    });
  });
}