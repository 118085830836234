import styles from '@components/icons/alignHorizontalCenter/AlignHorizontalCenter.module.scss';

export default function AlignHorizontalCenter(props) {
  const { className, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <polygon fill={color} points="11,2 13,2 13,7 21,7 21,10 13,10 13,14 18,14 18,17 13,17 13,22 11,22 11,17 6,17 6,14 11,14 11,10 3,10 3,7 11,7" />
    </svg>
  );
}
