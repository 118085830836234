/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import {
  Button, Tooltip,
} from '@material-ui/core';
import { ToForward, ToBack } from '@components/icons';

// icons
import AlignHorizontalCenter from '@components/icons/alignHorizontalCenter/AlignHorizontalCenter';
import AlignHorizontalLeft from '@components/icons/alignHorizontalLeft/AlignHorizontalLeft';
import AlignHorizontalRight from '@components/icons/alignHorizontalRight/AlignHorizontalRight';
import AlignVerticalBottom from '@components/icons/alignVerticalBottom/AlignVerticalBottom';
import AlignVerticalCenter from '@components/icons/alignVerticalCenter/AlignVerticalCenter';
import AlignVerticalTop from '@components/icons/alignVerticalTop/AlignVerticalTop';

import styles from '@pages/editor/workArea/designTools/components/alignment/Alignment.module.scss';
import config from '@assets/config';

class Alignment extends React.Component {
  changeLocation(direction, alignment) {
    return () => {
      const { receiveElement, element } = this.props;
      const updatedElement = { ...element };
      const { elementableAttributes: elementAttrs } = element;
      const { canvas } = config;
      let updatePositionPx;
      if (direction === 'vertical') {
        switch (alignment) {
          case 'center':
            updatePositionPx = (canvas.height - elementAttrs.height) / 2;
            break;
          case 'bottom':
            updatePositionPx = canvas.height - elementAttrs.height;
            break;
          default:
            updatePositionPx = 0;
            break;
        }
        updatedElement.posY = updatePositionPx;
      } else {
        switch (alignment) {
          case 'center':
            updatePositionPx = (canvas.width - elementAttrs.width) / 2;
            break;
          case 'right':
            updatePositionPx = canvas.width - elementAttrs.width;
            break;
          default:
            updatePositionPx = 0;
            break;
        }
        updatedElement.posX = updatePositionPx;
      }
      receiveElement({ ...updatedElement });
    };
  }

  changeZIndex(idx, most = false) {
    const {
      element, elements, receiveElement, receiveElements,
    } = this.props;
    if (element.elementableType === 'WallPaperImage') {
      return;
    }

    const nowList = Object.keys(elements).filter((e) => !elements[e]._destroy).sort((a, b) => {
      if (elements[a].zIndex < elements[b].zIndex) {
        return -1;
      } if (elements[a].zIndex > elements[b].zIndex) {
        return 1;
      }
      return 0;
    });
    const now = nowList.indexOf(element.id);
    let nextList;
    if (most) {
      nextList = [...nowList];
      nextList.splice(now, 1);
      if (idx > 0) {
        nextList.push(element.id);
      } else {
        nextList.unshift(element.id);
      }
    } else {
      const tempId = nowList[now + idx];
      const tempEle = elements[tempId];
      if (tempEle.elementableType == 'WallPaperImage') {
        return;
      }
      nextList = this.swapArray(nowList, now, now + idx);
    }
    let index = 1;
    for (const id of nextList) {
      elements[id].zIndex = index++;
    }
    receiveElements(elements);
    receiveElement(elements[element.id]);
  }

  swapArray(arr, curr, next) {
    const clone = [...arr];
    if (arr.length <= next) {
      return clone;
    }
    [clone[curr], clone[next]] = [arr[next], arr[curr]];
    return clone;
  }

  isMaxZIndex() {
    const { element, elements } = this.props;
    if (element.elementableType == 'WallPaperImage') {
      return true;
    }
    const maxIndex = Object.keys(elements).filter((e) => !elements[e]._destroy).reduce((prev, curr) => ((elements[curr].zIndex > prev) ? elements[curr].zIndex : prev), 0);
    return maxIndex == element.zIndex;
  }

  render() {
    const { color, layerOrder, iconClassName } = this.props;
    return (
      <>
        <div className={`${styles.alignmentWrapper} ${styles.space} ${styles.fullWidth}`}>
          <div className={`${styles.alignment} ${styles.space} ${styles.fullWidth}`}>
            <Tooltip title="上揃え">
              <div onClick={this.changeLocation('vertical', 'top')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignVerticalTop color={color} className={styles.icon} />
              </div>
            </Tooltip>
            <Tooltip title="上下中央揃え">
              <div onClick={this.changeLocation('vertical', 'center')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignVerticalCenter color={color} className={styles.icon} />
              </div>
            </Tooltip>
            <Tooltip title="下揃え">
              <div onClick={this.changeLocation('vertical', 'bottom')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignVerticalBottom color={color} className={styles.icon} />
              </div>
            </Tooltip>
          </div>
          <div className={`${styles.alignment} ${styles.space} ${styles.fullWidth}`}>
            <Tooltip title="左揃え">
              <div onClick={this.changeLocation('horizontal', 'left')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignHorizontalLeft color={color} className={styles.icon} />
              </div>
            </Tooltip>
            <Tooltip title="左右中央揃え">
              <div onClick={this.changeLocation('horizontal', 'center')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignHorizontalCenter color={color} className={styles.icon} />
              </div>
            </Tooltip>
            <Tooltip title="右揃え">
              <div onClick={this.changeLocation('horizontal', 'right')} className={`${iconClassName} ${styles.pointer}`}>
                <AlignHorizontalRight color={color} className={styles.icon} />
              </div>
            </Tooltip>
          </div>
        </div>
        {
          !layerOrder && (
            <>
              <Button
                size="large"
                className={styles.button}
                startIcon={<ToForward color={color} />}
                onClick={() => { this.changeZIndex(1); }}
                classes={{
                  iconSizeLarge: styles.iconSizeLarge16,
                  textSizeLarge: styles.textSizeLargePadding,
                }}
              >
                前面へ
              </Button>
              <Button
                size="large"
                className={styles.button}
                startIcon={<ToBack color={color} />}
                onClick={() => { this.changeZIndex(-1); }}
                classes={{
                  iconSizeLarge: styles.iconSizeLarge16,
                  textSizeLarge: styles.textSizeLargePadding,
                }}
              >
                背面へ
              </Button>
            </>
          )
        }
      </>
    );
  }
}

export default Alignment;
