// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__bold--2_eme{color:red;font-weight:bold}\n", ""]);
// Exports
exports.locals = {
	"bold": "styles-module__bold--2_eme"
};
module.exports = exports;
