function SvgToBack(props, svgRef) {
  return (
    <svg
      {...props}
      data-name="\u30EC\u30A4\u30E4\u30FC 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <path
        d="M13.01 2V0h-13v13h2v2h13V2zm-12-1h11v11h-11z"
        fill={props.color}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToBack);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
