import Axios from '@common/apis/axios';
import { ActionCreatorFactory } from '@common/ActionCreator';
import config from '@assets/config';

export const ACTION_TYPE = {
  CUTOUT_HUMAN: ActionCreatorFactory('humanCutModal', 'CUTOUT_HUMAN'),
};

export const cutOutHuman = (file, fileName) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.CUTOUT_HUMAN.start,
    payload: {
      loading: true,
      status: '',
      results: null,
      imgName: null,
      error: null,
    },
  });
  Axios.post(`${config.apiServer.backgroundCutUrl}segment/human`, {}, {
    params: {
      img_url: file,
      input_type: 'url',
    },
  }).then((res) => {
    setTimeout(() => {
      dispatch({
        type: ACTION_TYPE.CUTOUT_HUMAN.success,
        payload: {
          loading: false,
          status: 'success',
          results: res.data,
          imgName: fileName,
          error: null,
        },
      });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({
      type: ACTION_TYPE.CUTOUT_HUMAN.fail,
      payload: {
        loading: false,
        status: 'error',
        results: null,
        imgName: null,
        error: { message: '人物の切り抜きに失敗しました。' },
      },
    });
  });
};
