import { Typography } from '@material-ui/core';

// assets
import config from '@assets/config';

// components
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import SliderAndInput from '@components/utils/sliderAndInput/SliderAndInput';

// pages
import styles from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialog.module.scss';

class RoundedCornerDialog extends React.Component {
  constructor(props) {
    super(props);
    // effectの設定値
    this.effects = { max: 250, min: 0 };
    // スライダーの設定値
    this.sliderConfigs = { step: 1, min: this.effects.min, max: this.effects.max };
    // スライダーのマーク値
    this.marks = [
      { value: 0, label: '0px' },
      { value: 100, label: '100px' },
      { value: 250, label: '250px' },
    ];

    this.useAttrs = config.useAttrs.roundedCornerDialog;
  }

  // onChanges
  onChangeRoundedCorner(newValue) {
    const { receiveElement, element } = this.props;
    const updatedElement = { ...element };
    updatedElement.roundedCorner = this.withinLimit(parseInt(newValue || 0, 10));
    receiveElement({ ...updatedElement });
  }

  withinLimit(value) {
    let updatedValue = value;
    if (value >= this.effects.max) {
      updatedValue = this.effects.max;
    } else if (value <= this.effects.min) {
      updatedValue = this.effects.min;
    }
    return updatedValue;
  }

  render() {
    const { className, element } = this.props;
    return (
      <>
        <CustomPaper className={`${className} ${styles.roundedCornerDialogWrapper}`} right>
          <Typography variant="subtitle1" color="textSecondary">
            角丸
          </Typography>
          <div className={styles.roundedCornerWrapper}>
            <SliderAndInput
              styles={styles}
              sliderConfig={this.sliderConfigs}
              marks={this.marks}
              value={element.roundedCorner}
              unit="px"
              onChangeInput={(e) => { this.onChangeRoundedCorner(e.target.value); }}
              onChangeSlider={(e, newValue) => { this.onChangeRoundedCorner(newValue); }}
              color="primary"
            />
          </div>
        </CustomPaper>
      </>
    );
  }
}

export default RoundedCornerDialog;
