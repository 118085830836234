import axios from 'axios';
import config from '../../../assets/config';

const Axios = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  },
});

Axios.interceptors.request.use((conf) => {
  const token = localStorage.getItem(config.accessTokenKey);
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
    return conf;
  }
  return conf;
}, function(error) {
  return Promise.reject(error)
});

Axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const originalRequest = error.config;
  if (!error.response) {
    return Promise.reject('Network Error')
  } else if ((error.response.status === 401) && !originalRequest._retry) {
    localStorage.removeItem(config.accessTokenKey);
    window.location.href = '/#/login';
    return error.response;
  } else {
    return error.response;
  }
});

export default Axios;