// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShapesDrawer-module__ShapesDrawer--201eA{width:312px;display:flex;flex-direction:column;padding-left:16px;padding-right:24px}.ShapesDrawer-module__itemList--2NBXv{margin-top:16px;display:flex;flex-wrap:wrap;justify-content:space-between}.ShapesDrawer-module__item--3OuSD{width:152px;height:152px;margin-bottom:6px;display:flex;justify-content:center;align-items:center;cursor:pointer;background-color:#fff}.ShapesDrawer-module__item--3OuSD>svg{fill:#c7d0d8;width:100px;height:100px}.ShapesDrawer-module__image--2XOr_{cursor:pointer;padding-top:4px;padding-bottom:4px}.ShapesDrawer-module__imgObject--2AQRb{width:100px;height:100px}.ShapesDrawer-module__masonItem--1riPf{margin:0 8px 8px 0;position:relative}.ShapesDrawer-module__masonItem--1riPf>i{display:block}.ShapesDrawer-module__masonItem--1riPf>img{position:absolute;top:0;width:100%;vertical-align:bottom}\n", ""]);
// Exports
exports.locals = {
	"ShapesDrawer": "ShapesDrawer-module__ShapesDrawer--201eA",
	"itemList": "ShapesDrawer-module__itemList--2NBXv",
	"item": "ShapesDrawer-module__item--3OuSD",
	"image": "ShapesDrawer-module__image--2XOr_",
	"imgObject": "ShapesDrawer-module__imgObject--2AQRb",
	"masonItem": "ShapesDrawer-module__masonItem--1riPf"
};
module.exports = exports;
