import { connect } from 'react-redux';
import ShapesDrawer from '@pages/editor/designDrawer/shapes/ShapesDrawer';
import * as Action from '@pages/editor/designDrawer/shapes/ShapesDrawerAction';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';

function mapStateToProps(state, ownProps) {
  return { ...ownProps, ...state.materials.shapes, tags: state.entities.tags };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);

  actions.updateTag = (...args) => dispatch(update(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(ShapesDrawer);
