import { combineReducers } from 'redux';
import designsReducer from '@common/components/entities/designs/DesignsReducer';
import elementsReducer from '@common/components/entities/elements/ElementsReducer';
import tagDrawerReducer from '@pages/editor/designDrawer/tag/TagDrawerReducer';
import designHistoryReducer from '@pages/editor/designDrawer/designHistory/DesignHistoryReducer';

const entitiesReducer = combineReducers({
  designs: designsReducer,
  elements: elementsReducer,
  tags: tagDrawerReducer,
  designHistory: designHistoryReducer
});

export default entitiesReducer;
