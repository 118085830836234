export const signup = (user) => (
  $.ajax({
    url: 'rest/user/signup',
    method: 'POST',
    data: user,
  })
);

export const login = (user) => (
  $.ajax({
    url: '/rest/auth/login',
    method: 'POST',
    data: user,
  })
);

export const logout = () => (
  $.ajax({
    url: '/api/session',
    method: 'DELETE',
  })
);
