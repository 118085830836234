import Wait from '@components/wait/WaitComponent';
import {
  Card, CardContent, Container, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React from 'react';
import { toastr } from 'react-redux-toastr';
import { Redirect } from 'react-router-dom';
import config from '../../../assets/config';
import styles from './styles';

class LoginComponent extends React.Component {
  componentDidMount() {
    const { isChecked, check } = this.props;
    // logout()
    if (!isChecked) {
      check();
    }
  }

  responseGoogle(response) {
    console.log(response);
  }

  render() {
    const {
      classes, isAuthenticated, wait, login, error,
    } = this.props;
    return (
      isAuthenticated ? (
        <Redirect to="/design/1" />
      ) : (
        <Container maxWidth="xs">
          <Wait active={wait} />
          {error && toastr.error('error', error.message)}
          <div className={classes.paper}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography component="h1" variant="h1">GAZO</Typography>
                <GoogleOAuthProvider clientId={config.google.clientId}>
                  <GoogleLogin
                    onSuccess={(e) => login(e)}
                    onError={this.responseGoogle}
                    className={classes.signinBtn}
                    // isSignedIn={true}
                  />
                </GoogleOAuthProvider>
              </CardContent>
            </Card>
          </div>
        </Container>
      )
    );
  }
}

export default withStyles(styles)(LoginComponent);
