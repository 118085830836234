function SvgHand(props, svgRef) {
  return (
    <svg
      {...props}
      data-name="\u30EC\u30A4\u30E4\u30FC 8"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 24"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <path
        d="M22 4v20H9l-9-9.17L1.9 13 7 15.91V3h3v8h1V0h3v11h1V1h3v10h1V4z"
        fillOpacity={0.54}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHand);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
