function SvgFontOuterBorder(props, svgRef) {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#FFF"
      ref={svgRef}
    >
      <path stroke={props.borderColor} d="M.5.5h12v10.135H.5z" />
      <path
        d="M7.98 7.444H5.003L4.38 9H3l2.899-7h1.194L10 9H8.61l-.63-1.556zm-2.593-.98h2.226L6.504 3.486 5.387 6.464z"
        fill={props.color}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFontOuterBorder);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
