function SvgToForward(props, svgRef) {
  return (
    <svg
      {...props}
      data-name="\u30EC\u30A4\u30E4\u30FC 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <path fill="none" stroke={props.color} d="M3.5 3.5h12v12h-12z" />
      <path fill={props.color} d="M0 0h13v13H0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToForward);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
