import {
  Typography, Box, IconButton, Divider, Button, ButtonBase, Dialog, DialogContent
} from '@material-ui/core';
import styles from '@pages/editor/designDrawer/designHistory/DesignHistory.module.scss';
import { Clear, Star, StarOutline } from '@material-ui/icons'
import Loader from '../../../../components/loader/Loader';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';

class DesignHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      historySelect: false,
      selected: {}
    }
  }

  componentDidMount() {
    this.props.getHistory();
  }

  async componentDidUpdate() {
    const { addToHistory, getHistory, addedToHistory } = this.props;
    if (addToHistory && addedToHistory) {
      await getHistory();
      await addedToHistory();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.historyData && props.historyData.length > 0 && props.historyData !== state.history) {
      return { history: props.historyData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at) ) };
    }
    return null;
  }

  getSelectApproval(parts_info, categories, tags, fileName) {
    const selectedHistory = {
      parts: parts_info && JSON.parse(parts_info),
      tagCat: categories && JSON.parse(categories),
      tagIds: tags && JSON.parse(tags),
      fName: fileName
    }
    this.setState({
      selected: selectedHistory,
      historySelect: true
    })
  }

  async addFromHistory(selected) {
    this.setState({ historySelect: false })
    await this.props.deleteElements();
    const { user } = this.props;
    gtag('event', 'select_design_from_history', {
      event_name : 'select_design_from_history',
      user_name : user.name,
      user_email : user.mail,
      select_design_from_history_file_name: selected.fName
    });
    Object.keys(selected.parts).map((el)=>{
      this.props.receiveElementFromHistory(selected.parts[el]);
      // this.props.createElement(1, { ...selected.parts[el], id: selected.parts[el].id });
    })
    
    if (selected.tagIds) {
      const updatedTag = { ...this.props.tagState };
      for (let index = 0; index < selected.tagIds.length; index++) {
        const cat = selected.tagCat[index];
        updatedTag[cat] = selected.tagIds[index]
      }
      this.props.updateTag(updatedTag);
    }
  }

  handleCancel() {
    this.setState({ historySelect: false })
  }

  render() {
    const { getHistoryWait, closeAlert, isFlagFull, updateKeepFlag, user } = this.props;
    const { history, historySelect, selected } = this.state;
    return (
      <div className={scrollbar.customScroll +' '+ styles.historyBody}>
        { getHistoryWait ? 
          <Box className={styles.loaderBox}>
            <Loader leftDrawer={true} active={true}/>
          </Box>
        : history.length > 0 ? history.map((ele, index)=> {
          const imgFileName = ele.thumbnail_url.split("/").pop();
          return (
            <div className={styles.history} key={index}>
              <Box className={styles.historyBox}>
                <ButtonBase 
                  className={styles.imageBox} 
                  onClick={
                    ()=>this.getSelectApproval(ele.parts_informations, ele.categories_en, ele.tag_ids, imgFileName)
                  }
                >
                  <Box className={styles.imgBox}>
                    <img className={styles.img} src={ele.thumbnail_url} alt=''/>
                  </Box>
                  <Box className={styles.infoBox}>
                    <Typography className={styles.fileName}>{imgFileName.slice(0,14)}</Typography>
                    <Typography className={styles.fileName}>{imgFileName.slice(14)}</Typography>
                    <Typography className={styles.fileInfo}>{ele.created_at.slice(0,-3)}</Typography>
                    <Typography className={styles.fileInfo}>CompNo：{ele.company_id==="000000" ? "ー" : ele.company_id}</Typography>
                  </Box>
                </ButtonBase>
                <Box className={styles.starBox}>
                  <IconButton className={styles.starBtn} onClick={()=>updateKeepFlag(ele.id, ele.keep_flag, user)}>
                    {ele.keep_flag ? <Star className={styles.star}/> : <StarOutline className={styles.star}/>}
                  </IconButton>
                </Box>
              </Box>
              { (index + 1) !== history.length && <Divider className={styles.divider}/> }
            </div>
          )
        })
        : <Box className={styles.noDataBox}>
          <Typography className={styles.noDataText}>
            履歴はありません
          </Typography>
        </Box>
        }
        {isFlagFull &&
          <Dialog open={isFlagFull} style={{ zIndex: 3000 }} PaperProps={{ style: { borderRadius: 15, padding: 15 }}}>
            <DialogContent className={styles.flagAlert}>
              <Clear className={styles.crossIcon} onClick={()=>closeAlert()} />
              <Typography className={styles.info}>キープできるデザインは最大10件までです。</Typography>
              <Button 
                variant='contained'
                className={styles.okButton}
                onClick={()=>closeAlert()} 
              >
                <Typography className={styles.buttonText}>OK</Typography>
              </Button>
            </DialogContent>
          </Dialog>
        }

        {historySelect &&
          <Dialog open={historySelect} style={{ zIndex: 3000 }} PaperProps={{ style: { borderRadius: 15, padding: 15 }}}>
            <DialogContent className={styles.alertDialog}>
              <Clear className={styles.crossIcon} onClick={()=>this.handleCancel()} />
              <Typography className={styles.dialogTitle}>デザイン削除の確認</Typography>
              <Box className={styles.alertTextBox}>
                <Typography className={styles.alertText}>キャンバスに編集中のデザインがあります。</Typography>
                <Typography className={styles.alertText}>選択したデザインに上書きしますか？</Typography>
              </Box>
              <Box className={styles.dialogAction}>
                <Button 
                  variant='contained'
                  className={styles.cancelButton}
                  onClick={()=>this.handleCancel()} 
                >
                  <Typography className={styles.buttonText}>キャンセル</Typography>
                </Button>
                <Button 
                  variant='contained'
                  className={styles.okButton}
                  onClick={()=>this.addFromHistory(selected)} 
                >
                  <Typography className={styles.buttonText}>はい</Typography>
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        }
      </div>
    );
  }
}

export default DesignHistory;
