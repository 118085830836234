import Axios from '@common/apis/axios';
import axios from 'axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('assetImage', 'FETCH'),
  COUNT: ActionCreatorFactory('assetImage', 'COUNT'),
  UPLOAD_FILE: ActionCreatorFactory('assetImage', 'UPLOAD_FILE'),
  SAVE: SaveActionCreatorFactory('assetImage', 'SAVE'),
};

export const fetch = (queries) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetImage: false, error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/assetImage`, {
    params: { tags: queries.tags, limit: queries.limit, offset: queries.offset },
  }).then((res) => {

    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetImage: true,
        error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        assetImages: res.data,
        offset: queries.offset || 0,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetImage: false, error: { message: 'アセット画像の取得に失敗しました。' },
      },
    });
  });
};

export const count = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.COUNT.start,
    payload: {
      isGetCount: false, error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/assetImage/count`).then((res) => {
    dispatch({
      type: ACTION_TYPE.COUNT.success,
      payload: {
        isGetCount: true,
        error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        assetImagesCount: res.data.assetImagesCount,
      },
    });
  }).catch((error) => {
    console.log(error);
    dispatch({
      type: ACTION_TYPE.COUNT.fail,
      payload: {
        isGetCount: false, error: { message: 'アセット画像の取得に失敗しました。' },
      },
    });
  });
};

export const uploadFile = (file) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.UPLOAD_FILE.start,
    payload: {
      error: null,
    },
  });

  const fileNameList = file.name.split('.');
  const ext = fileNameList[fileNameList.length - 1];
  Axios.get(`${config.apiServer.baseUrl}/uploadImage/url`, {
    params: { ext }
  }).then((res) => {
    axios.put(
      res.data.url,
      file,
      {headers: {'Content-Type': file.type} }
    ).then((registerRes) => {
      dispatch({
        type: ACTION_TYPE.UPLOAD_FILE.success,
        payload: {
          error: null,
        },
      });
      dispatch({
        type: ACTION_TYPE.SAVE.save,
        payload: {
          uploadedImage: registerRes.config.url.split('?')[0],
        },
      });
    }).catch((error) => {
      console.log(error)
      dispatch({
        type: ACTION_TYPE.UPLOAD_FILE.fail,
        payload: {
          error: { message: '素材画像の登録に失敗しました。' },
        },
      });
    });
  });
};

export const updateAssetImage = (arg) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.save,
    payload: { ...arg }
  })
}

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};
