import { connect } from 'react-redux';
import TabSelector from '@pages/editor/designDrawer/tabSelector/TabSelector';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';
import { deleteElements } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => {
  return {
    elements: state.entities.elements,
    tagState: state.entities.tags.tagState,
    user: state.materials.accountMenu.user,
    ...ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  const actions = {};
  actions.updateTag = (...args) => dispatch(update(...args));
  actions.deleteElements = (...args) => dispatch(deleteElements(...args));
  return actions;
}

export default connect(mapStateToProps, mapDispatchToProps)(TabSelector);
