function SvgLogoCutout(props, svgRef) {
  return (
    <svg
      {...props}
      id="logoCutout_svg__\u30EC\u30A4\u30E4\u30FC_7"
      data-name="\u30EC\u30A4\u30E4\u30FC 7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.84 30.57"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <defs>
        <style>{`.logoCutout_svg__cls-1{fill:${props.color}}`}</style>
      </defs>
      <path
        className="logoCutout_svg__cls-1"
        d="M780 518.22a.24.24 0 00-.31 0l-1.93 1.94a.24.24 0 000 .32l-7 7.36h.38v.16l.84-.27a60.37 60.37 0 006.46-2.29c.32-.28 1.54-1.53 2.11-2.12a.24.24 0 00.32 0l1.93-1.94a.24.24 0 000-.31zm-2.09 6.57c-.29.19-2.56 1-4.66 1.66l5.05-5.35 1.65 1.64c-.64.68-1.76 1.83-2.02 2.05zM777.71 529.34a4 4 0 104 4 4 4 0 00-4-4zm0 6.6a2.6 2.6 0 112.59-2.59 2.6 2.6 0 01-2.59 2.59zM777.71 538.38a4 4 0 104 4 4 4 0 00-4-4zm0 6.6a2.6 2.6 0 112.59-2.6 2.61 2.61 0 01-2.59 2.62zM772.64 541.73h-3.93v1.73h2a2.25 2.25 0 11-2-3.33 2.24 2.24 0 011.3.42l1-1.42a4 4 0 101.53 4.33 3.81 3.81 0 00.15-1.08 3.49 3.49 0 00-.05-.65z"
        transform="translate(-762.49 -518.18)"
      />
      <path
        className="logoCutout_svg__cls-1"
        d="M4.82 17.17v-5.74H3.08V18.9H9.35v-1.73H4.82z"
      />
      <path
        className="logoCutout_svg__cls-1"
        d="M779.56 548.75v-1h2v1zm-2 0h-2v-1h2zm-4 0h-2v-1h2zm-4 0h-2v-1h2zm-4 0h-.37a2.86 2.86 0 01-1.81-.66l.65-.77a1.77 1.77 0 001.16.43h.37zm18-1l-.77-.64a1.79 1.79 0 00.42-1.15v-.39h1v.43a2.77 2.77 0 01-.52 1.74zM762.49 546v-2h1v2zm21.85-2.38h-1v-2h1zM763.49 542h-1v-2h1zm20.85-2.38h-1v-2h1zM763.49 538h-1v-2h1zm20.85-2.38h-1v-2h1zm-20.85-1.59h-1v-2h1zm20.85-2.38h-1v-2h1zm-20.85-1.59h-1v-.38a2.81 2.81 0 01.66-1.8l.77.64a1.85 1.85 0 00-.43 1.16zm19.21-1.76a1.8 1.8 0 00-1.16-.42h-.38v-1h.38a2.81 2.81 0 011.8.66zm-3.52-.42h-2v-1h2zm-4 0h-2v-1h2zm-4 0h-2v-1h2zm-4 0h-2v-1h2z"
        transform="translate(-762.49 -518.18)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogoCutout);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;