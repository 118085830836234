import { ACTION_TYPE } from './TrimmingModalAction';

const initState = {
  selectedItems: Array(6),
};

const TrimmingModalReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.GET_IMAGE_FROM_URL.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GET_IMAGE_FROM_URL.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GET_IMAGE_FROM_URL.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.CHANGE_READ.success:
      return Object.assign({}, state, action.payload);
      
    case ACTION_TYPE.SET_ERROR.success:
      return Object.assign({}, state, action.payload);
      
    default:
      return state;
  }
};

export default TrimmingModalReducer;