import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import styles from './styles.module.scss';

export default class ConfirmDialogComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, onClose, onSubmit } = this.props;

    return (
      <>
        <Dialog
          open={open}
          onClose={() => { onClose() }}
          aria-labelledby="download-dialog-title"
        >
          <DialogTitle id="download-dialog-title">{"デザイン削除の確認"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="download-dialog-description">
              現在のデザインが全て<span className={styles.bold}>削除</span>されますがよろしいですか?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { onClose() }} color="primary">
              いいえ
            </Button>
            <Button onClick={() => { onSubmit() }} color="secondary">
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
