import { ACTION_TYPE, UPDATE_TAG } from '@pages/editor/designDrawer/tag/TagDrawerAction';

const initState = {
  tagState: {
    companyId: '', // 企業ID
    professional: '', // 職業カテゴリ
    targetAttr: '', // ターゲット属性
    imageKind: '', // 画像種別
    imageImagine: '', // 画像イメージ
    feature: '', // 特徴
  },
};

const tagReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.FETCH.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.SAVE.save:
      if (state.mounted) {
        return { ...state, ...action.payload };
      }
      return state;
    case ACTION_TYPE.SAVE.mounted:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SAVE.unmount:
      return { ...state, ...action.payload };

    case UPDATE_TAG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default tagReducer;
