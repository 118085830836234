import Slider from 'react-slick';
import {
  ExpandLess, ExpandMore, ArrowLeftOutlined, ArrowRightOutlined,
} from '@material-ui/icons';
import BeatLoader from 'react-spinners/BeatLoader';

import DrawerKnob from '@components/utils/drawerKnob/DrawerKnob';
import { compareObject } from '@common/utils/ObjectUtils';
import path from 'path';

// css
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '@pages/editor/workArea/smallImages/SmallImages.module.scss';

class SmallImages extends React.Component {
  constructor(props) {
    super(props);

    // const { unmount } = this.props;
    // unmount();
    this.articleRef = React.createRef();
    this.rect = {
      width: 102,
      height: 78,
    };
    this.state = {
      sliderShown: 6,
    };
    this.genSliderCount = this.genSliderCount.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  componentDidMount() {
    const { getSmallImage } = this.props;
    this.intervalId = setInterval(this.genSliderCount, 1000);
    // mount();
    getSmallImage();
  }

  componentDidUpdate(prevProps) {
    const { tagState, getSmallImage } = this.props;
    if (!prevProps.tagState || !compareObject(tagState, prevProps.tagState)) {
      // companyId以外が変更された時に更新
      if (tagState.companyId === prevProps.tagState.companyId) {
        if (tagState) {
          getSmallImage({ ...tagState });
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    // const { unmount } = this.props;
    // unmount();
  }

  getSmallImages() {
    const { getSmallImage } = this.props;
    getSmallImage({ ...this.props.tagState });
  }

  genSliderCount() {
    const { width: prevWidth } = this.state;
    const { width } = this.articleRef.current.getBoundingClientRect();
    if (width !== prevWidth) {
      const showableCount = Math.floor(width / (this.rect.width + 40));
      this.setState({
        sliderShown: showableCount,
        width,
      });
    }
  }

  next() {
    this.slider.slickNext();
  }

  prev() {
    this.slider.slickPrev();
  }

  render() {
    const {
      display, toggleBottomDrawer, toggleImageModal, smallImageRecommend, smallImageLoading, url, plusSmallImageClickCount,
    } = this.props;
    const { sliderShown } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: sliderShown,
      slidesToScroll: sliderShown,
      arrows: false,
      className: styles.imagesSlider,
    };

    return (
      <article ref={this.articleRef} className={styles.recommendImagesWrapper}>
        <div
          className={styles.recommendImagesBehind}
          style={{ display: display ? 'none' : 'flex' }}
        >
          <div className={styles.handleClose}>
            <button type="button" className={`${styles.container} btn-none`} onClick={toggleBottomDrawer}>
              <div className={styles.drawerKnob}><DrawerKnob /></div>
              <div className={styles.handleIconClose}>
                <ExpandLess style={{ fontSize: 26, color: 'FFFFFF' }} />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.recommendImagesFrontWrapper} style={{ display: display ? 'flex' : 'none' }}>
          <div className={styles.handle}>
            <button type="button" className={`${styles.container} btn-none`} onClick={toggleBottomDrawer}>
              <div className={styles.drawerKnob}><DrawerKnob /></div>
              <div className={styles.handleIcon}>
                <ExpandMore style={{ fontSize: 26, color: 'FFFFFF' }} />
              </div>
            </button>
          </div>
          <div className={styles.recommendImagesFront}>
            <ArrowLeftOutlined style={{ fontSize: 80, color: 'FFFFFF' }} onClick={this.prev} />
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              { smallImageLoading || !smallImageRecommend
                ? Array.from(Array(20), (i, key) => (
                  <div key={key} className={styles.recommendImage}>
                    <BeatLoader />
                  </div>
                ))
                : smallImageRecommend.items.map((item) => (
                  <div
                    key={item.id}
                    className={(url === item.url) ? `${styles.recommendImage} ${styles.active}` : styles.recommendImage}
                    onClick={() => {
                      toggleImageModal(true, item.url);
                      plusSmallImageClickCount(item.id);
                      gtag('event', 'smallImage_recommended_select_file_name', { smallImage_recommended_select_file_name: path.basename(item.url) });
                    }}
                  >
                    <img src={item.url} alt="" />
                  </div>
                ))}
            </Slider>
            <ArrowRightOutlined style={{ fontSize: 80, color: 'FFFFFF' }} onClick={this.next} />
          </div>
        </div>
      </article>
    );
  }
}

export default SmallImages;
