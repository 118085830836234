import { connect } from 'react-redux';
import RoundedCornerDialog from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialog';
import * as Action from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialogAction';
import { receiveElement, receiveElements } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  drawerSettings: state.ui.drawer,
  elements: state.entities.elements,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  actions.receiveElements = (...args) => dispatch(receiveElements(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(RoundedCornerDialog);