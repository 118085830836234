/* eslint-disable no-restricted-syntax */
import {
  Typography,
} from '@material-ui/core';

// assets
import config from '@assets/config';

import { compareObject } from '@common/utils/ObjectUtils';

// components
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import SliderAndInput from '@components/utils/sliderAndInput/SliderAndInput';

// pages
import styles from '@pages/editor/workArea/designTools/components/dialogs/shadowDialog/ShadowDialog.module.scss';
import '@pages/editor/workArea/designTools/components/dialogs/shadowDialog/ShadowDialog.scss';

class ShadowDialog extends React.Component {
  static valueText(value) {
    return `${value}px`;
  }

  constructor(props) {
    super(props);
    const { element } = this.props;
    this.shadow = {
      vertical: '上下',
      horizontal: '左右',
      blur: 'ぼかし',
    };
    this.colorPaletteDrawerIndex = 1;

    // colorを変更するターゲットの指定
    this.changeColorTargets = {};
    const setChangeColorTargets = (key, changeColorTargetsId) => {
      if (this.changeColorTargets[key] === undefined) { this.changeColorTargets[key] = []; }
      this.changeColorTargets[key] = changeColorTargetsId;
    };
    ['shadow'].forEach((attrName) => {
      setChangeColorTargets(attrName, `${element.id}-${attrName}`);
    });
    // effectの設定値
    this.effects = { max: 100, min: -100 };

    // スライダーの設定値
    this.sliderConfigs = { step: 1, min: this.effects.min, max: this.effects.max };

    // スライダーのマーク値
    this.marks = [
      { value: -100, label: '-100px' },
      { value: 0, label: '0px' },
      { value: 100, label: '100px' },
    ];

    this.useAttrs = config.useAttrs.shadowDialog;
  }

  componentDidUpdate() {
    const { drawerSettings, element } = this.props;
    for (const key in this.changeColorTargets) {
      if (
        drawerSettings.colorPalette.color !== element[key].color
        && this.isChangeColorTargetOwn(key)
      ) {
        this.changeColor(key);
      }
    }
  }

  // onChanges
  onChangeShadow(attrName, newValue) {
    const { receiveElement, element } = this.props;
    const updatedElement = { ...element };
    updatedElement.shadow[attrName] = this.withinLimit(parseInt(newValue || 0, 10), attrName);
    receiveElement({ ...updatedElement });
  }

  changeColor(targetKey) {
    const { element, receiveElement, drawerSettings } = this.props;
    if (this.isChangeColorTargetOwn(targetKey)) {
      element[targetKey].color = drawerSettings.colorPalette.color;
      receiveElement({ ...element });
    }
  }

  isChangeColorTargetOwn(key) {
    const { drawerSettings } = this.props;
    return compareObject(drawerSettings.colorPalette.changeTarget, this.changeColorTargets[key]);
  }

  withinLimit(value) {
    let updatedValue = value;
    if (value >= this.effects.max) {
      updatedValue = this.effects.max;
    } else if (value <= this.effects.min) {
      updatedValue = this.effects.min;
    }
    return updatedValue;
  }

  toggleSubDrawer(id, color = undefined, targetIdx) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      const nextDrawerSettings = { ...drawerSettings };
      if (drawerSettings.closed) { nextDrawerSettings.closed = false; }
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
      if (color) {
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: config.colorTitle['影'],
          changeTarget: this.changeColorTargets[targetIdx],
          colorType: 'unit',
          allowGradient: false,
        };
      }
      if (!color) {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
        // 色ドロワーかつ、色変更対象が変わった時
      } else if (
        color && drawerSettings.colorPalette.changeTarget !== this.changeColorTargets[targetIdx]
      ) {
        nextDrawerSettings.isSubDrawerOpen = true;
      }

      if (drawerSettings.closed) {
        nextDrawerSettings.isSubDrawerOpen = true;
      } else {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
      }

      nextDrawerSettings.subDrawer = id;
      nextDrawerSettings.decorationTarget = null;

      changeDrawer(nextDrawerSettings);
    };
  }

  render() {
    const { className, element } = this.props;
    const { shadow } = element;

    return (
      <CustomPaper className={`${styles.shadowDialogWrapper} ${className}`} right>
        <div className={styles.titleAndColorSelection}>
          <Typography variant="subtitle1" color="textSecondary">影</Typography>
          <div
            id="boxShadow-colorPalette"
            className={`btn-color ${styles.space} ${styles.colorPalette}`}
            style={{ backgroundColor: shadow.color }}
            onClick={this.toggleSubDrawer(this.colorPaletteDrawerIndex, shadow.color, 'shadow')}
          />
        </div>
        {Object.keys(shadow).map((key) => {
          if (key === 'color') { return null; }
          return (
            <SliderAndInput
              styles={styles}
              key={key}
              sliderConfig={this.sliderConfigs}
              label={this.shadow[key]}
              marks={this.marks}
              value={shadow[key]}
              unit="px"
              onChangeInput={(e) => { this.onChangeShadow(key, e.target.value); }}
              onChangeSlider={(e, newValue) => { this.onChangeShadow(key, newValue); }}
            />
          );
        }).filter(Boolean)}
      </CustomPaper>
    );
  }
}

export default ShadowDialog;
