// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Scrollbar-module__customScroll--1EwBM{overflow-y:auto;overflow-x:hidden;height:calc(100% - 72px);margin-right:4px;margin-top:4px;scrollbar-width:8px}.Scrollbar-module__customScroll--1EwBM:hover::-webkit-scrollbar-thumb{background-color:#5f646b}.Scrollbar-module__customScroll--1EwBM::-webkit-scrollbar{width:8px;background-color:#293039}.Scrollbar-module__customScroll--1EwBM::-webkit-scrollbar-thumb{background:#293039;border-radius:4px}.Scrollbar-module__customScrollNoMargin--1sotd{overflow-y:auto;overflow-x:hidden;height:calc(100% - 72px);scrollbar-width:8px}.Scrollbar-module__customScrollNoMargin--1sotd:hover::-webkit-scrollbar-thumb{background-color:#5f646b}.Scrollbar-module__customScrollNoMargin--1sotd::-webkit-scrollbar{width:8px;background-color:#293039}.Scrollbar-module__customScrollNoMargin--1sotd::-webkit-scrollbar-thumb{background:#293039;border-radius:4px}.Scrollbar-module__customScrollOwnColor--3u3Mk{scrollbar-width:8px}.Scrollbar-module__customScrollOwnColor--3u3Mk:hover::-webkit-scrollbar-thumb{background-color:#5f646b}.Scrollbar-module__customScrollOwnColor--3u3Mk::-webkit-scrollbar{width:8px;background-color:#eeeeee}.Scrollbar-module__customScrollOwnColor--3u3Mk::-webkit-scrollbar-thumb{background:#eeeeee;border-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"customScroll": "Scrollbar-module__customScroll--1EwBM",
	"customScrollNoMargin": "Scrollbar-module__customScrollNoMargin--1sotd",
	"customScrollOwnColor": "Scrollbar-module__customScrollOwnColor--3u3Mk"
};
module.exports = exports;
