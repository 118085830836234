import Axios from '@common/apis/axios';
import axios from 'axios';
import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';
import { DateTime } from 'luxon';

export const ACTION_TYPE = {
  DOWNLOAD_IMAGE: ActionCreatorFactory('saving', 'DOWNLOAD_IMAGE'),
  SEARCH_COMPANY: ActionCreatorFactory('saving', 'SEARCH_COMPANY'),
  UPLOAD_IMAGE: ActionCreatorFactory('saving', 'UPLOAD_IMAGE'),
};

export const UPDATE_IMAGE_COUNT = 'UPDATE_IMAGE_COUNT';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const ADDED_TO_HISTORY ='ADDED_TO_HISTORY';
export const LARGE_JSON_NOTIFIED ='LARGE_JSON_NOTIFIED';

let fontEmbedCss = '';
for (const key in [...Array(18)]) {
  Axios.get(`${config.apiServer.baseUrl}/fonts/webfont${parseInt(key)}.txt`).then((ret) => {
    fontEmbedCss += ret.data;
  });
}

const getTag = (category, id, allTags) => {
  const targetTag = allTags[category].filter((tag) => tag.id === id)[0];
  return targetTag;
};

const setDownloadSmallImageEvent = (fileName, tagState, allTags) => {
  const customDimensions = {
    event_name: 'smallImage_download_count',
    smallImage_downloaded_file_name: fileName,
    smallImage_created_at: DateTime.now().toFormat('yyyy/MM/dd/ HH:mm:ss'),
    smallImage_download_company_id: tagState.companyId,
    smallImage_download_professional: getTag('職種カテゴリ', tagState.professional, allTags).name,
    smallImage_download_targetAttr: getTag('ターゲット属性', tagState.targetAttr, allTags).name,
    smallImage_download_imageKind: getTag('画像種別', tagState.imageKind, allTags).name,
  };
  if (tagState.imageImagine) { customDimensions.smallImage_download_imageImagine = getTag('画像イメージ', tagState.imageImagine, allTags).name; }
  if (tagState.feature) { customDimensions.smallImage_download_feature = getTag('特徴', tagState.feature, allTags).name; }
  gtag('event', 'smallImage_download_count', customDimensions);
};

const setUploadSmallImageEvent = (fileName, tagState, allTags) => {
  const customDimensions = {
    event_name: 'smallImage_upload',
    smallImage_downloaded_file_name: fileName,
    smallImage_created_at: DateTime.now().toFormat('yyyy/MM/dd/ HH:mm:ss'),
    smallImage_download_company_id: tagState.companyId,
    smallImage_download_professional: getTag('職種カテゴリ', tagState.professional, allTags).name,
    smallImage_download_targetAttr: getTag('ターゲット属性', tagState.targetAttr, allTags).name,
    smallImage_download_imageKind: getTag('画像種別', tagState.imageKind, allTags).name,
  };
  if (tagState.imageImagine) { customDimensions.smallImage_download_imageImagine = getTag('画像イメージ', tagState.imageImagine, allTags).name; }
  if (tagState.feature) { customDimensions.smallImage_download_feature = getTag('特徴', tagState.feature, allTags).name; }
  gtag('event', 'smallImage_upload', customDimensions);
};

const getSizeInBytes = (obj) => {
  let str = typeof obj === 'string' ? obj : JSON.stringify(obj);
  return new TextEncoder().encode(str).length;
};

export const downloadImage = (elements, tagState, count, allTags, designedImg, registImgName, saveOnly) => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.DOWNLOAD_IMAGE.start, 
    payload: { wait: true, error: null, networkErr: false, download: '', addToHistory: false }, 
  });
  setTimeout(async () => {
    try {
      let dwnld = '';
      const jsonSize = getSizeInBytes(elements);
      const fileName = (saveOnly && registImgName) ? registImgName : 
        `gazo_${DateTime.now().toFormat('yyyyMMdd_HHmm_ss')}_000000_${config.imageKindCode[tagState.imageKind] || ''}_${count}.jpg`;

      if (jsonSize < config.jsonMaxSize || jsonSize === config.jsonMaxSize) {
        const res = await Axios.post(`${config.apiServer.baseUrl}/smallImage`, {
          companyId: tagState.companyId,
          tagState,
          file: designedImg.replace(/^.*,/, ''),
          fileName,
          partsInformations: elements,
        });
        if (res.status === 200) {
          if (!saveOnly) {
            setDownloadSmallImageEvent(fileName, tagState, allTags);
            const link = document.createElement('a');
            link.download = fileName;
            link.href = designedImg;
            link.click();
            dwnld = 'done';
          }
          dispatch({ 
            type: ACTION_TYPE.DOWNLOAD_IMAGE.success, 
            payload: { wait: false, download: dwnld, addToHistory: true } 
          });
        } else {
          dispatch({ 
            type: ACTION_TYPE.DOWNLOAD_IMAGE.fail, 
            payload: { wait: false, networkErr: true, download: '', addToHistory: false, error: { message: '小画像の保存に失敗しました' } } 
          });
        }
      } else {
        if (!saveOnly) {
          setDownloadSmallImageEvent(fileName, tagState, allTags);
          const link = document.createElement('a');
          link.download = fileName;
          link.href = designedImg;
          link.click();
          dwnld = 'done';
        }
        dispatch({ 
          type: ACTION_TYPE.DOWNLOAD_IMAGE.success, 
          payload: { wait: false, isJsonLarge: true, download: dwnld, addToHistory: false } 
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({ 
        type: ACTION_TYPE.DOWNLOAD_IMAGE.fail, 
        payload: { wait: false, networkErr: true, download: '', addToHistory: false  } 
      });
    }
  }, 500);
};

export const login = (loginInfo) => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.UPLOAD_IMAGE.start, 
    payload: { wait: true, loginError: null, companies: null, apiKey: '' }, 
  });

  await axios.post(`${config.apiServer.baitoruUrl}/v1/sessions`, {
    name: loginInfo.username,
    password: loginInfo.pass
  }, { timeout: 30000 }).then((res) => {
    if (res.status === 200) {
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.success,
        payload: { wait: false, loginError: null, companies: res.data.companies, apiKey: res.data.key }
      })
    }
  }).catch((e)=>{
    if (e.response && e.response.data) {
      const errMsg = e.response.data.message ? e.response.data.message : e.response.data.error;
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.fail,
        payload: { wait: false, loginError: errMsg, companies: null, apiKey: '' }
      });
    } else {
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.fail,
        payload: { wait: false, loginError: e.message, companies: null, apiKey: '' }
      });
    }
  });
};

export const searchCompany = (companyInfo, token, loginInfo) => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.SEARCH_COMPANY.start, 
    payload: { wait: true, compError: null, companies: '' }, 
  });

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  }
  await axios.post(`${config.apiServer.baitoruUrl}/v1i/companies`, {
    company_only: companyInfo.companyOnly ? '1' : '0',
    company_id: companyInfo.companyId,
    f_ptnr_name: companyInfo.partnerName,
    f_comp_name: companyInfo.companyName,
    keiyaku_kbn: companyInfo.contractType,
    status: companyInfo.companyStatus,
    password: loginInfo.pass
  }, { headers: headers, timeout: 30000 }).then((res) => {
    if (res.status === 200) {
      dispatch({
        type: ACTION_TYPE.SEARCH_COMPANY.success,
        payload: { wait: false, compError: null, companies: res.data.companies }
      })
    }
  }).catch((e) => {
    if (e.response && e.response.data) {
      const errMsg = e.response.data.message ? e.response.data.message : e.response.data.error;
      dispatch({
        type: ACTION_TYPE.SEARCH_COMPANY.fail,
        payload: { wait: false, compError: errMsg, companies: '' }
      });
    } else {
      dispatch({
        type: ACTION_TYPE.SEARCH_COMPANY.fail,
        payload: { wait: false, compError: e.message, companies: '' }
      });
    }
  });
};

export const uploadImage = (uploadParams) => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.UPLOAD_IMAGE.start, 
    payload: { wait: true, uploadError: null, uploadMsg: null, registImgName: null }, 
  });

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${uploadParams.token}`
  }

  await axios.post(`${config.apiServer.baitoruUrl}/v1i/images`, {
    company_id: uploadParams.companyId,
    filename: uploadParams.filename,
    type: uploadParams.type,
    memo: uploadParams.memo,
    image_data: uploadParams.designedImg.split('base64,')[1],
    password: uploadParams.password
  }, { headers: headers, timeout: 30000 }).then((res) => {
    if (res.status === 201) {
      setUploadSmallImageEvent(uploadParams.filename, uploadParams.tagState, uploadParams.allTags);
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.success,
        payload: { wait: false, uploadError: null, uploadMsg: res.data.message , registImgName: res.data.registered_filename }
      })
    }
  }).catch((e) => {
    if (e.response && e.response.data) {
      let errMsg = e.response.data.message ? e.response.data.message : e.response.data.error;
      errMsg = errMsg.replace(/(\r\n|\\n|\n|\r)/gm, '\n');
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.fail,
        payload: { wait: false, uploadError: errMsg, uploadMsg: null , registImgName: null }
      });
    } else {
      dispatch({
        type: ACTION_TYPE.UPLOAD_IMAGE.fail,
        payload: { wait: false, uploadError: e.message, uploadMsg: null , registImgName: null }
      });
    }
  })
};

export const updateImageCount = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_IMAGE_COUNT,
    payload: {
      imgCount: payload,
    },
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload: {
      download: '',
    },
  });
};

export const addedToHistory = () => (dispatch) => {
  dispatch({
    type: ADDED_TO_HISTORY,
    payload: {
      addToHistory: false,
    },
  });
};

export const largeJsonNotified = () => (dispatch) => {
  dispatch({
    type: LARGE_JSON_NOTIFIED,
    payload: {
      isJsonLarge: false,
    },
  });
};