import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH_FONT: ActionCreatorFactory('textTools', 'FETCH_FONT'),
  SAVE: SaveActionCreatorFactory('textTools', 'SAVE'),
};

export const fetchFont = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH_FONT.start,
    payload: {
      wait: true, isGetFont: false, error: null,
    },
  });

  Axios.get(`${config.apiServer.baseUrl}/font`).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH_FONT.success,
      payload: {
        wait: false, isGetFont: true, error: null,
      },
    });
    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        fonts: res.data.items,
        fontCount: res.data.totalCount,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH_FONT.fail,
      payload: {
        wait: false, isGetFont: false, error: { message: 'フォントの取得に失敗しました。' },
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};
