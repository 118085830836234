class DoublyScrollObserver extends React.Component {
  constructor(props) {
    super(props);
    const {
      initPageCounter,
      maxLength,
      rootQueryName,
      // observeTargetQueryName, // 監視対象のHTMLクエリ名 required
      limit,
      unitOfPage,
      aboveOrder,
      belowOrder,
    } = this.props;

    this.observeConfig = {
      pageCounter: initPageCounter || 0, // ページのカウンター
      maxLength, // 最後かどうか required
      unitOfPage: unitOfPage || 50, // 一ページでどれだけ取得するか
      limit: limit || 100, // 一回のリクエストで取得する数
      aboveOrder: aboveOrder || 20, // 上向きスクロールのトリガー位置
      belowOrder: belowOrder || 80, // 下向きスクロールのトリガー位置

    };

    // root UI部のHTML
    this.observeTarget = {
      root: {
        name: rootQueryName || window, // 監視する表示位置のHTMLクエリ名
      },
      above: {
        name: 'above',
      },
      below: {
        name: 'below',
      },
    };
  }

  componentDidMount() {
    const { callBack } = this.props;
    const { pageCounter, unitOfPage, limit } = this.observeConfig;
    callBack(pageCounter, unitOfPage, limit);
    this.observe(false);
  }

  componentDidUpdate() {
    const { isUpdate, observeTargetQueryName, offset } = this.props;

    const { above, below } = this.observeTarget;
    const { aboveOrder, belowOrder } = this.observeConfig;
    const queries = document.querySelectorAll(observeTargetQueryName);
    if (queries.length !== 0) {
      if (
        queries[aboveOrder] !== undefined
        && !queries[aboveOrder].classList.contains(above.name)) {
        if (document.querySelector(`.${above.name}`)) {
          document.querySelector(`.${above.name}`).classList.remove(above.name);
        }
        document.querySelectorAll(observeTargetQueryName)[aboveOrder].classList.add(above.name);
      }
      if (
        queries[belowOrder] !== undefined
        && !queries[belowOrder].classList.contains(below.name)
      ) {
        if (document.querySelector(`.${below.name}`)) {
          document.querySelector(`.${below.name}`).classList.remove(below.name);
        }
        document.querySelectorAll(observeTargetQueryName)[belowOrder].classList.add(below.name);
      }
    }
    if (offset === 0) { this.observeConfig.pageCounter = offset; }
    this.observe(isUpdate);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setCurrentPosY() {
    const { above, below } = this.observeTarget;
    if (above.query) {
      above.prevPosY = above.boundingClientRect.top;
    }
    if (below.query) {
      below.prevPosY = below.boundingClientRect.top;
    }
  }

  // observerの中身を定義し、監視を開始。
  updateObserver() {
    const { above, below, root } = this.observeTarget;
    root.query = document.querySelector(root.name);
    root.boundingClientRect = root.query.getBoundingClientRect();

    above.query = document.querySelector(`.${above.name}`);
    if (above.query) {
      above.boundingClientRect = above.query.getBoundingClientRect();
    }

    below.query = document.querySelector(`.${below.name}`);
    if (below.query) {
      below.boundingClientRect = below.query.getBoundingClientRect();
    }
    const observeTarget = { root, above, below };

    this.observeTarget = { ...this.observeTarget, ...observeTarget };
  }

  observe(isUpdate) {
    const { observeTargetQueryName } = this.props;
    const queries = document.querySelectorAll(observeTargetQueryName);

    if (queries === undefined || queries.length === 0) { return; }
    // 最大値の設定

    if (isUpdate) {
      this.updateObserver();
      this.setCurrentPosY();
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      this.whileObserving();
    }, 100);
  }

  whileObserving() {
    const { root, above, below } = this.observeTarget;
    this.updateObserver();
    if (below.query && root.boundingClientRect.bottom > below.boundingClientRect.top) {
      // 監視対象のbottomがbelowの上部よりも下にいる時
      this.nextOrPrev(below.name);
    } else if (above.query && root.boundingClientRect.top < above.boundingClientRect.bottom) {
      // 監視対象のtopがaboveの下部よりも上にいる時
      this.nextOrPrev(above.name);
    }
    this.setCurrentPosY();
  }

  nextOrPrev(className) {
    const { above, below } = this.observeTarget;
    const { limit, unitOfPage } = this.observeConfig;
    const { callBack } = this.props;

    let processFlg = false;
    if (className === above.name) {
      processFlg = this.checkScrollToAbove();
    } else if (className === below.name) {
      processFlg = this.checkScrollToBellow();
    }

    if (processFlg) {
      callBack(this.observeConfig.pageCounter, unitOfPage, limit);
    }
  }

  checkScrollToAbove() {
    const { above, root } = this.observeTarget;
    const { pageCounter } = this.observeConfig;
    const { observeTargetQueryName } = this.props;
    const firstChild = document.querySelector(`${observeTargetQueryName}:first-child`);
    // 下から上へスクロールしている時 && pageCounterが0かどうか
    if (pageCounter > 0 && above.prevPosY < above.boundingClientRect.top) {
      this.observeConfig.pageCounter -= 1;
      return true;
    } else if( pageCounter > 0 && root.boundingClientRect.top < firstChild.getBoundingClientRect().bottom) {
      this.observeConfig.pageCounter -= 1;
      return true;
    }
    return false;
  }

  checkScrollToBellow() {
    const { below, root } = this.observeTarget;
    const { pageCounter, limit, unitOfPage } = this.observeConfig;
    const { maxLength, observeTargetQueryName } = this.props;
    const lastChild = document.querySelector(`${observeTargetQueryName}:last-child`);
    // 上から下へスクロールしている時 && 画像があるかどうか
    const isMax = ((unitOfPage * pageCounter) + limit) >= maxLength;
    if (!isMax && below.prevPosY > below.boundingClientRect.top ) {
      this.observeConfig.pageCounter += 1;
      return true;
    } else if(!isMax && root.boundingClientRect.bottom > lastChild.getBoundingClientRect().top) {
      this.observeConfig.pageCounter += 1;
      return true;
    }
    return false;
  }

  render() {
    const { children, className } = this.props;
    return (
      <div className={className}>
        {children}
      </div>
    );
  }
}

export default DoublyScrollObserver;
