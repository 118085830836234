import { ACTION_TYPE } from '@pages/editor/workArea/smallImages/SmallImagesAction';

const smallImagesReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.fail:
      return Object.assign({}, state, action.payload);
      
    default:
      return state;
  }
};

export default smallImagesReducer;