/* eslint-disable react/no-did-update-set-state */
import {
  Slider, Input, InputAdornment, Typography,
} from '@material-ui/core';

import CustomPaper from '@components/utils/customPaper/CustomPaper';

import styles from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialog.module.scss';

const TransparencyDialog = (props) => {
  const {
    className, element, receiveElement,
  } = props;

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  const sliderConfig = {
    defaultValue: 100,
    step: 0.25,
    min: 0,
    max: 100,
  };

  const changeTransparencySlider = () => (e, newValue) => {
    const targetValue = newValue || 0;
    const changeValue = targetValue / 100;
    const updatedElement = { ...element, transparency: changeValue };

    receiveElement({ ...updatedElement });
  };

  const changeTransparencyInputValue = () => (e) => {
    let { value } = e.target;
    if (/\d+/.test(value)) {
      value = parseInt(value, 10) / 100;
    }
    const updatedElement = { ...element, transparency: value };

    receiveElement({ ...updatedElement });
  };
  return (
    <>
      <CustomPaper className={`${className} ${styles.transparencyPaper}`} right>
        <Typography variant="subtitle1" color="textSecondary">
          不透明度
        </Typography>
        <div className={`${styles.transparencyDialog} transparencyAdjust`}>
          <Input
            value={element.transparency * 100}
            onChange={changeTransparencyInputValue()}
            type="number"
            className={styles.inputNumber}
            endAdornment={
              <InputAdornment position="end">%</InputAdornment>
          }
            inputProps={{
              'aria-label': 'opacity',
            }}
          />
          <Slider
            defaultValue={sliderConfig.defaultValue}
            step={sliderConfig.step}
            min={sliderConfig.min}
            max={sliderConfig.max}
            marks={marks}
            onChange={changeTransparencySlider()}
            value={element.transparency * 100}
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={(value) => (`${value}%`)}
            valueLabelDisplay="auto"
            className={styles.sliders}
          />
        </div>
      </CustomPaper>
    </>
  );
};

export default TransparencyDialog;
