import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import config from '../../../assets/config';

export default class AuthComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: (localStorage.getItem(config.accessTokenKey)),
    };
  }

  render() {
    const { check, children } = this.props;
    const { isAuthenticated } = this.state;
    if (!this.state || !isAuthenticated) {
      return <Redirect to="/login" />;
    }
    if (!sessionStorage.getItem('user_id')) {
      check();
    }
    return (
      <Route>{children}</Route>
    );
  }
}
