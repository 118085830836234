import { connect } from 'react-redux';
import ShapeTools from '@pages/editor/workArea/designTools/shapeTools/ShapeTools';
import * as Action from '@pages/editor/workArea/designTools/textTools/TextToolsAction';
import { changeDrawer, changeColor } from '@pages/editor/designDrawer/DesignDrawerAction';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  drawerSettings: state.ui.drawer,
  ...state.materials.shapeTools,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  actions.changeDrawer = (...args) => dispatch(changeDrawer(...args));
  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  actions.changeColor = (...args) => dispatch(changeColor(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ShapeTools);