// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.TrimmingModal-module__root--2cqrO{margin:4;align-items:center}.TrimmingModal-module__AppBody--2Lq_G{display:flex;text-align:center;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin)}.TrimmingModal-module__contentWrapper--2GQBu{display:flex;flex-direction:column;justify-content:center;align-items:center;width:700px}.TrimmingModal-module__headText--1zlx2{color:black;font-weight:bold}.TrimmingModal-module__imageWizard--3BGtQ{margin:20px 10% 0;position:relative;background-color:burlywood}.TrimmingModal-module__imageBody--2JsFW{width:400px;height:300px;margin:auto;margin-top:10px;padding-top:10px}.TrimmingModal-module__imageBody--2JsFW .TrimmingModal-module__cropTarget--1t8Yu{width:400px;height:300px}.TrimmingModal-module__imageElement--QVOjA{border:4px solid black}.TrimmingModal-module__BtnWizard--2RqYE{width:70%;height:120px;display:flex;align-items:flex-end;justify-content:space-between;margin-top:3%}.TrimmingModal-module__croppedImage--1ZbVR{height:120px;width:160px;object-fit:contain;object-position:center;background-color:#AAA}.TrimmingModal-module__leftBtn--3x3JG{margin:0 10px;display:inline-block}.TrimmingModal-module__rightBtn--32L37{display:inline-block}.TrimmingModal-module__actionButtonText--10AMO{font-size:1.2rem}.TrimmingModal-module__buttonText--1RrsQ{font-size:1.2rem}\n", ""]);
// Exports
exports.locals = {
	"root": "TrimmingModal-module__root--2cqrO",
	"AppBody": "TrimmingModal-module__AppBody--2Lq_G",
	"contentWrapper": "TrimmingModal-module__contentWrapper--2GQBu",
	"headText": "TrimmingModal-module__headText--1zlx2",
	"imageWizard": "TrimmingModal-module__imageWizard--3BGtQ",
	"imageBody": "TrimmingModal-module__imageBody--2JsFW",
	"cropTarget": "TrimmingModal-module__cropTarget--1t8Yu",
	"imageElement": "TrimmingModal-module__imageElement--QVOjA",
	"BtnWizard": "TrimmingModal-module__BtnWizard--2RqYE",
	"croppedImage": "TrimmingModal-module__croppedImage--1ZbVR",
	"leftBtn": "TrimmingModal-module__leftBtn--3x3JG",
	"rightBtn": "TrimmingModal-module__rightBtn--32L37",
	"actionButtonText": "TrimmingModal-module__actionButtonText--10AMO",
	"buttonText": "TrimmingModal-module__buttonText--1RrsQ"
};
module.exports = exports;
