function SvgFontOuterShadow(props, svgRef) {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#FFF"
      ref={svgRef}
    >
      <path fill={props.shadowColor} d="M2 2.64h13v11.135H2z" />
      <path fill={props.backgroundColor} stroke={props.color} d="M.5 1.427h12v10.135H.5z" />
      <path
        d="M7.98 8.444H5.003L4.38 10H3l2.899-7h1.194L10 10H8.61l-.63-1.556zm-2.593-.98h2.226L6.504 4.486 5.387 7.464z"
        fill={props.color}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFontOuterShadow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
