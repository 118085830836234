// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Text-module__textBefore--2eIQ0{-webkit-background-clip:text !important}.Text-module__arcText--Kgwg_{background:inherit}.Text-module__arcText--Kgwg_.Text-module__textBefore--2eIQ0{background:inherit}.Text-module__arcText--Kgwg_.Text-module__textBefore--2eIQ0 div{background:inherit}.Text-module__arcText--Kgwg_.Text-module__textBefore--2eIQ0 div span{background:inherit;-webkit-background-clip:text !important}.Text-module__textArea--1wO0C{resize:none}\n", ""]);
// Exports
exports.locals = {
	"textBefore": "Text-module__textBefore--2eIQ0",
	"arcText": "Text-module__arcText--Kgwg_",
	"textArea": "Text-module__textArea--1wO0C"
};
module.exports = exports;
