import React from 'react';
import { toastr } from 'react-redux-toastr';
import {
  Grid,
  Chip,
  Step,
  Box,
  Button,
  Select,
  Stepper,
  Checkbox,
  TextField,
  InputLabel,
  StepButton,
  IconButton,
  FormControl,
  ButtonGroup,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import {
  Close,
  Palette,
  PhotoCamera,
  InsertPhoto,
  PhotoLibrary,
  FontDownload,
  HighlightOff,
  Wallpaper,
  CropFree,
} from '@material-ui/icons';
import config from '@assets/config';
import Dropzone from 'react-dropzone';
import path from 'path';

import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';
import { calcPosMiddle } from '@common/utils/EditUtils';
import { getTextRect, templateToTextElement } from '@common/utils/TextOperation';
import { Stack } from '@common/utils/Stack';
import '@common/scss/globalImports/Imports.scss';

import RecommendImages from './components/RecommendImagesComponent';
import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';
import styles from './WizardModal.module.scss';
import './WizardModal.scss';

const SELECTED_ASSET_IMAGE_KEY = 1;

class WizardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: 0,
      activeStep: 1,
      uploadImage: 1,
      buttonActive: 0,
      selectedText: '',
      selectedTextId: null,
      activeGroupId: '',
      selectedData: Array(6).fill(''),
      completed: new Set(),
      closeWizardClicked: false,
      companyIdError: false
    };
    this.tagConfig = [
      { id: 'professional', name: '職種カテゴリ' },
      { id: 'targetAttr', name: 'ターゲット属性' },
      { id: 'imageKind', name: '画像種別' },
      { id: 'imageImagine', name: '画像イメージ' },
      { id: 'feature', name: '特徴' },
    ];
    this.props.init();
    // this.props.getSmallImage();
    this.props.getAssetImage();
    this.props.getWallPaperImage({});
    this.props.getFrameImage({});
    this.props.getGroupTextIds();
    this.getRecommendTexts({});
    this.props.getFontDesignPattern();

    this.defaultTextDecorate = {
      text: '未経験OK' ,fontWeight: 900, fontSize: 80, fontFamily: 'UDShinGoPr6N', color: '#000',
    };
  }

  componentDidUpdate() {
    const { uploadImages, selectedUploadImages} = this.props;
    if(
      uploadImages
      && selectedUploadImages
      && selectedUploadImages.stack.length > 0
      && selectedUploadImages.stack.filter(Boolean).length === uploadImages.filter(Boolean).length
    ) {
      this.addElements()
    }
  }

  onChangeTag(id, value) {
    const updatedTag = { ...this.props.tagState };
    const { update } = this.props;
    this.setState({ companyIdError: false })
    if(id === 'companyId' && !value.match(/^[0-9０-９]*$/)) {
      this.setState({ companyIdError: true })
      return;
    }

    if(id === 'companyId') {
      updatedTag[id] = this.toHalfWidth(value)
    } else {
      updatedTag[id] = value;
    }

    this.props.changeTag(updatedTag);
    if (id !== 'companyId') {
      this.props.getSmallImage(updatedTag);
    }

    if(['professional', 'targetAttr', 'imageKind'].includes(id)) {
      this.props.getAssetImage(updatedTag);
    }

    if(['imageImagine'].includes(id)) {
      this.props.getWallPaperImage({tags: {...updatedTag}, color: this.props.wallPaperImageColor});
      this.props.getFrameImage({tags: {...updatedTag}, color: this.props.frameImageColor});
    }

    if(['professional', 'targetAttr', 'feature'].includes(id)) {
      const { textGroupId } = this.props;
      this.getRecommendTexts({tags: updatedTag, groupId: textGroupId});
    }

    if(['professional'].includes(id)) {
      this.props.getFontDesignPattern(updatedTag);
    }
    update(updatedTag);
  }

  onChangeCloseWizardClicked(e) {
    const { closeWizardClicked } = this.state;
    this.setState({
      closeWizardClicked: !closeWizardClicked,
    })
  }

  prevStep(step) {
    return this.setState({
      activeStep: step - 1,
    });
  }

  nextStep(step) {
    return this.setState({
      activeStep: step + 1,
    });
  }

  getSteps() {
    return ['参考デザイン', '素材画像', '背景画像', 'フレーム画像', '文言', 'フォントパターン'];
  }

  getRecommendTexts({tags={} ,offset=0, groupId=null}) {
    const { getRecommendedTexts, getGroupText } = this.props;
    if(!groupId) {
      getRecommendedTexts({tags, offset});
    } else {
      getGroupText(textGroupId, offset);
    }
  }

  getMoreTexts({tags={}, index=0, groupId=null, offset}){
    const { textGroupId, getGroupText } = this.props;
    if(index === 0){
      this.getRecommendTexts({tags, offset, groupId: textGroupId});
    } else {
      getGroupText(groupId, offset);
    }
  }

  handleTextGroup(grpId, index) {
    this.setState({ 
      buttonActive: index,
      activeGroupId: grpId
    });
    if(grpId === 0){
      this.getRecommendTexts({tags: this.props.tagState});
    } else {
      this.props.getGroupText(grpId);
    }
  }

  saveSelected() {
    const { selectedData, uploadImage, closeWizardClicked } = this.state;
    if(selectedData[SELECTED_ASSET_IMAGE_KEY] && uploadImage) {
      // uploadImageがある時は、先にuploadImageをuploadする
      this.uploadImages();
    } else {
      this.addElements();
    }

    if(closeWizardClicked) {this.neverOpenAllowWizard();}
  }

  changeUploadImage() {
    const { selectedData, completed } = this.state;
    const { selectedItems,  changeSelectedItems, selectedUploadImages} = this.props;
    const updatedSelectedData = [...selectedData];
    updatedSelectedData[SELECTED_ASSET_IMAGE_KEY] = selectedUploadImages
    selectedItems[SELECTED_ASSET_IMAGE_KEY] = selectedUploadImages

    if(selectedUploadImages.length > 0) {
      completed.add(SELECTED_ASSET_IMAGE_KEY + 1);
    } else {
      completed.delete(SELECTED_ASSET_IMAGE_KEY + 1)
    }

    changeSelectedItems(selectedItems)
    return this.setState({
      uploadImage: 1,
      selectedData: updatedSelectedData,
      completed,
    });
  }

  changeChooseImage() {
    const { selectedData, completed } = this.state;
    const { selectedItems,  changeSelectedItems} = this.props;
    const updatedSelectedData = [...selectedData];
    updatedSelectedData[SELECTED_ASSET_IMAGE_KEY] = null
    selectedItems[SELECTED_ASSET_IMAGE_KEY] = null
    completed.delete(SELECTED_ASSET_IMAGE_KEY + 1)
    changeSelectedItems(selectedItems)
    return this.setState({
      uploadImage: 0,
      selectedData: updatedSelectedData,
      completed,
    });
  }

  neverOpenAllowWizard() {
    const {changeAllowWizard, user} = this.props;
    changeAllowWizard({id: user.id, allowWizard: false});
  }

  uploadImages() {
    const { selectedData } = this.state;
    const { uploadFile } = this.props;
    selectedData[SELECTED_ASSET_IMAGE_KEY].stack.filter(Boolean).forEach((file, key) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          if (file) {
            uploadFile(file, key);
          }
        };
        image.src = reader.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  }

  toHalfWidth(value) {
    return value.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
  }

  // ---------------------------------
  // キャンパスへ追加する関数群

  async addElements() {
    const { toggleImageModal, uploadImages, selectedItems, addElement, plusSmallImageClickCount, plusTextClickCount } = this.props;
    const { selectedTextId } = this.state;
    let textElement = null;
    for(const key in selectedItems){
      const numKey = parseInt(key, 10)

      if(numKey >= SELECTED_ASSET_IMAGE_KEY && numKey <= 3 && selectedItems[key]) {
        const types = ['AssetImage', 'WallPaperImage', 'FrameImage'];
        if(uploadImages && numKey === SELECTED_ASSET_IMAGE_KEY && uploadImages.length > 0) {
          for(const uploadImageUrl of uploadImages) {
            const imgElement = await this.genImageElement({url: uploadImageUrl, type: 'UploadImage'});
            addElement(imgElement);
          }
        }else if(numKey === SELECTED_ASSET_IMAGE_KEY) {
          for(const assetImage of selectedItems[numKey]) {
            const imgElement = await this.genImageElement({url: assetImage.url, type: types[numKey - 1]});
            addElement(imgElement);
          }
        }else {
          const imgElement = await this.genImageElement({url: selectedItems[numKey].url, type: types[numKey - 1]});
          addElement(imgElement);
        }
      } else if(numKey === 4 && selectedItems[numKey]){
        textElement = this.genTextElement(selectedItems[numKey]);
        plusTextClickCount(selectedItems[numKey]);
      } else if(numKey === 5 ) {
        if(selectedItems[numKey]) {
          const { template ,decorationName, decorationId } = selectedItems[numKey]
          this.addFontDesignPatternElement(template, decorationName, decorationId, textElement);
        } else if(textElement){
          addElement(textElement);
        }
      } else if(numKey === 0 && selectedItems[numKey]) {
        toggleImageModal(true, selectedItems[numKey].url);
        plusSmallImageClickCount(selectedItems[numKey].id);
        gtag('event', 'wizard_recommended_select_file_name', {'wizard_recommended_select_file_name': path.basename(selectedItems[numKey].url)});
      }
    }
    this.props.onClose();
  }

  genImageElement({ url, type}) {
    const { addElement } = this.props;
    return new Promise((resolve, reject) => {
      getImageDimensions(url).then(
        (image) => {
          const resizedImageDimensions = resizeImage({
            width: image.naturalWidth,
            height: image.naturalHeight,
          });
          const [posX, posY] = calcPosMiddle({
            width: resizedImageDimensions.width,
            height: resizedImageDimensions.height,
          });
          const element = {
            elementableType: type,
            transparency: 1,
            zIndex: 0,
            posX,
            posY,
            elementableAttributes: {
              url,
              width: resizedImageDimensions.width,
              height: resizedImageDimensions.height,
              naturalWidth: image.naturalWidth,
              naturalHeight: image.naturalHeight,
            },
          };
          resolve(element);
        },
      )
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  genTextElement(text) {
    const rect = getTextRect({text, font: `${this.defaultTextDecorate.fontWeight} ${this.defaultTextDecorate.fontSize}px ${this.defaultTextDecorate.fontFamily}`, height: this.defaultTextDecorate.fontSize * 1.2});
    return {
      elementableType: 'Text',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      elementableAttributes: {
        color: '#000000',
        text,
        fontFamily: this.defaultTextDecorate.fontFamily,
        fontWeight: this.defaultTextDecorate.fontWeight,
        fontSize: this.defaultTextDecorate.fontSize,
        height: rect.height,
        width: rect.width + 10,
      },
    };
  } 

  addFontDesignPatternElement(template, designPatternName, designPatternId, textElement) {
    const { addElement } = this.props;
    const fontDesignPatternElement = templateToTextElement(template, this.defaultTextDecorate);
    if(fontDesignPatternElement.gradients && fontDesignPatternElement.gradients.configs && Object.keys(fontDesignPatternElement.gradients.configs).length > 0) {
      fontDesignPatternElement.colorType = 'gradient';
    }
    let width, text;
    if(textElement) {
      const textElementAttrs = textElement.elementableAttributes;
      width = textElementAttrs.width;
      text = textElementAttrs.text;
    } else {
      width = getTextRect({text: this.defaultTextDecorate.text, font: `${this.defaultTextDecorate.fontWeight} ${this.defaultTextDecorate.fontSize}px ${this.defaultTextDecorate.fontFamily}`});
      text = this.defaultTextDecorate.text;
    }
    const element = {
      elementableType: 'Text',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      designPatternName,
      designPatternId,
      elementableAttributes: {
        ...this.defaultTextDecorate, 
        ...fontDesignPatternElement, 
        fontFamily: fontDesignPatternElement.font,
        fontWeight: fontDesignPatternElement.weight,
        width,
        text,
      },
    };
    addElement(element);
  }

  // ---------------------------------

  selectItem(element) {
    const selectedData = [...this.state.selectedData];
    const { completed, activeStep } = this.state;
    switch (activeStep) {
      case 1:
        selectedData[0] = element;
        break;
      case 2:
        selectedData[1] = element;
        break;
      case 3:
        selectedData[2] = element;
        break;
      case 4:
        selectedData[3] = element;
        break;
      case 5:
        selectedData[4] = element;
        break;
      case 6:
        selectedData[5] = element;
        break;
      default:
        break;
    }
    completed.add(activeStep);
    this.setState({
      selectedData,
      completed,
    });
    const newSelectedItems = [...this.props.selectedItems];
    if (activeStep === 2) {
      // 複数選択可能
      newSelectedItems[activeStep - 1] = newSelectedItems[activeStep - 1] || [];
      if (Array.isArray(newSelectedItems[activeStep - 1]) && newSelectedItems[activeStep - 1].indexOf(element) < 0) {
        newSelectedItems[activeStep - 1].push(element);
      } else {
        newSelectedItems[activeStep - 1] = element;
      }
    } else {
      newSelectedItems[activeStep - 1] = element;
    }
    this.props.changeSelectedItems(newSelectedItems);
  }

  deleteItem(idx) {
    const selectedData = [...this.state.selectedData];
    const { selectedItems,  changeSelectedItems} = this.props;
    const { completed, uploadImage } = this.state;
    selectedData[idx] = '';
    selectedItems[idx] = '';
    completed.delete(idx + 1);

    const updatedState = {
      selectedData,
      completed,
    };
    if(idx === 1 && uploadImage) {
      const { changeSelectedUploadImages } = this.props
      changeSelectedUploadImages(new Stack(5));
    }
    changeSelectedItems({...selectedItems})
    this.setState({...updatedState});
  }

  deleteUploadImage(idx) {
    const { selectedUploadImages, changeSelectedUploadImages } = this.props

    const updatedSelectedUploadImages = selectedUploadImages
    updatedSelectedUploadImages.stack.splice(idx, 1);
    if(updatedSelectedUploadImages.stack > 0) {
      this.selectItem(updatedSelectedUploadImages);
    } else {
      this.deleteItem(SELECTED_ASSET_IMAGE_KEY);
    }

    changeSelectedUploadImages(updatedSelectedUploadImages);
  }

  changeSelectUploadImage(uploadImages) {
    const { selectedUploadImages, changeSelectedUploadImages } = this.props
    const updatedSelectedUploadImages = selectedUploadImages;

    uploadImages.forEach((file) => {
      const updatedFile = Object.assign(file, { preview: URL.createObjectURL(file) });
      updatedSelectedUploadImages.push(updatedFile);
    });
    this.selectItem(updatedSelectedUploadImages)
    changeSelectedUploadImages(updatedSelectedUploadImages);
  }


  onLoadUploadImage(idx) {
    return (e) => {
      const imageRatio = e.target.height / e.target.naturalHeight;
      const width = Math.floor(e.target.naturalWidth * imageRatio);
      const { selectedUploadImages, changeSelectedUploadImages } = this.props
      const MAX_WIDTH = 184
      if(width > MAX_WIDTH) {
        selectedUploadImages.stack[idx].width = MAX_WIDTH;
        const imageRatioFromWidth =  MAX_WIDTH / e.target.naturalWidth;
        const height = Math.floor(e.target.naturalHeight * imageRatioFromWidth);
        selectedUploadImages.stack[idx].height = height;
        selectedUploadImages.stack[idx].width = MAX_WIDTH;
      } else {
        selectedUploadImages.stack[idx].width = width;
        selectedUploadImages.stack[idx].height = e.target.height;
      }
      this.selectItem(selectedUploadImages)
      changeSelectedUploadImages(selectedUploadImages);
    }
  }

  onClickColor(targetColor, type) {
    return () => {
      switch (type) {
        case 'frameImage':
          this.frameImageTargetColor = targetColor;
          this.props.getFrameImage({tags: {...this.props.tagState}, color: targetColor});
          break;
      
        case 'wallPaperImage':
          this.wallPaperImageTargetColor = targetColor;
          this.props.getWallPaperImage({tags: {...this.props.tagState}, color: targetColor});
          break;
        default:
          break;
      }
    };
  }

  render() {
    const {
      completed,
      activeStep,
      uploadImage,
      selectedData,
      buttonActive,
      selectedText,
      activeGroupId,
      closeWizardClicked,
    } = this.state;
    const { tagInfo, tagState, error, color, textGroupIds, selectedUploadImages} = this.props;
    const steps = this.getSteps();

    return (
      <div className={styles.AppBody}>
        {error && toastr.error('error', error)}
        <div className={styles.containerWizard}>
          {/* header & selected item */}
          <Close
            style={{
              top: 0, right: 0, position: 'absolute', fontSize: 30, color: '#ccc',
            }}
            onClick={() => {
              if(this.state.closeWizardClicked) {this.neverOpenAllowWizard();}
              this.props.onClose()
            }}
          />
          { selectedData.length > 0
            ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <h3 className={styles.wizardTitle}>GAZO Wizard</h3>
                </Grid>
                <Grid container item xs={12} sm={8} direction="row" justify="flex-end" alignItems="center">
                  { selectedData.map((value, key) => (
                    (() => {
                      if(value && key === 4) {
                        return (
                          <div className={styles.selectedChipItem}>
                            <Chip label={value} className={styles.selectedListChip} />
                              <div className={styles.middle}>
                                <div className={styles.delBtn} onClick={() => { this.deleteItem(key) }}><HighlightOff style={{ fontSize: 32 }} /></div>
                              </div>
                          </div>
                        )
                      } else if(value && key === 5) {
                        const { 
                          template,
                          defaultDecorate,
                          decorateName,
                          decorateId,
                          templateStyle,
                          parentTemplateStyle
                        } = value;
                        return (
                          <div className={styles.selectedChipItem}>
                            {
                            (template.parent) 
                            ? <ParseStyleText
                                className={`${styles.item} ${decorateName}`}
                                content={defaultDecorate.text}
                                style={parentTemplateStyle}
                              >
                                <ParseStyleText
                                  style={templateStyle}
                                  content={defaultDecorate.text}
                                >
                                  {defaultDecorate.text}
                                </ParseStyleText>
                              </ParseStyleText>
                            : <ParseStyleText
                                className={`${styles.item} ${decorateName}`}
                                style={templateStyle}
                                content={defaultDecorate.text}
                              >
                                {defaultDecorate.text}
                              </ParseStyleText>
                            }
                            <div className={styles.middle}>
                              <div className={styles.delBtn} onClick={() => { this.deleteItem(key) }}><HighlightOff style={{ fontSize: 32 }} /></div>
                            </div>
                          </div>
                        )
                      } else if (value && key === 1 && uploadImage) {
                        if(value.stack.length === 0) {
                          return (
                            <div className={styles.unSelectedItems}>
                              未選択
                            </div>
                          );
                        }
                        return (
                          <div className={styles.selectedItems}>
                            <div className={styles.strItem}>
                              <span>アップロード</span>
                              <span>{value.stack.filter(Boolean).length}/5 枚</span>
                            </div>
                            <div className={styles.middle}>
                              <div className={styles.delBtn} onClick={() => { this.deleteItem(key) }}><HighlightOff style={{ fontSize: 32 }} /></div>
                            </div>
                          </div>
                          )
                      } else if(value) {
                        return (
                          <div className={styles.selectedItems}>
                            <img src={value.thumbnail_url} className={styles.image} />
                            <div className={styles.middle}>
                              <div className={styles.delBtn} onClick={() => { this.deleteItem(key) }}><HighlightOff style={{ fontSize: 32 }} /></div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                        <div className={styles.unSelectedItems}>
                          未選択
                        </div>)
                      }
                  }
                  )()
                  ))}
                </Grid>
              </Grid>
            )
            : <h2 className={styles.wizardTitle}>GAZO Wizard</h2>}
          {/* header options */}
          <header className={styles.appHeader}>
            <Grid container spacing={1}>
              <Grid container item xs={12} direction="row" justify="space-between" alignItems="center" style={{backgroundColor: '#ccc'}}>
                <span className={styles.formTitle}>原稿情報の設定</span>
                {/* 企業ID */}
                <form noValidate autoComplete="off" className={`${styles.selectForm} muiSelectLabel`}>
                  <TextField 
                    error={this.state.companyIdError ? true : false}
                    helperText={this.state.companyIdError? "半角数字のみ入力が可能です" : ''}
                    id="companyId" 
                    label="企業ID" 
                    value={tagState['companyId']} 
                    variant="outlined"
                    onChange={e => this.onChangeTag('companyId', e.target.value)}
                  />
                  {
                  this.tagConfig.map((tag) => {
                    return (
                    <FormControl variant="outlined" className="muiSelectLabel">
                      <InputLabel id={`${tag.id}-label`}>{tag.name === '画像種別' ? '画像カテゴリ' : tag.name }</InputLabel>
                      <Select
                        labelId={`${tag.id}-label`}
                        id={tag.id}
                        className={styles.selectOutlined}
                        value={tagState[tag.id]}
                        onChange={e => this.onChangeTag(tag.id, e.target.value)}
                        MenuProps={{
                          getContentAnchorEl: () => null,
                        }}
                      >
                        <MenuItem value="">未選択</MenuItem>
                        {
                          (tagInfo && tagInfo[tag.name] !== undefined)
                            ? tagInfo[tag.name].map((node) => (
                              <MenuItem value={node.id}>{node.name}</MenuItem>
                            ))
                            : <MenuItem />
                        }
                      </Select>
                    </FormControl>
                    )
                  })
                  }
                </form>
              </Grid>
            </Grid>
          </header>
          <div className={`${styles.stepperDiv} muiStepperLabel`}>
            <Stepper activeStep={activeStep - 1} nonLinear alternativeLabel>
              {steps.map((label, index) => {
                const stepProps = {};
                if (!completed.has(index + 1)) {
                  stepProps.completed = false;
                } else if (completed.has(index + 1)) {
                  stepProps.completed = true;
                }
                return (
                  <Step {...stepProps}>
                    <StepButton
                      onClick={() => this.nextStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          {/* wizard components */}
          { activeStep === 1
					&& (
<div className={styles.imgBana}>
  <div className={styles.main_box}>
    <h3>
      <InsertPhoto className={styles.iconTitle} style={{ fontSize: 28 }} />
      参考デザイン
    </h3>
  </div>
  {(this.props.smallImageLoading　|| this.props.smallImageRecommend) 
  ? <RecommendImages
      loading={this.props.smallImageLoading}
      recommend={this.props.smallImageRecommend}
      selectItem={(e) => { this.selectItem(e); }}
      onLoad={(offset) => { this.props.getSmallImage(this.props.tagState, offset); }}
    />
  :
    <article className={styles.note}>
      原稿情報を設定後に小画像が表示されます。
    </article>
  }
</div>
					)}
          { activeStep === 2 && uploadImage === 0
          && (
            <div className={styles.imgBana}>
              <div className={styles.main_box}>
                <div className={styles.left_box}>
                  <h3>
                    <PhotoLibrary className={styles.iconTitle} style={{ fontSize: 28 }} />
                    素材画像
                  </h3>
                  <div className={styles.sozai}>
                    <h4>アセット画像から選択する。</h4>
                  </div>
                </div>
                <div className={styles.right_box}>
                  <Button className={styles.step2_button} variant="outlined" onClick={() => this.changeUploadImage()}>
                    <h4 className={styles.step2_text}>
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                      手持ちの画像やイラストをアップする。
                    </h4>
                  </Button>
                </div>
              </div>
              {/* <div className={styles.imgRow2}> */}
                <RecommendImages
                  loading={this.props.assetImageLoading}
                  recommend={this.props.assetImage}
                  selectItem={(e) => { this.selectItem(e); }}
                  onLoad={(offset) => { this.props.getAssetImage(this.props.tagState, offset); }}
                />
              </div>
            // </div>
          )}
          { activeStep === 2 && uploadImage === 1
					  && (
<div className={styles.imgBana}>
  <div className={styles.main_box}>
    <div className={styles.left_box}>
      <h3>
        <PhotoLibrary className={styles.iconTitle} style={{ fontSize: 28 }} />
        素材画像
      </h3>
      <div className={styles.sozai}>
        <h4>手持ちの画像やイラストをアップする。（5枚まで）</h4>
      </div>
    </div>
    <div className={styles.right_box}>
      <Button className={styles.step2_button} variant="outlined" onClick={() => this.changeChooseImage()} startIcon={<PhotoLibrary className={styles.iconTitle} />}>
        <h4 className={styles.step2_text}>
          アセット画像から選択する。
        </h4>
      </Button>
    </div>
  </div>
  {/* <div className={styles.imgRow2}> */}
    <Dropzone accept="image/*" onDrop={(acceptedFiles) => this.changeSelectUploadImage(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className={styles.uploadDescription}>
            画像をドラッグして、ボックス内に入れてください
          </div>
          <Box component="span" className={styles.uploadBox} {...getRootProps()}>
            <input {...getInputProps()} />
            {selectedUploadImages.stack.length > 0 && selectedUploadImages.stack.map((file, key) => (
              <div className={`${styles.uploadImages} ${styles.selectedItems}`}>
                <img
                  alt="Preview"
                  key={`uploadImage-${key}`}
                  src={file.preview}
                  className={styles.uploadedImage}
                  onLoad={this.onLoadUploadImage(key)}
                  style={(file.width) ? {width: file.width, height: file.height} : {}}
                  onClick={(e) => {e.stopPropagation();}}
                />
                <div className={styles.middle}>
                  <div className={styles.delBtn} onClick={(e) => { this.deleteUploadImage(key); e.stopPropagation();}}><HighlightOff style={{ fontSize: 32 }} /></div>
                </div>
              </div>
            ))}
          </Box>
        </section>
      )}
    </Dropzone>
  </div>
// </div>
					  )}
          { activeStep === 3
					&& (
<div className={styles.imgBana}>
  <div className={`${styles.colorAndSubjectWrapper} ${styles.main_box}`}>
    <h3>
      <Wallpaper className={styles.iconTitle} style={{ fontSize: 28 }} />
      背景画像
    </h3>
    <div>
      <Grid container direction="row" alignment="left" spacing={2}>
        <div className={styles.colors}>
          {Object.keys(config.wall_paper_colors.selectable_colors).map((colorKey) => {
            let className = styles.colorDiv;
            if (colorKey === this.wallPaperImageTargetColor) {
              className += ` ${styles.active}`;
            }
            return (
              <div
                className={className}
                style={{ backgroundColor: config.wall_paper_colors.selectable_colors[colorKey] }}
                key={colorKey}
                onClick={this.onClickColor(colorKey, 'wallPaperImage')}
              />
            );
          })}
        </div>
      </Grid>
    </div>
  </div>
    <RecommendImages
      loading={this.props.wallPaperImageLoading}
      recommend={this.props.wallPaperImage}
      selectItem={(e) => { this.selectItem(e); }}
      onLoad={(offset) => { this.props.getWallPaperImage({tags: {...this.props.tagState}, offset, color: this.props.wallPaperImageColor}); }}
    />
  </div>
					)}
          { activeStep === 4
					&& (
<div className={styles.imgBana}>
  <div className={`${styles.colorAndSubjectWrapper} ${styles.main_box}`}>
    <h3>
      <CropFree className={styles.iconTitle} style={{ fontSize: 28 }} />
      フレーム画像
    </h3>
    <div>
      <Grid container direction="row" alignment="left" spacing={2}>
        <div className={styles.colors}>
          {Object.keys(config.wall_paper_colors.selectable_colors).map((colorKey) => {
            let className = styles.colorDiv;
            if (colorKey === this.frameImageTargetColor) {
              className += ` ${styles.active}`;
            }
            return (
              <div
                className={className}
                style={{ backgroundColor: config.wall_paper_colors.selectable_colors[colorKey] }}
                key={colorKey}
                onClick={this.onClickColor(colorKey, 'frameImage')}
              />
            );
          })}
        </div>
      </Grid>
    </div>
  </div>
    <RecommendImages
      loading={this.props.frameImageLoading}
      recommend={this.props.frameImage}
      selectItem={(e) => { this.selectItem(e); }}
      onLoad={(offset) => { this.props.getFrameImage({tags: {...this.props.tagState}, offset, color: this.props.frameImageColor}); }}
    />
  </div>
					)}
          { activeStep === 5
					&& (
<>
  <div className={styles.imgBana}>
    <div className={styles.main_box}>
      <h3>
        <FontDownload className={styles.iconTitle} style={{ fontSize: 28 }} />
        文言レコメンド
      </h3>
    </div>
    <div className={styles.textRecommend}>
      <ButtonGroup variant="contained" color="primary" aria-label="contained button group">
        <Button 
          onClick={() => this.handleTextGroup(0, 0)}
          color={buttonActive == 0 ? "secondary" : "primary"}
        >
          おすすめ
        </Button>
        { textGroupIds && textGroupIds.map((value, key) => (
          <Button
          color={buttonActive == key+1 ? "secondary" : "primary"}
          className={styles.textGroupButton}
          onClick={() => this.handleTextGroup(value.id, key+1)}
          >
            {value.name}
          </Button>
        )) }
      </ButtonGroup>
    </div>

    <div className={styles.textRecommends}>
      <RecommendImages
        recommendText={true}
        loading={this.props.textLoading}
        recommend={this.props.recommendedTexts}
        selectItem={(e) => { this.setState({selectedText: e.content}) }}
        onLoad={(offset) => { this.getMoreTexts({tags: this.props.tagState,index: buttonActive, groupId: activeGroupId, offset}) }}
      />
    </div>
    <div className={`${styles.textInput} muiSelectLabel`}>
      <form noValidate autoComplete="off">
        <TextField id="outlined-basic" 
          label="文言入力" 
          variant="outlined" 
          value={selectedText}
          className={styles.textInputBox}
          onChange={e => {this.setState({selectedText: e.target.value})}}
        />
      </form>
      <Button variant="contained" size="large" color="secondary" 
        onClick={() => this.selectItem(selectedText)}
        className={styles.textInputButton}>
        決定
      </Button>
    </div>
  </div>
</>
					)}
          { activeStep === 6
					&& (
<div className={styles.imgBana}>
  <div className={styles.main_box}>
    <h3>
      <Palette className={styles.iconTitle} style={{ fontSize: 40 }} />
      フォントデザインパターン
    </h3>
  </div>
    <RecommendImages
      loading={this.props.fontDesignPatternLoading}
      fontDesignPattern={this.props.fontDesignPattern}
      selectItem={(e) => { this.selectItem(e); }}
      onLoad={(offset) => { this.props.getFontDesignPattern(this.props.tagState, offset); }}
    />
</div>
					)}

          {/* footer */}
          <div className={styles.footer}>
            <Grid
              container
              justify="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <div className={styles.leftBtn}>
                  { activeStep !== 1
                && (
                <Button variant="contained" size="medium" color="secondary" onClick={() => this.prevStep(activeStep)}>
                  <span className={styles.buttonText}>＜＜ 戻る</span>
                </Button>
                )}
                </div>
              </Grid>
              <Grid item xs={2} className={styles.nextNone}>
                { activeStep === 1
                && <FormControlLabel control={<Checkbox name="checkedC" checked={closeWizardClicked} onChange={(e) => {this.onChangeCloseWizardClicked(e)}} />} label="次回ウィザードなし" />}
              </Grid>
              <Grid item xs={2}>
                <div className={styles.rightBtn}>
                  { activeStep === 6
                    ? (
                      <Button variant="contained" size="medium" color="secondary" onClick={() => this.saveSelected()}>
                        <span className={styles.buttonText}>　完了　</span>
                      </Button>
                    )
                    : (
                      <Button variant="contained" size="medium" color="secondary" onClick={() => this.nextStep(activeStep)}>
                        <span className={styles.buttonText}>次 ＞＞ </span>
                      </Button>
                    )}
                </div>
              </Grid>
            </Grid>
          </div>
          {/* End */}
        </div>
      </div>
    );
  }
}

export default WizardModal;
