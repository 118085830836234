import {
  ACTION_TYPE,
} from '@pages/editor/designDrawer/text/TextDrawerAction';

const TextDrawerReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.FETCH.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.COUNT.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.COUNT.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.COUNT.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.SAVE.save:
      if (state.mounted) {
        return { ...state, ...action.payload };
      }
      return state;
    case ACTION_TYPE.SAVE.mounted:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SAVE.unmount:
      return { ...state, ...action.payload };

    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.fail:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default TextDrawerReducer;
