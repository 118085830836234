import { ACTION_TYPE } from './HumanCutModalAction';

const initState = {
  selectedItems: Array(6),
};

const HumanCutModalReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.CUTOUT_HUMAN.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CUTOUT_HUMAN.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CUTOUT_HUMAN.fail:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export default HumanCutModalReducer;