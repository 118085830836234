import { connect } from 'react-redux';
import SmallImages from '@pages/editor/workArea/smallImages/SmallImages';
import * as Action from '@pages/editor/workArea/smallImages/SmallImagesAction';

const mapStateToProps = (state, ownProps) => ({
  tagState: state.entities.tags.tagState,
  ...state.materials.smallImages,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(SmallImages);