import TextContainer from '@components/elements/text/TextContainer';
import AssetImage from '@components/elements/assetImage/AssetImage';
import UploadImage from '@components/elements/uploadImage/UploadImage';
import WallPaperImage from '@components/elements/wallPaperImage/WallPaperImage';
import ShapeImage from '@components/elements/shapeImage/ShapeImage';
import FrameImage from '@components/elements/frameImage/FrameImage';
import styles from '@components/elements/Element.module.scss';

const components = {
  Text: TextContainer,
  AssetImage,
  WallPaperImage,
  FrameImage,
  UploadImage,
  ShapeImage
};

class Element extends React.Component {
  constructor(props) {
    super(props);
    this.typeOfImages = [
      'AssetImage',
      'FrameImage',
      'WallPaperImage',
      'UploadImage',
    ]
  }

  componentDidUpdate(prevProps) {
    const { fontDecorations, element, reflectDecoration, receiveElement, moveableRef, zoom} = this.props;
    const { decoration, decorationTarget, didReflectDecoration, decorationName, decorationId } = fontDecorations;

    if(!didReflectDecoration && decorationTarget === element.id) {
      const rectInfo = moveableRef.current.getRect();
      const updatedElement = { 
        ...element, 
        elementableAttributes: {
          ...decoration.elementableAttributes, 
          text: element.elementableAttributes.text, 
          height: rectInfo.height / zoom, 
          width: rectInfo.width / zoom,
          fontSize: element.elementableAttributes.fontSize,
        },
        decorationName,
        decorationId,
        changeableConfigs: decoration.changeableConfigs,
      }
      receiveElement({...updatedElement})
      reflectDecoration({decorationName, decorationId});
    }
  }

  genBorderStyle(border) {
    const cssObj = { 
      borderWidth: '',
      borderStyle: '',
      borderColor: '',
    }
    const borderKeys = ['top', 'right', 'bottom', 'left'];
    borderKeys.forEach((key) => {
      Object.keys(cssObj).forEach((attr) => {
        if(key !== 'top') {
          cssObj[attr] += ' ';
        }
        if(attr === 'borderWidth') {
          cssObj[attr] += `${border[key]['weight']}px`;
        } else if(attr === 'borderStyle') {
          cssObj[attr] += border[key]['style'];
        } else {
          cssObj[attr] += border[key]['color'];
        }
      });
    });
    return cssObj;
  }

  render() {
    const { element, zoom, changeDesignState } = this.props;
    const { transparency, elementableAttributes, roundedCorner, border, shadow } = element;

    const customStyle = {};
    let customClass;
    if (this.typeOfImages.includes(components[element.elementableType])) {
      const { naturalWidth, naturalHeight } = elementableAttributes;
      customStyle.maxWidth = naturalWidth * zoom;
      customStyle.maxHeight = naturalHeight * zoom;
      customClass = styles.image;
    }
    const borderCss = this.genBorderStyle(border);
    return (
      <div
        className={`${styles.element} cursor-pointer ${customClass}`}
        style={
          {
            opacity: transparency,
            height: element.elementableAttributes.height * zoom,
            width: element.elementableAttributes.width * zoom,
            borderRadius: (roundedCorner) ? `${roundedCorner}px` : '0px',
            ...borderCss,
            boxShadow: `${shadow.horizontal}px ${shadow.vertical}px ${shadow.blur}px ${shadow.color}`,
            ...customStyle,
          }
        }
      >
        {React.createElement(
          components[element.elementableType],
          {
            element,
            elementAttr: elementableAttributes,
            zoom,
            borderRadius: (element.roundedCorner) ? `${element.roundedCorner}px` : '0px',
            changeDesignState: changeDesignState,
          },
          null,
        )}
      </div>
    );
  }
}

export default Element;
