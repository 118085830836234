// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShadowDialog-module__shadowDialogWrapper--3o0U2{bottom:40px;top:unset;width:100px;padding:12.5px;left:-12px;display:flex;flex-direction:column;justify-content:space-evenly;align-items:center}.ShadowDialog-module__shadowDialogWrapper--3o0U2 .ShadowDialog-module__titleAndColorSelection--1IRvR{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.ShadowDialog-module__shadowDialogWrapper--3o0U2 .ShadowDialog-module__settingValue--2pEmo{width:100%;display:flex;flex-direction:column;justify-content:center}.ShadowDialog-module__shadowDialogWrapper--3o0U2 .ShadowDialog-module__settingValue--2pEmo .ShadowDialog-module__attrNameAndTextInput--22kM5{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.ShadowDialog-module__shadowDialogWrapper--3o0U2 .ShadowDialog-module__settingValue--2pEmo .ShadowDialog-module__attrNameAndTextInput--22kM5 .ShadowDialog-module__inputNumber--3CpEl{width:45px}.ShadowDialog-module__hidden--2tE9H{visibility:hidden}\n", ""]);
// Exports
exports.locals = {
	"shadowDialogWrapper": "ShadowDialog-module__shadowDialogWrapper--3o0U2",
	"titleAndColorSelection": "ShadowDialog-module__titleAndColorSelection--1IRvR",
	"settingValue": "ShadowDialog-module__settingValue--2pEmo",
	"attrNameAndTextInput": "ShadowDialog-module__attrNameAndTextInput--22kM5",
	"inputNumber": "ShadowDialog-module__inputNumber--3CpEl",
	"hidden": "ShadowDialog-module__hidden--2tE9H"
};
module.exports = exports;
