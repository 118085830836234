import { Button, Tooltip } from '@material-ui/core';
import {
  FormatAlignLeft, FormatAlignRight, FormatAlignCenter, FormatAlignJustify, Opacity as Transparency, DeleteForever,
} from '@material-ui/icons';
import { ulid } from 'ulid';

import config from '@assets/config';

import { genGradientCss } from '@common/utils/TextOperation';
import ActiveController from '@components/activeController/ActiveController';

import Toolbar from '@pages/editor/workArea/designTools/components/toolbar/Toolbar';
import TransparencyDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialogContainer';
import AlignmentContainer from '@pages/editor/workArea/designTools/components/alignment/AlignmentContainer';

import styles from '@pages/editor/workArea/designTools/shapeTools/ShapeTools.module.scss';
import '@pages/editor/workArea/designTools/textTools/TextTools.scss';

class ShapeTools extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;
    this.state = {
      dialogIsOpen: [
        false, // image shadow dialog
      ],
    };

    this.alignments = {
      left: { index: 0, class: FormatAlignLeft },
      right: { index: 1, class: FormatAlignRight },
      center: { index: 2, class: FormatAlignCenter },
      justify: { index: 3, class: FormatAlignJustify },
    };
    this.changeColorTarget = `shapes${ulid()}`;
    unmount();
  }

  componentDidMount() {
    const { mount } = this.props;
    mount();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState && this.props === nextProps);
  }

  componentDidUpdate(prevProps) {
    const { element, drawerSettings } = this.props;
    const { elementableAttributes: elementAttrs } = element;
    const { colorPalette } = drawerSettings;

    if (prevProps.element.id !== element.id) {
      this.changeColorTarget = `shapes${ulid()}`;
    }

    if (this.isChangeColorTargetOwn() && elementAttrs.color !== colorPalette.color) {
      this.changeColor();
    }
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  changeColor() {
    const { receiveElement, element, drawerSettings } = this.props;
    const { elementableAttributes } = element;
    const { colorPalette } = drawerSettings;
    const updatedElementableAttrs = { ...elementableAttributes };
    if (this.isChangeColorTargetOwn()) {
      updatedElementableAttrs.color = colorPalette.color;
      updatedElementableAttrs.colorType = colorPalette.colorType;
      receiveElement({ ...element, elementableAttributes: updatedElementableAttrs });
    }
  }

  toggleSubDrawer(id, color = undefined) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      const nextDrawerSettings = { ...drawerSettings };
      if (!drawerSettings.closed) { nextDrawerSettings.closed = false; }
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
      if (color) {
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: config.colorTitle['図形色'],
          changeTarget: this.changeColorTarget,
          colorType: 'unit',
          allowGradient: false,
        };
      }
      if (!color) {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
        // 色ドロワーかつ、色変更対象が変わった時
      } else if (color && drawerSettings.colorPalette.changeTarget !== this.changeColorTarget) {
        nextDrawerSettings.isSubDrawerOpen = true;
      }
      nextDrawerSettings.subDrawer = id;
      nextDrawerSettings.decorationTarget = null;
      changeDrawer(nextDrawerSettings);
    };
  }

  isChangeColorTargetOwn() {
    const { drawerSettings } = this.props;
    return (drawerSettings.colorPalette.changeTarget === this.changeColorTarget);
  }

  colorStyle() {
    const { color, colorType, gradients } = this.props.element.elementableAttributes;
    if (colorType === 'gradient') {
      return { background: genGradientCss(gradients, 'to top') };
    }
    return { backgroundColor: color };
  }

  toggleDialog(dialogNum) {
    return () => {
      const { closeDialog, allowDialogs } = this.props;
      const { dialogIsOpen } = this.state;

      let openOrClose = !dialogIsOpen[dialogNum];
      // 上部のダイアログが空いていて、ボタンが押された時。
      if (dialogIsOpen[dialogNum] && !allowDialogs) {
        openOrClose = true;
      }
      closeDialog();

      // dialog判定の管理
      const updatedDialogIsOpen = dialogIsOpen.map(() => false);
      updatedDialogIsOpen[dialogNum] = openOrClose;
      this.setState({
        dialogIsOpen: updatedDialogIsOpen,
      });
    };
  }

  generateToolbarData() {
    const {
      element, isOpen, toggleDialog, designToolsStyles, deleteElement, drawerSettings,
    } = this.props;
    const { color } = element.elementableAttributes;
    const colorStyle = this.colorStyle();
    const mainColor = '#FFF';
    return {
      main: {
        content: [
          <div className={`${styles.mainWrapper} ${styles.fullWidth} ${designToolsStyles.dialogParent}`}>
            <ActiveController
              active={
                drawerSettings.colorPalette.changeTarget === this.changeColorTarget
                && drawerSettings.subDrawer === 1
                && drawerSettings.isSubDrawerOpen === true
              }
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="図形色">
                <div
                  id="ShapeTool-ColorPalette"
                  className={`btn-color ${styles.space} ${styles.colorPalette} cursor-pointer`}
                  style={colorStyle}
                  onClick={this.toggleSubDrawer(1, color)}
                />
              </Tooltip>
            </ActiveController>
            <ActiveController
              active={isOpen[4]}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="不透明度">
                <Transparency className={designToolsStyles.iconDefault} onClick={toggleDialog(4)} />
              </Tooltip>
              <TransparencyDialogContainer
                className={(isOpen[4]) ? '' : designToolsStyles.hidden}
                element={element}
              />
            </ActiveController>
          </div>,
        ],
      },
      alignment: {
        title: '配置',
        content: [
          <AlignmentContainer element={element} color={mainColor} />,
        ],
      },
      delete: {
        content: [
          <Tooltip title="削除　Delete">
            <Button
              onClick={deleteElement}
              className={`${styles.space} ${styles.button}`}
              startIcon={<DeleteForever className={designToolsStyles.iconDefault} />}
            >
              削除
            </Button>
          </Tooltip>,
        ],
      },
    };
  }

  render() {
    return (
      <>
        <Toolbar title="図形" data={this.generateToolbarData()} />
      </>
    );
  }
}

export default ShapeTools;
