import React from 'react';
import { 
	Button, 
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';

import styles from './TrimmingModal.module.scss';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';



class TrimmingModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			crop: {
				x: 130,
				y: 50,
				width: 200,
				height: 200,
			},
		};
		this.trimImage = this.trimImage.bind(this);
		this.onCropComplete = this.onCropComplete.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
  }

	componentDidUpdate() {
		const {read, targetImageBlob, changeRead} = this.props
		if(targetImageBlob && !read) {
			const reader = new FileReader();
			reader.onload = (e) => {
				this.imageUrl = e.target.result;
				this.trimImage();
			} 
			reader.readAsDataURL(targetImageBlob);
			changeRead(true)
		}
	}

	closeModal(){
		const { onRequestClose, tmpToggleDialogs } = this.props;
		tmpToggleDialogs();
		onRequestClose();
	}

	onImageLoaded(image) {
    this.imageRef = image;
	};
	
	onCropComplete(crop) {
		const { getImageFromUrl, element:{elementableAttributes: { url }} } = this.props;
		if(this.imageUrl) {
			this.trimImage();
		}else {
			this.setState({crop});
			getImageFromUrl(url);
		}
  }

	onCropChange(crop) {
    this.setState({ crop })
  }

	trimImage(){
		gtag('event', 'click_trimming',{
			'event_name' : 'click_trimming',
		});
		const { receiveElement, element } = this.props;
		const { crop } = this.state;
		const downloadedImg = new Image;
		downloadedImg.onload = async () => {
			const croppedImageUrl = await this.getCroppedImg(
				downloadedImg,
				crop,
				"newFile.jpeg"
			);
			receiveElement
			this.setState({ croppedImageUrl });
		}
		downloadedImg.src = this.imageUrl;
	}

	getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
    const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
		return canvas.toDataURL("image/jpeg")
  }

	async saveTrimming() {
		gtag('event', 'click_logocut',{
			'event_name' : 'logoCutOut',
		});
		const { receiveElement, element, setError, zoom } = this.props;
		const { croppedImageUrl, crop } = this.state;
		try {
			const {elementableAttributes: elementAttrs} = element;
			const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
			const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
			const workSpaceCrop = {
				x: crop.x * scaleX,
      	y: crop.y * scaleY,
      	width: crop.width * scaleX,
				height: crop.height * scaleY,
			}
			const updatedElementAttrs  = {
				...element.elementableAttributes,
				crop: workSpaceCrop,
			}
			receiveElement({...element, elementableAttributes: updatedElementAttrs});
		} catch(e) {
			console.log(e);
			setError({message: 'トリミング画像の保存に失敗しました。'})
		} finally {
			this.closeModal();
		}
	}

	render() {
		const { crop, croppedImageUrl } = this.state;
		const { element, error } = this.props;
		const { naturalHeight, naturalWidth} = element.elementableAttributes;
		return (
			<div className={styles.AppBody}>
				{error && toastr.error('error', error.message)}
				<div className={styles.contentWrapper} style={{ marginTop: 5 }}>
					<div className={styles.headText}>
						画像リサイズ・トリミング
					</div>
					<div className={styles.imageBody}>
					{element && !element._destroy &&
						<ReactCrop
							src={element.elementableAttributes.url}
							crop={crop}
							onImageLoaded={this.onImageLoaded}
							onComplete={this.onCropComplete}
							onChange={(crop)=>{this.onCropChange(crop)}}
							className={styles.cropTarget}
							style={resizeImage({height: naturalHeight, width: naturalWidth, maxHeight: 300, maxWidth: 400})}
						/>
					}
					</div>
					<div className={styles.BtnWizard}>
						<div>
							{croppedImageUrl && (
								<img className={styles.croppedImage} alt="トリミング画像" src={croppedImageUrl}/>
							)}
						</div>
						<div className={styles.rightaction}>     
							<div className={styles.leftBtn}>
								<Button variant="contained" size="medium" onClick={() => this.saveTrimming()}>
								<span className={styles.buttonText}>保存</span>
								</Button>
							</div>
							<div className={styles.rightBtn}>
								<Button variant="contained" size="medium" onClick={() => this.closeModal()}>
									<span className={styles.buttonText}>キャンセル</span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TrimmingModal;