import React from 'react';
import './Saving.scss';
import styles from './Saving.module.scss';
import { Close, Computer, Forward, GetApp, Done, Person, Lock, FilterNone, Info } from '@material-ui/icons';
import { Grid, Box, Typography, Step, Stepper, StepButton, StepLabel, FormControl, MenuItem, 
  Button, Radio, RadioGroup, FormControlLabel, Checkbox, InputAdornment, TextField,
  Table, TableContainer, TableBody, TableRow, TableCell, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '@components/loader/Loader';

const maxMemoText = 200;
const maxImgNameText = 20;

const CssTextField = withStyles({
  root:{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F5F5F5',
      },
      '&:hover fieldset': {
        borderColor: '#F5F5F5CC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F5F5F5CC',
      },
    },
  },
})(TextField);

const PinkRadio = withStyles({
  root: {
    '&$checked': {
      color: '#D47780',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class SavingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      presentStep: 1,
      radioStep: 'a',
      uploadingStep: 1,
      memo: '',
      imgFileName: '',
      upImgInputErr: '',
      remainingMemoText: maxMemoText,
      remainingNameText: maxImgNameText,
      imageTypVal: 1,
      step2completed: false,
      copyStatus: '',
      token: '',
      loginInfo: {
        username: '',
        pass: ''
      },
      companyInfo: {
        companyId: '',
        companyName: '',
        contractType: '0',
        partnerName: '',
        companyStatus: '0',
        companyOnly: true,
      },
      selectedCompany: [],
      completed: new Set(),
      txtFieldErr: new Map(),
      copySupport: {
        navigatorClipboard: !!navigator.clipboard,
        exec: !!document.queryCommandSupported('copy'),
      }
    };
    this.tagConfig = [
      { id: 'professional', name: '職種カテゴリ' },
      { id: 'targetAttr', name: 'ターゲット属性' },
      { id: 'imageKind', name: '画像種別' },
    ];
  }

  async login() {
    const { completed, loginInfo } = this.state;
    await this.props.login(loginInfo);
    const { apiKey } = this.props;
    if (apiKey) {
      completed.add(0);
      this.setState({ 
        completed, 
        uploadingStep: 2, 
        token: apiKey
      });
    }
  }

  async search() {
    const { companyInfo, token, loginInfo } = this.state;
    await this.props.searchCompany(companyInfo, token, loginInfo);
  }

  clear() {
    const { companyInfo } = this.state;
    const temp = { ...companyInfo };
    temp.companyId = '';
    temp.companyName = '';
    temp.contractType = '0';
    temp.partnerName = '';
    temp.companyStatus = '0';
    this.setState({ companyInfo: temp });
  }

  select(row) {
    const { imgCount } = this.props;
    const count = (imgCount.prevCompanyId !== row.company_id) ? 1 : imgCount.uploadCount + 1;
    const initFileName = `gazo_${row.company_id}_${count}`;
    this.setState({ 
      selectedCompany: row,
      imgFileName: initFileName,
      remainingNameText: maxImgNameText - initFileName.length,
      step2completed: true, 
      uploadingStep: 3,
    });
  }

  async upload() {
    const { completed, step2completed, imageTypVal, imgFileName, memo, selectedCompany, loginInfo, token } = this.state;
    const { designedImg, uploadImage, tagState, allTags, imgCount, updateImageCount } = this.props;
    await this.setState({ upImgInputErr: '' });
    if (!step2completed) {
      return;
    }
    if (!imageTypVal) {
      return this.setState({ upImgInputErr: '画像種別を選択してください' });
    } else if (!imgFileName || imgFileName.length > maxImgNameText) {
      return this.setState({ upImgInputErr: 'ファイル名への文字数は1以上20以下にしてください' });
    } else if (memo && memo.length > maxMemoText) {
      return this.setState({ upImgInputErr: 'メモ欄への文字数は200以下にしてください' });
    }
    this.setState({ loading: true });
    // await this.downloadImage(true);
    const uploadParams = {
      memo: memo,
      token: token,
      allTags: allTags,
      tagState: tagState,
      password: loginInfo.pass,
      designedImg: designedImg,
      type: String(imageTypVal),
      filename: `${imgFileName}.jpg`,
      companyId: selectedCompany.company_id
    }
    await uploadImage(uploadParams);
    const updatedCount = { ...imgCount };
    updatedCount.uploadCount = (updatedCount.prevCompanyId !== selectedCompany.company_id) ? 1 : updatedCount.uploadCount + 1;
    updatedCount.prevCompanyId = selectedCompany.company_id;
    await updateImageCount(updatedCount);

    this.setState({ loading: false });
    if(this.props.registImgName) {
      completed.add(1);
      completed.add(2);
      this.setState({ 
        completed, 
        step2completed: false,
        uploadingStep: 4
      });
    }
    await this.downloadImage(true);
  }

  async handleClose() {
    await this.props.closeModal();
    this.props.onClose();
  }

  uploadComplete() {
    const { completed } = this.state;
    completed.clear();
    this.setState({ 
      completed
    });
    this.handleClose();
  }

  async closeLargeFileAlert() {
    await this.props.largeJsonNotified();
  }

  nextUploadingStep(step) {
    const { completed, step2completed } = this.state;
    if ( !completed.has(0) || step === 3 || (step === 2 && !step2completed) || (completed.has(0) && step === 0) ) {
      return;
    }
    return this.setState({
      uploadingStep: step + 1,
    });
  }

  handleLogin(id) {
    const { loginInfo } = this.state;
    return (e) => {
      const updatedInfo = { ...loginInfo };
      updatedInfo[id] = e.target.value;
      this.setState({ loginInfo: updatedInfo });
    };
  }

  onChangeCompInfo(id) {
    const { companyInfo } = this.state;
    return (e) => {
      const updatedInfo = { ...companyInfo };
      if (id === 'companyOnly') {
        updatedInfo[id] = e.target.checked;
      } else {
        updatedInfo[id] = e.target.value;
      }
      this.setState({ companyInfo: updatedInfo });
    };
  }

  radioHandleChange () {
    return (e) => this.setState({ radioStep: e.target.value });
  }
  
  handleImgName (filename) {
    if ((/^[a-zA-z0-9._-]*$/g).test(filename)) {
      this.setState({ 
        imgFileName: filename,
        remainingNameText: maxImgNameText - filename.length,
      })
    }
  }

  handleMemo() {
    return (e) => {
      this.setState({
        memo: e.target.value,
        remainingMemoText: maxMemoText - e.target.value.length,
      });
    };
  }

  /**
   * @param {string} tagName 英語のタグ名
   */
  onChangeTag(tagName) {
    const { tagState, updateTag } = this.props;
    return (e) => {
      const updatedTag = { ...tagState };
      updatedTag[tagName] = e.target.value;
      updateTag(updatedTag);
    };
  }

  async imgCategoryConfirm() {
    const { tagState } = this.props;
    const errMap = new Map();
    if (tagState.professional && tagState.targetAttr && tagState.imageKind) {
      return this.setState({
        presentStep: 2
      });
    } else {
      if (!tagState.professional) {
        errMap.set('professional', '職種カテゴリが選択されていません');
      }
      if (!tagState.targetAttr) {
        errMap.set('targetAttr', 'ターゲット属性が選択されていません');
      }
      if (!tagState.imageKind) {
        errMap.set('imageKind', '画像カテゴリが選択されていません')
      }
      return this.setState({ txtFieldErr: errMap });
    }
  }

  async copyToClipboard() {
    const { registImgName } = this.props;
    const { copySupport, copyStatus } = this.state;
    if ('' !== copyStatus) {
      this.setState({ copyStatus: '' })
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    if (copySupport.navigatorClipboard) {
      try {
        navigator.clipboard.writeText(registImgName);
        this.setState({ copyStatus: 'success' })
      } catch (e) {
        const temp = { ...copySupport };
        temp.navigatorClipboard = false;
        this.setState({
          copySupport: temp,
          copyStatus: 'fail'
        })
      }
    }
    if (!copySupport.navigatorClipboard) {
      try {
        var dummy = document.createElement('textarea');
        dummy.innerHTML = registImgName;
        document.body.appendChild(dummy);
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this.setState({ copyStatus: 'success' })
      } catch (e) {
        const temp = { ...copySupport };
        temp.exec = false;
        this.setState({
          copySupport: temp,
          copyStatus: 'fail'
        })
      }
    }

  }

  getTag(category, id) {
    const { allTags } = this.props;
    const tag = allTags[category].filter((tag) => tag.id === id)[0];
    return tag;
  }

  async downloadImage(saveOnly = false) {
    const {
      downloadImage, elements, tagState, allTags, designedImg, imgCount, updateImageCount, registImgName
    } = this.props;
    const { selectedCompany } = this.state;
    if (saveOnly) {
      tagState.companyId = selectedCompany.company_id;
    } else {
      if(!tagState.company_id) {
        tagState.companyId = '000000';
      } else if (selectedCompany.company_id && tagState.company_id === selectedCompany.company_id) {
        tagState.companyId = '000000';
      }
    }

    // GA小画像ダウンロード確認モーダル
    this.getTag('職種カテゴリ', tagState.professional);
    if (tagState.companyId) {
      gtag('event', 'smallImage_download_company_id', { smallImage_download_company_id: tagState.companyId });
    }
    if (tagState.professional) {
      gtag('event', 'smallImage_download_professional', { smallImage_download_professional: this.getTag('職種カテゴリ', tagState.professional).name });
    }
    if (tagState.targetAttr) {
      gtag('event', 'smallImage_download_targetAttr', { smallImage_download_targetAttr: this.getTag('ターゲット属性', tagState.targetAttr).name });
    }
    if (tagState.imageKind) {
      gtag('event', 'smallImage_download_imageKind', { smallImage_download_imageKind: this.getTag('画像種別', tagState.imageKind).name });
    }
    if (tagState.imageImagine) {
      gtag('event', 'smallImage_download_imageImagine', { smallImage_download_imageImagine: this.getTag('画像イメージ', tagState.imageImagine).name });
    }
    if (tagState.feature) {
      gtag('event', 'smallImage_download_feature', { smallImage_download_feature: this.getTag('特徴', tagState.feature).name });
    }

    const updatedCount = { ...imgCount };
    const count = updatedCount.downloadCount + 1;
    updatedCount.downloadCount = count;
    await downloadImage(elements, tagState, count, allTags, designedImg, registImgName, saveOnly);
    updateImageCount(updatedCount);
  }

  render() {
    const steps = ['画像カテゴリ確認', '画像保存'];
    const uploadSteps = ['ログイン', 'Comp選択', '画像保存', '保存完了'];
    const contractType = ['指定しない', '直販', 'パートナー'];
    const statusList = ['指定しない', '契約あり', '契約なし', '利用期間終了', '掲載不可' ];
    const { presentStep, completed, radioStep, uploadingStep, loginInfo, companyInfo, selectedCompany, copyStatus, copySupport,
      imageTypVal, imgFileName, upImgInputErr, remainingNameText, memo, remainingMemoText, step2completed, txtFieldErr, loading } = this.state;
    const { allTags, tagState, designedImg, wait, uploadError, registImgName, loginError, companies, compError, download, isJsonLarge } = this.props;

    return (
      <>
        { (wait || loading) ? <Loader active={true}/>:
        <div className={styles.modalBody}>
          <Grid container>
            {/* Model Header */}
            <Grid container item justify='center'>
              {/* <Grid item xs={3} className={styles.headerLGrid}></Grid> */}
              <Grid item xs={9} className={`${styles.headerMGrid} mui_stepperGrid`}>
                <Stepper activeStep={presentStep - 1} className={styles.stepper}>
                  { steps.map((label) => {
                    return (
                      <Step key={label}>
                        <StepLabel>
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  }) }
                </Stepper>
              </Grid>
              <Grid item xs={3} className={styles.modalCloseIcon}>
                <Close
                  className={styles.modalClose}
                  onClick={() => { this.handleClose() }}
                />
              </Grid>
            </Grid>

            {/* Model content */}
            <Grid container item justify='center'>
              {/* 画像カテゴリ確認 */}
              { presentStep === 1 &&
                <Grid item xs={8}>
                  <Box className={styles.textBoxII}>
                    作成した小画像に対する項目は正しく選択されていますか？
                  </Box>
                  <Box display={'flex'}>
                    <Box flexGrow={'2'}>
                      <Box className={styles.designedImgBox}>
                        <img src={designedImg} className={styles.designedImg} alt="" />
                      </Box>
                    </Box>
                    <Box flexGrow={'1'}>
                      { this.tagConfig.map((tag) => {
                        return (
                          <Box mx={2} className={styles.tagBox}>
                            <Typography className={styles.tagTitle}>
                              {tag.name === '画像種別' ? '画像カテゴリ' : tag.name}
                              <span className={styles.requiredDiv}>必須</span>
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                              <CssTextField
                                select
                                variant='outlined'
                                id={tag.id}
                                value={tagState[tag.id]}
                                onChange={this.onChangeTag(tag.id)}
                                SelectProps={{
                                  MenuProps: {
                                    style: { zIndex: 3002 },
                                    getContentAnchorEl: () => null,
                                  }
                                }}
                                error={txtFieldErr.has(tag.id)}
                                helperText={txtFieldErr.has(tag.id) ? txtFieldErr.get(tag.id) : ''}
                                inputProps={{ className: styles.tagSelectBox }}
                              >
                                <MenuItem value=''>未選択</MenuItem>
                                { (allTags && allTags[tag.name] !== undefined)
                                  ? allTags[tag.name].map((node) => (
                                    <MenuItem value={node.id}>{node.name}</MenuItem>
                                  ))
                                  : <MenuItem />
                                }
                              </CssTextField>
                            </FormControl>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                  <Box className={styles.yesButton}>
                    <Button 
                      variant='contained' 
                      type='submit'
                      onClick={() => this.imgCategoryConfirm()} 
                      startIcon={<Done/>}>
                      <Typography className={styles.txtSize13}>はい</Typography>
                    </Button>
                  </Box>
                </Grid>
              }

              {/* 画像保存 */}
              { presentStep === 2 &&
                <Grid item xs={12}>
                  <Box className={styles.radioButtonBox}>
                    <FormControl component="fieldset" style={{ width: '34%' }}>
                      <RadioGroup
                        row
                        value={radioStep}
                        onChange={this.radioHandleChange()}
                      >
                        <FormControlLabel value={'a'} control={<PinkRadio />} 
                          style={{ marginRight: 'auto' }}
                          label={<Typography className={styles.radioLabel}>PCに保存</Typography>} />
                        <FormControlLabel value={'b'} control={<PinkRadio />} 
                          style={{ marginLeft: 'auto' }}
                          label={<Typography className={styles.radioLabel}>バイトル管理画面に保存</Typography>} />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  { radioStep === 'a' ?
                  <>
                    { download === 'done' ?
                      <Box>
                        <Box m={1} className={styles.radioStepDoneBox}>
                          <Typography className={styles.txtSize16}>画像ファイルのダウンロードが完了しました</Typography>
                        </Box>
                        <Box className={styles.closeButton}>
                          <Button variant='contained'
                            className={styles.closeBtn}
                            onClick={()=>this.handleClose()}>
                            <Typography className={styles.txtSize13}>閉じる</Typography>
                          </Button>
                        </Box>
                      </Box>
                    : <>
                        <Box m={1} className={styles.radioStepMiddleBox}>
                          <Box className={styles.middleBox}>
                            <Box className={styles.designedImgMediumBox}>
                              <img src={designedImg} className={styles.designedImg} alt="" />
                            </Box>
                            <Box className={styles.iconBox}>
                              <Forward className={styles.forward}/>
                            </Box>
                            <Box className={styles.iconBox}>
                              <Computer className={styles.computer}/>
                            </Box>
                          </Box>
                          <Box className={styles.textBoxI}>
                            PCに画像を保存します
                          </Box>
                        </Box>
                        <Box className={styles.saveButton}>
                          <Button 
                            variant='contained' 
                            startIcon={<GetApp/>}
                            onClick={()=>this.downloadImage()}>
                            <Typography className={styles.txtSize13}>保存</Typography>
                          </Button>
                        </Box>
                      </>
                    }
                  </> :
                  // {/* upload to company */}
                  <>
                    <Box display={'flex'}>
                      <Box className={`${styles.uploadingStepperBox} mui_uploadingStepper`}>
                        <Stepper activeStep={uploadingStep-1} nonLinear orientation="vertical" className={styles.uploadingStepper}>
                          { uploadSteps.map((label, idx) => {
                            const stepProps = {};
                            if (uploadingStep === 4) {
                              stepProps.disabled = true;
                            }
                            if (!completed.has(idx)) {
                              stepProps.completed = false;
                            } else if (completed.has(idx)) {
                              stepProps.completed = true;
                            }
                            return (
                              <Step {...stepProps}>
                                <StepButton onClick={() => this.nextUploadingStep(idx)} 
                                  className={(step2completed && idx===1) || (step2completed && idx===2) ? styles.stepperActiveBtn : styles.stepperBtn}>
                                  {label}
                                </StepButton>
                              </Step>
                            );
                          }) }
                        </Stepper>
                        <Box className={styles.designedImgSmallBox}>
                          <img src={designedImg} className={styles.designedImg} alt="" />
                        </Box>
                      </Box>
                      <Box ml={2} className={styles.uploadingSteps}>
                        { uploadingStep === 1 && 
                          <Box className={styles.loginBox}>
                            <Box className={styles.loginTitle}>
                              バイトル管理画面にログイン
                            </Box>
                            <Box className={styles.textField}>
                              <CssTextField
                                type={'text'}
                                variant={'outlined'}
                                placeholder='Username'
                                fullWidth
                                autoFocus={true}
                                autoComplete='true'
                                InputProps={{
                                  className: styles.input,
                                  startAdornment: 
                                    <InputAdornment position="start">
                                      <Person style={{ fontSize: 24, color: '#9E9E9E' }}/>
                                    </InputAdornment>,
                                  endAdornment: 
                                    <InputAdornment position="end">
                                      <Typography style={{ color: '#000', fontSize: 14 }}>@dip-net.co.jp</Typography>
                                    </InputAdornment>
                                }}
                                value={loginInfo.username}
                                onChange={this.handleLogin('username')}
                              />
                            </Box>
                            <Box className={styles.textField}>
                              <CssTextField
                                type={'password'}
                                variant={'outlined'}
                                placeholder='Password'
                                fullWidth
                                autoFocus={true}
                                autoComplete='true'
                                InputProps={{
                                  className: styles.input,
                                  startAdornment: 
                                    <InputAdornment position="start">
                                      <Lock style={{ fontSize: 24, color: '#9E9E9E' }}/>
                                    </InputAdornment>,
                                }}
                                value={loginInfo.pass}
                                onChange={this.handleLogin('pass')}
                              />
                            </Box>
                            { loginError && 
                              <Typography className={styles.loginError}>＊｛{loginError}｝</Typography>
                            }
                            <Box className={styles.loginButton}>
                              <Button 
                                variant='contained'
                                onClick={()=>this.login()}>
                                <Typography className={styles.txtSize13}>ログイン</Typography>
                              </Button>
                            </Box>
                          </Box>
                        }
                        { uploadingStep === 2 && 
                          <Box className={styles.uploadingStep2}>
                            <Box>
                              <Typography className={styles.compSearch}>Comp Search</Typography>
                              <Box className={styles.selectionBox}>
                                <Box className={styles.selectBoxWidth}>
                                  <Typography className={styles.txtSize13}>企業ID</Typography>
                                  <CssTextField
                                    id={'companyId'}
                                    variant="outlined"
                                    fullWidth
                                    value={companyInfo.companyId}
                                    inputProps={{ className: styles.compInputField }}
                                    onChange={this.onChangeCompInfo('companyId')}
                                  />
                                </Box>
                                <Box className={styles.selectBoxWidth}>
                                  <Typography className={styles.txtSize13}>企業名</Typography>
                                  <CssTextField
                                    id={'companyName'}
                                    variant="outlined"
                                    fullWidth
                                    value={companyInfo.companyName}
                                    inputProps={{ className: styles.compInputField }}
                                    onChange={this.onChangeCompInfo('companyName')}
                                  />
                                </Box>
                                <Box className={styles.selectBoxWidth}>
                                  <Typography className={styles.txtSize13}>契約区分</Typography>
                                  <CssTextField
                                    select
                                    id={'contractType'}
                                    value={companyInfo.contractType}
                                    onChange={this.onChangeCompInfo('contractType')}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ className: styles.compInputField }}
                                    SelectProps={{
                                      MenuProps: {
                                        style: { zIndex: 3002 },
                                        getContentAnchorEl: () => null,
                                      }
                                    }}
                                  >
                                    { contractType
                                      ? contractType.map((v, i) => (
                                        <MenuItem value={i}>{v}</MenuItem>
                                      ))
                                      : <MenuItem />
                                    }
                                  </CssTextField>
                                </Box>
                              </Box>
                              <Box className={styles.selectionBox}>
                                <Box className={styles.selectBoxWidth}>
                                  <Typography className={styles.txtSize13}>担当パートナー</Typography>
                                  <CssTextField
                                    id={'partnerName'}
                                    variant="outlined"
                                    fullWidth
                                    value={companyInfo.partnerName}
                                    inputProps={{ className: styles.compInputField }}
                                    onChange={this.onChangeCompInfo('partnerName')}
                                  />
                                </Box>
                                <Box className={styles.selectBoxWidth}>
                                  <Typography className={styles.txtSize13}>ステータス</Typography>
                                  <CssTextField
                                    select
                                    id={'companyStatus'}
                                    value={companyInfo.companyStatus}
                                    onChange={this.onChangeCompInfo('companyStatus')}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ className: styles.compInputField }}
                                    SelectProps={{
                                      MenuProps: {
                                        style: { zIndex: 3002 },
                                        getContentAnchorEl: () => null,
                                      }
                                    }}
                                  >
                                    { statusList
                                      ? statusList.map((v, i) => (
                                        <MenuItem value={i}>{v}</MenuItem>
                                      ))
                                      : <MenuItem />
                                    }
                                  </CssTextField>
                                </Box>
                                <Box className={styles.selectBoxWidth}>
                                  <Box fullWidth className={styles.companyOnly}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={companyInfo.companyOnly}
                                          onChange={this.onChangeCompInfo('companyOnly')}
                                          name='companyOnly'
                                          className={styles.checkBox}
                                        />
                                      }
                                    label={<Typography className={styles.txtSize13}>担当企業のみ</Typography>}
                                    />
                                  </Box>
                                  <Box fullWidth className={styles.search}>
                                    <Button variant='contained' className={styles.searchBtn} onClick={()=>this.search()}> 
                                      <Typography className={styles.txtSize13}>検索</Typography>
                                    </Button>
                                    <Button variant='contained' className={styles.clearBtn} onClick={()=>this.clear()}>
                                      <Typography className={styles.txtSize13}>クリア</Typography>
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box className={styles.compSelectBox}>
                              <Typography className={styles.compSearch}>Comp Select</Typography>
                              <Box className={styles.tableBox}>
                                <TableContainer className={styles.tableContainer}>
                                  <Table className={styles.tablebody}>
                                    <TableBody>
                                      { companies && companies.map((row) => {
                                        return (
                                          <TableRow
                                            className={styles.tableRow}
                                            tabIndex={-1}
                                            key={row.index}>
                                            <TableCell className={styles.tableFirstCell}>{row.company_info}</TableCell>
                                            <TableCell className={styles.tablePersonCell}>{row.person}</TableCell>
                                            <TableCell align='center' className={styles.tableCell}>{row.status}</TableCell>
                                            <TableCell align='right' className={styles.tableLastCell}>
                                              <Button variant='contained' className={styles.selectBtn} onClick={()=>this.select(row)}>
                                                <Typography className={styles.txtSize14}>選択</Typography>
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                  { !companies || companies.length <= 0 && 
                                    <Box className={styles.notFound}>
                                      <Typography className={styles.notFoundTxt}>Not Found</Typography>
                                    </Box>
                                  }
                                </TableContainer>
                                <Box className={styles.compError}>
                                  { compError ? 
                                    <Typography className={styles.compErr}>｛{compError}｝</Typography>
                                  : <Typography align='right' className={styles.txtSize13}>最大100件まで表示</Typography>}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        }
                        { uploadingStep === 3 &&
                          <Box className={styles.uploadingStep3}>
                            <Typography className={styles.confirmScreenTitle}> 画像アップロード確認 </Typography>
                            <Typography className={styles.compInfoPink}>{selectedCompany.company_id}　{(selectedCompany.company_info).split('\n')[1]}</Typography>
                            <Box className={styles.imgTypeSelect}>
                              <Typography className={styles.txtSize13}>
                                画像種別
                                <span className={styles.requiredDiv}>必須</span>
                              </Typography>
                              <CssTextField
                                select
                                id={'imageType'}
                                value={imageTypVal}
                                onChange={e => this.setState({ imageTypVal: e.target.value })}
                                variant="outlined"
                                fullWidth
                                required
                                style={{ marginTop: '2px' }}
                                inputProps={{ className: styles.compInputField }}
                                SelectProps={{
                                  MenuProps: {
                                    style: { zIndex: 3002 },
                                    getContentAnchorEl: () => null,
                                  }
                                }}
                              >
                                <MenuItem value={1}>仕事画像</MenuItem>
                                <MenuItem value={4}>ロゴ画像</MenuItem>
                              </CssTextField>
                            </Box>
                            <Box className={styles.imgFileName}>
                              <Typography className={styles.txtSize13}>
                                ファイル名（半角英数字20文字以内、記号は「_」「.」「-」のみ可能）
                                <span className={styles.requiredDiv}>必須</span>
                              </Typography>
                              <Box display={'flex'} style={{ marginTop: '2px' }}>
                                <Box className={styles.imgNameInput}>
                                  <CssTextField
                                    id={'imageName'}
                                    variant="outlined"
                                    fullWidth
                                    value={imgFileName}
                                    required
                                    InputProps={{
                                      endAdornment: 
                                        <InputAdornment position="end">
                                          <Typography className={styles.imgEndTxt}>_日付-ランダム文字列-va</Typography>
                                        </InputAdornment>
                                    }}
                                    inputProps={{ className: styles.compInputField }}
                                    onChange={e => this.handleImgName(e.target.value)}
                                  />
                                </Box>
                                <Box className={styles.jpgAdornmentBox}>
                                  <Typography className={styles.jpgAdornment}>.jpg</Typography>
                                  <Typography className={styles.txtSize13_5}>残り </Typography>
                                  <Typography className={styles.memoPinkText}>{remainingNameText}</Typography>
                                  <Typography className={styles.txtSize13_5}>文字</Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box className={styles.memoInput}>
                              <Typography className={styles.txtSize13}>
                                メモ（全角{maxMemoText}文字まで）
                                <span className={`${styles.requiredDiv} ${styles.colorGray}`}>任意</span>
                              </Typography>
                              <Box display={'flex'} style={{ marginTop: '2px' }}>
                                <CssTextField 
                                  multiline
                                  fullWidth
                                  variant='outlined'
                                  value={memo}
                                  onChange={this.handleMemo()}
                                  className={styles.memoTextfield}
                                  inputProps={{ 
                                    className: styles.mInput
                                  }}
                                />
                                <Box className={styles.memoInfo} >
                                  <Typography className={styles.txtSize13_5}>残り </Typography>
                                  <Typography className={styles.memoPinkText}>{remainingMemoText}</Typography>
                                  <Typography className={styles.txtSize13_5}>文字程度</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        }
                        { uploadingStep === 4 && 
                          <Box className={styles.uploadingStep4}>
                            <Typography className={styles.txtSize16}>管理画面への登録が完了しました</Typography>
                            <Typography className={styles.registFileName}>{registImgName}</Typography>
                            { copySupport &&
                              <Button
                                variant='contained' 
                                className={styles.saveBtn}
                                onClick={()=>this.copyToClipboard()}
                                startIcon={<FilterNone style={{ fontSize: 14 }}/>}>
                                  <Typography className={styles.txtSize13}>Copy</Typography>
                              </Button>
                            }
                            <Typography className={styles.copyStatus}>{copyStatus === 'success' && 'コピーしました!'}</Typography>
                          </Box>
                        }
                      </Box>
                    </Box>
                    { uploadingStep === 3 &&
                      <Box className={styles.footer}>
                        <Box className={styles.blankBox}></Box>
                        <Box className={styles.btnBox}>
                          <Button 
                            variant='contained' 
                            startIcon={<GetApp style={{ fontSize: 14 }}/>}
                            className={styles.saveBtn}
                            onClick={()=>this.upload()}>
                              <Typography className={styles.txtSize13}>保存</Typography>
                          </Button>
                        </Box>
                        <Box className={styles.errorBox}>
                        { uploadError ?
                          <Typography className={styles.uploadError}>｛{uploadError}｝</Typography>
                        : upImgInputErr &&
                          <Typography className={styles.uploadError}>｛{upImgInputErr}｝</Typography>
                        }
                        </Box>
                      </Box>
                    }
                    { uploadingStep === 4 &&
                      <Box className={styles.closeButton}>
                        <Button 
                          variant='contained' 
                          className={styles.closeBtn}
                          onClick={()=>this.uploadComplete()}
                        >
                          <Typography className={styles.txtSize13}>閉じる</Typography>
                        </Button>
                      </Box>
                    }
                  </>}
                </Grid>
              }
            </Grid>
          </Grid>
          <Dialog 
            open={isJsonLarge}
            className={styles.largeFileAlert}
            aria-labelledby="large-json-alert-title"
            aria-describedby="large-json-alert-description">
            <DialogTitle id="large-json-alert-title" className={styles.dialogTitle}>
              <Info className={styles.infoIcon}/>
            </DialogTitle>
            <DialogContent id="large-json-alert-description" className={styles.dialogContent}>
              作成した画像のファイルサイズが許容サイズを超えているため、<br/>
              履歴に保存することができませんでした。
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <Button 
                variant='contained' 
                className={styles.closeBtn}
                onClick={()=>this.closeLargeFileAlert()}
              >
                <Typography className={styles.txtSize13}>閉じる</Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        }
      </>
    );
  }
}

export default SavingComponent;
