import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';

class TextParent extends React.Component {
  templateToStyle(style) {
    if(style) {
      if(style.before) { style.before = this.templateToStyle(style.before);}
      if(style.after) { style.after = this.templateToStyle(style.after);}
  
      if(style.border) {
        for(const key in style.border) {
          const upperKey = key.charAt(0).toUpperCase() + key.slice(1);;
          const border = style.border[key]
          style[`border${upperKey}`] = `${border.style} ${border.width} ${border.color}`
        }
      }
  
      return style;
    }
  }

  render() {
    const { style, children, className, full, parentRef } = this.props;
    const parentStyle = this.templateToStyle(style.parent);
    return (
      (style.parent) 
      ? <ParseStyleText
          style={parentStyle}
          className={`textParent ${className}`}
          full={full}
          parent={true}
          devRef={parentRef}
        >
          {children}
        </ParseStyleText>
      : children
    )
  }
}

export default TextParent;