import { connect } from 'react-redux';
import Editor from '@pages/editor/Editor';
import { requestDesign, updateDesign, getDesign } from '@common/components/entities/designs/DesignActions';
import { receiveElement, createElement } from '@common/components/entities/elements/ElementActions';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';
import * as Action from '@pages/editor/EditorAction';

const mapStateToProps = (state, ownProps) => {
  const design = state.entities.designs[ownProps.match.params.id];
  return {
    design,
    elements: (design) ? state.entities.elements : [],
    ...state.ui.editor,
    tagState: state.entities.tags.tagState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = {};
  const keys = Object.keys(Action);
  actions.updateTag = (...args) => dispatch(update(...args));
  actions.requestDesign = () => dispatch(requestDesign(ownProps.match.params.id));
  actions.getDesign = (payload) => dispatch(getDesign(payload));
  actions.updateDesign = (design) => dispatch(updateDesign(design));
  actions.receiveElement = (element) => dispatch(receiveElement(element));
  actions.createElement = (designId, element) => dispatch(createElement(designId, element));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
