import { connect } from 'react-redux';
import { changeColor } from '@pages/editor/designDrawer/DesignDrawerAction';
import ColorPalette from '@pages/editor/designDrawer/subDrawer/colorPalette/ColorPalette';
import * as Action from '@pages/editor/designDrawer/subDrawer/colorPalette/ColorPaletteAction';
import { changeDrawer } from '@pages/editor/designDrawer/DesignDrawerAction';

const mapStateToProps = (state) => {
  const drawerSettings = state.ui.drawer;
  return {
    drawerSettings,
    ...state.materials.colors,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  actions.changeColor = (...args) => dispatch(changeColor(...args));
  actions.changeDrawer = (...args) => dispatch(changeDrawer(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPalette);
