/* eslint-disable no-restricted-syntax */
import {
  FormControl, FormControlLabel, FormLabel, Typography, RadioGroup, Radio,
} from '@material-ui/core';
import lodash from 'lodash';
// assets

import { compareObject } from '@common/utils/ObjectUtils';

// components
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import SliderAndInput from '@components/utils/sliderAndInput/SliderAndInput';

// pages
import styles from '@pages/editor/workArea/designTools/textTools/components/dialogs/archDialog/ArchDialog.module.scss';
import '@pages/editor/workArea/designTools/textTools/components/dialogs/archDialog/ArchDialog.scss';

class ArchDialog extends React.Component {
  static valueText(value) {
    return `${value}px`;
  }

  constructor(props) {
    super(props);
    // effectの設定値
    this.effects = { max: 360, min: 0 };
    // スライダーの設定値
    this.sliderConfigs = { step: 1, min: this.effects.min, max: this.effects.max };
    // スライダーのマーク値
    this.marks = [
      { value: 0, label: '0°' },
      { value: 360, label: '360°' },
    ];
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  // onChanges

  onChangeArchAngle(newValue) {
    const { receiveElement, element } = this.props;
    const { elementableAttributes } = element;
    const updatedAttr = { ...elementableAttributes };
    updatedAttr.arch.angle.value = this.withinLimit(parseInt(newValue || 0, 10));
    receiveElement({ ...element, elementableAttributes: updatedAttr });
  }

  onChangeRadioButton() {
    return (e) => {
      const { receiveElement, element } = this.props;
      const { elementableAttributes } = element;
      const updatedAttr = { ...elementableAttributes };
      updatedAttr.arch.direction.value = parseInt(e.target.value, 10);
      receiveElement({ ...element, elementableAttributes: updatedAttr });
    };
  }

  withinLimit(value) {
    let updatedValue = value;
    if (value >= this.effects.max) {
      updatedValue = this.effects.max;
    } else if (value <= this.effects.min) {
      updatedValue = this.effects.min;
    }
    return updatedValue;
  }

  render() {
    const { className, element } = this.props;
    const { decorationId } = element;
    const { arch } = element.elementableAttributes;
    const classes = {
      colorSecondary: styles.colorSecondary,
      checked: styles.checked,
    };
    const formControlLabelClasses = {
      label: styles.label,
    };
    return (
      <CustomPaper className={`${styles.archDialogWrapper} ${styles.scrollable} ${className} archDialog`}>
        <div className={styles.titleAndColorSelection}>
          <Typography variant="subtitle1" color="textSecondary">{arch.text}</Typography>
        </div>
        <div className={`${styles.settingValue} archDialog-InputAdjust`}>
          <FormControl component="fieldset" disabled={!!(decorationId)}>
            <FormLabel component="legend">
              <Typography variant="body1" color="textSecondary">{arch.direction.text}</Typography>
            </FormLabel>
            <RadioGroup aria-label="direction" name="arcDirection" value={arch.direction.value} onChange={this.onChangeRadioButton()} row>
              <FormControlLabel value={1} control={<Radio classes={classes} />} label="上向き" classes={formControlLabelClasses} />
              <FormControlLabel value={-1} control={<Radio classes={classes} />} label="下向き" classes={formControlLabelClasses} />
            </RadioGroup>
          </FormControl>

          <SliderAndInput
            styles={styles}
            sliderConfig={this.sliderConfigs}
            label={arch.angle.text}
            marks={this.marks}
            value={arch.angle.value}
            unit="°"
            onChangeInput={(e) => { this.onChangeArchAngle(e.target.value); }}
            onChangeSlider={(e, newValue) => { this.onChangeArchAngle(newValue); }}
            disabled={!!(decorationId)}
          />
        </div>
      </CustomPaper>
    );
  }
}

export default ArchDialog;
