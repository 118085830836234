import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  GET_HISTORY: ActionCreatorFactory('designHistory', 'GET_HISTORY'),
  UPDATE_KEEP_FLAG: ActionCreatorFactory('designHistory', 'UPDATE_KEEP_FLAG'),
  CLOSE_ALERT: ActionCreatorFactory('designHistory', 'CLOSE_ALERT'),
};

export const getHistory = () => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.GET_HISTORY.start, 
    payload: { getHistoryWait: true, historyData: null, error: null }, 
  });

  await Axios.get(`${config.apiServer.baseUrl}/smallImage/history`).then((res) => {
    if (res.status === 200) {
      dispatch({
        type: ACTION_TYPE.GET_HISTORY.success,
        payload: { getHistoryWait: false, historyData: res.data, error: null }
      })
    }
  }).catch((e) => {
    dispatch({
      type: ACTION_TYPE.GET_HISTORY.fail,
      payload: { getHistoryWait: false, historyData: null, error: e }
    });
  })
};

export const updateKeepFlag = (id, flag, user) => (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.UPDATE_KEEP_FLAG.start, 
    payload: { wait: true, isFlagFull: false, error: null } 
  });
  Axios.put(`${config.apiServer.baseUrl}/smallImage/updateFlag`, {id, flag}).then((res) => {
    if (res.status === 200) {
      if (!res.data.isFlagFull) {
        if (flag) {
          gtag('event', 'click_keep_flag_off', {
            event_name : 'click_keep_flag_off',
            user_name : user.name,
            user_email : user.mail,
          });
        } else {
          gtag('event', 'click_keep_flag_on', {
            event_name : 'click_keep_flag_on',
            user_name : user.name,
            user_email : user.mail,
          });
        }
      }
      dispatch({ 
        type: ACTION_TYPE.UPDATE_KEEP_FLAG.success, 
        payload: { 
          wait: false, 
          isFlagFull: res.data.isFlagFull, 
          historyData: res.data.historyData && res.data.historyData,
          error: null } 
      });
    }
  }).catch((error) => {
    console.log(error);
    dispatch({ 
      type: ACTION_TYPE.UPDATE_KEEP_FLAG.fail, 
      payload: { wait: false, isFlagFull: false, error: { message: 'キープフラグの更新に失敗しました' } } });
  });
}

export const closeAlert = () => (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.CLOSE_ALERT.success, 
    payload: { isFlagFull: false } });
}