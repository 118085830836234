function SvgArch(props, svgRef) {
  return (
    <svg
      {...props}
      id="arch_svg__\u30EC\u30A4\u30E4\u30FC_7"
      data-name="\u30EC\u30A4\u30E4\u30FC 7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.03 16"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <defs>
        <style>{`.arch_svg__cls-1{fill: ${props.color}}`}</style>
      </defs>
      <path
        className="arch_svg__cls-1"
        d="M854.28 474.36l-3 7.2h1.42l.64-1.6h3.06l.65 1.6h1.43l-3-7.2zm-.53 4.59l1.15-3.06L856 479z"
        transform="translate(-842.49 -471.76)"
      />
      <path
        className="arch_svg__cls-1"
        d="M865.5 478.43l2-3.49-3.5-2-.79 1.37a18.66 18.66 0 00-8.71-2.53 15.75 15.75 0 00-8 2.06l-.52-.9-3.5 2 2 3.49 1.17-.67 3.36 5.83-1.16.67 2 3.5 3.5-2-.33-.58h.06a4.48 4.48 0 011.37-.3v.05a7.79 7.79 0 011.68.19 6 6 0 01.71.22l-.26.45 3.5 2 2-3.5-1.17-.67 3.37-5.83zm-1-3.67l1.16.67-.67 1.16-1.17-.67zm-20.19.67l1.17-.67.67 1.16-1.17.68zm6.06 10.49l-.68-1.17 1.17-.67.67 1.17zm9.24 0l-1.17-.68.67-1.16 1.17.67zm.18-3l-1.17-.67-.75 1.31a5.37 5.37 0 00-1.49-.57 8.84 8.84 0 00-1.91-.22 5 5 0 00-1.59.34 7.49 7.49 0 00-.81.38l-.72-1.24-1.16.67-3.37-5.82 1.17-.68-.73-1.26a17.21 17.21 0 017.24-1.91 17.56 17.56 0 018 2.3l-.5.87 1.16.68z"
        transform="translate(-842.49 -471.76)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArch);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
