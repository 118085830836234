import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';
import { DateTime } from 'luxon';

export const ACTION_TYPE = {
  GET_USER_INFORMATION: ActionCreatorFactory('accountMenu', "GET_USER_INFORMATION"),
  CHANGE_ALLOW_WIZARD: ActionCreatorFactory('accountMenu', "CHANGE_ALLOW_WIZARD"),
};

export const getUserInformation = (e) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_USER_INFORMATION.start, payload: { wait: true, isGetUser: false, error: null } });
  const token = localStorage.getItem(config.accessTokenKey);
  if (token) {
    Axios.get(`${config.apiServer.baseUrl}/user/me`).then((res) => {
      if (DateTime.fromISO(res.data.expires_at).diffNow('days').days < 0) {
        localStorage.removeItem(config.accessTokenKey);
        sessionStorage.removeItem('user_id');
        dispatch({ type: ACTION_TYPE.GET_USER_INFORMATION.fail, payload: { wait: false, error: '再度ログインし直してください。'} });
      } else {
        setTimeout(() => {
          dispatch({ 
            type: ACTION_TYPE.GET_USER_INFORMATION.success, 
            payload: { wait: false, isGetUser: true, error: null, user: {name: res.data.name, mail: res.data.email, allowWizard: res.data.allow_wizard, id: res.data.id } }})
        }, 500);
      }
    }).catch((error) => {
      console.log(error);
      dispatch({ type: ACTION_TYPE.GET_USER_INFORMATION.fail, payload: { wait: false, isGetUser: false, error: 'ユーザ情報の取得に失敗しました。' } });
    });
  } else {
    dispatch({ type: ACTION_TYPE.GET_USER_INFORMATION.fail, payload: { wait: false, error: '認証トークンを保持していません。再度ログインし直してください。'} });
  }
};

export const changeAllowWizard = (body) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.CHANGE_ALLOW_WIZARD.start, payload: { wait: true, isGetUser: false, error: null } });
  const token = localStorage.getItem(config.accessTokenKey);
  if (token) {
    Axios.put(`${config.apiServer.baseUrl}/user/me`,{...body}).then((res) => {
      if (DateTime.fromISO(res.data.expires_at).diffNow('days').days < 0) {
        localStorage.removeItem(config.accessTokenKey);
        sessionStorage.removeItem('user_id');
        dispatch({ type: ACTION_TYPE.CHANGE_ALLOW_WIZARD.fail, payload: { wait: false, error: '再度ログインし直してください。'} });
      } else {
        setTimeout(() => {
          dispatch({ 
            type: ACTION_TYPE.CHANGE_ALLOW_WIZARD.success, 
            payload: { wait: false, isGetUser: true, error: null, user: {name: res.data.name, mail: res.data.email, allowWizard: res.data.allow_wizard, id: res.data.id } }})
        }, 500);
      }
    }).catch((error) => {
      console.log(error);
      dispatch({ type: ACTION_TYPE.CHANGE_ALLOW_WIZARD.fail, payload: { wait: false, isGetUser: false, error: 'ユーザ情報の更新に失敗しました。' } });
    });
  } else {
    dispatch({ type: ACTION_TYPE.CHANGE_ALLOW_WIZARD.fail, payload: { wait: false, error: '認証トークンを保持していません。再度ログインし直してください。'} });
  }
}