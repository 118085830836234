// module
import {
  Input, Fab, FormControl, InputLabel, FormHelperText,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import BeatLoader from 'react-spinners/BeatLoader';
import { toastr } from 'react-redux-toastr';

import config from '@assets/config';
// common
import { getTextRect } from '@common/utils/TextOperation';
// pages
import styles from '@pages/editor/designDrawer/text/TextDrawer.module.scss';
import '@pages/editor/designDrawer/text/TextDrawer.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';

class TextDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;
    this.state = {
      text: '',
      innerError: false,
    };
    this.tagConfigs = {
      professional: { name: '職種カテゴリ', select: true },
      targetAttr: { name: 'ターゲット属性', select: true },
      feature: { name: '特徴', select: true },
    };
    this.isUpdate = false;
    this.textInputRef = React.createRef();

    this.defaultTextDecorate = {
      fontWeight: 900, fontSize: 80, fontFamily: 'UDShinGoPr6N', color: '#000',
    };
    this.text = '未経験歓迎!';
    this.handleChangeText = this.handleChangeText.bind(this);

    unmount();
  }

  componentDidMount() {
    const { mount } = this.props;
    mount();
    this.fetch();
  }

  shouldComponentUpdate(nextProps) {
    const { texts } = this.props;
    if (texts !== nextProps.texts && !this.isUpdate) {
      this.isUpdate = true;
    } else if (this.isUpdate) {
      this.isUpdate = false;
    }
    return true;
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  handleChangeText(e) {
    const text = e.target.value;
    if (text.length <= 50) {
      this.setState({ text, innerError: false });
    } else {
      this.setState({ text, innerError: true });
    }
  }

  addElement({
    text, select_text, fontWeight, fontSize, fontFamily,
  }) {
    if (select_text) {
      gtag('event', 'select_word_input', { select_word_input: select_text });
      text = select_text;
    } else {
      gtag('event', 'free_word_input', { free_word_input: text });
    }
    const { addElement } = this.props;
    const rect = getTextRect({ text, font: `${fontWeight} ${fontSize}px ${fontFamily}`, height: fontSize * 1.2 });
    const element = {
      elementableType: 'Text',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 20,
      elementableAttributes: {
        color: '#000000',
        text,
        fontFamily,
        fontWeight,
        fontSize,
        height: rect.height,
        width: rect.width + 10,
      },
    };
    addElement(element);
    this.setState({
      text: '',
    });
  }

  fetch() {
    const {
      fetch, tags, mount,
    } = this.props;
    const { tagState } = tags;
    mount();
    const tagMap = {};
    for (const key in this.tagConfigs) {
      tagMap[key] = tagState[key];
    }
    const payload = { tags: tagMap };
    fetch(payload);
  }

  render() {
    const { text: textValue, innerError } = this.state;
    const { texts, plusTextClickCount, error } = this.props;
    return (
      <>
        <div className={styles.freeInputArea}>
          {error && toastr.error('error', error.message)}
          <FormControl className={ innerError ? `${styles.formControl} TextDrawerSelect ${styles.bgError}` :`${styles.formControl} TextDrawerSelect`}>
            <InputLabel id="textInput-label" htmlFor="standard-full-width" error={innerError}>テキストを入力</InputLabel>
            <Input
              className={styles.textField}
              style={{ overflow: 'hidden' }}
              id="standard-full-width"
              value={textValue}
              onChange={this.handleChangeText}
              error={innerError}
              ref={this.textInputRef}
            />
            <FormHelperText error={innerError} style={innerError ? {} : { display: 'none' }}>
              <span className={styles.helperText}>{config.validate.textLength}</span>
            </FormHelperText>
          </FormControl>
          <Fab
            className={styles.addIcon}
            style={{ marginLeft: '2%' }}
            size="medium"
            onClick={() => {
              if (!innerError) {
                this.addElement({ text: textValue, ...this.defaultTextDecorate });
              } else {
                this.textInputRef.current.focus();
              }
            }}
          >
            <Add style={{ fontSize: 25 }} />
          </Fab>
        </div>
        <div className={scrollbar.customScroll}>
          <div className={styles.textDrawer}>
            <div className={styles.itemList}>
              {(texts) ? texts.map((text) => (
                <div
                  key={text.id}
                  className={`${styles.item}`}
                  onClick={() => {
                    this.addElement({
                      select_text: text.content, ...this.defaultTextDecorate,
                    });
                    plusTextClickCount(text.id);
                  }}
                >
                  { text.content }
                </div>
              ))
                : (
                  <>
                    {
                  [...Array(20)].map((value, idx) => (
                    <div
                      key={`text-${String(idx)}`}
                      className={`${styles.item} ${styles.loading}`}
                    >
                      <BeatLoader />
                    </div>
                  ))
                }
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TextDrawer;
