import {
  Input, Slider, Typography, InputAdornment,
} from '@material-ui/core';
import styles from '@pages/editor/workArea/designTools/textTools/components/dialogs/letterSpacingDialog/LetterSpacingDialog.module.scss';
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import { getTextRect } from '@common/utils/TextOperation';

class LetterSpacingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.letterSpace = { min: -5, max: 50 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  onChangeValue(newValue) {
    const { element, receiveElement } = this.props;
    const { elementableAttributes } = element;
    const updatedElementableAttributes = { ...elementableAttributes };
    const changedValue = this.withInRange(newValue || 0);
    updatedElementableAttributes.letterSpacing = changedValue;

    receiveElement({ ...element, elementableAttributes: updatedElementableAttributes });
  }

  genNewFrameWidthAndHeight(lineHeight) {
    const { element } = this.props;
    const {
      fontWeight, fontSize, fontFamily, text, width,
    } = element.elementableAttributes;
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const newLineCount = (text.match(/(\n|\r|\rn)/g) || []).length;
    const calcedHeight = fontSize * (parseInt(lineHeight, 10) / 100) * (newLineCount + 1);
    const rect = getTextRect({
      text, font, height: calcedHeight, width,
    });
    return {
      height: rect.height,
      width: rect.width,
    };
  }

  withInRange(strInt) {
    let resultInt = parseInt(strInt, 10);
    if (this.max <= resultInt) {
      resultInt = this.max;
    } else if (this.min >= resultInt) {
      resultInt = this.min;
    }
    return resultInt;
  }

  render() {
    const { className, element } = this.props;
    const { letterSpacing } = element.elementableAttributes;

    const marks = [
      { value: -5, label: '-5px' },
      { value: 10, label: '10px' },
      { value: 25, label: '25px' },
      { value: 50, label: '50px' },
    ];

    const sliderConfig = {
      defaultValue: 0,
      step: 1,
      min: this.letterSpace.min,
      max: this.letterSpace.max,
    };

    return (
      <CustomPaper className={`${className} ${styles.letterSpaceWrapper}`}>
        <div className={styles.letterSpacing}>
          <Typography variant="caption" color="textSecondary">字間</Typography>
          <div className={`${styles.sliders} letterSpacing-InputAdjust`}>
            <div className={styles.sliderLabels}>
              <Input
                value={letterSpacing || 0}
                className={styles.inputNumber}
                onChange={(e) => this.onChangeValue(e.target.value)}
                type="number"
                endAdornment={
                  <InputAdornment position="end">px</InputAdornment>
                }
              />
            </div>
            <Slider
              defaultValue={sliderConfig.defaultValue}
              step={sliderConfig.step}
              min={sliderConfig.min}
              max={sliderConfig.max}
              marks={marks}
              onChange={(e, newValue) => this.onChangeValue(newValue)}
              value={letterSpacing || 0}
              aria-labelledby="discrete-slider-custom"
              getAriaValueText={(value) => (`${value}px`)}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
      </CustomPaper>
    );
  }
}

export default LetterSpacingDialog;
