// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParseStyleText-module__parsePseudoStyleText--1kzUO{width:100%;height:100%}.ParseStyleText-module__parsePseudoStyleText--1kzUO .ParseStyleText-module__after--3Waok,.ParseStyleText-module__parsePseudoStyleText--1kzUO .ParseStyleText-module__before--3Ao2G{display:inline}.ParseStyleText-module__parsePseudoStyleText--1kzUO .ParseStyleText-module__verticalCenter--eo5TY{position:absolute;top:50% !important;transform:translate(0, -50%);-webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%)}.ParseStyleText-module__backgroundClipText--3yDHF{-webkit-background-clip:text !important;background-clip:text}\n", ""]);
// Exports
exports.locals = {
	"parsePseudoStyleText": "ParseStyleText-module__parsePseudoStyleText--1kzUO",
	"after": "ParseStyleText-module__after--3Waok",
	"before": "ParseStyleText-module__before--3Ao2G",
	"verticalCenter": "ParseStyleText-module__verticalCenter--eo5TY",
	"backgroundClipText": "ParseStyleText-module__backgroundClipText--3yDHF"
};
module.exports = exports;
