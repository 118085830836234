import {
  FormControl, Fab, Grid, InputLabel, MenuItem, Select, Button
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import path from 'path';
import BeatLoader from 'react-spinners/BeatLoader';
import EllipsisText from "react-ellipsis-text";
import { toastr } from 'react-redux-toastr';

import config from '@assets/config';
import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';
import { calcPosMiddle } from '@common/utils/EditUtils';
import DoublyScrollObserver from '@components/doublyScrollObserver/DoublyScrollObserver';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import styles from '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawer.module.scss';
import '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawer.scss';

class WallPaperImagesDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;

    this.tagConfigs = {
      imageImagine: { name: '画像イメージ', select: true },
    };
    this.isUpdate = false;

    unmount();
  }

  componentDidMount() {
    const { count, mount } = this.props;
    mount();
    count();
  }

  shouldComponentUpdate(nextProps) {
    const { wallPaperImages } = this.props;
    if (wallPaperImages !== nextProps.wallPaperImages && !this.isUpdate) {
      this.isUpdate = true;
    } else if (this.isUpdate) {
      this.isUpdate = false;
    }
    return true;
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
    clearInterval(this.intervalId);
  }

  /**
   * @param {string} tagName 英語のタグ名
   */
  onChangeTag(tagName) {
    const { tags, updateTag } = this.props;
    const { tagState } = tags;
    return (e) => {
      const updatedTag = { ...tagState };
      updatedTag[tagName] = e.target.value;
      updateTag(updatedTag);
    };
  }

  onClickResearch(limit) {
    return () => {
      const {
        fetch, tags, mount, color,
      } = this.props;
      const { tagState } = tags;
      mount();
      const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
        .filter(Boolean).join(',');
      const payload = { tags: tagListStr, limit: limit || 100, color };
      fetch(payload);
    };
  }

  onClickColor(targetColor) {
    document.getElementById('noElement').style.backgroundColor = config.wall_paper_colors.selectable_colors[targetColor];
    const {
      fetch, tags, mount,
    } = this.props;
    const { tagState } = tags;
    mount();
    const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
      .filter(Boolean).join(',');
    const payload = { tags: tagListStr, limit: 100, color: targetColor };
    fetch(payload);
  }

  fetch() {
    return (pageCounter, unitOfPage, limit) => {
      const {
        fetch, tags, mount, color,
      } = this.props;
      const { tagState } = tags;
      mount();

      const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
        .filter(Boolean).join(',');
      const offset = pageCounter * unitOfPage;
      const payload = {
        tags: tagListStr, limit, offset, color,
      };
      fetch(payload);
    };
  }

  addElement({ url, id }) {
    const { addElement, elements, receiveElement } = this.props;
    gtag('event', 'wallPaperImages_file_name', {'wallPaperImages_file_name': path.basename(url,'.png')});
    getImageDimensions(url).then(
      (image) => {
        const resizedImageDimensions = resizeImage({
          width: image.width,
          height: image.height,
        });
        const [posX, posY] = calcPosMiddle({
          width: resizedImageDimensions.width,
          height: resizedImageDimensions.height,
        });
        const element = {
          id,
          elementableType: 'WallPaperImage',
          transparency: 1,
          zIndex: 0,
          posX,
          posY,
          elementableAttributes: {
            url,
            width: resizedImageDimensions.width,
            height: resizedImageDimensions.height,
            naturalWidth: image.naturalWidth,
            naturalHeight: image.naturalHeight,
          },
        };
        if(Object.keys(elements).length !== 0){
          {Object.keys(elements).forEach((elementId, index) => {
            const ele = elements[elementId];
            if ((index === Object.keys(elements).length-1) && (ele.elementableType !== 'WallPaperImage')) {
              addElement(element);
              return;
            }
            if (ele.elementableType === 'WallPaperImage') {
              ele.zIndex = 0;
              ele.posX = posX;
              ele.posY = posY;
              ele.elementableAttributes.url = url;
              ele.elementableAttributes.width = resizedImageDimensions.width;
              ele.elementableAttributes.height = resizedImageDimensions.height;
              ele.naturalWidth = image.naturalWidth;
              ele.naturalHeight = image.naturalHeight;
              ele._destroy = false;
              receiveElement({ ...ele });
            }
          })}
        } else {
          addElement(element);
        }
      },
    )
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    const {
      wallPaperImages, tags, wallPaperImagesCount, color, offset, error
    } = this.props;
    const currentOffset = offset || 0;
    const { allTags, tagState } = tags;
    return (
      <>
        <div className={styles.container}>
        {error && toastr.error('error', error.message)}
          <Grid container spacing={2} className={styles.adjust}>
            {
              Object.keys(this.tagConfigs).map((tagName) => {
                const tagConfig = this.tagConfigs[tagName];
                return (
                  <Grid item xs={4} key={tagName}>
                    <FormControl variant="outlined" className={`${styles.formControl} WallPaperImagesDrawerSelect`}>
                      <InputLabel id={`${tagName}-label`}>
                        <EllipsisText
                          text={tagConfig.name}
                          length={5}
                        />
                      </InputLabel>
                      <Select
                        labelid={tagName}
                        id={tagName}
                        onChange={this.onChangeTag(tagName)}
                        className={`${styles.input} muiSelectIcon`}
                        value={tagState[tagName]}
                        placeholder={tagConfig.name}
                      >
                        <MenuItem value="">未選択</MenuItem>
                        {
                          (() => {
                            if (allTags !== undefined) {
                              if (allTags[tagConfig.name] !== undefined) {
                                return allTags[tagConfig.name].map((tag) => (
                                  <MenuItem
                                    key={tag.id}
                                    value={tag.id}
                                  >
                                    {tag.name}
                                  </MenuItem>
                                ));
                              }
                            }
                            return '';
                          })()
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })
            }
            <Grid item xs={8}>
              <Button variant="contained"
                aria-label="search"
                style={{ marginRight: 8, marginTop: 6, fontSize: 12 }}
                onClick={this.onClickResearch()}>
                検索
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" alignment="center" justify="space-evenly" spacing={2} className={styles.adjust}>
            <div className={styles.colors}>
              {Object.keys(config.wall_paper_colors.selectable_colors).map((colorKey) => {
                let className = styles.colorDiv;
                if (colorKey === color) {
                  className += ` ${styles.active}`;
                }
                return (
                  <div
                    className={className}
                    style={{ backgroundColor: config.wall_paper_colors.selectable_colors[colorKey] }}
                    key={colorKey}
                    onClick={() => { this.onClickColor(colorKey) }}
                  />
                );
              })}
            </div>
          </Grid>
        </div>
        <div className={scrollbar.customScroll} id={scrollbar.customScroll}>
          <div className={styles.wallPaperImagesDrawer}>
            <DoublyScrollObserver
              className={styles.masonry}
              callBack={this.fetch()}
              observeTargetQueryName={`.${styles.observeTarget}`}
              rootQueryName={`.${scrollbar.customScroll}`}
              isUpdate={this.isUpdate}
              maxLength={wallPaperImagesCount}
              offset={currentOffset}
            >
              {(wallPaperImages !== undefined)
                ? (
                  <>
                    {(wallPaperImages.length !== 0)
                      ? wallPaperImages.map((imageObject, idx) => (
                        <LazyLoad
                          key={`wallPaperImageDrawer-${currentOffset + idx}`}
                          className={`${styles.observeTarget} ${styles.masonItem}`}
                          scrollContainer={`.${scrollbar.customScroll}`}
                          style={{
                            height: config.thumbnail_images.height,
                            width: config.thumbnail_images.width,
                          }}
                          placeholder={<div className={`${styles.masonItem} ${styles.loading}`}><BeatLoader /></div>}
                        >
                          <i style={{ paddingBottom: `${(config.thumbnail_images.height / config.thumbnail_images.width) * 100.0}%` }} />
                          <img
                            src={imageObject.thumbnail_url}
                            alt={path.basename(imageObject.thumbnail_url).split('.')[0]}
                            onClick={() => this.addElement({ url: imageObject.url })}
                            className={styles.image}
                          />
                        </LazyLoad>
                      ))
                      : ''}
                    {(wallPaperImages !== undefined && wallPaperImages.length % 2 === 0)
                      ? ''
                      : (
                        <div
                          className={`${styles.masonItem} ${styles.observeTarget}`}
                          style={{
                            height: config.thumbnail_images.height,
                            width: config.thumbnail_images.width,
                          }}
                        />
                      )}
                  </>
                )
                : <>
                {
                  [...Array(100)].map((value, idx) => (
                    <div 
                      key={`wallPaperImageDrawer-${currentOffset + idx}`} 
                      style={{
                        height: config.thumbnail_images.height,
                        width: config.thumbnail_images.width,
                      }}
                      className={`${styles.masonItem} ${styles.observeTarget} ${styles.loading}`}
                    >
                      <BeatLoader />
                    </div>
                  ))
                }
                </>}
            </DoublyScrollObserver>
          </div>
        </div>
      </>
    );
  }
}

export default WallPaperImagesDrawer;
