function SvgMove(props, svgRef) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <path
        fillOpacity={0.54}
        d="M24 12l-5.45-5.46v3.28h-4.37V5.46h3.27L12 0 6.54 5.46h3.28v4.36H5.46V6.54L0 12l5.46 5.45v-3.27h4.36v4.37H6.54L12 24l5.45-5.45h-3.27v-4.37h4.37v3.27L24 12z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMove);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
