import React from 'react';
import { 
	Button, 
} from '@material-ui/core';
import Element from '@components/elements/Element';
import styles from './LogoCutModal.module.scss';
import Wait from '@components/wait/WaitComponent';
import path from 'path';
import { toastr } from 'react-redux-toastr';
import config from '@assets/config';
import { resizeImage } from '@common/utils/ImageOperation.js'

class LogoCutModal extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
		}
	}

	closeModal(){
		const { onRequestClose, tmpToggleDialogs } = this.props;
		tmpToggleDialogs(true);
		onRequestClose();
	}

	save(url) {
		const { receiveElement, element } = this.props;
		const updatedElementAttrs = { ...element.elementableAttributes }
		updatedElementAttrs.cutOut = true;
		updatedElementAttrs.lCutOutUrl = url;
		receiveElement({...element, elementableAttributes: updatedElementAttrs});
		this.closeModal();
  }

	async cutOutLogo(element){
		gtag('event', 'click_logocut',{
			'event_name' : 'logoCutOut',
		});
		const { cutOutLogo } = this.props;
		const file = element.elementableAttributes.url;
		const fileName = path.basename(file);
		await cutOutLogo(file, fileName);
	}

	render() {
		const { element, zoom, status, results, imgName, loading, error } = this.props;
		const elementName = path.basename(element.elementableAttributes.url);
		const elementAttr = element.elementableAttributes;
		const imageStyle = resizeImage({height: elementAttr.height, width: elementAttr.width, maxHeight: 300, maxWidth: 400, strict: true});

		return (
			<div className={styles.AppBody}>
				{error && toastr.error('error', error.message)}
				<div style={{ marginTop: 5 }} className='titleAndImageWrapper'>
					<div className={styles.headText}>
						ロゴ・イラスト切り抜き
					</div>
					<div className={loading ? styles.loadingDiv : styles.imageBody}>
					{ loading ? <Wait active={loading}/> :
						element && !element._destroy ?
							status == 'success' && imgName === elementName ?
								<div className={styles.imageElement} style={{background: `no-repeat center url("${config.s3Url}wall_paper_image/background_003_black.png")`}}>
									<img src={"data:image/jpeg;base64,"+results.image} style={{...imageStyle}}/>
								</div>
							: <div className={styles.imageElement} style={{background: `no-repeat center url("${config.s3Url}wall_paper_image/background_003_black.png")`}}>
									<img src={element.elementableAttributes.url} style={{...imageStyle}}/>
								</div>
						: null
					}
					</div>
				</div>
				<article className={styles.logoText}>
					<span>※「ロゴ・イラスト切り抜き」機能を実行すると、背景やロゴ・イラストの白色の箇所が透明になります。<br/>事前にお客様へロゴの取り扱いについて確認をお願い致します。</span><br/>
					<span>※ 背景を切り抜けるのは、背景が単色のロゴ・イラストに限ります。</span><br/>
					<span>※ 本機能では背景色と同じ色の部分も透明になります。ご注意ください。<br/>例)背景が白の場合、白い服も透明になります。</span><br/>
					<span>※ 複数のロゴ・イラストが含まれる場合はトリミング機能も併用して切り出してください。</span>
				</article>
				<div className={styles.BtnWizard}>
					<div className={styles.cutout_btn}>
						<Button size="small" variant="contained" onClick={()=>this.cutOutLogo(element)}>
							<span className={styles.actionButtonText}>切り抜きを実行</span>
						</Button>
					</div>
					<div className={styles.rightaction}>     
						<div className={styles.leftBtn}>
							<Button size="small" variant="contained"
							onClick={() => this.save("data:image/jpeg;base64,"+results.image)}>
								<span className={styles.buttonText}>保存</span>
							</Button>
						</div>
						<div className={styles.rightBtn}>
							<Button size="small" variant="contained" onClick={() => this.closeModal()}>
								<span className={styles.buttonText}>キャンセル</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LogoCutModal;