/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import BeatLoader from 'react-spinners/BeatLoader';

import configObject from '@assets/config';
import {
  defaultDecoration, templateToReactName, templateToStyle, templateToTextElement,
} from '@common/utils/TextOperation';
import { pickUpValue, setRecursiveObject, compareObject } from '@common/utils/ObjectUtils';

import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';
import styles from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawer.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';

class FontDecorationDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { unmount, drawerSettings } = this.props;

    this.defaultTextDecorate = {
      text: '未経験OK', fontWeight: 900, fontSize: 80, fontFamily: 'UDShinGoPr6N', color: '#000',
    };
    this.changeColorOnChange = {
      border: this.changeBorderColor(),
      fontColor: this.changeFontColor(),
      fontShadow: this.changeShadowColor(),
      wallPaper: this.changeWallPaper(),
    };

    unmount();
  }

  componentDidMount() {
    const { mount, fetch } = this.props;
    mount();
    fetch();
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  changeBorderColor() {
    return async (e) => {
      await this.changeColor(['border', 'color'], e.color, ['changeableInformation', 'configs']);
    };
  }

  changeFontBorderColor(idx) {
    return async (e) => {
      await this.changeColor(['border', 'configs', `${idx}`, 'color'], e.color, ['changeableInformation']);
    };
  }

  changeFontColor() {
    return async (e) => {
      const withOut = ['changeableInformation', 'border', 'fontBorders', 'fontShadows', 'glow', 'shadow', 'background', 'wallPaper'];
      await this.changeColor(['color'], e.color, withOut);
    };
  }

  changeShadowColor() {
    return async (e) => {
      const changedElement = await this.changeColor(['shadow', 'configs', 'color'], e.color, ['changeableInformation'], true);
      await this.changeColor(['glow', 'color'], e.color, ['changeableInformation'], false, changedElement);
    };
  }

  changeWallPaper() {
    return async (e) => {
      await this.changeColor(['background'], e.color, ['changeableInformation']);
    };
  }

  changeDecoration(template, name, id, number) {
    gtag('event', 'font_decoration', { font_decoration: `${number}_${name}` });
    const {
      changeDecoration, drawerSettings, fontDecorations, elements,
    } = this.props;
    const element = elements[drawerSettings.decorationTarget];
    let changeableConfigs;
    if ((!element.changeableConfigs || element.decorationId !== id) && fontDecorations) {
      changeableConfigs = this.genChangeableColor(template);
    }
    const templateElement = templateToTextElement(template, this.defaultTextDecorate);
    const decoration = {
      changeableConfigs,
      elementableAttributes: { ...defaultDecoration, ...this.defaultTextDecorate, ...templateElement },
    };
    delete decoration.elementableAttributes.text;
    changeDecoration(decoration, drawerSettings.decorationTarget, { decorationName: name, decorationId: id });
  }

  async changeColor(keys, color, withOut = [], returnObj = false, providedElement = null) {
    const { receiveElement, drawerSettings, elements } = this.props;
    let element = providedElement || elements[drawerSettings.decorationTarget];
    element = await setRecursiveObject(element, keys, color, withOut);
    if (returnObj) {
      return element;
    }
    receiveElement({ ...element });
  }

  toggleSubDrawer({
    subDrawerId, color = undefined, onChange, changeColor, returnToSubDrawer = null, allowGradient = false, targetName,
  }) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      const nextDrawerSettings = { ...drawerSettings };
      if (drawerSettings.closed) { nextDrawerSettings.closed = false; }
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
      if (color) {
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: configObject.colorTitle['フォント装飾'][targetName],
          colorType: 'unit',
          allowGradient: true,
          changeColor,
          returnToSubDrawer,
          allowGradient,
          changeTarget: 'fontDecoration',
        };
      }

      if (drawerSettings.colorPalette.changeTarget !== this.changeColorTarget
        || nextDrawerSettings.subDrawer !== subDrawerId) {
        // 変更対象が変わった時
        nextDrawerSettings.isSubDrawerOpen = true;
        nextDrawerSettings.closed = false;
      } else if (drawerSettings.closed) {
        nextDrawerSettings.isSubDrawerOpen = true;
      } else {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
      }
      nextDrawerSettings.subDrawer = subDrawerId;
      nextDrawerSettings.onChange = onChange;
      nextDrawerSettings.prevDrawerSettings = { ...drawerSettings };
      changeDrawer(nextDrawerSettings);
    };
  }

  genChangeableColor(template) {
    const changeableColor = template.changeableInformation.color;
    const searchedTemplate = { ...template };
    if (!changeableColor.changeable) { return ''; }
    const changeableConfigs = {};
    const isBorderChangeable = (border) => (
      Object.keys(border).reduce((key1, key2) => (border[key1] || border[key2]))
    );

    if (isBorderChangeable(changeableColor.border)) {
      const withOut = ['changeableInformation', 'configs'];
      const borders = pickUpValue(searchedTemplate, 'border', withOut);
      const colors = pickUpValue(borders, 'color', withOut);
      const color = colors.reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
      changeableConfigs.border = { name: 'ボーダー', colorType: 'unit', color };
    }
    if (changeableColor.fontBorder.count > 0) {
      const withOut = ['changeableInformation'];
      const borders = pickUpValue(searchedTemplate, 'fontBorders', withOut);
      const result = [];
      for (const i in [...Array(changeableColor.fontBorder.count)]) {
        const config = pickUpValue(borders, i);
        const colors = pickUpValue(config, 'color');
        const color = colors.reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
        result.push(color);
      }
      changeableConfigs.fontBorder = { name: '縁', count: changeableColor.fontBorder.count, colors: result };
    }
    if (changeableColor.fontColor.color !== 'none') {
      const withOut = ['changeableInformation', 'border', 'fontBorders', 'fontShadows', 'glow', 'background', 'wallPaper'];
      const colors = pickUpValue(searchedTemplate, 'color', withOut);
      const color = colors.reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
      changeableConfigs.fontColor = {
        name: '文字色',
        colorType: changeableColor.fontColor.color,
        gradientCount: changeableColor.fontColor.gradientCount,
        color,
      };
    }
    if (changeableColor.fontShadow.color !== 'none') {
      const withOut = ['changeableInformation'];
      const fontShadows = pickUpValue(searchedTemplate, 'fontShadows', withOut);
      const glows = pickUpValue(searchedTemplate, 'glow', withOut);
      let colors = [];
      if (fontShadows && Object.keys(fontShadows).length > 0) {
        colors = pickUpValue(fontShadows, 'color');
      } else if (glows && glows.length > 0) {
        colors = pickUpValue(glows, 'color');
      }
      const color = (colors.length > 1) ? colors.reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent')) : colors[0];
      changeableConfigs.fontShadow = {
        name: '影',
        colorType: changeableColor.fontShadow.color,
        color,
      };
    }
    if (changeableColor.wallPaper.color !== 'none') {
      const withOut = ['changeableInformation'];
      const colors = pickUpValue(searchedTemplate, 'background', withOut);
      const color = colors.reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
      changeableConfigs.wallPaper = {
        name: '背景',
        colorType: changeableColor.wallPaper.color,
        color,
      };
    }

    return changeableConfigs;
  }

  render() {
    const { fontDecorations, elements, drawerSettings } = this.props;
    const { decorationTarget } = drawerSettings;
    const wrapStyle = {
      width: '201px',
      height: '201px',
      textAlign: 'center',
    };
    const element = elements[decorationTarget];
    return (
      <>
        <div className={scrollbar.customScroll}>
          <div className={styles.textDrawer}>
            <div className={styles.itemList}>
              {(fontDecorations)
                ? fontDecorations.map((fontDecoration) => {
                  const { template } = fontDecoration;
                  let allTemplateStyle = templateToReactName(template);
                  const templateStyle = {}; let
                    parentTemplateStyle;
                  allTemplateStyle = templateToStyle(allTemplateStyle);
                  // parent用CSS
                  if (allTemplateStyle.parent) {
                    parentTemplateStyle = { ...allTemplateStyle.parent };
                  }
                  // 中心のスタイルオブジェクトの作成
                  Object.keys(allTemplateStyle).forEach((key) => {
                    if (!['parent', 'gradient'].includes(key)) {
                      templateStyle[key] = allTemplateStyle[key];
                    }
                  });

                  return (
                    <>
                      {
                    (allTemplateStyle.parent)
                      ? (
                        <ParseStyleText
                          key={fontDecoration.id}
                          className={`${styles.item} ${fontDecoration.name}`}
                          onClick={() => this.changeDecoration(allTemplateStyle, fontDecoration.name, fontDecoration.id, fontDecoration.number)}
                          content={this.defaultTextDecorate.text}
                          style={parentTemplateStyle}
                          logger={(fontDecoration.name)}
                          wrapStyle={(/丸背景付き文字/.test(fontDecoration.name)) ? wrapStyle : ''}
                        >
                          <ParseStyleText
                            key={fontDecoration.id}
                            style={templateStyle}
                            content={this.defaultTextDecorate.text}
                            logger={(fontDecoration.name)}
                            full={/丸背景付き文字/.test(fontDecoration.name)}
                          >
                            {this.defaultTextDecorate.text}
                          </ParseStyleText>
                        </ParseStyleText>
                      )
                      : (
                        <ParseStyleText
                          key={fontDecoration.id}
                          className={`${styles.item} ${fontDecoration.name}`}
                          onClick={() => this.changeDecoration(allTemplateStyle, fontDecoration.name, fontDecoration.id, fontDecoration.number)}
                          style={templateStyle}
                          content={this.defaultTextDecorate.text}
                          logger={(fontDecoration.name)}
                        >
                          {this.defaultTextDecorate.text}
                        </ParseStyleText>
                      )
                      }
                      {
                      (element.changeableConfigs && fontDecoration.id === element.decorationId)
                        ? (
                          <>
                            {Object.keys(element.changeableConfigs).map((key) => {
                              const changeConfig = element.changeableConfigs[key];
                              if (key === 'fontBorder') {
                                return [...Array(changeConfig.count)].map((value, idx) => (
                                  <div className={styles.selectColor}>
                                    <span>{`${changeConfig.name}${idx + 1}`}</span>
                                    <label
                                      className={`btn-color ${styles.space} ${styles.colorPalette} cursor-pointer`}
                                // style={colorStyle}
                                      style={{ backgroundColor: element.elementableAttributes.border.configs[idx].color }}
                                      onClick={this.toggleSubDrawer({
                                        subDrawerId: 1, color: changeConfig.colors[idx], returnToSubDrawer: true, onChange: this.changeFontBorderColor(idx), targetName: `${changeConfig.name}${idx + 1}`,
                                      })}
                                      htmlFor="TextTool-ColorPalette"
                                    >
                                      <div id="TextTool-ColorPalette" className={styles.colorPalette} />
                                    </label>
                                  </div>
                                ));
                              }
                              if (key === 'fontShadow') {
                                const shadows = element.elementableAttributes.shadow.configs;
                                const shadowColor = Object.keys(shadows).map((shadowKey) => shadows[shadowKey].color).reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
                                const glows = element.elementableAttributes.glow.configs;
                                const glowColor = Object.keys(glows).map((glowKey) => glows[glowKey].color).reduce((color1, color2) => ((color1 === color2) ? color1 : 'transparent'));
                                return (
                                  <div className={styles.selectColor}>
                                    <span>{changeConfig.name}</span>
                                    <label
                                      className={`btn-color ${styles.space} ${styles.colorPalette} cursor-pointer`}
                                // style={colorStyle}
                                      style={{ backgroundColor: (shadowColor !== 'transparent' && shadowColor !== '#000') ? shadowColor : (glowColor !== 'transparent') ? glowColor : 'black' }}
                                      onClick={this.toggleSubDrawer({
                                        subDrawerId: 1, color: changeConfig.color, returnToSubDrawer: true, onChange: this.changeColorOnChange[key], targetName: changeConfig.name,
                                      })}
                                      htmlFor="TextTool-ColorPalette"
                                    >
                                      <div id="TextTool-ColorPalette" className={styles.colorPalette} />
                                    </label>
                                  </div>
                                );
                              }
                              return (
                                <div className={styles.selectColor}>
                                  <span>{changeConfig.name}</span>
                                  <label
                                    className={`btn-color ${styles.space} ${styles.colorPalette} cursor-pointer`}
                                // style={colorStyle}
                                    style={{ backgroundColor: element.changeableConfigs[key].color }}
                                    onClick={this.toggleSubDrawer({
                                      subDrawerId: 1, color: changeConfig.color, returnToSubDrawer: true, onChange: this.changeColorOnChange[key], targetName: changeConfig.name,
                                    })}
                                    htmlFor="TextTool-ColorPalette"
                                  >
                                    <div id="TextTool-ColorPalette" className={styles.colorPalette} />
                                  </label>
                                </div>
                              );
                            })}
                          </>
                        )
                        : ''
                      }
                    </>
                  );
                })
                : (
                  <>
                    {[...Array(20)].map((key) => (
                      <div className={`${styles.item}`}>
                        <BeatLoader />
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FontDecorationDrawer;
