import { ACTION_TYPE } from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenuAction';

const AccountMenuReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.GET_USER_INFORMATION.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GET_USER_INFORMATION.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GET_USER_INFORMATION.fail:
      return Object.assign({}, state, action.payload);
    
    case ACTION_TYPE.CHANGE_ALLOW_WIZARD.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CHANGE_ALLOW_WIZARD.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CHANGE_ALLOW_WIZARD.fail:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export default AccountMenuReducer;