import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deleteElements } from '@common/components/entities/elements/ElementActions';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';

import AccountMenu from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenu';
import * as Action from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenuAction';
import { logout } from '@pages/login/LoginAction';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.materials.accountMenu,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);
  actions.logout = (...args) => dispatch(logout(...args));
  actions.deleteElements = (...args) => dispatch(deleteElements(...args));
  actions.updateTag = (...args) => dispatch(update(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((AccountMenu)));
