import React from 'react';
import {
	Button, 
} from '@material-ui/core';
import Element from '@components/elements/Element';
import styles from './HumanCutModal.module.scss';
import Wait from '@components/wait/WaitComponent';
import path from 'path';
import { toastr } from 'react-redux-toastr';
import config from '@assets/config';

class HumanCutModal extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
		}
	}

	closeModal(){
		const { onRequestClose, tmpToggleDialogs } = this.props;
		tmpToggleDialogs(true);
		onRequestClose();
	}

	save(url) {
		const { receiveElement, element } = this.props;
		const updatedElementAttrs = {...element.elementableAttributes}
		updatedElementAttrs.cutOut = true;
		updatedElementAttrs.hCutOutUrl = url;
		receiveElement({...element, elementableAttributes: updatedElementAttrs});
		this.closeModal();
  }

	async cutOutHuman(element){
		gtag('event', 'click_humancut',{
			'event_name' : 'humanCutOut',
		});
		const { cutOutHuman } = this.props;
		const file = element.elementableAttributes.url;
		const fileName = path.basename(file);
		await cutOutHuman(file, fileName);
	}

	render() {
		const { element, zoom, status, results, imgName, loading, error } = this.props;
		const eWidth = element.elementableAttributes.width;
		const eHeight = element.elementableAttributes.height;
		const elementName = path.basename(element.elementableAttributes.url);

		return (
			<div className={styles.AppBody}>
				{error && toastr.error('error', error.message)}
				<div style={{ marginTop: 5 }} className='titleAndImageWrapper'>
					<div className={styles.headText}>
						人物切り抜き
					</div>
					<div className={loading ? styles.loadingDiv : styles.imageBody}>
					{ loading ? <Wait active={loading}/> :
						element && !element._destroy ?
							status == 'success' && imgName == elementName ?
								<div className={styles.imageElement} style={{background: `no-repeat center url("${config.s3Url}wall_paper_image/background_003_black.png")`}}>
									<img src={"data:image/jpeg;base64,"+results.image}/>
								</div>
							: <div className={styles.imageElement} style={{background: `no-repeat center url("${config.s3Url}wall_paper_image/background_003_black.png")`}}>
									<img src={element.elementableAttributes.url} />
								</div>
						: null
					}
					</div>
				</div>
				<div className={styles.BtnWizard}>
					<div className={styles.cutout_btn}>
						<Button variant="contained" size="medium" onClick={()=>this.cutOutHuman(element)}>
							<span className={styles.actionButtonText}>切り抜きを実行</span>
						</Button>
					</div>
					<div className={styles.rightaction}>     
						<div className={styles.leftBtn}>
							<Button variant="contained" size="medium" 
							onClick={() => this.save("data:image/jpeg;base64,"+results.image)}>
								<span className={styles.buttonText}>保存</span>
							</Button>
						</div>
						<div className={styles.rightBtn}>
							<Button variant="contained" size="medium" onClick={() => this.closeModal()}>
								<span className={styles.buttonText}>キャンセル</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HumanCutModal;