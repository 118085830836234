import { RECEIVE_DESIGN, REMOVE_DESIGN } from '@common/components/entities/designs/DesignActions';
import {
  RECEIVE_ELEMENT, CREATE_ELEMENT, UNDO_ELEMENT, REMOVE_ELEMENT, DELETE_ELEMENTS, RECEIVE_ELEMENT_FROM_HISTORY
} from '@common/components/entities/elements/ElementActions';
import { elementCacher } from '@common/elements/ElementCacher';
import { compareObject } from '@common/utils/ObjectUtils';

const elementsReducer = (state = {}, action) => {
  Object.freeze(state);
  const nextState = { ...state };
  switch (action.type) {
    case RECEIVE_DESIGN:
      return { ...state, ...action.payload.elements };
    case DELETE_ELEMENTS:
      return {};
    case REMOVE_DESIGN:
      if (action.payload.elements) {
        Object.keys(action.payload.elements).forEach((id) => {
          delete nextState[id];
        });
        return nextState;
      }
      return state;
    case REMOVE_ELEMENT:
      const removeEle = state[action.element.id];
      if (state) {
        const tempEle = { ...state };
        Object.keys(tempEle).forEach((id) => {
          if (tempEle[id].id == removeEle.id) {
            delete tempEle[id];
          }
        });
        return tempEle;
      }
      return state;
    case RECEIVE_ELEMENT:
      let prevElement = state[action.element.id];
      if (!compareObject(prevElement, action.element) && !action.element._fromHistory) {
        elementCacher.pushCache({ prevElement, changedElement: action.element });
      }
      return { ...state, [action.element.id]: action.element };
    case RECEIVE_ELEMENT_FROM_HISTORY:
      let historyElement = {...action.element};
      historyElement._fromHistory = true;
      return { ...state, [action.element.id]: historyElement };
    case UNDO_ELEMENT:
      prevElement = state[action.element.id];
      if (!compareObject(prevElement, action.element)) {
        elementCacher.pushUndone({ prevElement, changedElement: action.element });
      }
      return { ...state, [action.element.id]: action.element };
    case CREATE_ELEMENT:
      let defaultDecoration;
      switch (action.element.elementableType) {
        case 'AssetImage':
          defaultDecoration = {
            ...action.element.elementableAttributes,
            imageShadow: {
              vertical: 0, horizontal: 0, color: '#000', blur: 0,
            },
          };
          break;
        case 'UploadImage':
          defaultDecoration = {
            ...action.element.elementableAttributes,
            imageShadow: {
              vertical: 0, horizontal: 0, color: '#000', blur: 0,
            },
          };
          break;
        case 'Text':
          defaultDecoration = {
            ...action.element.elementableAttributes,
            letterSpacing: 0,
            lineHeight: 120,
            fontAlign: 'left',
            border: { text: '文字ボーダー', configs: [{ weight: 0, color: '#000' }] },
            glow: { text: '光彩', configs: [{ weight: 0, color: '#000' }] },
            shadow: {
              text: 'テキスト影',
              configs: [{
                vertical: 0, horizontal: 0, color: '#000', blur: 0,
              }],
            },
            arch: { text: '円形', direction: { value: 1, text: '方向' }, angle: { value: 0, text: '中心角' } },
          };
          break;
        case 'ShapeImage':
          defaultDecoration = {
            ...action.element.elementableAttributes,
            imageShadow: {
              vertical: 0, horizontal: 0, color: '#000', blur: 0,
            },
          };
          break;
        default:
          defaultDecoration = { ...action.element.elementableAttributes };
          break;
      }

      const createElement = { ...action.element, elementableAttributes: { ...defaultDecoration, ...action.element.elementableAttributes } };
      const zIndex = Object.keys(state).reduce((prev, curr) => {
        if (state[curr]._destroy || state[curr].zIndex < prev) {
          return prev;
        }
        return state[curr].zIndex;
      }, 0);
      createElement.zIndex = zIndex + 1;
      if (action.element.elementableType == 'WallPaperImage') {
        createElement.zIndex = 0;
      }
      createElement.shadow = {
        vertical: 0, horizontal: 0, blur: 0, color: '#000',
      };
      createElement.border = {
        top: {
          text: '上', color: '#000', style: 'solid', weight: 0,
        },
        bottom: {
          text: '下', color: '#000', style: 'solid', weight: 0,
        },
        left: {
          text: '左', color: '#000', style: 'solid', weight: 0,
        },
        right: {
          text: '右', color: '#000', style: 'solid', weight: 0,
        },
      };
      elementCacher.pushCache({ prevElement: undefined, changedElement: createElement });
      return { ...state, [action.element.id]: createElement };
    default:
      return state;
  }
};

export default elementsReducer;
