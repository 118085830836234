class ShapeImage extends React.Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
  }

  componentDidMount() {
    const { elementAttr: { color, imageShadow }, element } = this.props;
    const addSvg = `<defs>
      <filter id=shape-filter-${element.id}>
        <feDropShadow dx=${imageShadow.horizontal} dy=${imageShadow.vertical} flood-color=${imageShadow.color} stdDeviation=${imageShadow.blur} />
      </filter>
    </defs>`;
    this.svgRef.current.querySelector('svg').insertAdjacentHTML('afterbegin', addSvg);
    this.svgRef.current.querySelector('svg').setAttribute('overflow', 'visible');

    const svgPath = this.svgRef.current.querySelector('svg path');
    if (color !== "#919191") {
      svgPath.setAttribute('fill', color);
    }
    svgPath.setAttribute('style', `filter:url(#shape-filter-${element.id})`);
    this.changeSize(this.svgRef.current);
  }

  componentDidUpdate(prevProps) {
    const { elementAttr: { color, imageShadow } } = this.props;
    const path = this.svgRef.current.querySelector('svg path');
    if (path.getAttribute('fill') !== color) {
      this.svgRef.current.querySelector('svg path').setAttribute('fill', color);
    }
    if (JSON.stringify(imageShadow) !== JSON.stringify(this.currentImageShadow(this.svgRef.current.querySelector('svg')))) {
      this.changeFilterTag(this.svgRef.current.querySelector('svg'));
    }
    this.changeSize(this.svgRef.current);
  }

  currentImageShadow(element) {
    return {
      vertical: parseInt(element.querySelector('defs>filter>feDropShadow').getAttribute('dy'), 10),
      horizontal: parseInt(element.querySelector('defs>filter>feDropShadow').getAttribute('dx'), 10),
      color: element.querySelector('defs>filter>feDropShadow').getAttribute('flood-color'),
      blur: parseInt(element.querySelector('defs>filter>feDropShadow').getAttribute('stdDeviation'), 10),
    };
  }

  changeFilterTag(element) {
    const { elementAttr: { imageShadow } } = this.props;
    // クエリの取得
    element.querySelector('defs>filter>feDropShadow').setAttribute('dx', imageShadow.horizontal);
    element.querySelector('defs>filter>feDropShadow').setAttribute('dy', imageShadow.vertical);
    element.querySelector('defs>filter>feDropShadow').setAttribute('flood-color', imageShadow.color);
    element.querySelector('defs>filter>feDropShadow').setAttribute('stdDeviation', imageShadow.blur);
  }

  changeSize(element) {
    const { elementAttr: { width, height }, zoom } = this.props;
    element.querySelector('svg').setAttribute('width', width * zoom);
    element.querySelector('svg').setAttribute('height', height * zoom);
    element.querySelector('svg').setAttribute('preserveAspectRatio', 'none');
  }

  render() {
    const { elementAttr: { svg } } = this.props;
    return (
      <div ref={this.svgRef} dangerouslySetInnerHTML={{ __html: svg }} />
    );
  }
}
export default ShapeImage;
