import styles from '@components/icons/alignHorizontalLeft/AlignHorizontalLeft.module.scss';

export default function AlignHorizontalLeft(props) {
  const { className, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <path fill={color} d="M4,22H2V2h2V22z M22,7H6v3h16V7z M16,14H6v3h10V14z" />
    </svg>
  );
}
