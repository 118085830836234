// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LetterSpacingDialog-module__letterSpaceWrapper--E_QM5{padding:12.5px !important;width:100px;top:40px !important;left:-12px !important}.LetterSpacingDialog-module__letterSpaceWrapper--E_QM5 .LetterSpacingDialog-module__letterSpacing--3N4bn{width:100%}.LetterSpacingDialog-module__letterSpaceWrapper--E_QM5 .LetterSpacingDialog-module__letterSpacing--3N4bn .LetterSpacingDialog-module__sliders--1iI5L{width:80% !important;margin:0 10%}.LetterSpacingDialog-module__letterSpaceWrapper--E_QM5 .LetterSpacingDialog-module__letterSpacing--3N4bn .LetterSpacingDialog-module__sliders--1iI5L .LetterSpacingDialog-module__sliderLabels--2Yzov{display:flex;justify-content:flex-end}.LetterSpacingDialog-module__letterSpaceWrapper--E_QM5 .LetterSpacingDialog-module__letterSpacing--3N4bn .LetterSpacingDialog-module__sliders--1iI5L .LetterSpacingDialog-module__sliderLabels--2Yzov .LetterSpacingDialog-module__inputNumber--3ujvA{width:45px}.LetterSpacingDialog-module__letterSpaceWrapper--E_QM5 .LetterSpacingDialog-module__letterSpacing--3N4bn .LetterSpacingDialog-module__sliders--1iI5L .LetterSpacingDialog-module__sliderLabels--2Yzov .LetterSpacingDialog-module__labelWithIcon--ju7mm{display:flex;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"letterSpaceWrapper": "LetterSpacingDialog-module__letterSpaceWrapper--E_QM5",
	"letterSpacing": "LetterSpacingDialog-module__letterSpacing--3N4bn",
	"sliders": "LetterSpacingDialog-module__sliders--1iI5L",
	"sliderLabels": "LetterSpacingDialog-module__sliderLabels--2Yzov",
	"inputNumber": "LetterSpacingDialog-module__inputNumber--3ujvA",
	"labelWithIcon": "LetterSpacingDialog-module__labelWithIcon--ju7mm"
};
module.exports = exports;
