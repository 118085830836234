class UploadImage extends React.Component {

  render(){
    const { element, elementAttr: { url, brightness, contrast, imageShadow, hCutOutUrl, lCutOutUrl, crop, width, height, naturalWidth, naturalHeight }, borderRadius, zoom } = this.props;
    let filter = '';
    filter += `brightness(${(brightness !== undefined) ? brightness : 100}%)`; 
    filter += ` contrast(${(contrast !== undefined) ? contrast : 100 }%)`;
    // filter += ` drop-shadow(${imageShadow.horizontal}px ${imageShadow.vertical}px ${imageShadow.blur}px ${(imageShadow.color) ? imageShadow.color : '#000'})`
    const scaleX = width / naturalWidth;
    const scaleY = height / naturalHeight;
    return (
      (crop) 
      ?
        <div
          style={{
            width: width * zoom, 
            height: height * zoom, 
            cursor: 'move',
            position: 'relative',
          }}
        >
          <div style={{
            width: crop.width * scaleX * zoom,
            height: crop.height * scaleY * zoom,
            overflow: 'hidden',
            top: crop.y * scaleY * zoom,
            left: crop.x * scaleX * zoom,
            position: 'absolute',
            }}
          >
            <img
              src={ hCutOutUrl || lCutOutUrl || url}
              style={{
                width: width * zoom, 
                height: height * zoom, 
                cursor: 'move',
                borderRadius, 
                filter,
                top: -crop.y * scaleY * zoom,
                left: -crop.x * scaleX * zoom,
                position: 'absolute',
              }}
              alt=""
            />
          </div>
        </div>
      :
        <img
          src={ hCutOutUrl || lCutOutUrl || url}
          style={{
            width: width * zoom, 
            height: height * zoom, 
            cursor: 'move',
            borderRadius, 
            filter, 
          }}
          alt=""
        />
    )
  };
}

export default UploadImage;