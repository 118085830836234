
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from '@components/app/app';

import '@common/scss/globalImports/Font.scss'

const Root = ({ store }) => (
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

export default Root;
