import { ButtonGroup, Button, Box, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import config from '@assets/config';
import styles from '@pages/editor/Editor.module.scss';
import DesignDrawerContainer from '@pages/editor/designDrawer/DesignDrawerContainer';
import WorkAreaContainer from '@pages/editor/workArea/WorkAreaContainer';
import ElementContainer from '@components/elements/ElementContainer';
import { Clear } from '@material-ui/icons';

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      design: {}, // design attributes
      elements: [], // key-value pair elementId and the current element, array index=z-index order
      zoom: 0.5,
      selection: null,
      loading: false,
      open: true,
      prevTag: {},
      prevElements: {},
      thumbZoom: 0.25,
      setPrevDesignGA: true
      // undoHistory: [], array of key-value pair of elementId and the element copy before
    };
    this.changeZoomFactor = this.changeZoomFactor.bind(this);
    this.updateDesign = this.updateDesign.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.updateElement = this.updateElement.bind(this);
    this.addElement = this.addElement.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { getDesign, getPreviousDesign } = this.props;
    getPreviousDesign();
    const elements = [];
    const design = {
      id: 1,
      elements,
      creater_id: 1,
      folder_id: 1,
      title: 'test',
      description: 'test',
      public: true,
      width: config.canvas.width,
      height: config.canvas.height,
      trash: false,
    };
    getDesign({ design });
    this.setState({ design, elements });
  }
  
  static getDerivedStateFromProps(props, state) {
    if (state.setPrevDesignGA && props.prevDesign && Object.keys(props.prevDesign).length > 0) {
      gtag('event', 'show_previous_design', {
        event_name : 'show_previous_design',
        user_name : props.user.name,
        user_email : props.user.mail,
      });
      return { setPrevDesignGA: false }
    }
    if (Object.keys(props.elements).length > 0 && 
      (props.elements !== state.prevElements)) {
      const params = {
        partsInformations: props.elements,
        tagState: props.tagState,
        companyId: props.tagState.companyId,
      }
      props.saveTemp(params);
    }
    return { prevElements: props.elements }
  }
  

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  setSelection(idx) {
    this.setState({ selection: idx });
  }

  changeZoomFactor(fact, e) {
    if (document.querySelector(`.${styles.active}`).classList.contains(styles.active)) {
      document.querySelector(`.${styles.active}`).classList.remove(styles.active);
    }
    e.currentTarget.classList.add(styles.active);
    this.setState({ zoom: fact });
  }

  updateElement(idx, element) {
    const { receiveElement } = this.props;
    receiveElement(element);
  }

  addElement(element) {
    const { createElement } = this.props;
    const { design } = this.state;
    createElement(design.id, { ...element, id: `temp-${Date.now()}` });
  }

  screenshot() {
    const { design: { id, width, height } } = this.state;
    return fetch(`${process.env.SCREENSHOT_URL}screenshot?id=${id}&width=${width}&height=${height}`)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'File name', { type: 'image/png' });
        return file;
      });
  }

  updateDesign() {
    const { design } = this.state;
    const { elements } = this.props;
    elements.each((element, key) => {
      if (typeof element.id === 'string') {
        delete element.id;
      }
    });
    design.elementsAttributes = elements;
    delete design.thumbnail;
    this.setState({ loading: true });
    this.setState({ loading: false });
  }

  handleClose() {
    this.setState({
      open: false
    })
    const { user } = this.props;
    gtag('event', 'create_new_design', {
      event_name : 'create_new_design',
      user_name : user.name,
      user_email : user.mail,
    });
  }

  addPrevDesign(eleArray) {
    const { tagInfo, tagState, updateTag, receiveElement, user } = this.props;
    this.setState({ open: false })
    Object.keys(eleArray).map((el)=>{
      receiveElement(eleArray[el])
    })
    if (tagInfo) {
      const updatedTag = { ...tagState };
      tagInfo.map((ele)=>
        updatedTag[ele.categoryEn] = ele.tag_id
      )
      updateTag(updatedTag);
    }
    gtag('event', 'resume_previous_design', {
      event_name : 'resume_previous_design',
      user_name : user.name,
      user_email : user.mail,
    });
  }

  render() {
    const {
      design, zoom, selection, open, thumbZoom
    } = this.state;
    const { elements, prevDesign } = this.props;
    return (
      <div className={styles.editorContainer}>
        <div className={styles.editorBottomContainer}>
          { prevDesign && Object.keys(prevDesign).length > 0 &&
            <Dialog
              open={open}
              className={styles.dialog}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{ style: { borderRadius: 15, padding: 15 }}}
            >
              <DialogContent className={styles.dialogContent}>
                <Clear className={styles.crossIcon} onClick={this.handleClose}/>
                <Typography component='div' className={styles.savingTimeTitle}>保存日時　
                  <Typography className={styles.savingTime}>{prevDesign.updated_at.replaceAll("-", "/")}</Typography>
                </Typography>
                <Box className={styles.thumbnailBox}>
                  { Object.keys(prevDesign.parts_informations).map((elementId) => {
                    const element = prevDesign.parts_informations[elementId];
                    const elementAttrs = element.elementableAttributes;
                    if (element._destroy) {
                      return null
                    }
                    return (
                      <div
                        key={elementId}
                        id={elementId}
                        style={{
                          position: 'absolute',
                          zIndex: element.zIndex,
                          left: (element.posX * thumbZoom),
                          top: (element.posY * thumbZoom),
                          transform: `rotate(${element.rotate}deg)`,
                          width: elementAttrs.width * thumbZoom,
                          height: elementAttrs.height * thumbZoom,
                        }}
                      >
                        <ElementContainer element={element} zoom={thumbZoom} isThumbnail={true} changeDesignState={()=>{}}/>
                      </div>
                    );
                  })}
                </Box>
                <Typography className={styles.dialogText}>前回作成中のデータがあります。編集を再開しますか？</Typography>
                <Typography className={styles.dialogText}>新規作成に進むと作成中のデータは削除されます</Typography>
              </DialogContent>
              <DialogActions className={styles.dialogAction}>
                <Button 
                  variant='contained' 
                  className={styles.newBtn} 
                  onClick={this.handleClose}
                >
                  <Typography className={styles.actionBtnText}>新規作成</Typography>
                </Button>
                <Button 
                  variant='contained' 
                  className={styles.continueBtn} 
                  onClick={()=>this.addPrevDesign(prevDesign.parts_informations)}
                >
                  <Typography className={styles.actionBtnText}>つづける</Typography>
                </Button>
              </DialogActions>
            </Dialog>
          }
          <DesignDrawerContainer addElement={this.addElement} zoom={zoom} selection={selection} setSelection={this.setSelection}/>
          <WorkAreaContainer
            design={design}
            elements={elements}
            zoom={zoom}
            updateElementPos={this.updateElementPos}
            updateElement={this.updateElement}
            selection={selection}
            setSelection={this.setSelection}
            addElement={this.addElement}
          />
          <ButtonGroup className={styles.zoomBar}>
            <Button type="button" className={`btn-icon ${styles.zoomButton} ${styles.active}`} onClick={(e) => this.changeZoomFactor(0.5, e)}>50%</Button>
            <Button type="button" className={`btn-icon ${styles.zoomButton}`} onClick={(e) => this.changeZoomFactor(0.75, e)}>75%</Button>
            <Button type="button" className={`btn-icon ${styles.zoomButton}`} onClick={(e) => this.changeZoomFactor(1, e)}>100%</Button>
            <Button type="button" className={`btn-icon ${styles.zoomButton}`} onClick={(e) => this.changeZoomFactor(1.25, e)}>125%</Button>
            <Button type="button" className={`btn-icon ${styles.zoomButton}`} onClick={(e) => this.changeZoomFactor(0.1375, e)}>SP</Button>
            <Button type="button" className={`btn-icon ${styles.zoomButton}`} onClick={(e) => this.changeZoomFactor(0.25, e)}>PC</Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default Editor;
