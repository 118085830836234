import {
  MenuList, MenuItem, ListItemIcon, Typography, Link,
} from '@material-ui/core';
import {
  AccountCircle, Help, Mail, PowerSettingsNew, WebAsset, LayersClear,
} from '@material-ui/icons';
import BeatLoader from 'react-spinners/BeatLoader';

import CustomPaper from '@components/utils/customPaper/CustomPaper';

import ConfirmDialogComponent from '@pages/editor/workArea/designTools/components/accountMenu/confirmDialog/ConfirmDialogComponent';

import styles from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenu.module.scss';

class AccountMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialogState: false,
    };
  }

  componentDidMount() {
    const { getUserInformation } = this.props;
    getUserInformation();
  }

  closeConfirmDialog() {
    this.setState({
      confirmDialogState: false,
    });
  }

  clearDesign() {
    const { deleteElements, updateTag } = this.props;
    document.querySelector('#noElement').style.backgroundColor = 'white';
    deleteElements();
    const newTag = {
      professional: '',
      targetAttr: '',
      imageKind: '',
      imageImagine: '',
      feature: ''
    };
    updateTag(newTag);
    this.closeConfirmDialog();
  }

  render() {
    const {
      iconClassName, className, user, logout, changeAllowWizard,
    } = this.props;
    return (
      <>
        <CustomPaper elevation={4} className={`${styles.AccountMenuWrapper} ${className}`}>
          <AccountCircle className={styles.accountCircle} />
          {(user)
            ? (
              <>
                <Typography variant="body1">{user.name}</Typography>
                <Typography variant="body1">{user.mail}</Typography>
                <Typography variant="body1">
                  ウィザードステータス:
                  {(user.allowWizard) ? '表示' : '非表示'}
                </Typography>
              </>
            )
            : <BeatLoader />}
          <MenuList>
            <Link href="https://dip-net.box.com/s/w2s1v5jiobujhw3545lrh2walfmmt19u" target="_blank" rel="noopener noreferrer" color="inherit">
              <MenuItem>
                <ListItemIcon>
                  <Help className={iconClassName} />
                </ListItemIcon>
                <Typography variant="body1">使い方・ヘルプ</Typography>
              </MenuItem>
            </Link>

            <Link href="https://forms.gle/GCudzxyo53DwM9xq5" target="_blank" rel="noopener noreferrer" color="inherit">
              <MenuItem>
                <ListItemIcon>
                  <Mail className={iconClassName} />
                </ListItemIcon>
                <Typography variant="body1">お問い合わせフォーム</Typography>
              </MenuItem>
            </Link>
            {(user)
              ? (
                <MenuItem onClick={() => {
                  gtag('event', 'click_wizard_change', { event_name: 'clickWizardChange' });
                  if (user.allowWizard) {
                    gtag('event', 'wizard_now_use_count', { wizard_now_use_count: 1 });
                  } else {
                    gtag('event', 'wizard_now_use_count', { wizard_now_use_count: -1 });
                  }
                  changeAllowWizard({ id: user.id, allowWizard: !user.allowWizard });
                }}
                >
                  <ListItemIcon>
                    <WebAsset className={iconClassName} />
                  </ListItemIcon>
                  <Typography variant="body1">
                    ウィザード画面
                    {(user.allowWizard) ? 'オフ'　: 'オン'}
                  </Typography>
                </MenuItem>
              )
              : ''}
            <MenuItem onClick={() => { this.setState({ confirmDialogState: true }); }}>
              <ListItemIcon>
                <LayersClear className={iconClassName} />
              </ListItemIcon>
              <Typography variant="body1">はじめからやりなおす</Typography>
            </MenuItem>

            <MenuItem className={styles.logOut} onClick={() => logout(this.props.history)}>
              <ListItemIcon>
                <PowerSettingsNew className={`${iconClassName} ${styles.logOut}`} />
              </ListItemIcon>
              <Typography variant="body1">ログアウト</Typography>
            </MenuItem>

          </MenuList>
          <ConfirmDialogComponent
            open={this.state.confirmDialogState}
            onClose={() => { this.closeConfirmDialog(); }}
            onSubmit={() => { this.clearDesign(); }}
          />
        </CustomPaper>
      </>
    );
  }
}

export default AccountMenu;
