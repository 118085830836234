/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { ClickAwayListener } from '@material-ui/core';
import ReactArcText from 'react-arc-text-fix';
import { toastr } from 'react-redux-toastr';

import config from '@assets/config';
import {
  newLineToBreak, genShadowCss, getArcRadius, getTextRect, getTextLineRect, genGradientCss,
} from '@common/utils/TextOperation';
import { compareObject } from '@common/utils/ObjectUtils';
import '@common/scss/globalImports/Imports.scss';

import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';
import TextParent from '@components/elements/text/components/textParent/TextParent';

import styles from '@components/elements/text/Text.module.scss';

class Text extends React.Component {
  constructor(props) {
    super(props);
    this.onDoubleClickText = this.onDoubleClickText.bind(this);
    this.onClickAwayText = this.onClickAwayText.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.inputRef = React.createRef();
    this.textRef = React.createRef();
    this.parentRef = React.createRef();
    this.arcTextClassName = `${props.element.id}-reactArcText`;
    this.isComposition = false;
    this.state = {
      error: false,
      editable: false,
    };
    this.prev = {
      border: { object: {}, css: null },
      glow: { object: {}, css: null },
      shadow: { object: {}, css: null },
      css: undefined,
    };
  }

  componentDidMount() {
    const { elementAttr } = this.props;
    const { border, glow, shadow } = elementAttr;
    this.prev = { border: { ...border }, glow: { ...glow }, shadow: { ...shadow } };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(nextProps === this.props && nextState === this.state);
  }

  componentDidUpdate(prevProps) {
    const {
      element, receiveElement, elementAttr, changeDesignState, zoom,
    } = this.props;
    const { element: prevElement, elementAttr: prevElementAttr } = prevProps;
    const { editable } = this.state;
    if (editable) {
      changeDesignState('draggable', false);
      this.inputRef.current.focus();
    } else {
      changeDesignState('draggable', true);
    }

    const updatedAttr = { ...elementAttr };
    let isUpdated = false;
    if (!compareObject(prevElement, element)) {
      const css = this.genTextShadowCss(elementAttr);
      updatedAttr.textShadow = css;
      if (css !== this.prev.css) {
        this.prev.css = css;
        isUpdated = true;
      }

      if (elementAttr.lineHeight !== prevElementAttr.lineHeight) {
        updatedAttr.height = elementAttr.fontSize * (parseInt(elementAttr.lineHeight, 10) / 100);
      }
    }

    if (this.textRef.current) {
      const htmlElement = this.textRef.current;
      if (!updatedAttr.offsetWidth || !updatedAttr.offsetHeight || htmlElement.offsetWidth > updatedAttr.offsetWidth || htmlElement.offsetHeight > updatedAttr.offsetHeight) {
        updatedAttr.offsetWidth = ((updatedAttr.offsetWidth) ? Math.max(updatedAttr.offsetWidth, htmlElement.offsetWidth) : htmlElement.offsetWidth) / zoom;
        updatedAttr.offsetHeight = ((updatedAttr.offsetHeight) ? Math.max(updatedAttr.offsetHeight, htmlElement.offsetHeight) : htmlElement.offsetHeight) / zoom;
      }
    }
    if (this.parentRef.current) {
      const htmlElement = this.parentRef.current;
      if (!updatedAttr.offsetWidth || !updatedAttr.offsetHeight || htmlElement.offsetWidth > updatedAttr.offsetWidth || htmlElement.offsetHeight > updatedAttr.offsetHeight) {
        updatedAttr.offsetWidth = ((updatedAttr.offsetWidth) ? Math.max(updatedAttr.offsetWidth, htmlElement.offsetWidth) : htmlElement.offsetWidth) / zoom;
        updatedAttr.offsetHeight = ((updatedAttr.offsetHeight) ? Math.max(updatedAttr.offsetHeight, htmlElement.offsetHeight) : htmlElement.offsetHeight) / zoom;
      }
    }

    if (isUpdated) {
      receiveElement({ ...element, elementableAttributes: updatedAttr });
    }
  }

  onChangeText(e) {
    const {
      receiveElement, element, elementAttr, zoom,
    } = this.props;
    const updatedElement = { ...element };
    const {
      lineHeight, fontWeight, fontSize, fontFamily,
    } = elementAttr;
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`;

    const newLineCount = (e.target.value.match(/(\n|\r|\rn)/g) || []).length;
    const calcedHeight = fontSize * (parseInt(lineHeight, 10) / 100) * (newLineCount + 1);
    const updateAttr = { ...elementAttr };

    this.cursor = e.target.selectionStart;
    let text = e.target.value;
    if (!this.isComposition && this.validateTextLength(text)) {
      text = text.substr(0, 50);
    }
    updateAttr.text = text;
    if (/丸背景付き文字/.test(element.decorationName)) {
      const width = elementAttr.fontWidth || elementAttr.width;
      const rect = getTextRect({
        text: e.target.value, font, height: calcedHeight, width,
      });
      updateAttr.fontWidth = rect.width;
      updateAttr.fontHeight = rect.height;
    } else {
      const rect = getTextRect({
        text: e.target.value, font, height: calcedHeight, width: elementAttr.width,
      });
      updateAttr.width = rect.width;
      updateAttr.height = rect.height;
    }

    updatedElement.elementableAttributes = updateAttr;
    receiveElement({ ...updatedElement });
  }

  onDoubleClickText() {
    this.setState({ editable: true });
  }

  onClickAwayText() {
    this.setState({ editable: false, error: false });
  }

  validateTextLength(text) {
    if (text.length > 50) {
      this.setState({
        error: {
          message: config.validate.textLength,
        },
      });
      return true;
    }
    this.setState({
      error: false,
    });
    return false;
  }

  genTextShadowCss(elementAttr) {
    let css = '';
    let borderCss = '';

    if (!compareObject(elementAttr.border, this.prev.border.object)) {
      const { border } = elementAttr;

      for (const idx in border.configs) {
        if (idx !== '0') { borderCss += ','; }
        borderCss += genShadowCss({ end: (border.configs[idx].width) ? border.configs[idx].width : border.configs[idx].weight, color: border.configs[idx].color });
      }
      this.prev.border = { object: { ...border }, css: borderCss };
    } else {
      borderCss += this.prev.border.css;
    }

    if (borderCss) { css += borderCss; }

    // glow
    let glowCss = '';
    if (!compareObject(elementAttr.glow, this.prev.glow.object)) {
      const { glow } = elementAttr;
      for (const idx in glow.configs) {
        if (idx !== '0') { glowCss += ','; }
        glowCss += `0px 0px ${glow.configs[idx].weight}px ${glow.configs[idx].color}`;
      }
      this.prev.glow = { object: { ...glow }, css: glowCss };
    } else {
      glowCss += this.prev.glow.css;
    }
    if (glowCss) { css += `,${glowCss}`; }

    // shadow
    let shadowCss = '';
    if (!compareObject(elementAttr.shadow, this.prev.shadow.object)) {
      const { shadow } = elementAttr;
      for (const idx in shadow.configs) {
        let blur = 0;
        let vertical = 0;
        let horizontal = 0;
        let color = 'transparent';
        if (shadow.configs[idx].vertical !== undefined) { vertical = shadow.configs[idx].vertical; }
        if (shadow.configs[idx].horizontal !== undefined) { horizontal = shadow.configs[idx].horizontal; }
        if (shadow.configs[idx].blur !== undefined) { blur = shadow.configs[idx].blur; }
        if (shadow.configs[idx].color !== undefined) { color = shadow.configs[idx].color; }

        if (vertical || horizontal || blur) {
          if (idx !== '0') { shadowCss += ','; }
          shadowCss += `${horizontal}px ${vertical}px ${blur}px ${color}`;
        }
      }
      this.prev.shadow = { object: { ...shadow }, css: shadowCss };
    } else {
      shadowCss += this.prev.shadow.css;
    }

    if (shadowCss) { css += `,${shadowCss}`; }
    return css;
  }

  changeBorderWidth(borderWidth, zoom) {
    const widths = borderWidth.match(/\d+/g);
    const result = {};
    result.max = Math.max(...widths) * zoom * 2;
    result.borderWidth = widths.reduce((accumulator, current, index) => {
      if (index !== 0) { accumulator += ' '; }
      accumulator += `${parseInt(current, 10) * zoom * 2}px`;
      return accumulator;
    }, '');
    return result;
  }

  render() {
    const { zoom, elementAttr, element } = this.props;
    const { editable, error } = this.state;
    const {
      color,
      colorType,
      gradients,
      fontFamily,
      fontSize,
      fontWeight,
      text,
      fontAlign,
      lineHeight,
      letterSpacing,
      textShadow,
      arch,
      width,
      height,
      fontWidth,
      fontHeight,
      glow
    } = elementAttr;

    const gradientName = 'gradient';
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const style = {
      ...elementAttr,
      color: (color) || 'transparent',
      fontFamily,
      fontSize: fontSize * zoom,
      fontWeight,
      textAlign: fontAlign,
      lineHeight: `${lineHeight}%`,
      letterSpacing: `${letterSpacing}px`,
      textShadow,
      width: width * zoom,
      height: height * zoom,
      minHeight: height * zoom,
      maxHeight: height * zoom,
      caretColor: (elementAttr.background === 'black' || (elementAttr.parent && elementAttr.parent.background === 'black')) ? 'white' : 'black',
      overflowWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      overflowY: 'hidden'
      // padding: '10px 20px',
    };
    delete style.height;

    if (element && element.decorationId) {
      if (fontAlign === "left") {
        style.paddingLeft = 10;
        style.paddingBottom = 6;
      } else if (fontAlign === "right") {
        style.paddingRight = 10;
        style.paddingBottom = 6;
      } else if (fontAlign === "justify" || fontAlign === "center") {
        style.paddingLeft = 8;
        style.paddingRight = 8;
        style.paddingBottom = 6;
      }
    }

    if (colorType === gradientName) {
      style.before = {
        textShadow: null,
        ...elementAttr.before,
        left: 0,
        top: 0,
        background: genGradientCss(gradients),
        color: 'transparent',
        content: text,
        fontFamily,
        fontSize: fontSize * zoom,
        fontWeight,
        textAlign: fontAlign,
        lineHeight: `${lineHeight}%`,
        letterSpacing: `${letterSpacing}px`,
      };
    }
    const childStyle = { ...style };
    childStyle.width = '100%';
    childStyle.height = fontHeight;
    delete childStyle.maxHeight;
    delete childStyle.minHeight;

    if (/吹き出し風背景付き文字(?!②)/.test(element.decorationName)) {
      const newBorderInfo = this.changeBorderWidth(style.after.borderWidth, zoom);
      style.after = {
        ...style.after,
        bottom: -newBorderInfo.max,
        left: fontSize * zoom / 2,
        borderWidth: newBorderInfo.borderWidth,
        borderColor: `${element.elementableAttributes.background} transparent transparent transparent`,
      };
    } else if (/点線背景付き文字(?!②)/.test(element.decorationName)) {
      // const paddings = style.padding.match(/\d+/g);
      // style.padding = paddings.reduce((accumulator, current, index) => {
      //   if (index !== 0) { accumulator += ' '; }
      //   accumulator += (index) ? `${parseInt(current, 10) * zoom * 2}px` : '0px';
      //   return accumulator;
      // }, '');
      delete style.padding;
      delete style.before.top;
      delete style.before.left;
      style.before = {
        ...style.before,
        height: "calc(100% - 2px)",
        padding: '8px 0px',
      };
      style.paddingTop = '18px';
      style.paddingBottom = '18px';
      style.paddingLeft = '14px';
      style.paddingRight = '14px';
    } else if (/点線背景付き文字②/.test(element.decorationName)) {
      delete style.before.top;
      delete style.after.bottom;
      style.before.paddingTop = '10px';
      style.after.paddingBottom = '10px';
    }

    return (
      <>
        {error && toastr.error('error', error.message)}
        <ClickAwayListener onClickAway={this.onClickAwayText}>
          <TextParent style={style} full parentRef={this.parentRef}>
            <ParseStyleText
              style={(/丸背景付き文字/.test(element.decorationName)) ? childStyle : style}
              onDoubleClick={this.onDoubleClickText}
              beforeClassName={
                colorType === gradientName
                  ? `${styles.textBefore}`
                  : ''
}
              content={text}
              full
              devRef={this.textRef}
              zoom={zoom}
            >
              {
              (editable)
                ? (
                  <textarea
                    value={text}
                    style={style}
                    onChange={this.onChangeText}
                    onFocus={(e) => {
                      e.target.selectionStart = this.cursor;
                    }}
                    ref={this.inputRef}
                    onCompositionStart={(e) => { this.isComposition = true; }}
                    onCompositionEnd={(e) => {
                      this.isComposition = false;
                      this.onChangeText(e);
                    }}
                    className={
                    colorType === gradientName
                      ? `${styles.textBefore} ${styles.textArea}`
                      : styles.textArea
                  }
                  />
                )
                : (arch.angle.value !== 0)
                  ? (
                    <ReactArcText
                      text={text}
                      direction={arch.direction.value}
                      arc={getArcRadius(arch.angle.value, getTextLineRect({ text, font }))}
                      class={
                        colorType === gradientName
                          ? `${styles.arcText} ${styles.textBefore} ${this.arcTextClassName}`
                          : `${styles.arcText} ${this.arcTextClassName}`
                      }
                    />
                  )
                  : newLineToBreak(text)
              }
            </ParseStyleText>
          </TextParent>
        </ClickAwayListener>
      </>
    );
  }
}
export default Text;
