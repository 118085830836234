import {
  ACTION_TYPE
} from '@pages/editor/EditorAction';

const EditorReducer = (state={}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.GET_PREV_DESIGN.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.GET_PREV_DESIGN.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.GET_PREV_DESIGN.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.SAVE_TEMP.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SAVE_TEMP.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SAVE_TEMP.fail:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default EditorReducer;