import { connect } from 'react-redux';
import DesignTools from '@pages/editor/workArea/designTools/DesignTools';
import { receiveElement, receiveElements, undoElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  element: state.entities.elements[ownProps.selection],
  elements: state.entities.elements,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  // const keys = Object.keys(Action);

  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  actions.undoElement = (...args) => dispatch(undoElement(...args));
  actions.receiveElements = (...args) => dispatch(receiveElements(...args));
  // for (const key of keys) {
  //   if (typeof Action[key] === 'function') {
  //     actions[key] = (...args) => dispatch(Action[key](...args));
  //   }
  // }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignTools);
