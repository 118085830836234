import {
  ButtonBase,
  FormControl,
  Input,
  Select,
  Typography,
  MenuItem,
  Tooltip,
  Button,
  ButtonGroup,
  MenuList,
} from '@material-ui/core';
import {
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
  FormatAlignJustify,
  RoundedCorner,
  Opacity as Transparency,
  TextFormat,
  FormatLineSpacing,
  DeleteForever,
} from '@material-ui/icons';
import { ulid } from 'ulid';
import { toastr } from 'react-redux-toastr';

import config from '@assets/config';

import { genGradientCss, getTextRect } from '@common/utils/TextOperation';
import { compareObject } from '@common/utils/ObjectUtils';

// icons
import {
  FontShadow, Glow, Arch, FontOuterBorder, FontOuterShadow,
} from '@components/icons';
import ActiveController from '@components/activeController/ActiveController';
import CustomPaper from '@components/utils/customPaper/CustomPaper';

import LineHeightDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/lineHeightDialog/LineHeightDialogContainer';
import LetterSpacingDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/letterSpacingDialog/LetterSpacingDialogContainer';
import FontShadowDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/fontShadowDialog/FontShadowDialogContainer';
import FontBorderDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/fontBorderDialog/FontBorderDialogContainer';
import GlowDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/glowDialog/GlowDialogContainer';
import ArchDialogContainer from '@pages/editor/workArea/designTools/textTools/components/dialogs/archDialog/ArchDialogContainer';
import BorderColorSquare from '@pages/editor/workArea/designTools/textTools/components/borderColor/BorderColor';
import Toolbar from '@pages/editor/workArea/designTools/components/toolbar/Toolbar';
import RoundedCornerDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialogContainer';
import TransparencyDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialogContainer';
import AlignmentContainer from '@pages/editor/workArea/designTools/components/alignment/AlignmentContainer';
import BorderDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/borderDialog/BorderDialogContainer';
import ShadowDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/shadowDialog/ShadowDialogContainer';

import styles from '@pages/editor/workArea/designTools/textTools/TextTools.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import '@pages/editor/workArea/designTools/textTools/TextTools.scss';

class TextTools extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;
    this.state = {
      dialogIsOpen: [
        false, // space dialog
        false, // fontShadow dialog
        false, // glow dialog
        false, // arch dialog
        false, // fontBorder dialog
        false, // letterSpace dialog
        false, // lineHeight dialog
        false, // fontSize dialog
      ],
      attention: false,
      localFontSize: 0,
    };

    this.alignments = {
      left: { label: '左寄せ', class: FormatAlignLeft },
      right: { label: '右寄せ', class: FormatAlignRight },
      center: { label: '中央寄せ', class: FormatAlignCenter },
      justify: { label: '均等割付', class: FormatAlignJustify },
    };
    this.changeColorTarget = `textContent${ulid()}`;
    this.inputRef = React.createRef();

    this.changeValue = this.changeValue.bind(this);
    this.toggleDialogs = this.toggleDialogs.bind(this);
    unmount();
  }

  componentDidMount() {
    const { fetchFont, mount, element } = this.props;
    const { fontSize } = element.elementableAttributes;
    this.setState({ localFontSize: fontSize });
    mount();
    fetchFont();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  componentDidUpdate(prevProps) {
    const {
      element, drawerSettings, fonts, changeDrawer,
    } = this.props;
    const { elementableAttributes: elementAttrs } = element;
    const { colorPalette } = drawerSettings;

    let isColorDifference = true;

    if (element.id !== prevProps.element.id) {
      this.changeColorTarget = `textContent${ulid()}`;
      const nextDrawerSettings = { ...drawerSettings, decorationTarget: element.id };
      changeDrawer(nextDrawerSettings);
    }

    if (colorPalette.colorType === 'gradient') {
      isColorDifference = (colorPalette.gradients !== undefined && !compareObject(colorPalette.gradients, elementAttrs.gradients));
    } else {
      isColorDifference = (colorPalette.color !== elementAttrs.color);
    }
    if (this.isChangeColorTargetOwn() && isColorDifference && !elementAttrs.before) {
      this.changeColor();
    }

    const fontIndex = (fonts) ? fonts.findIndex((font) => font.css_name === elementAttrs.fontFamily) : null;
    if (fontIndex && fontIndex > -1) {
      this.onLoadWeight(fontIndex);
    }
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  onClickFontAlign(attrValue) {
    return () => {
      const { element, receiveElement } = this.props;
      const updateElementableAttributes = element.elementableAttributes;

      updateElementableAttributes.fontAlign = attrValue;
      receiveElement({ ...element, elementableAttributes: updateElementableAttributes });
    };
  }

  onLoadWeight(idx) {
    const { element, receiveElement, fonts } = this.props;
    const { fontWeight } = element.elementableAttributes;
    const updateElementableAttributes = { ...element.elementableAttributes };
    if (fonts) {
      const weightIndex = fonts[idx].weights.findIndex((weightInfo) => weightInfo.weight === fontWeight);
      if (weightIndex < 0) {
        updateElementableAttributes.fontWeight = fonts[idx].weights[0].weight;
        receiveElement({ ...element, elementableAttributes: updateElementableAttributes });
      }
    }
  }

  onChangeFontSize(size, isToNeedDialogOpen = true) {
    return (e) => {
      const { element, receiveElement } = this.props;
      const { dialogIsOpen } = this.state;
      const updateElementableAttributes = { ...element.elementableAttributes };
      const {
        lineHeight, fontWeight, fontFamily, text,
      } = updateElementableAttributes;

      if (!dialogIsOpen[7] && isToNeedDialogOpen) { return; }
      if (this.validateFontSize(size)) { return; }

      updateElementableAttributes.fontSize = size;

      const font = `${fontWeight} ${size}px ${fontFamily}`;
      const newLineCount = (text.match(/(\n|\r|\rn)/g) || []).length;
      const calcedHeight = size * (parseInt(lineHeight, 10) / 100) * (newLineCount + 1);
      if (/丸背景付き文字/.test(element.decorationName)) {
        const width = element.elementableAttributes.fontWidth || element.elementableAttributes.width;
        const rect = getTextRect({
          text, font, height: calcedHeight, width,
        });
        updateElementableAttributes.fontHeight = rect.height;
        updateElementableAttributes.fontWidth = rect.width;
      } else {
        const rect = getTextRect({
          text, font, height: calcedHeight, width: element.elementableAttributes.width,
        });
        updateElementableAttributes.height = rect.height;
        updateElementableAttributes.width = rect.width;
      }

      this.setState({ localFontSize: size });
      dialogIsOpen[7] = false;
      this.setState({ dialogIsOpen });
      receiveElement({ ...element, elementableAttributes: updateElementableAttributes });
    };
  }

  changeValue(attr) {
    return (e) => {
      const { element, receiveElement } = this.props;
      const updateElementableAttributes = { ...element.elementableAttributes };
      const {
        lineHeight, fontWeight, fontSize, fontFamily, text,
      } = updateElementableAttributes;

      let newFontSize;
      if (typeof fontSize === 'string') {
        newFontSize = parseInt(fontSize, 10) || 0;
      } else {
        newFontSize = fontSize || 0;
      }

      updateElementableAttributes[attr] = e.target.value;

      const font = `${fontWeight} ${newFontSize}px ${fontFamily}`;
      const newLineCount = (text.match(/(\n|\r|\rn)/g) || []).length;
      const calcedHeight = newFontSize * (parseInt(lineHeight, 10) / 100) * (newLineCount + 1);
      if (/丸背景付き文字/.test(element.decorationName)) {
        const width = element.elementableAttributes.fontWidth || element.elementableAttributes.width;
        const rect = getTextRect({
          text, font, height: calcedHeight, width,
        });
        updateElementableAttributes.fontHeight = rect.height;
        updateElementableAttributes.fontWidth = rect.width;
      } else {
        const rect = getTextRect({
          text, font, height: calcedHeight, width: element.elementableAttributes.width,
        });
        updateElementableAttributes.height = rect.height;
        updateElementableAttributes.width = rect.width;
      }

      receiveElement({ ...element, elementableAttributes: updateElementableAttributes });
    };
  }

  changeColor() {
    const { receiveElement, element, drawerSettings } = this.props;
    const { elementableAttributes } = element;
    const { colorPalette } = drawerSettings;
    const updatedElementableAttrs = { ...elementableAttributes };

    if (this.isChangeColorTargetOwn()) {
      if (colorPalette.colorType === 'gradient') {
        updatedElementableAttrs.gradients = colorPalette.gradients;
        updatedElementableAttrs.color = null;
      } else {
        updatedElementableAttrs.color = colorPalette.color;
        updatedElementableAttrs.gradients = [];
      }
      updatedElementableAttrs.colorType = colorPalette.colorType;
      receiveElement({ ...element, elementableAttributes: updatedElementableAttrs });
    }
  }

  validateFontSize(fontSize) {
    if (fontSize < 40) {
      this.setState({ attention: { message: config.validate.fontSize } });
    } else {
      this.setState({ attention: false });
      return false;
    }
    return true;
  }

  toggleDialogs(dialogNumber, boolean) {
    return () => {
      const { closeDialog, allowDialogs } = this.props;
      const { dialogIsOpen } = this.state;

      let openOrClose = boolean || !dialogIsOpen[dialogNumber];
      // 上部のダイアログが空いていて、ボタンが押された時。
      if (dialogIsOpen[dialogNumber] && !allowDialogs) {
        openOrClose = true;
      }
      closeDialog();
      // dialog判定の管理
      const updatedDialogIsOpen = dialogIsOpen.map(() => false);
      updatedDialogIsOpen[dialogNumber] = openOrClose;
      this.setState({
        dialogIsOpen: updatedDialogIsOpen,
      });
    };
  }

  toggleSubDrawer({ subDrawerId, color = undefined, elementId = undefined }) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      let nextDrawerSettings = { ...drawerSettings };
      // ドロワーが閉じていたら開ける。
      if (drawerSettings.closed) { nextDrawerSettings.closed = false; }
      // ドロワーが閉じていたら開けるアニメーションをつける。
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }

      // colorに何か入っていたら、カラーパレットの設定を更新する。
      if (color) {
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: config.colorTitle['文字色'],
          changeTarget: this.changeColorTarget,
          colorType: 'unit',
          allowGradient: true,
          decorationTarget: null,
        };
      } else if (elementId) {
        // colorが入っていなくてエレメントIDがあれば、装飾ターゲットを更新する。
        nextDrawerSettings = {
          ...nextDrawerSettings,
          decorationTarget: elementId,
        };
      }

      // カラーパレットの変更対象が自分ではない時か次のサブドロワーのIDが違う時,かつエレメントIDが入っている時
      if ((
        drawerSettings.colorPalette.changeTarget !== this.changeColorTarget
        || nextDrawerSettings.subDrawer !== subDrawerId)
        && !elementId
      ) {
        nextDrawerSettings.isSubDrawerOpen = true;
      } else if (drawerSettings.closed) {
        nextDrawerSettings.isSubDrawerOpen = true;
      } else {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
      }

      // フォント装飾サブドロワーが開いてるときにフォント装飾サブドロワーボタンを押した時
      if (subDrawerId === 0 && drawerSettings.isSubDrawerOpen) {
        nextDrawerSettings.drawer = 6;
      }
      nextDrawerSettings.subDrawer = subDrawerId;

      changeDrawer(nextDrawerSettings);
    };
  }

  changeDrawer(id) {
    const { drawerSettings, changeDrawer } = this.props;
    const updateDrawerSettings = {
      ...drawerSettings,
      drawer: id,
      closed: false,
      isSubDrawerOpen: false,
      decorationTarget: null,
    };

    // サブドロワーが開いていない時に同じIDならドロワーを閉じる
    if (drawerSettings.drawer === id && drawerSettings.isSubDrawerOpen === false) {
      updateDrawerSettings.drawer = 6;
    }

    if (updateDrawerSettings.closed) {
      updateDrawerSettings.animate = false;
    } else {
      // 開くとき
      updateDrawerSettings.animate = true;
    }
    changeDrawer(updateDrawerSettings);
  }

  isChangeColorTargetOwn() {
    const { drawerSettings } = this.props;
    return (drawerSettings.colorPalette.changeTarget === this.changeColorTarget);
  }

  colorStyle() {
    const { element: { elementableAttributes } } = this.props;
    const { color, colorType, gradients } = elementableAttributes;
    if (colorType === 'gradient') {
      return { background: genGradientCss(gradients, 'to top'), border: '1px solid #FFF' };
    }
    return { backgroundColor: color };
  }

  generateToolbarData() {
    const {
      element,
      zoom,
      isOpen,
      toggleDialog,
      designToolsStyles,
      drawerSettings,
      fonts,
      allowDialogs,
      deleteElement,
    } = this.props;
    const { dialogIsOpen, localFontSize } = this.state;
    const fontSizes = [40, 44, 48, 52, 56, 60, 64, 72, 80, 88, 96, 112, 130, 156, 196, 258];
    const {
      color, fontFamily, fontSize, fontWeight, border, fontAlign,
    } = element.elementableAttributes;
    const colorStyle = this.colorStyle();
    const mainColor = '#FFF';
    const fontIndex = (fonts) ? fonts.findIndex((font) => font.css_name === fontFamily) : null;
    const buttonTextLarge = { textSizeLarge: styles.textSizeLarge };
    const numLocalFontSize = (typeof localFontSize === 'string') ? parseInt(localFontSize, 10): localFontSize;
    return {
      main: {
        content: [
          <Button
            className={(drawerSettings.isSubDrawerOpen && drawerSettings.subDrawer === 0)
              ? `${styles.active} ${styles.ButtonBase} ${styles.toolbarButton}`
              : `${styles.ButtonBase} ${styles.toolbarButton}`}
            onClick={this.toggleSubDrawer({ subDrawerId: 0, elementId: element.id })}
            classes={buttonTextLarge}
          >
            フォント装飾
          </Button>,
          <Button
            className={(!drawerSettings.closed && drawerSettings.drawer === 7 && !drawerSettings.isSubDrawerOpen)
              ? `${styles.active} ${styles.ButtonBase} ${styles.toolbarButton} ${styles.space}`
              : `${styles.ButtonBase} ${styles.toolbarButton} ${styles.space}`}
            onClick={() => this.changeDrawer(7)}
            classes={buttonTextLarge}
          >
            デザインパターン
            <br />
            から選ぶ
          </Button>,
          <div className={`${styles.mainWrapper} ${styles.fullWidth} ${designToolsStyles.dialogParent}`}>
            <ActiveController
              active={
                  drawerSettings.colorPalette.changeTarget === this.changeColorTarget
                  && drawerSettings.subDrawer === 1
                  && drawerSettings.isSubDrawerOpen === true
                }
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="文字色">
                <div
                  id="TextTool-ColorPalette"
                  className={`btn-color ${styles.colorPalette} cursor-pointer`}
                  style={colorStyle}
                  onClick={this.toggleSubDrawer({ subDrawerId: 1, color })}
                />
              </Tooltip>
            </ActiveController>
            <ActiveController
              active={dialogIsOpen[4] && allowDialogs}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="文字ボーダー色">
                <div>
                  <BorderColorSquare
                    color={border.configs[0].color}
                    className="cursor-pointer"
                    onClick={this.toggleDialogs(4)}
                    active={dialogIsOpen[4] && allowDialogs}
                  />
                </div>
              </Tooltip>
            </ActiveController>
            <FontBorderDialogContainer
              className={(dialogIsOpen[4] && allowDialogs) ? '' : `${styles.hidden}`}
              element={element}
            />
            <ActiveController
              active={isOpen[3]}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="角丸">
                <RoundedCorner
                  className={`${designToolsStyles.iconDefault}`}
                  onClick={toggleDialog(3)}
                />
              </Tooltip>
            </ActiveController>
            <RoundedCornerDialogContainer
              className={(isOpen[3]) ? '' : designToolsStyles.hidden}
              zoom={zoom}
              element={element}
            />
            <ActiveController
              active={isOpen[4]}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="不透明度">
                <Transparency
                  className={`${designToolsStyles.iconDefault}`}
                  onClick={toggleDialog(4)}
                />
              </Tooltip>
            </ActiveController>
            <TransparencyDialogContainer
              className={(isOpen[4]) ? '' : designToolsStyles.hidden}
              element={element}
            />
          </div>,
        ],
      },
      font: {
        title: 'フォント',
        content: [
          <FormControl className={`${styles.space} ${styles.maxFullWidth}`}>
            <Select
              type="text"
              id="font-kind"
              size={(fontFamily && typeof fontFamily === 'string') ? fontFamily.length + 1 : fontFamily.toString().length + 1}
              value={fontFamily}
              onChange={this.changeValue('fontFamily')}
              classes={{ selectMenu: styles.textToolsSelect }}
            >
              {(fonts !== undefined)
                ? fonts.map((font) => (<MenuItem key={font.name} style={{ color: '#000' }} value={font.css_name}><span style={{ fontFamily: font.css_name }}>{font.name}</span></MenuItem>))
                : <MenuItem key={0} value={null} style={{ color: '#000' }} />}
            </Select>
          </FormControl>,
          <div className={styles.space}>
            <label htmlFor="font-weight" style={{ color: '#FFF', fontSize: '1rem' }}>
              太さ
              <Select
                id="font-weight"
                className={`input-attr ${styles.hideArrow}`}
                size={(fontWeight && typeof fontWeight === 'string') ? fontWeight.length + 1 : 2}
                value={fontWeight}
                onChange={this.changeValue('fontWeight')}
                disabled={!!((fonts && fonts[fontIndex] && fonts[fontIndex].weights.length === 1))}
                classes={{ selectMenu: styles.textToolsSelect }}
              >
                { (() => {
                  if (fontIndex && fontIndex > -1) {
                    return fonts[fontIndex].weights.map((weightInfo, key) => <MenuItem key={key} style={{ color: '#000' }} value={weightInfo.weight} selected={fontWeight === weightInfo.weight}>{weightInfo.weightName}</MenuItem>);
                  }
                })()}
              </Select>
            </label>
          </div>,
          <div className={`${styles.sizeWrapper} ${styles.space}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="font-size" style={{ color: '#FFF', fontSize: '1rem' }}>サイズ</label>
            <div className={`${styles.inputWithSelect} ${styles.numberInput}`} onBlur={this.onChangeFontSize(localFontSize)}>
              <Input
                className="input-attr"
                id="font-size"
                type="number"
                value={localFontSize}
                onChange={(e) => this.setState({ localFontSize: e.target.value })}
                classes={{ input: styles.textToolsSelect }}
                inputProps={{
                  onFocus: this.toggleDialogs(7, true),
                }}
              />
              <CustomPaper className={(dialogIsOpen[7]) ? `${styles.sizePaper} ${scrollbar.customScroll}` : `${styles.sizePaper} ${styles.hidden}`} right>
                <MenuList classes={{ padding: styles.menuListPadding }}>
                  {
                    fontSizes.map((size, key) => (
                      <MenuItem selected={fontSize === size} onMouseDown={this.onChangeFontSize(size)} key={`fontSizeMenu-${key}`}>
                        <Typography color="textSecondary" variant="body1">
                          { size }
                        </Typography>
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </CustomPaper>
            </div>
            <ButtonGroup style={{ alignItems: 'center', justifyContent: 'center', flexBasis: '40%' }}>
              <Button
                style={{
                  padding: 0, width: '50%', flexBasis: '50%', height: '50%', minWidth: '20px', minHeight: '20px', fontSize: '1.4rem',
                }}
                onClick={this.onChangeFontSize(numLocalFontSize + 1, false)}
              >
                +
              </Button>
              <Button
                style={{
                  padding: 0, width: '50%', flexBasis: '50%', height: '50%', minWidth: '20px', minHeight: '20px', fontSize: '1.4rem',
                }}
                onClick={this.onChangeFontSize(numLocalFontSize - 1, false)}
              >
                -
              </Button>
            </ButtonGroup>
          </div>,
        ],
      },
      alignment: {
        title: '配置',
        content: [
          <AlignmentContainer element={element} color={mainColor} iconClassName={designToolsStyles.horizontalSpace} />,
        ],
      },
      paragraph: {
        title: '段落',
        content: [
          <div className={`${styles.paragraph} ${styles.space} ${styles.fullWidth}`}>
            <ActiveController
              active={fontAlign === 'left'}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="左寄せ">
                <div onClick={this.onClickFontAlign('left')}>
                  <FormatAlignLeft />
                </div>
              </Tooltip>
            </ActiveController>

            <ActiveController
              active={fontAlign === 'right'}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="右寄せ">
                <div onClick={this.onClickFontAlign('right')}>
                  <FormatAlignRight />
                </div>
              </Tooltip>
            </ActiveController>
            <ActiveController
              active={fontAlign === 'center'}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="中央寄せ">
                <div onClick={this.onClickFontAlign('center')}>
                  <FormatAlignCenter />
                </div>
              </Tooltip>
            </ActiveController>
            <ActiveController
              active={fontAlign === 'justify'}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="均等割付">
                <div onClick={this.onClickFontAlign('justify')}>
                  <FormatAlignJustify />
                </div>
              </Tooltip>
            </ActiveController>
          </div>,
        ],
      },
      space: {
        title: 'スペース',
        content: [
          <div className={styles.dialogParent}>
            <Button
              size="large"
              startIcon={<TextFormat className={styles.toolbarIcon} />}
              className={(dialogIsOpen[5] && allowDialogs)
                ? `${styles.active} ${styles.button}` : styles.button}
              onClick={this.toggleDialogs(5)}
              classes={{ textSizeLarge: styles.textSizeLargePadding }}
            >
              字間
            </Button>
            <LetterSpacingDialogContainer
              className={(dialogIsOpen[5] && allowDialogs) ? '' : `${styles.hidden}`}
              element={element}
            />
          </div>,
          <div className={styles.dialogParent}>
            <Button
              size="large"
              startIcon={<FormatLineSpacing className={styles.toolbarIcon} />}
              className={(dialogIsOpen[6] && allowDialogs)
                ? `${styles.active} ${styles.button}` : styles.button}
              onClick={this.toggleDialogs(6)}
              classes={{ textSizeLarge: styles.textSizeLargePadding }}
            >
              行間
            </Button>
            <LineHeightDialogContainer
              className={(dialogIsOpen[6] && allowDialogs) ? '' : `${styles.hidden}`}
              element={element}
            />
          </div>,
        ],
      },
      effect: {
        title: 'エフェクト',
        content: [
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical outlined primary button group"
          >
            <div className={styles.dialogParent}>
              <Button
                size="large"
                startIcon={<FontShadow color={mainColor} />}
                className={(dialogIsOpen[1] && allowDialogs)
                  ? `${styles.active} ${styles.button}` : styles.button}
                onClick={this.toggleDialogs(1)}
                classes={{
                  iconSizeLarge: styles.iconSizeLarge14,
                  textSizeLarge: styles.textSizeLargePadding,
                }}
              >
                テキスト影
              </Button>
              <FontShadowDialogContainer
                className={(dialogIsOpen[1] && allowDialogs) ? '' : `${styles.hidden}`}
                element={element}
              />
            </div>
            <div className={styles.dialogParent}>
              <Button
                size="large"
                startIcon={<Glow color={mainColor} />}
                className={(dialogIsOpen[2] && allowDialogs)
                  ? `${styles.active} ${styles.button}` : styles.button}
                onClick={this.toggleDialogs(2)}
                classes={{ textSizeLarge: styles.textSizeLargePadding }}
              >
                光彩
              </Button>
              <GlowDialogContainer
                className={(dialogIsOpen[2] && allowDialogs) ? '' : `${styles.hidden}`}
                element={element}
              />
            </div>
            <div className={styles.dialogParent}>
              <Button
                size="large"
                startIcon={<Arch color={mainColor} />}
                className={(dialogIsOpen[3] && allowDialogs)
                  ? `${styles.active} ${styles.button}` : `${styles.button}`}
                onClick={this.toggleDialogs(3)}
                classes={{ textSizeLarge: styles.textSizeLargePadding }}
              >
                円形
              </Button>
              <ArchDialogContainer
                className={(dialogIsOpen[3] && allowDialogs) ? '' : `${styles.hidden}`}
                element={element}
              />
            </div>
          </ButtonGroup>,
        ],
      },
      outerDesign: {
        title: '外枠装飾',
        content: [
          <div className={designToolsStyles.dialogParent}>
            <Button
              size="large"
              className={isOpen[0]
                ? `${styles.active} ${styles.button}`
                : `${styles.button} `}
              onClick={toggleDialog(0)}
              startIcon={<FontOuterShadow color={mainColor} shadowColor={mainColor} backgroundColor="#747474" />}
              classes={{
                iconSizeLarge: styles.iconSizeLarge18,
                textSizeLarge: styles.textSizeLargePadding,
              }}
            >
              影
            </Button>
            <ShadowDialogContainer
              className={(isOpen[0]) ? '' : `${styles.hidden}`}
              element={element}
            />
          </div>,
          <div className={designToolsStyles.dialogParent}>
            <Button
              size="large"
              className={isOpen[2]
                ? `${styles.active} ${styles.button}`
                : `${styles.button}`}
              onClick={toggleDialog(2)}
              startIcon={<FontOuterBorder color={mainColor} borderColor={mainColor} />}
              classes={{
                iconSizeLarge: styles.iconSizeLarge18,
                textSizeLarge: styles.textSizeLargePadding,
              }}
            >
              縁
            </Button>
            <BorderDialogContainer
              className={(isOpen[2]) ? '' : `${styles.hidden}`}
              element={element}
            />
          </div>,
        ],
      },
      delete: {
        content: [
          <Tooltip title="削除　Delete">
            <Button
              onClick={deleteElement}
              className={`${styles.ButtonBase} ${styles.fullWidth} ${styles.border}`}
              startIcon={<DeleteForever className={designToolsStyles.iconDefault} />}
            >
              削除
            </Button>
          </Tooltip>,
        ],
      },
    };
  }

  render() {
    const { attention } = this.state;
    return (
      <>
        {attention && toastr.warning('attention', attention.message, { onHideComplete: () => this.setState({ attention: false }) })}
        <Toolbar title="テキスト" data={this.generateToolbarData()} />
      </>
    );
  }
}

export default TextTools;
