import axios from 'axios';
import Axios from '@common/apis/axios';
import { ActionCreatorFactory } from '@common/ActionCreator';
import config from '@assets/config';

export const ACTION_TYPE = {
  GET_IMAGE_FROM_URL: ActionCreatorFactory('TrimmingModal', "GET_IMAGE_FROM_URL"),
  CHANGE_READ:  ActionCreatorFactory('TrimmingModal', "CHANGE_READ"),
  SET_ERROR: ActionCreatorFactory('TrimmingModal', 'SET_ERROR'),
};


export const getImageFromUrl = (url) => (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.GET_IMAGE_FROM_URL.start, 
    payload: { loading: true,  error: null } 
  });
  Axios.get(`${config.apiServer.baseUrl}/assetImage/url`, { params: { url }}).then((res) => {
    setTimeout(() => {
      axios.get(res.data, {responseType: 'blob', headers: { 'Content-Type': 'image/*'}}).then((response) => {
        dispatch({
          type: ACTION_TYPE.GET_IMAGE_FROM_URL.success, 
          payload: { loading: false,  error: null, targetImageBlob: response.data, read: false } 
        })
      }).catch((error) => {
        dispatch({ 
          type: ACTION_TYPE.GET_IMAGE_FROM_URL.fail, 
          payload: { loading: false,  error: error  } 
        });
      })
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ 
      type: ACTION_TYPE.GET_IMAGE_FROM_URL.fail, 
      payload: { loading: false,  error: error  } 
    });
  });
}

export const changeRead = (boolean) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.CHANGE_READ.success,
    payload: {read: boolean}
  })
}

export const setError = (string) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_ERROR.success,
    payload: {error: string}
  })
}