import styles from '@components/icons/alignVerticalBottom/AlignVerticalBottom.module.scss';

export default function AlignVerticalBottom(props) {
  const { className, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <path fill={color} d="M22,22H2v-2h20V22z M10,2H7v16h3V2z M17,8h-3v10h3V8z" />
    </svg>
  );
}
