import { combineReducers } from 'redux';
import designDrawerReducer from '@pages/editor/designDrawer/DesignDrawerReducer';
import gridLineReducer from '@pages/editor/workArea/design/components/GridLineReducer';
import editorReducer from '@pages/editor/EditorReducer';

const uiReducer = combineReducers({
  drawer: designDrawerReducer,
  grid: gridLineReducer,
  editor: editorReducer
});

export default uiReducer;
