import { ACTION_TYPE } from '@pages/editor/workArea/design/components/GridLineAction';

const initializeState = {
  open: false
}

const gridLineReducer = (state = initializeState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.MANIPULATE_GRID_LINE.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.MANIPULATE_GRID_LINE.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.MANIPULATE_GRID_LINE.fail:
      return Object.assign({}, state, action.payload);
      
    default:
      return state;
  }
};

export default gridLineReducer;