// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GlowDialog-module__glowDialogWrapper--37HUl{display:flex;flex-direction:column;align-items:center;padding:12.5px;width:100px;bottom:40px;top:unset;left:-12px}.GlowDialog-module__glowDialogWrapper--37HUl .GlowDialog-module__titleAndColorSelection--FOfSq{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.GlowDialog-module__glowDialogWrapper--37HUl .GlowDialog-module__colorPalette--Rx-Gp{width:20px;height:20px;max-height:20px;max-width:20px;min-width:20px;min-height:20px}\n", ""]);
// Exports
exports.locals = {
	"glowDialogWrapper": "GlowDialog-module__glowDialogWrapper--37HUl",
	"titleAndColorSelection": "GlowDialog-module__titleAndColorSelection--FOfSq",
	"colorPalette": "GlowDialog-module__colorPalette--Rx-Gp"
};
module.exports = exports;
