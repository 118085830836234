import { Switch, Route, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import Auth from '@components/auth/AuthContainer';
import Login from '@pages/login/LoginContainer';
import EditorContainer from '@pages/editor/EditorContainer';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Material ui theme
const theme = createMuiTheme();

function App() {
  return (
    <>
      <div className="main">
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Auth>
              <Switch>
                <Route path="/design/:id" component={EditorContainer} />
                <Route
                  path="/"
                  render={() => <Redirect to="/design/1" />}
                />
              </Switch>
            </Auth>
          </Switch>
        </ThemeProvider>
      </div>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
    </>
  );
}

export default App;
