// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TagDrawer-module__requiredInfoWrapper--174_r{display:flex;justify-content:flex-start;align-items:center}.TagDrawer-module__requiredInfoIcon--zf2Cc{height:100%;display:flex;align-items:center;flex:1 1 5%}.TagDrawer-module__requiredInfo--299Sv{flex:1 1 95%}.TagDrawer-module__requiredInputs--1T2Jw{width:100%}.TagDrawer-module__requiredInputs--1T2Jw .TagDrawer-module__requiredInput--21Xwh{display:flex;justify-content:center;margin:5% 1%}.TagDrawer-module__requiredInputs--1T2Jw .TagDrawer-module__requiredInput--21Xwh .TagDrawer-module__requiredBlank--EHG7z{flex:0 1 10%;font-size:1rem;display:flex;justify-content:center;align-items:center;border-radius:5px;margin:2% 0}.TagDrawer-module__textInputWrapper--2eXZT{flex:0 1 75%;border-radius:4px;height:35px;font-size:4.5rem;display:flex;align-items:center;justify-content:space-between;padding:2 4px}.TagDrawer-module__formControl--3TRZF{width:100%}.TagDrawer-module__formControl--3TRZF .TagDrawer-module__input--1kA2x{color:#FFF;width:100%;padding-left:4px;font-size:1.5rem;flex:1 1 90%;border:0px;overflow:hidden}.TagDrawer-module__formControl--3TRZF .TagDrawer-module__requiredDiv--1e6jm{background-color:#D47780;font-size:1rem;font-weight:700;color:#FFF;border-radius:5px;padding:2px 5px;margin-right:10px;margin-bottom:12px;align-items:flex-start !important}.TagDrawer-module__textField--3GXmG{width:100%}.TagDrawer-module__textField--3GXmG .TagDrawer-module__input--1kA2x{color:white !important;font-size:1.5rem}.TagDrawer-module__textField--3GXmG .TagDrawer-module__label--1ZdUm{color:#FFF;font-size:1.5rem}\n", ""]);
// Exports
exports.locals = {
	"requiredInfoWrapper": "TagDrawer-module__requiredInfoWrapper--174_r",
	"requiredInfoIcon": "TagDrawer-module__requiredInfoIcon--zf2Cc",
	"requiredInfo": "TagDrawer-module__requiredInfo--299Sv",
	"requiredInputs": "TagDrawer-module__requiredInputs--1T2Jw",
	"requiredInput": "TagDrawer-module__requiredInput--21Xwh",
	"requiredBlank": "TagDrawer-module__requiredBlank--EHG7z",
	"textInputWrapper": "TagDrawer-module__textInputWrapper--2eXZT",
	"formControl": "TagDrawer-module__formControl--3TRZF",
	"input": "TagDrawer-module__input--1kA2x",
	"requiredDiv": "TagDrawer-module__requiredDiv--1e6jm",
	"textField": "TagDrawer-module__textField--3GXmG",
	"label": "TagDrawer-module__label--1ZdUm"
};
module.exports = exports;
