import {
  FormControl, InputLabel, MenuItem, Select, Typography, IconButton
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import styles from '@pages/editor/designDrawer/tag/TagDrawer.module.scss';
import '@pages/editor/designDrawer/tag/TagDrawer.scss';
import { toastr } from 'react-redux-toastr';

class TagDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // companyIdError: false,
      openSelect: false
    };
    const { unmount } = this.props;
    unmount();

    this.tagConfigs = {
      // companyId: { name: '企業ID' },
      professional: { name: '職種カテゴリ', select: true },
      targetAttr: { name: 'ターゲット属性', select: true },
      imageKind: { name: '画像種別', select: true },
      imageImagine: { name: '画像イメージ', select: true },
      feature: { name: '特徴', select: true },
    };
  }

  async componentDidMount() {
    const { fetch, mount } = this.props;
    mount();
    fetch();
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  /**
   * @param {string} tagName 英語のタグ名
   */
  onChangeTag(tagName) {
    const { update, tags } = this.props;
    const { tagState } = tags;
    return (e) => {
      const updatedTag = { ...tagState };
      updatedTag[tagName] = e.target.value;
      update(updatedTag);
    };
  }

  // onChangeCompanyId(tagName, value) {
  //   const { update, tags } = this.props;
  //   const { tagState } = tags;
  //   let isError = false;
  //   const updatedTag = { ...tagState };
  //   updatedTag[tagName] = this.toHalfWidth(value);
  //   if (tagName === 'companyId' && !(value).match(/^[0-9０-９]*$/)) {
  //     isError = true;
  //   } else {
  //     update(updatedTag);
  //   }
  //   this.setState({ companyIdError: isError });
  // }

  toHalfWidth(value) {
    return value.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  }

  render() {
    const { tags, error } = this.props;
    const { tagState, allTags, wait } = tags;
    return (
      <article className={styles.requiredInfoWrapper}>
        {error && toastr.error('error', error.message)}
        <div className={styles.requiredInfo}>
          <article className={styles.requiredInputs}>
            {
              Object.keys(this.tagConfigs).map((tagName) => {
                const tagConfig = this.tagConfigs[tagName];
                return (
                  <article className={styles.requiredInput} key={tagName}>
                    <div className={styles.textInputWrapper}>
                      <FormControl fullWidth className={`${styles.formControl} TagDrawerSelect`}>
                        <InputLabel id={`${tagName}-label`}>
                          <Typography>{tagConfig.name === '画像種別' ? '画像カテゴリ' : tagConfig.name}</Typography>
                        </InputLabel>
                        <Select
                          labelId={`${tagName}-label`}
                          id={tagName}
                          onChange={this.onChangeTag(tagName)}
                          className={styles.input}
                          value={tagState[tagName]}
                          placeholder={tagConfig.name}
                          IconComponent={(props) => {
                            if (props.className.includes("iconOpen" ) || (tagName !== 'professional' && tagName !== 'targetAttr')) {
                              return (
                                <ArrowDropDown {...props}/>
                              )
                            }
                            return (
                            <IconButton {...props} style={{ padding: 0, alignItems: 'flex-start' }}>
                              <div className={styles.requiredDiv}>必須</div>
                              <ArrowDropDown/>
                            </IconButton>
                          )}}
                        >
                          <MenuItem value="">未選択</MenuItem>
                          {
                            (() => {
                              if (allTags !== undefined) {
                                if (allTags[tagConfig.name] !== undefined) {
                                  return allTags[tagConfig.name].map((tag) => (
                                    <MenuItem
                                      key={tag.id}
                                      value={tag.id}
                                    >
                                      {tag.name}
                                    </MenuItem>
                                  ));
                                }
                              }
                              return '';
                            })()
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </article>
                );
              })
            }
          </article>
        </div>
      </article>
    );
  }
}

export default TagDrawer;
