import Moveable from 'react-moveable';
import ElementContainer from '@components/elements/ElementContainer';
import styles from '@pages/editor/workArea/design/Design.module.scss';
import { compareObject } from '@common/utils/ObjectUtils';
import GridLineContainer from '@pages/editor/workArea/design/components/GridLineContainer';

class Design extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null,
      draggable: true,
    };
    this.frame = {
      translate: [0, 0],
      rotate: 0,
    };
    this.keepRatio = false;
    this.myRef = React.createRef();
    this.holdShift = this.holdShift.bind(this);
    this.releaseShift = this.releaseShift.bind(this);
    this.changeDesignState = this.changeDesignState.bind(this);
  }

  componentDidMount() {
    const { elements } = this.props;
    document.addEventListener('keydown', this.holdShift);
    document.addEventListener('keyup', this.releaseShift);
    const target = document.querySelector('.target');
    this.setState({ target });
    const elementIds = Object.keys(elements);
    const element = { ...elements[elementIds[0]] };
    this.element = element;
  }

  componentDidUpdate(prevProps, prevState) {
    const { zoom, selection, elements } = this.props;
    const { target } = this.state;
    const { target: prevTarget } = prevState;
    if (selection !== prevProps.selection) {
      this.element = { ...elements[selection] };
      if (prevProps.selection && !selection) {
        this.setState({ target: null });
      }
    }
    if (elements[selection] && !compareObject(this.element, elements[selection])) {
      this.element = elements[selection];
      this.select(this.element.id);
    }

    if (prevProps.zoom !== zoom) {
      this.element && this.element.id && this.select(this.element.id);
    }

    if (prevTarget && target && JSON.stringify(prevTarget, ['id', 'style']) !== JSON.stringify(target, ['id', 'style'])) {
      this.myRef.current.updateRect();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.holdShift);
    document.removeEventListener('keyup', this.releaseShift);
  }

  holdShift(e) {
    if (e.shiftKey) {
      this.keepRatio = true;
    }
  }

  releaseShift() {
    this.keepRatio = false;
  }

  select(id) {
    const target = document.getElementById(id);
    const { setSelection, elements } = this.props;
    this.setState({ target });
    setSelection(elements[id].id);
    this.myRef.current.updateRect();
  }

  updateSelected() {
    const { receiveElement } = this.props;
    receiveElement(this.element);
  }

  isImage(keepRatio) {
    const images = [
      'AssetImage',
      'WallPaperImage',
      'FrameImage',
      'UploadImage',
      // 'ShapeImage',
    ];

    if (this.element !== undefined && images.includes(this.element.elementableType)) {
      return true;
    }
    return keepRatio;
  }

  changeDesignState(key, value) {
    if (this.state[key] !== value) {
      this.setState({
        [key]: value,
      });
    }
  }

  render() {
    const {
      elements, design, zoom,
    } = this.props;
    const { target, draggable } = this.state;
    return (
      <div
        className={styles.design}
        style={{ width: design.width * zoom, height: design.height * zoom }}
      >
        <Moveable
          ref={this.myRef}
          target={target}
          draggable={draggable}
          throttleDrag={0}
          resizable
          keepRatio={this.isImage(this.keepRatio)}
          throttleResize={0}
          rotatable
          rotationPosition="top"
          throttleRotate={0}
          origin={false}
          onDragStart={({ set }) => {
            this.frame.rotate = target.style.transform ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0]) : 0;
            this.frame.translate = [
              parseInt(target.style.left, 10),
              parseInt(target.style.top, 10),
            ];
            set(this.frame.translate);
          }}
          onDrag={({ beforeTranslate }) => {
            this.frame.translate = beforeTranslate;
          }}
          onDragEnd={({ isDrag }) => {
            this.element.posX = this.frame.translate[0] / zoom;
            this.element.posY = this.frame.translate[1] / zoom;
            if (isDrag) this.updateSelected();
          }}
          onResizeStart={({ setOrigin, dragStart, set }) => {
            setOrigin(['%', '%']);
            this.frame.rotate = target.style.transform ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0]) : 0;
            this.frame.translate = [
              parseInt(target.style.left, 10),
              parseInt(target.style.top, 10),
            ];
            if (dragStart) {
              dragStart.set(this.frame.translate);
            }
          }}
          onResize={({
            width, height, drag,
          }) => {
            const { receiveElement } = this.props;
            this.frame.translate = drag.beforeTranslate;
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;

            this.element.elementableAttributes.width = width / zoom;
            this.element.elementableAttributes.height = height / zoom;

            receiveElement({ ...this.element });
          }}
          onResizeEnd={({ isDrag }) => {
            this.element.posX = this.frame.translate[0] / zoom;
            this.element.posY = this.frame.translate[1] / zoom;
            if (isDrag) this.updateSelected();
          }}
          onRotateStart={({ set }) => {
            this.frame.translate = [
              parseInt(target.style.left, 10),
              parseInt(target.style.top, 10),
            ];
            this.frame.rotate = target.style.transform ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0]) : 0;
            set(this.frame.rotate);
          }}
          onRotate={({ beforeRotate }) => {
            this.frame.rotate = beforeRotate;
          }}
          onRotateEnd={({ isDrag }) => {
            this.element.rotate = this.frame.rotate;
            if (isDrag) this.updateSelected();
          }}
          onRender={() => {
            target.style.left = `${this.frame.translate[0]}px`;
            target.style.top = `${this.frame.translate[1]}px`;
            target.style.transform = `rotate(${this.frame.rotate}deg)`;
          }}
        />
        <GridLineContainer zoom={zoom} />
        <div className={styles.elementsContainer} id="noElement" style={{ backgroundColor: 'white' }}>

          {Object.keys(elements).map((elementId, index) => {
            const element = elements[elementId];
            if (element._destroy) return null;
            const elementAttrs = element.elementableAttributes;
            return (
              <div
                key={elementId}
                id={elementId}
                style={{
                  position: 'absolute',
                  zIndex: element.zIndex,
                  left: element.posX * zoom,
                  top: element.posY * zoom,
                  transform: `rotate(${element.rotate}deg)`,
                  width: elementAttrs.width * zoom,
                  height: elementAttrs.height * zoom,
                  cursor: 'pointer',
                }}
                onClick={() => this.select(elementId)}
              >
                <ElementContainer element={element} zoom={zoom} moveableRef={this.myRef} changeDesignState={this.changeDesignState} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Design;
