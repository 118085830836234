import Axios from '@common/apis/axios';
import axios from 'axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('shapes', 'FETCH'),
  GET_URL: ActionCreatorFactory('shapes', 'GET_URL'),
};

export const fetch = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetImage: false, error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/shapes`).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetImage: true,
				shapes: res.data,
        error: null,
      },
    });

  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetImage: false, error: { message: '図形画像の取得に失敗しました。' },
      },
    });
  });
};

export const getSvg = (url, callBack) => (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.FETCH.start,
    payload: { isGetShapeUrl: false, error: null } 
  });

  Axios.get(`${config.apiServer.baseUrl}/shapes/url`, { params: { url }} ).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: { isGetShapeUrl: true, error: null },
    });

    axios.get(res.data).then((svgRes) => {
      callBack(svgRes.data);
    }).catch((error) => {
      dispatch({
        type: ACTION_TYPE.FETCH.fail,
        payload: {
          isGetShapeUrl: false, error: { message: '図形の取得に失敗しました。' },
        },
      });
    })
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetShapeUrl: false, error: { message: '図形URLの取得に失敗しました。' },
      },
    });
  });
}