// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabSelector-module__tabBody--2fX_- .TabSelector-module__tabBtnBox--1geE-{width:75%;margin-left:auto;margin-right:auto;margin-top:10vh;margin-bottom:10px}.TabSelector-module__tabBody--2fX_- .TabSelector-module__tabBtnBox--1geE- .TabSelector-module__tabBtn--vXHdT{background-color:#F5F5F5;color:#9E9E9E;font-size:11px;font-weight:600}.TabSelector-module__tabBody--2fX_- .TabSelector-module__tabBtnBox--1geE- .TabSelector-module__activeTab--1kXpD{background-color:#D47780;color:#FFF;font-size:11px;font-weight:600}.TabSelector-module__tabBody--2fX_- .TabSelector-module__historyContainer--1Wx1p{width:97%;margin-left:auto;margin-right:auto}.TabSelector-module__alertDialog--3r7O3{text-align:center;margin:10px 20px 20px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__crossIcon--8mX8n{top:12px;right:12px;position:absolute;font-size:40px;color:#9E9E9E;cursor:pointer}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__dialogTitle--1LgOX{font-size:13px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__alertTextBox--1piUi{margin-top:15px;margin-bottom:25px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__alertTextBox--1piUi .TabSelector-module__alertText--AEaod{font-size:13px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__dialogAction--1ZgKi{display:flex;justify-content:space-evenly}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__dialogAction--1ZgKi .TabSelector-module__cancelButton--llZGJ{background-color:#F5F5F5;border-radius:50px;height:35px;width:100px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__dialogAction--1ZgKi .TabSelector-module__okButton--pYio2{color:#FFF;background-color:#D47780;border-radius:50px;height:35px;width:100px}.TabSelector-module__alertDialog--3r7O3 .TabSelector-module__dialogAction--1ZgKi .TabSelector-module__buttonText--3WeWT{font-size:12px}\n", ""]);
// Exports
exports.locals = {
	"tabBody": "TabSelector-module__tabBody--2fX_-",
	"tabBtnBox": "TabSelector-module__tabBtnBox--1geE-",
	"tabBtn": "TabSelector-module__tabBtn--vXHdT",
	"activeTab": "TabSelector-module__activeTab--1kXpD",
	"historyContainer": "TabSelector-module__historyContainer--1Wx1p",
	"alertDialog": "TabSelector-module__alertDialog--3r7O3",
	"crossIcon": "TabSelector-module__crossIcon--8mX8n",
	"dialogTitle": "TabSelector-module__dialogTitle--1LgOX",
	"alertTextBox": "TabSelector-module__alertTextBox--1piUi",
	"alertText": "TabSelector-module__alertText--AEaod",
	"dialogAction": "TabSelector-module__dialogAction--1ZgKi",
	"cancelButton": "TabSelector-module__cancelButton--llZGJ",
	"okButton": "TabSelector-module__okButton--pYio2",
	"buttonText": "TabSelector-module__buttonText--3WeWT"
};
module.exports = exports;
