import { Tooltip, Button } from '@material-ui/core';
import {
  Opacity as Transparency, RoundedCorner, DeleteForever,
} from '@material-ui/icons';

import ActiveController from '@components/activeController/ActiveController';

import Toolbar from '@pages/editor/workArea/designTools/components/toolbar/Toolbar';
import RoundedCornerDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialogContainer';
import TransparencyDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialogContainer';
import AlignmentContainer from '@pages/editor/workArea/designTools/components/alignment/AlignmentContainer';

import styles from '@pages/editor/workArea/designTools/frameImageTools/FrameImageTools.module.scss';

class FrameImageTools extends React.Component {
  generateToolbarData() {
    const {
      element, zoom, designToolsStyles, isOpen, toggleDialog, deleteElement,
    } = this.props;
    const mainColor = '#FFF';
    return {
      main: {
        content: [
          <div className={`${styles.mainWrapper} ${styles.fullWidth} ${designToolsStyles.dialogParent}`}>
            <ActiveController
              active={isOpen[3]}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="角丸">
                <RoundedCorner
                  className={designToolsStyles.iconDefault}
                  onClick={toggleDialog(3)}
                />
              </Tooltip>
            </ActiveController>
            <RoundedCornerDialogContainer
              className={(isOpen[3]) ? '' : designToolsStyles.hidden}
              zoom={zoom}
              element={element}
            />
            <ActiveController
              active={isOpen[4]}
              className={designToolsStyles.horizontalSpace}
            >
              <Tooltip title="不透明度">
                <Transparency
                  className={designToolsStyles.iconDefault}
                  onClick={toggleDialog(4)}
                />
              </Tooltip>
            </ActiveController>
            <TransparencyDialogContainer
              className={(isOpen[4]) ? '' : designToolsStyles.hidden}
              element={element}
            />
          </div>,
        ],
      },
      alignment: {
        title: '配置',
        content: [
          <AlignmentContainer element={element} color={mainColor} />,
        ],
      },
      delete: {
        content: [
          <Tooltip title="削除　Delete">
            <Button
              onClick={deleteElement}
              className={`${styles.space} ${styles.button}`}
              startIcon={<DeleteForever className={designToolsStyles.iconDefault} />}
            >
              削除
            </Button>
          </Tooltip>,
        ],
      },
    };
  }

  render() {
    return (
      <Toolbar title="フレーム" data={this.generateToolbarData()} />
    );
  }
}

export default FrameImageTools;
