// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmallImage-module__draggable--3a57R{background-color:white;border:1px solid #000;position:relative;z-index:10000}.SmallImage-module__draggable--3a57R .SmallImage-module__deleteButton--2MunU{position:absolute;top:0;right:0}.SmallImage-module__draggable--3a57R .SmallImage-module__deleteButton--2MunU .SmallImage-module__icon--3s3_T{font-size:1.5rem}\n", ""]);
// Exports
exports.locals = {
	"draggable": "SmallImage-module__draggable--3a57R",
	"deleteButton": "SmallImage-module__deleteButton--2MunU",
	"icon": "SmallImage-module__icon--3s3_T"
};
module.exports = exports;
