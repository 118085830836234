/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import {
  FormControl, Fab, Grid, InputLabel, MenuItem, Select, Button,
} from '@material-ui/core';
import BeatLoader from 'react-spinners/BeatLoader';
import EllipsisText from 'react-ellipsis-text';
import { toastr } from 'react-redux-toastr';

import { pickUpValue } from '@common/utils/ObjectUtils';
import {
  templateToReactName, templateToStyle, templateToTextElement, defaultDecoration, getTextRect,
} from '@common/utils/TextOperation';
import '@common/scss/globalImports/Imports.scss';

import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';
import styles from '@pages/editor/designDrawer/fontDesignPatterns/FontDesignPatternDrawer.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import '@pages/editor/designDrawer/fontDesignPatterns/FontDesignPatternDrawer.scss';

class FontDesignPatternDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;
    this.tagConfigs = {
      professional: { name: '職種カテゴリ', option: true },
    };
    this.defaultTextDecorate = {
      text: '未経験OK', fontWeight: 900, fontSize: 80, fontFamily: 'UDShinGoPr6N', color: '#000',
    };
    unmount();
  }

  componentDidMount() {
    const { mount, fetch } = this.props;
    mount();
    fetch();
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  /**
   * @param {string} tagName 英語のタグ名
   */
  onChangeTag(tagName) {
    const { tags, updateTag } = this.props;
    const { tagState } = tags;
    return (e) => {
      const updatedTag = { ...tagState };
      updatedTag[tagName] = e.target.value;
      updateTag(updatedTag);
    };
  }

  onClickResearch() {
    return () => {
      const {
        fetch, tags,
      } = this.props;
      const { tagState } = tags;
      const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
        .filter(Boolean).join(',');
      fetch(tagListStr);
    };
  }

  changeOrAddElement(template, decorationName, decorationId, decorationNumber, decorationTagName) {
    gtag('event', 'font_design_pattern', { font_design_pattern: `${decorationNumber}_${decorationTagName}_${decorationName}` });
    const { addElement, element, receiveElement } = this.props;
    const textElement = templateToTextElement(template, this.defaultTextDecorate);
    if (textElement.gradients && textElement.gradients.configs && Object.keys(textElement.gradients.configs).length > 0) {
      textElement.colorType = 'gradient';
    } else {
      textElement.colorType = 'unit';
    }

    const decoration = {
      decorationName,
      decorationId,
      changeableConfigs: this.genChangeableColor(template),
      elementableAttributes: {
        ...this.defaultTextDecorate,
        ...textElement,
        fontFamily: textElement.font,
      },
    };

    if (element && element.elementableType === 'Text') {
      const elementAttr = element.elementableAttributes;

      decoration.elementableAttributes = {
        ...defaultDecoration,
        ...decoration.elementableAttributes,
        width: getTextRect({ text: elementAttr.text, font: `${elementAttr.fontWeight} ${elementAttr.fontSize}px ${textElement.fontFamily}`, width: elementAttr.width }).width + 10,
        text: elementAttr.text,
        fontSize: elementAttr.fontSize,
        letterSpacing: elementAttr.letterSpacing,
        lineHeight: elementAttr.lineHeight,
        fontAlign: elementAttr.fontAlign
      };

      receiveElement({ ...element, ...decoration });
    } else {
      decoration.elementableAttributes.width = getTextRect({ text: this.defaultTextDecorate.text, font: `${this.defaultTextDecorate.fontWeight} ${this.defaultTextDecorate.fontSize}px ${textElement.fontFamily}` }).width + 10;

      const newElement = {
        elementableType: 'Text',
        transparency: 1,
        zIndex: 0,
        posX: 0,
        posY: 0,
        ...decoration,
      };
      addElement(newElement);
    }
  }

  genChangeableColor(template) {
    const changeableColor = template.changeableInformation.color;
    const searchedTemplate = { ...template };
    if (!changeableColor.changeable) { return ''; }
    const changeableConfigs = {};
    const isBorderChangeable = (border) => (
      Object.keys(border).reduce((key1, key2) => (border[key1] || border[key2]))
    );

    if (isBorderChangeable(changeableColor.border)) {
      const withOut = ['changeableInformation', 'configs'];
      const borders = pickUpValue(searchedTemplate, 'border', withOut);
      const colors = pickUpValue(borders, 'color', withOut);
      const color = colors.reduce((color1, color2) => color1 === color2);
      changeableConfigs.border = { name: 'ボーダー', colorType: 'unit', color };
    }
    if (changeableColor.fontBorder.count > 0) {
      const withOut = ['changeableInformation'];
      const borders = pickUpValue(searchedTemplate, 'fontBorders', withOut);
      const result = [];
      for (const i in [...Array(changeableColor.fontBorder.count)]) {
        const config = pickUpValue(borders, i);
        const colors = pickUpValue(config, 'color');
        const color = colors.reduce((color1, color2) => color1 === color2);
        result.push(color);
      }
      changeableConfigs.fontBorder = { name: '縁', count: changeableColor.fontBorder.count, colors: result };
    }
    if (changeableColor.fontColor.color !== 'none') {
      const withOut = ['changeableInformation', 'border', 'fontBorders', 'fontShadows', 'glow', 'background', 'wallPaper'];
      const colors = pickUpValue(searchedTemplate, 'color', withOut);
      const color = colors.reduce((color1, color2) => color1 === color2);
      changeableConfigs.fontColor = {
        name: '文字色',
        colorType: changeableColor.fontColor.color,
        gradientCount: changeableColor.fontColor.gradientCount,
        color,
      };
    }
    if (changeableColor.fontShadow.color !== 'none') {
      const withOut = ['changeableInformation'];
      const fontShadows = pickUpValue(searchedTemplate, 'fontShadows', withOut);
      const glows = pickUpValue(searchedTemplate, 'glow', withOut);

      let colors = [];
      if (fontShadows && fontShadows.configs && Object.keys(fontShadows.configs).length > 0) {
        colors = pickUpValue(fontShadows, 'color');
      } else if (glows.length > 0) {
        colors = pickUpValue(glows, 'color');
      }
      const color = (colors.length > 1) ? colors.reduce((color1, color2) => color1 === color2) : colors[0];
      changeableConfigs.fontShadow = {
        name: '影',
        colorType: changeableColor.fontShadow.color,
        color,
      };
    }
    if (changeableColor.wallPaper.color !== 'none') {
      const withOut = ['before', 'changeableInformation'];
      const colors = pickUpValue(searchedTemplate, 'color', withOut);
      const color = colors.reduce((color1, color2) => color1 === color2);
      changeableConfigs.wallPaper = {
        name: '背景',
        colorType: changeableColor.wallPaper.color,
        color,
      };
    }

    return changeableConfigs;
  }

  render() {
    const { tags, fontDesignPatterns, error } = this.props;
    const { allTags, tagState } = tags;
    const wrapStyle = {
      width: '201px',
      height: '201px',
      textAlign: 'center',
    };
    return (
      <>
        <div className={styles.container}>
          {error && toastr.error('error', error.message)}
          <Grid container spacing={2}>
            {
              Object.keys(this.tagConfigs).map((tagName) => {
                const tagConfig = this.tagConfigs[tagName];
                return (
                  <Grid item xs={4} key={tagName}>
                    <FormControl variant="outlined" className={`${styles.formControl} AssetImagesDrawerSelect`}>
                      <InputLabel id={`${tagName}-label`}>
                        <EllipsisText
                          text={tagConfig.name}
                          length={5}
                        />
                      </InputLabel>
                      <Select
                        labelid={tagName}
                        id={tagName}
                        onChange={this.onChangeTag(tagName)}
                        className={`${styles.input} muiSelectIcon`}
                        value={tagState[tagName]}
                        placeholder={tagConfig.name}
                      >
                        <MenuItem value="">未選択</MenuItem>
                        {
                          (() => {
                            if (allTags !== undefined) {
                              if (allTags[tagConfig.name] !== undefined) {
                                return allTags[tagConfig.name].map((tag) => (
                                  <MenuItem
                                    key={tag.id}
                                    value={tag.id}
                                  >
                                    {tag.name}
                                  </MenuItem>
                                ));
                              }
                            }
                            return '';
                          })()
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })
            }
            <Grid item xs={8}>
              <Button
                variant="contained"
                aria-label="search"
                style={{ marginRight: 8, marginTop: 6, fontSize: 12 }}
                onClick={this.onClickResearch()}
              >
                検索
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={scrollbar.customScroll}>
          <div className={styles.textDrawer}>
            <div className={styles.itemList}>
              {(fontDesignPatterns && fontDesignPatterns.items)
                ? fontDesignPatterns.items.map((fontDesignPattern) => {
                  let { template } = fontDesignPattern;
                  if (typeof template === 'string') {
                    template = JSON.parse(template);
                  }
                  let allTemplateStyle = templateToReactName(template);
                  allTemplateStyle.fontFamily = allTemplateStyle.font;
                  allTemplateStyle.fontWeight = allTemplateStyle.weight;
                  const templateStyle = {}; let
                    parentTemplateStyle;
                  allTemplateStyle = templateToStyle(allTemplateStyle);
                  // parent用CSS
                  if (allTemplateStyle.parent) {
                    parentTemplateStyle = { ...allTemplateStyle.parent };
                  }
                  // 中心のスタイルオブジェクトの作成
                  Object.keys(allTemplateStyle).forEach((key) => {
                    if (!['parent', 'gradient'].includes(key)) {
                      templateStyle[key] = allTemplateStyle[key];
                    }
                  });
                  return (
                    <>
                      {
                    (allTemplateStyle.parent)
                      ? (
                        <ParseStyleText
                          key={fontDesignPattern.id}
                          className={`${styles.item} ${fontDesignPattern.name}`}
                          onClick={() => this.changeOrAddElement(allTemplateStyle, fontDesignPattern.name, fontDesignPattern.font_decoration_id, fontDesignPattern.number, fontDesignPattern.tag_name)}
                          content={this.defaultTextDecorate.text}
                          style={parentTemplateStyle}
                          logger={(fontDesignPattern.name)}
                          wrapStyle={(/丸背景付き文字/.test(fontDesignPattern.name)) ? wrapStyle : ''}
                        >
                          <ParseStyleText
                            key={fontDesignPattern.id}
                            style={templateStyle}
                            content={this.defaultTextDecorate.text}
                            logger={(fontDesignPattern.name)}
                            full={/丸背景付き文字/.test(fontDesignPattern.name)}
                          >
                            {this.defaultTextDecorate.text}
                          </ParseStyleText>
                        </ParseStyleText>
                      )
                      : (
                        <ParseStyleText
                          key={fontDesignPattern.id}
                          className={`${styles.item} ${fontDesignPattern.name}`}
                          onClick={() => this.changeOrAddElement(allTemplateStyle, fontDesignPattern.name, fontDesignPattern.font_decoration_id, fontDesignPattern.number, fontDesignPattern.tag_name)}
                          style={templateStyle}
                          content={this.defaultTextDecorate.text}
                          logger={(fontDesignPattern.name)}
                        >
                          {this.defaultTextDecorate.text}
                        </ParseStyleText>
                      )
                      }
                    </>
                  );
                })
                : (
                  <>
                    {[...Array(20)].map((key) => (
                      <div className={`${styles.item}`}>
                        <BeatLoader />
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FontDesignPatternDrawer;
