import config from '@assets/config';
import { Stack } from '@common/utils/Stack';

// Singleton Class
class ElementCacher {
  constructor() {
    this.cachedElements = new Stack(config.elementCache.limit);
    this.undoneElements = new Stack(config.elementCache.limit);
  }

  pushCache(element){ 
    this.cachedElements.push(element);
  }
  
  popCache() {
    const popElement = this.cachedElements.pop();
    return popElement;
  };
  
  pushUndone(element){ 
    this.undoneElements.push(element); 
  }
  
  popUndone(){
    return this.undoneElements.pop();
  };
}


export const elementCacher = new ElementCacher();