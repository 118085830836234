import { connect } from 'react-redux';
import * as Action from '@pages/editor/designDrawer/tag/TagDrawerAction';
import TagDrawer from '@pages/editor/designDrawer/tag/TagDrawer';

const mapStateToProps = (state) => {
  const { tags } = state.entities;
  return {
    tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(TagDrawer);
