import { connect } from 'react-redux';
import GridLine from '@pages/editor/workArea/design/components/GridLine';
import * as Action from '@pages/editor/workArea/design/components/GridLineAction';

const mapStateToProps = (state, ownProps) => {
  const grid = state.ui.grid
  return {
    ...grid,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  const keys = Object.keys(Action);

  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(GridLine);
