import { Paper } from '@material-ui/core';
import styles from '@components/utils/customPaper/CustomPaper.module.scss';

function CustomPaper(props) {
  const { children, className, elevation, right } = props;
  return (
    <Paper className={`${className} ${(right) ? styles.rightPaper : styles.paper}`} elevation={elevation}>
      {children}
    </Paper>
  );
}

export default CustomPaper;
