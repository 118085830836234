export const ActionCreatorFactory = (className, name) => {
  return {
    'start' : `${className}_${name}_START`,
    'success' : `${className}_${name}_DONE`,
    'fail' : `${className}_${name}_FAIL`,
  }
};

export const SaveActionCreatorFactory = (className, name) => {
  return {
    'save' : `${className}_${name}_SAVE`,
    'mounted' : `${className}_${name}_MOUNTED`,
    'unmount' : `${className}_${name}_UNMOUNT`,
  }
};
