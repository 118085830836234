// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BrightnessDialog-module__brightnessPaper--IXp1a{top:40px;width:100px;padding:12.5px;left:-12px}.BrightnessDialog-module__brightnessPaper--IXp1a .BrightnessDialog-module__brightnessDialog--jVQbQ{width:100%}.BrightnessDialog-module__brightnessPaper--IXp1a .BrightnessDialog-module__brightnessDialog--jVQbQ .BrightnessDialog-module__sliderLabels--1wTbR{display:flex;justify-content:flex-end}.BrightnessDialog-module__brightnessPaper--IXp1a .BrightnessDialog-module__brightnessDialog--jVQbQ .BrightnessDialog-module__sliderLabels--1wTbR .BrightnessDialog-module__labelWithIcon--PqLf3{display:flex;justify-content:center;align-items:center}.BrightnessDialog-module__brightnessPaper--IXp1a .BrightnessDialog-module__brightnessDialog--jVQbQ .BrightnessDialog-module__sliderLabels--1wTbR .BrightnessDialog-module__inputNumber--2MbfV{width:60px}\n", ""]);
// Exports
exports.locals = {
	"brightnessPaper": "BrightnessDialog-module__brightnessPaper--IXp1a",
	"brightnessDialog": "BrightnessDialog-module__brightnessDialog--jVQbQ",
	"sliderLabels": "BrightnessDialog-module__sliderLabels--1wTbR",
	"labelWithIcon": "BrightnessDialog-module__labelWithIcon--PqLf3",
	"inputNumber": "BrightnessDialog-module__inputNumber--2MbfV"
};
module.exports = exports;
