function SvgGlow(props, svgRef) {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={props.color}
      ref={svgRef}
    >
      <path
        d="M16.215 18.69l.128.31h3.166l-.294-.695-5.076-12-.13-.305H11.5l-.129.305-5.076 12L6 19h3.169l.126-.314.986-2.455h4.93l1.004 2.458zm-2.145-5.305h-2.63l1.314-3.26 1.316 3.26z"
        fill="white"
        filter="url(#glow_svg__filter0_d)"
      />
      <path
        d="M16.215 18.69l.128.31h3.166l-.294-.695-5.076-12-.13-.305H11.5l-.129.305-5.076 12L6 19h3.169l.126-.314.986-2.455h4.93l1.004 2.458zm-2.145-5.305h-2.63l1.314-3.26 1.316 3.26z"
        fill={props.color}
      />
      <path
        d="M16.215 18.69l.128.31h3.166l-.294-.695-5.076-12-.13-.305H11.5l-.129.305-5.076 12L6 19h3.169l.126-.314.986-2.455h4.93l1.004 2.458zm-2.145-5.305h-2.63l1.314-3.26 1.316 3.26z"
        stroke="#696969"
      />
      <defs>
        <filter
          id="glow_svg__filter0_d"
          x={0.246}
          y={0.5}
          width={25.018}
          height={24}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feOffset />
          <feGaussianBlur in="SourceGraphic" stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
