function SvgImageShadow(props, svgRef) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <path
        d="M12.8 12.8V0H0v12.8zM4 8l1.63 2.17 2.37-3 3.2 4H1.6zm12-4.8V16H3.2v-1.6h11.2V3.2z"
        fill={props.color}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgImageShadow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
