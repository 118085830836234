import styles from '@pages/editor/workArea/designTools/textTools/components/borderColor/BorderColor.module.scss';

const BorderColorSquare = ({
  color, backgroundSize = 20, className, onClick, active,
}, ref) => {
  const borderWidth = 1;
  const outerSquareOneSidePx = backgroundSize - borderWidth * 2;
  const innerSquareOneSidePx = backgroundSize / 2 - borderWidth * 2;
  const mainColor = (color === 'transparent' || !color) ? '#FFF' : color;
  return (
    <div
      className={`${styles.borderColor} ${className}`}
      style={{
        width: outerSquareOneSidePx,
        height: outerSquareOneSidePx,
        maxWidth: outerSquareOneSidePx,
        maxHeight: outerSquareOneSidePx,
        minWidth: outerSquareOneSidePx,
        minHeight: outerSquareOneSidePx,
        backgroundColor: mainColor,
        border: `solid ${borderWidth}px #FFF`,
      }}
      onClick={onClick}
      ref={ref}
    >
      <div
        className={active ? `${styles.innerBorderColor} ${styles.active}` : styles.innerBorderColor}
        style={{
          width: innerSquareOneSidePx,
          height: innerSquareOneSidePx,
          maxWidth: innerSquareOneSidePx,
          maxHeight: innerSquareOneSidePx,
          minWidth: innerSquareOneSidePx,
          minHeight: innerSquareOneSidePx,
          border: `solid ${borderWidth}px #FFF`,
        }}
      />
      {
      (color === 'transparent') && (
      <div style={{
        height: `${backgroundSize}px`, transform: 'rotate(135deg)', position: 'absolute',
      }}
      >
        <div style={{ height: `${backgroundSize}px` }} className={styles.borderCenter}>
          <div className={styles.circleTop} />
          <div className={styles.circleBottom} />
        </div>

      </div>
      )
    }
    </div>
  );
};

export default React.forwardRef(BorderColorSquare);
