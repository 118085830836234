import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('wallPaperImage', 'FETCH'),
  COUNT: ActionCreatorFactory('wallPaperImage', 'COUNT'),
  SAVE: SaveActionCreatorFactory('wallPaperImage', 'SAVE'),
};

export const fetch = (queries) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetImage: false, error: null, color: queries.color,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/wallPaperImage`, {
    params: {
      tags: queries.tags,
      limit: queries.limit,
      offset: queries.offset,
      color: queries.color,
    },
  }).then((res) => {

    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetImage: true,
        error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        wallPaperImages: res.data,
        offset: queries.offset || 0,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetImage: false, error: { message: '背景画像の取得に失敗しました。' },
      },
    });
  });
};

export const count = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.COUNT.start,
    payload: {
      isGetCount: false, error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/wallPaperImage/count`).then((res) => {
    dispatch({
      type: ACTION_TYPE.COUNT.success,
      payload: {
        isGetCount: true,
        error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        wallPaperImagesCount: res.data.wallPaperImagesCount,
      },
    });
  }).catch((error) => {
    console.log(error);
    dispatch({
      type: ACTION_TYPE.COUNT.fail,
      payload: {
        isGetCount: false, error: { message: '背景画像の取得に失敗しました。' },
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};
