import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Design from '@pages/editor/workArea/design/Design';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => {
  const design = state.entities.designs[ownProps.match.params.id];
  return {
    design,
    elements: (design) ? state.entities.elements : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  receiveElement: (element) => dispatch(receiveElement(element)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Design));
