const defaultConfig = {
  accessTokenKey: 'token',
  apiServer: {
    baseUrl: 'http://localhost:8000/rest',
    backgroundCutUrl: 'https://dip-gazo.com/api/v1/',
    baitoruUrl: 'https://gazo-management-api.stg-recolin.com',
  },
  google: {
    clientId: '829666073362-qdn6cp0ngkq0tnosuv4bkekh6404u5eq.apps.googleusercontent.com',
  },
  canvas: {
    height: 600,
    width: 800,
    latticeSpacing: 60,
  },
  thumbnail_images: {
    height: '115px',
    width: '152px',
  },
  elementCache: {
    limit: 100,
  },
  colorTitle: {
    文字色: '文字色',
    文字ボーダー色: '文字ボーダー色',
    テキスト影色: 'テキスト影色',
    光彩色: '光彩色',
    フォント装飾: {
      文字色: 'フォント装飾 文字色',
      縁1: 'フォント装飾 縁色（1）',
      縁2: 'フォント装飾 縁色（2）',
      影: 'フォント装飾 影色',
      背景: 'フォント装飾 背景色',
      ボーダー: 'フォント装飾 ボーダー色',
    },
    図形色: '図形色',
    影: '影色',
    縁: {
      上: '縁色（上）',
      下: '縁色（下）',
      左: '縁色（左）',
      右: '縁色（右）',
    },
  },
  wall_paper_colors: {
    selectable_colors: {
      black: '#000000',
      orange: '#eb6100',
      yellow: '#fff100',
      green: '#22ac38',
      skyblue: '#00a0e9',
      blue: '#0068b7',
      purple: '#7a1dac',
      pink: '#ff0080',
      red: '#e60012',
      brown: '#662906',
      gray: '#d9d9d9',
      white: '#ffffff',
      pastelorange: '#fdefd8',
      pastelyellow: '#fffcdd',
      pastelgreen: '#ecffdf',
      pastelskyblue: '#d5effb',
      pastelblue: '#e1ebfb',
      pastelpurple: '#ebe2f1',
      pastelpink: '#ffedf6',
      pastelred: '#ffe0e1',
      gold: '#bf9000',
      lightgreen: '#85e443',
    },
    default: '#ffffff',
  },
  imageKindCode: {
    '01F2R7DABG3XHB8WQF57YNNN0A': 'pit', // 写真・イラスト・テキスト
    '01F2R7DABG8NPHS5TGXRSBRKRZ': 'pi', // 写真・イラスト
    '01F2R7DABGBTVT8VQ830W17P4S': 't', // テキストのみ
    '01F2R7DABGQNPZTY6E5RFADP5M': 'it', // イラスト・テキスト
    '01F2R7DABGQYF26GM2Q8NCD3MD': 'p', // 写真のみ
    '01F2R7DABGT14JZ1Y8GZN571M2': 'i', // イラストのみ
    '01F2R7DABGXQRK66WC70DJT3B7': 'pt', // 写真・テキスト
  },
  useAttrs: {
    // designTools
    designTools: {
      elementableType: 'elementableType',
      roundedCorner: 'roundedCorner',
      rotate: 'rotate',
      transparency: 'transparency',
      zIndex: 'zIndex',
    },
    // designToolsComponent
    borderDialog: {
      border: 'border',
    },
    shadowDialog: {
      shadow: 'shadow',
    },
    // textTools
    textTools: {
      fontWeight: 'fontWeight',
      fontAlign: 'fontAlign',
      fontFamily: 'fontFamily',
      fontSize: 'fontSize',
      color: 'color',
      colorType: 'colorType',
      gradients: 'gradients',
    },
    // textToolsComponent
    effectDialogs: {
      shadow: 'shadow',
      border: 'border',
      glow: 'glow',
      arch: 'arch',
    },
    spaceDialogs: {
      letterSpacing: 'letterSpacing',
      lineHeight: 'lineHeight',
    },
  },
  s3Url: 'https://development-gazo-assets.s3.ap-northeast-1.amazonaws.com/',
  validate: {
    textLength: '入力できる文字数は最大50文字です。',
    fontSize: '文字が読みにくくなるため、40未満のフォントサイズは指定できません',
  },
  jsonMaxSize: 8388608 //8MB = 8388608 Bytes
};

const staging = {
  apiServer: {
    baseUrl: "https://stage1-gazo-api.dip-admin.com",
    backgroundCutUrl: "https://dip-gazo.com/api/v1/",
    baitoruUrl: "https://stage1-gazo-management-api.dip-admin.com",
  },
  google: {
    clientId:
      "422047696904-qrr56m8ndd7d6o8qe9cib9diot356re4.apps.googleusercontent.com",
  },
  s3Url: "https://stage1-gazo-assets.s3.ap-northeast-1.amazonaws.com/",
};

const develop = {
  apiServer: {
    baseUrl: "https://dev-gazo-api.dip-admin.com",
    backgroundCutUrl: "https://dip-gazo.com/api/v1/",
    baitoruUrl: "https://dev-gazo-management-api.dip-admin.com",
  },
  google: {
    clientId:
      "49330868707-jtb6m3g4d36sk3492m3n6n1on42m07bl.apps.googleusercontent.com",
  },
  s3Url: "https://dev-gazo-assets.s3.ap-northeast-1.amazonaws.com/",
};

const production = {
  apiServer: {
    baseUrl: 'https://gazo-api.dip-admin.com',
    backgroundCutUrl: 'https://dip-gazo.com/api/v1/',
    baitoruUrl: 'https://gazo-management-api.dip-admin.com',
  },
  google: {
    clientId: '1012502349055-nn6aabdqla2rsjbld3jqbkt8sire3ihn.apps.googleusercontent.com',
  },
  imageKindCode: {
    '01F7TFABPC1S44H4HABJ0T5HXS': 'pit', // 写真・イラスト・テキスト
    '01F7TFABPCR8BCB2EHA8EYQPQ7': 'pi', // 写真・イラスト
    '01F7TFABPC73P06KEDGT56GECF': 't', // テキストのみ
    '01F7TFABPCG496BWSS4HJSZ5CP': 'it', // イラスト・テキスト
    '01F7TFABPC1VK5S2T314F7JMJR': 'p', // 写真のみ
    '01F7TFABPCKEYPQAXFTN70M1T1': 'i', // イラストのみ
    '01F7TFABPCEWG8YASCZ24NC64E': 'pt', // 写真・テキスト
  },
  s3Url: 'https://prod-gazo-assets.s3.ap-northeast-1.amazonaws.com/',
};

let config = {};
if (process.env.NODE_ENV === 'production') {
  config = { ...defaultConfig, ...production };
} else if (process.env.NODE_ENV === "staging") {
  config = { ...defaultConfig, ...staging };
} else if (process.env.NODE_ENV === "develop") {
  config = { ...defaultConfig, ...develop };
} else {
  config = { ...defaultConfig };
}

export default config;
