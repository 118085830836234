export class Stack {
  /**
   * 
   * @param {number} limit 
   */
  constructor(limit=null) {
    if(limit) { this.limit = limit; }
    this.stack = [];
  }

  isLimit() { return Boolean(this.limit); }

  push(el){
    if(this.isLimit()) {
      // limit - 1まで配列の削除
      while(this.stack.length >= this.limit) {
        this.stack.shift();
      }
    }
    this.stack.push(el);
  }

  pop() {
    return this.stack.pop();
  }
}