const WallPaperImage = ({ elementAttr: { url }, borderRadius }) => {
  return (<img
    src={url}
    style={{
      width: '100%', height: '100%', 
      cursor: 'move',
      borderRadius,
    }}
    alt=""
  />)
};

export default WallPaperImage;
