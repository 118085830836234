import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import entitiesReducer from '@common/components/entities/EntitiesReducer';
import sessionReducer from '@common/components/session/SessionReducer';
import uiReducer from '@common/components/ui/UiReducer';
import loginReducer from '@pages/login/LoginReducer';
import designDrawerReducer from '@pages/editor/designDrawer/DesignDrawerReducer';
import wizardModalReducer from '@pages/editor/workArea/modal/WizardModalReducer';
import materialsReducer from '@common/components/materials/MaterialsReducer';

let middleWare;
if (process.env.NODE_ENV === 'production') {
  middleWare = applyMiddleware(thunk);
} else {
  const logger = createLogger({
    diff: true,
    collapsed: true,
  });
  middleWare = applyMiddleware(thunk, logger);
}

const configureStore = (preloadedState = {}) => (
  createStore(
    combineReducers({
      login: loginReducer,
      drawer: designDrawerReducer,
      wizardModal: wizardModalReducer,
      entities: entitiesReducer,
      session: sessionReducer,
      ui: uiReducer,
      toastr: toastrReducer,
      materials: materialsReducer,
    }),
    preloadedState,
    middleWare,
  )
);

export default configureStore;
