import React from 'react';
import {
  ArrowBackIos,  ArrowForwardIos,
} from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import EllipsisText from "react-ellipsis-text";
import BeatLoader from 'react-spinners/BeatLoader';

import { templateToReactName, templateToStyle, templateToTextElement } from '@common/utils/TextOperation';

import ParseStyleText from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText';
import styles from './styles.module.scss';

const SHOW_ITEM_COUNT = 5;

export default class RecommendImagesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: 0,
      isPrev: false,
      noData: false,
    }
    this.defaultTextDecorate = {
      text: '未経験OK' ,fontWeight: 900, fontSize: 80, fontFamily: 'UDShinGoPr6N', color: '#000',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // カテゴリが再選択された時にリセット
    if (this.props.loading === false && prevProps.loading === true) {
      const updatedData = {
        idx: this.state.isPrev ? this.state.idx : 0,
        isPrev: false,
        noData: false,
      }

      const { recommend, fontDesignPattern} = this.props;
      const target = recommend || fontDesignPattern;
      const totalCount = (target && target.totalCount) ? parseInt(target.totalCount, 10) : 0;
      if((target.offset + target.items.length) === totalCount) {
        updatedData.noData = true;
      }
      this.setState(updatedData);
    }
  }

  prev() {
    const { idx } = this.state;
    const { recommend , fontDesignPattern, limit} = this.props;
    const currentLimit = limit || 30;
    const target = recommend || fontDesignPattern;
    const newIdx = idx - SHOW_ITEM_COUNT;
    const newOffset = target.offset - currentLimit;

    if (newIdx < 0 && newOffset >= 0) {
      this.props.onLoad(newOffset);
      this.setState({
        idx: currentLimit - SHOW_ITEM_COUNT,
        isPrev: true,
      });
    } else if (newIdx >= 0) {
      this.setState({
        idx: newIdx,
        noData: false,
      });
    } else {
      // 終端
    }
  }

  next() {
    const { idx, noData } = this.state;
    const { recommend, fontDesignPattern} = this.props;
    const target = recommend || fontDesignPattern;
    const totalCount = (target && target.totalCount) ? target.totalCount : 0;
    const newIdx = idx + SHOW_ITEM_COUNT;

    if(noData) {
      return 
    }
    if (target.items.length <= newIdx && totalCount > target.offset + newIdx) {
      this.props.onLoad(target.offset + target.items.length);
      this.setState({
        idx: 0,
      });
    } else if (target.offset + newIdx + SHOW_ITEM_COUNT >= totalCount) {
      this.setState({
        noData: true,
        idx: newIdx,
      });
    } else if (totalCount > target.offset + newIdx) {
      this.setState({
        idx: newIdx,
        noData: false,
      });
    } else {
      // 終端
    }
  }

  render() {
    const { idx, noData } = this.state;
    const { loading, recommend, recommendText, selectItem, fontDesignPattern } = this.props;
    const target = recommend || fontDesignPattern;
    const totalCount = (target && target.totalCount) ? target.totalCount : 0;
    const offset = (target && target.offset) ? target.offset : 0;
    return (
      loading ? (
        <div className={styles.imgRow}>
          <ArrowBackIos className={styles.arrow2} style={{ fontSize: 50 }} />
          { 
          (() => {
            if(recommendText) {
              return (
                <div className={styles.loadTextList}>
                  <BeatLoader />
                </div>
              )
            } else if(fontDesignPattern) {
              return Array(SHOW_ITEM_COUNT).fill('').map((e) => (
                <div className={styles.loadFontDesignPatternList}><BeatLoader /></div>
              ))
            } else {
              return Array(SHOW_ITEM_COUNT).fill('').map((e) => (
                <div className={styles.loadImgList}><BeatLoader /></div>
              ))
            }
          })()
          }
          <ArrowForwardIos className={styles.arrow2} style={{ fontSize: 50 }} />
        </div>
      ) : (()=> {
        if(recommend && !recommendText) {
          return (
            <div className={styles.imgRow}>
              <ArrowBackIos className={idx > 0 || offset > 0? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={() => { this.prev() }}/>
              {
                (() => {
                  const elements = recommend.items.slice(idx, idx + SHOW_ITEM_COUNT).map(e => (
                    <div className={styles.imgWrapper}>
                      <img src={e.thumbnail_url} className={styles.imgList} onClick={() => selectItem(e)} />
                    </div>
                  ));
                  if(elements.length < 5) {
                    [...Array(5 - elements.length)].forEach((value) => {
                      elements.push(<div className={styles.imgWrapper}></div>)
                    });
                  }
                  return elements;
                })()
              }
              <ArrowForwardIos className={totalCount - offset - SHOW_ITEM_COUNT > 0 && !noData ? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={ () => { this.next() }} />
            </div>
          )
        } else if(recommend && recommendText){
          // レコメンドテキスト
          return (
            <div className={styles.imgRow}>
              <ArrowBackIos className={idx > 0 || offset > 0? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={() => { this.prev() }}/>
              <div className={styles.textRecommendsPad}>
                {
                  recommend.items.slice(idx, idx + SHOW_ITEM_COUNT).map(e => {
                    const element = (
                    <EllipsisText
                      text={e.content}
                      length={20}
                    />
                    )
                    return (
                    <Chip label={element} clickable className={styles.pad} onClick={() => selectItem(e)} />
                    )
                  })
                }
              </div>
              <ArrowForwardIos className={totalCount - offset - SHOW_ITEM_COUNT > 0 && !noData ? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={ () => { this.next() }} />
            </div>
          )
        } else if(fontDesignPattern) {
          return (
            <div className={styles.fontDesignPatternRow}>
              <ArrowBackIos className={(idx > 0 || offset > 0 )? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={() => { this.prev() }}/>
              <div className={styles.fontDesignPatternPad}>
                {
                  (() => {
                    const elements = fontDesignPattern.items.slice(idx, idx + SHOW_ITEM_COUNT).map(item => {
                      const template = JSON.parse(item.template);
                      let allTemplateStyle = templateToReactName(template);
                      let templateStyle = {}, parentTemplateStyle;
                      allTemplateStyle = templateToStyle(allTemplateStyle);
                      // parent用CSS
                      if(allTemplateStyle.parent) {
                        parentTemplateStyle = {...allTemplateStyle.parent};
                      }
                      // 中心のスタイルオブジェクトの作成
                      Object.keys(allTemplateStyle).forEach((key) => {
                        if(!['parent', 'gradient'].includes(key)) {
                          templateStyle[key] = allTemplateStyle[key];
                        }
                      })
                      return (
                        <div className={styles.itemWrapper}>
                          {
                          (allTemplateStyle.parent) 
                          ? <ParseStyleText
                              key={item.id}
                              className={`${styles.item} ${item.name}`}
                              onClick={() => selectItem({
                                template: allTemplateStyle, 
                                templateStyle,
                                parentTemplateStyle,
                                decorationName: item.name, 
                                decorationId: item.id,
                                defaultDecorate: this.defaultTextDecorate,
                              })}
                              content={this.defaultTextDecorate.text}
                              style={parentTemplateStyle}
                            >
                              <ParseStyleText
                                key={item.id}
                                style={templateStyle}
                                content={this.defaultTextDecorate.text}
                              >
                                {this.defaultTextDecorate.text}
                              </ParseStyleText>
                            </ParseStyleText>
                          : <ParseStyleText
                              key={item.id}
                              className={`${styles.item} ${item.name}`}
                              onClick={() => selectItem({
                                template: allTemplateStyle, 
                                templateStyle,
                                decorationName: item.name, 
                                decorationId: item.id,
                                defaultDecorate: this.defaultTextDecorate,
                              })}
                              style={templateStyle}
                              content={this.defaultTextDecorate.text}
                            >
                              {this.defaultTextDecorate.text}
                            </ParseStyleText>
                            }
                        </div>
                      )
                    });
                    if(elements.length < 5) {
                      [...Array(5 - elements.length)].forEach((value) => {
                        elements.push(<div className={styles.imgList}></div>)
                      });
                    }
                    return elements;
                  })()
                }
              </div>
              <ArrowForwardIos className={fontDesignPattern.totalCount - offset - SHOW_ITEM_COUNT > 0 && !noData ? styles.arrow : styles.arrow2} style={{ fontSize: 50 }} onClick={ () => { this.next() }} />
            </div>
          )
        }
        
        return ''
      })()
    );
  }
}
