import { connect } from 'react-redux';
import FontBorderDialog from '@pages/editor/workArea/designTools/textTools/components/dialogs/fontBorderDialog/FontBorderDialog';
import { changeDrawer, changeColor } from '@pages/editor/designDrawer/DesignDrawerAction';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  drawerSettings: state.ui.drawer,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  receiveElement: (...args) => dispatch(receiveElement(...args)),
  changeDrawer: (...args) => dispatch(changeDrawer(...args)),
  changeColor: (...args) => dispatch(changeColor(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontBorderDialog);
