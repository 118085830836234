// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomPaper-module__paper--1Q7bJ{position:absolute;top:46px;left:0;padding:5%;z-index:4000;background-color:white;box-shadow:2px 2px 4px #333}.CustomPaper-module__rightPaper--nhrfq{position:absolute;top:46px;right:0;padding:5%;z-index:4000;background-color:white;box-shadow:2px 2px 4px #333}\n", ""]);
// Exports
exports.locals = {
	"paper": "CustomPaper-module__paper--1Q7bJ",
	"rightPaper": "CustomPaper-module__rightPaper--nhrfq"
};
module.exports = exports;
