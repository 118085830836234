import styles from '@components/icons/alignVerticalTop/AlignVerticalTop.module.scss';

export default function AlignVerticalTop(props) {
  const { className, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <path fill={color} d="M22,2v2H2V2H22z M7,22h3V6H7V22z M14,16h3V6h-3V16z" />
    </svg>
  );
}
