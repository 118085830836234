import styles from '@components/utils/drawerKnob/DrawerKnob.module.scss';

export default function DrawerKnob(props) {
  const { className } = props;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="199 149 104 404" width="20" height="80" className={`${className} ${styles.defaultIconStyle}`}>
      <defs><path d="M200 550C200.3 533.74 216.97 517.07 250 500C283.03 482.93 299.7 466.26 300 450L300 250C299.67 233.13 283 216.46 250 200C217 183.54 200.33 166.87 200 150L200 550Z" id="fEGO0r42v" /></defs>
      <g>
        <g>
          <use xlinkHref="#fEGO0r42v" opacity="1" fillOpacity="1" />
          <g><use xlinkHref="#fEGO0r42v" opacity="1" fillOpacity="0" strokeWidth="0" strokeOpacity="1" /></g>
        </g>
      </g>
    </svg>
  );
}
