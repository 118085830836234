// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GridLine-module__grid--ZMN7w{background-color:transparent;position:absolute;top:0;left:0;z-index:1000;pointer-events:none}.GridLine-module__line--3s7Oi{position:absolute;width:1px;height:1px}\n", ""]);
// Exports
exports.locals = {
	"grid": "GridLine-module__grid--ZMN7w",
	"line": "GridLine-module__line--3s7Oi"
};
module.exports = exports;
