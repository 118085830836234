// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SliderAndInput-module__wrapper--1Fjvl{width:100%;display:flex;flex-direction:column;align-items:center}.SliderAndInput-module__wrapper--1Fjvl .SliderAndInput-module__attrNameAndTextInput--2AUIo{display:flex;justify-content:flex-end;width:100%}.SliderAndInput-module__wrapper--1Fjvl .SliderAndInput-module__attrNameAndTextInput--2AUIo .SliderAndInput-module__inputNumber--2dYrE{width:40%}.SliderAndInput-module__wrapper--1Fjvl .SliderAndInput-module__sliders--1YE4l{width:90%}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "SliderAndInput-module__wrapper--1Fjvl",
	"attrNameAndTextInput": "SliderAndInput-module__attrNameAndTextInput--2AUIo",
	"inputNumber": "SliderAndInput-module__inputNumber--2dYrE",
	"sliders": "SliderAndInput-module__sliders--1YE4l"
};
module.exports = exports;
