import { connect } from 'react-redux';
import Component from './WizardModal';
import * as Action from './WizardModalAction';
import { changeAllowWizard } from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenuAction';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';

const mapStateToProps = (state, ownProps) => ({
  tagState: state.entities.tags.tagState,
  ...state.wizardModal,
  ...ownProps,
  user: state.materials.accountMenu.user,
});

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);
  actions.changeAllowWizard = (...args) => dispatch(changeAllowWizard(...args));
  actions.update = (...args) => dispatch(update(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(Component);
