import {
  ACTION_TYPE,
} from '@pages/editor/designDrawer/shapes/ShapesDrawerAction';

const ShapesDrawerReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.FETCH.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.FETCH.fail:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default ShapesDrawerReducer;