import { connect } from 'react-redux';
import FrameImagesDrawer from '@pages/editor/designDrawer/frameImages/FrameImagesDrawer';

import * as Action from '@pages/editor/designDrawer/frameImages/FrameImagesDrawerAction';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';
import { removeElement } from '@common/components/entities/elements/ElementActions';

function mapStateToProps(state, ownProps) {
  return { ...ownProps, 
    ...state.materials.frameImages, 
    tags: state.entities.tags,
    elements: state.entities.elements,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);

  actions.updateTag = (...args) => dispatch(update(...args));
  actions.removeElement = (...args) => dispatch(removeElement(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(FrameImagesDrawer);
