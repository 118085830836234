import { ACTION_TYPE } from './LogoCutModalAction';

const initState = {
  selectedItems: Array(6),
};

const LogoCutModalReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.CUTOUT_LOGO.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CUTOUT_LOGO.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CUTOUT_LOGO.fail:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export default LogoCutModalReducer;