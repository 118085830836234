export const RECEIVE_ELEMENTS = 'RECEIVE_ELEMENTS';
export const RECEIVE_ELEMENT = 'RECEIVE_ELEMENT';
export const UNDO_ELEMENT = 'UNDO_ELEMENT';
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';
export const CREATE_ELEMENT = 'CREATE_ELEMENT';
export const DELETE_ELEMENTS = 'DELETE_ELEMENTS';
export const RECEIVE_ELEMENT_FROM_HISTORY = 'RECEIVE_ELEMENT_FROM_HISTORY';

export const receiveElement = (element) => ({
  type: RECEIVE_ELEMENT,
  element,
});

export const undoElement = (element) => ({
  type: UNDO_ELEMENT,
  element,
})

export const createElement = (designId, element) => ({
  type: CREATE_ELEMENT,
  designId,
  element,
});

export const deleteElements = (payload) => ({
  type: DELETE_ELEMENTS,
  payload,
})

export const receiveElements = (elements) => ({
  type: RECEIVE_ELEMENTS,
  elements,
});

export const receiveElementFromHistory = (element) => ({
  type: RECEIVE_ELEMENT_FROM_HISTORY,
  element,
});

export const removeElement = (element) => ({
  type: REMOVE_ELEMENT,
  element,
});
