import { connect } from 'react-redux';
import SavingComponent from '@pages/editor/designDrawer/saving/SavingComponent';
import * as Action from '@pages/editor/designDrawer/saving/SavingAction';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.materials.saving,
    ...ownProps,
    elements: state.entities.elements,
    allTags: state.entities.tags.allTags,
  };
};

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);

  actions.updateTag = (...args) => dispatch(update(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingComponent);
