import styles from '@pages/editor/workArea/designTools/components/toolbar/Toolbar.module.scss';

const Toolbar = ({ data, title }) => {
  return (
    <div className={styles.toolbarWrapper}>
      <h2 className={styles.toolbarTitle}>{title}</h2>
      {
        data
          ? Object.keys(data).map((key) => (
            <section key={key} className={styles.section}>
              {(data[key].title) ? <h3 className={styles.subTItle}>{data[key].title}</h3> : ''}
              {data[key].content}
            </section>
          ))
          : ''
      }
    </div>
  );
};

export default Toolbar;
