import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('tag', 'FETCH'),
  SAVE: SaveActionCreatorFactory('tag', 'SAVE'),
};
export const UPDATE_TAG = 'UPDATE_TAG';

export const fetch = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetTag: false, error: null,
      wait: true,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/tag`).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetTag: true,
        error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        allTags: res.data,
        wait: false
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetImage: false, error: { message: 'タグの取得に失敗しました。' },
        wait: false
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};

export const update = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_TAG,
    payload: {
      tagState: payload,
    },
  });
};
