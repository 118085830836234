import { combineReducers } from 'redux';
import AssetImagesDrawerReducer from '@pages/editor/designDrawer/assetImages/AssetImagesDrawerReducer';
import AccountMenuReducer from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenuReducer'
import FrameImagesDrawerReducer from '@pages/editor/designDrawer/frameImages/FrameImagesDrawerReducer';
import WallPaperImagesDrawerReducer from '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawerReducer';
import TextDrawerReducer from '@pages/editor/designDrawer/text/TextDrawerReducer';
import TextToolsReducer from '@pages/editor/workArea/designTools/textTools/TextToolsReducer';
import ShapesDrawerReducer from '@pages/editor/designDrawer/shapes/ShapesDrawerReducer';
import ShapeToolsReducer from '@pages/editor/workArea/designTools/shapeTools/ShapeToolsReducer';
import HumanCutModalReducer from '@pages/editor/workArea/designTools/imageTools/components/modals/humanCutout/HumanCutModalReducer';
import LogoCutModalReducer from '@pages/editor/workArea/designTools/imageTools/components/modals/logoCutout/LogoCutModalReducer';
import TrimmingModalReducer from '@pages/editor/workArea/designTools/imageTools/components/modals/trimming/TrimmingModalReducer';
import ColorPaletteReducer from '@pages/editor/designDrawer/subDrawer/colorPalette/ColorPaletteReducer';
import FontDecorationDrawerReducer from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawerReducer';
import FontDesignPatternDrawerReducer from '@pages/editor/designDrawer/fontDesignPatterns/FontDesignPatternDrawerReducer';
import smallImagesReducer from '@pages/editor/workArea/smallImages/SmallImagesReducer';
import SavingReducer from '@pages/editor/designDrawer/saving/SavingReducer';

const materialsReducer = combineReducers({
  assetImages: AssetImagesDrawerReducer,
  accountMenu: AccountMenuReducer,
  frameImages: FrameImagesDrawerReducer,
  wallPaperImages: WallPaperImagesDrawerReducer,
  text: TextDrawerReducer,
  textTools: TextToolsReducer,
  shapes: ShapesDrawerReducer,
  shapeTools: ShapeToolsReducer,
  humanCutModal: HumanCutModalReducer,
  logoCutModal: LogoCutModalReducer,
  trimmingModal: TrimmingModalReducer,
  colors: ColorPaletteReducer,
  fontDecorations: FontDecorationDrawerReducer,
  fontDesignPattern: FontDesignPatternDrawerReducer,
  smallImages: smallImagesReducer,
  saving: SavingReducer,
});

export default materialsReducer;
