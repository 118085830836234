import { connect } from 'react-redux';
import BrightnessDialog from '@pages/editor/workArea/designTools/imageTools/components/dialogs/brightnessDialog/BrightnessDialog';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  receiveElement: (element) => dispatch(receiveElement(element)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrightnessDialog);
