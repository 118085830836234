import axios from 'axios';
import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  INIT: ActionCreatorFactory('wizardModal', "INIT"),
  CHANGE_TAG: ActionCreatorFactory('wizardModal', "CHANGE_TAG"),
  CHANGE_SELECTED_ITEMS: ActionCreatorFactory('wizardModal', "CHANGE_SELECTED_ITEMS"),
  CHANGE_SELECTED_UPLOAD_IMAGES: ActionCreatorFactory('wizardModal', "CHANGE_SELECTED_UPLOAD_IMAGES"),
  SMALL_IMAGE_RECOMMEND: ActionCreatorFactory('wizardModal', "SMALL_IMAGE_RECOMMEND"),
  ASSET_IMAGE: ActionCreatorFactory('wizardModal', "ASSET_IMAGE"),
  WALLPAPER_IMAGE: ActionCreatorFactory('wizardModal', "WALLPAPER_IMAGE"),
  FRAME_IMAGE: ActionCreatorFactory('wizardModal', "FRAME_IMAGE"),
  RECOMMEND_TEXTS: ActionCreatorFactory('wizardModal', "RECOMMEND_TEXTS"),
  GROUP_TEXT: ActionCreatorFactory('wizardModal', "GROUP_TEXT"),
  GROUP_TEXT_IDS: ActionCreatorFactory('wizardModal', "GROUP_TEXT_IDS"),
  FONT_DESIGN_PATTERN: ActionCreatorFactory('wizardModal', "FONT_DESIGN_PATTERN"),
  UPLOAD_FILE: ActionCreatorFactory('wizardModal', 'UPLOAD_FILE'),
  CHANGE_SELECTED_DATA: ActionCreatorFactory('wizardModal', 'CHANGE_SELECTED_DATA'),
  PLUS_SMALL_IMAGE_CLICK_COUNT: ActionCreatorFactory('wizardModal', 'PLUS_SMALL_IMAGE_CLICK_COUNT'),
  PLUS_TEXT_CLICK_COUNT: ActionCreatorFactory('wizardModal', 'PLUS_TEXT_CLICK_COUNT'),
};

export const init = (e) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.INIT.start, payload: { wait: true, tagInfo: null, error: null } });
  Axios.get(`${config.apiServer.baseUrl}/tag`, {}).then((res) => {
    dispatch({ type: ACTION_TYPE.INIT.success, payload: { wait: false, tagInfo: res.data, error: null } });
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.INIT.fail, payload: { wait: false, error: { message: '画面の初期化失敗しました' } } });
  });
}

export const changeTag = (updatedTag) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.CHANGE_TAG.success, payload: { wait: false, tagState: updatedTag, error: null } });
}

export const changeSelectedUploadImages = (selectedUploadImages) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.CHANGE_SELECTED_ITEMS.success, payload: { selectedUploadImages } });
}

export const changeSelectedItems = (selectedItems) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.CHANGE_SELECTED_ITEMS.success, payload: { selectedItems } });
}

export const getSmallImage = (updatedTag = {}, offset = 0, limit = 30) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.start, payload: { smallImageLoading: true, smallImageRecommend: null, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/recommend/smallImage`, { params: { offset, limit }, tags: {...updatedTag}}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.success, payload: { smallImageLoading: false, smallImageRecommend: res.data } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.fail, payload: { smallImageLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const getAssetImage = (updatedTag = {}, offset = 0, limit = 30) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.ASSET_IMAGE.start, payload: { assetImageLoading: true, assetImage: null, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/recommend/assetImage`, { params: { offset, limit }, tags: {...updatedTag}}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.ASSET_IMAGE.success, payload: { assetImageLoading: false, assetImage: res.data } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.ASSET_IMAGE.fail, payload: { assetImageLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const getWallPaperImage = ({tags = {}, color='black', offset = 0, limit = 30}) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.WALLPAPER_IMAGE.start, payload: { wallPaperImageLoading: true, wallPaperImage: null, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/recommend/wallpaperImage`, { params: { offset, limit, color }, tags }).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.WALLPAPER_IMAGE.success, payload: { wallPaperImageLoading: false, wallPaperImage: res.data, wallPaperImageColor: color } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.WALLPAPER_IMAGE.fail, payload: { wallPaperImageLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const getFrameImage = ({tags = {}, color = 'black', offset = 0, limit = 30}) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.FRAME_IMAGE.start, payload: { frameImageLoading: true, frameImage: null, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/recommend/frameImage`, { params: { color, offset, limit }, tags }).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.FRAME_IMAGE.success, payload: { frameImageLoading: false, frameImage: res.data, frameImageColor: color } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.FRAME_IMAGE.fail, payload: { frameImageLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const getGroupTextIds = () => (dispatch) => {
  dispatch({ type: ACTION_TYPE.GROUP_TEXT_IDS.start, payload: { textLoading: true, textGroupIds:'', error: null } });
  Axios.get(`${config.apiServer.baseUrl}/recommend/textGroupIds`, {}
  ).then((res) => {
    dispatch({ type: ACTION_TYPE.GROUP_TEXT_IDS.success, payload: { textLoading: false, textGroupIds: res.data, error: null } });
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.GROUP_TEXT_IDS.fail, payload: { textLoading: false, textGroupIds:'', error: { message: '文言の取得に失敗しました。' }} });
  });
};

export const getRecommendedTexts = ({tags, offset = 0, limit = 30}) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.RECOMMEND_TEXTS.start, payload: { textLoading: true, recommendedTexts:'', error: null } });
  Axios.get(`${config.apiServer.baseUrl}/recommend/text`, { params: { ...tags, offset, limit },
  }).then((res) => {
    dispatch({ type: ACTION_TYPE.RECOMMEND_TEXTS.success, payload: { textLoading: false, recommendedTexts: res.data, textGroupId: null, error: null } });
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.RECOMMEND_TEXTS.fail, payload: { textLoading: false, recommendedTexts:'', error: { message: '文言の取得に失敗しました。' }} });
  });
};

export const getGroupText = (groupId, offset = 0, limit = 30) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.GROUP_TEXT.start, payload: { textLoading: true, recommendedTexts:'', error: null } });
  Axios.get(`${config.apiServer.baseUrl}/recommend/textGroup`, { params: { groupId, offset, limit },
  }).then((res) => {
    dispatch({ type: ACTION_TYPE.GROUP_TEXT.success, payload: { textLoading: false, recommendedTexts: res.data, error: null, textGroupId: groupId } });
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.GROUP_TEXT.fail, payload: { textLoading: false, recommendedTexts:'', error: { message: '文言の取得に失敗しました。' }} });
  });
};

export const getFontDesignPattern = (updatedTag = {}, offset = 0, limit = 30) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.FONT_DESIGN_PATTERN.start, payload: { fontDesignPatternLoading: true, fontDesignPattern: null, error: null } });
  Axios.get(`${config.apiServer.baseUrl}/fontDesignPattern`, { params: { tags: updatedTag['professional'], offset, limit }}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.FONT_DESIGN_PATTERN.success, payload: { fontDesignPatternLoading: false, fontDesignPattern: res.data, error: null } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.FONT_DESIGN_PATTERN.fail, payload: { fontDesignPatternLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const uploadFile = (file, key) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.UPLOAD_FILE.start, payload: { error: null, } });
  const fileNameList = file.name.split('.');
  const ext = fileNameList[fileNameList.length - 1];
  Axios.get(`${config.apiServer.baseUrl}/uploadImage/url`, { params: { ext } }).then((res) => {
    axios.put(
      res.data.url,
      file,
      {headers: {'Content-Type': file.type} }
    ).then((registerRes) => {
      dispatch({
        type: ACTION_TYPE.UPLOAD_FILE.success,
        payload: { error: null, uploadedImage: registerRes.config.url.split('?')[0], key },
      });
    }).catch((error) => {
      console.log(error)
      dispatch({ 
        type: ACTION_TYPE.UPLOAD_FILE.fail, 
        payload: { error: { message: '素材画像の登録に失敗しました。' },
      },
      });
    });
  });
};

export const plusSmallImageClickCount = (id) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.start, payload: { smallImageLoading: false, didClicked: false, error: null } });
  Axios.put(`${config.apiServer.baseUrl}/smallImage/plus`, {id}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.success, payload: { smallImageLoading: false, didClicked: true } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.fail, payload: { smallImageLoading: false, didClicked: false, error: { message: 'レコメンドの更新に失敗しました' } } });
  });
}

export const plusTextClickCount = (content) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.start, payload: { textLoading: false, didClicked: false, error: null } });
  Axios.put(`${config.apiServer.baseUrl}/recommend/text/content`, {content}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.success, payload: { textLoading: false, didClicked: true } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.fail, payload: { textLoading: false, didClicked: false, error: { message: '文言の更新に失敗しました' } } });
  });
}