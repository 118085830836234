import { Rnd } from 'react-rnd';
import { IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import styles from '@pages/editor/workArea/smallImage/SmallImage.module.scss';
import '@pages/editor/workArea/smallImage/SmallImage.scss';

class SmallImage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      width: 200,
      height: 150,
      x: 0,
      y: 46,
    }
  }

  render() {
    const { isOpen, url, toggleImageModal } = this.props;
    return (
      (isOpen) 
      ? <Rnd
          size={{ width: this.state.width,  height: this.state.height }}
          position={{ x: this.state.x, y: this.state.y }}
          lockAspectRatio={true}
          onDragStop={(e, d) => { this.setState({ x: d.x, y: d.y }) }}
          className={styles.draggable}
          onResizeStop={(e, direction, ref, delta, position) => {
            this.setState({
              width: ref.style.width,
              height: ref.style.height,
              ...position,
            });
          }}
        >
          <IconButton className={styles.deleteButton} onClick={() => {toggleImageModal(false, null)}}>
            <HighlightOff className={styles.icon} />
          </IconButton>
          <img src={url} alt="" draggable={false}/>
        </Rnd>
      : ''
    );
  }
}

export default SmallImage;