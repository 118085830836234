/* eslint-disable no-restricted-syntax */
import {
  Typography, IconButton, SvgIcon,
} from '@material-ui/core';
import {
  BorderTop, BorderBottom, BorderLeft, BorderRight, BorderStyle,
} from '@material-ui/icons';

// assets
import config from '@assets/config';

import { compareObject } from '@common/utils/ObjectUtils';

// components
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import SliderAndInput from '@components/utils/sliderAndInput/SliderAndInput';

// pages
import styles from '@pages/editor/workArea/designTools/components/dialogs/borderDialog/BorderDialog.module.scss';

class BorderDialog extends React.Component {
  constructor(props) {
    super(props);
    const { element } = props;
    this.borders = {
      top: <BorderTop className={styles.borderIcon} />,
      bottom: <BorderBottom className={styles.borderIcon} />,
      left: <BorderLeft className={styles.borderIcon} />,
      right: <BorderRight className={styles.borderIcon} />,
    };

    // ボーダースタイル関連
    this.borderStyles = ['none', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset'];
    this.idx = 1;

    this.colorPaletteDrawerIndex = 1;

    // colorを変更するターゲットの指定
    this.changeColorTargets = {};
    const setChangeColorTargets = (key, changeColorTargetsId) => {
      if (this.changeColorTargets[key] === undefined) { this.changeColorTargets[key] = []; }
      this.changeColorTargets[key] = changeColorTargetsId;
    };
    ['top', 'bottom', 'left', 'right'].forEach((attrName) => {
      setChangeColorTargets(attrName, `${element.id}-border-${attrName}`);
    });

    // effectの設定値
    this.effects = { max: 10, min: 0 };
    // スライダーの設定値
    this.sliderConfigs = { step: 1, min: this.effects.min, max: this.effects.max };
    // スライダーのマーク値
    this.marks = [
      { value: 0, label: '0px' },
      { value: 10, label: '10px' },
    ];

    this.useAttrs = config.useAttrs.borderDialog;
  }

  componentDidMount() {
    document.addEventListener('keyup', this.toggleShift());
    document.addEventListener('keydown', this.toggleShift());
  }

  componentDidUpdate() {
    const { drawerSettings, element } = this.props;
    for (const key in this.changeColorTargets) {
      if (
        drawerSettings.colorPalette.color !== element.border[key].color
        && this.isChangeColorTargetOwn(key)
      ) {
        this.changeColor(key);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.toggleShift());
    document.removeEventListener('keydown', this.toggleShift());
  }

  // onChanges
  onChangeBorder(attrName, newValue) {
    const { receiveElement, element } = this.props;
    const updatedElement = { ...element };
    updatedElement.border[attrName].weight = this.withinLimit(parseInt(newValue || 0, 10), attrName);
    receiveElement({ ...updatedElement });
  }

  changeColor(targetKey) {
    const { element, receiveElement, drawerSettings } = this.props;
    if (this.isChangeColorTargetOwn(targetKey)) {
      element.border[targetKey].color = drawerSettings.colorPalette.color;
      receiveElement({ ...element });
    }
  }

  changeBorderStyle(key) {
    return () => {
      const { receiveElement, element } = this.props;
      const { border } = element;
      const nowStyleIdx = this.borderStyles.indexOf(border[key].style);

      // indexの作成
      let nextStyleIdx = nowStyleIdx + this.idx;
      if (nextStyleIdx >= this.borderStyles.length) {
        nextStyleIdx = 0;
      } else if (nextStyleIdx < 0) {
        nextStyleIdx = this.borderStyles.length - 1;
      }
      const updateBorderAttr = { ...border[key] };
      updateBorderAttr.style = this.borderStyles[nextStyleIdx];
      const updatedBorder = { ...border, [key]: updateBorderAttr };
      receiveElement({ ...element, border: updatedBorder });
    };
  }

  toggleShift() {
    return (e) => {
      if (e.shiftKey) {
        this.idx = -1;
      } else {
        this.idx = 1;
      }
    };
  }

  isChangeColorTargetOwn(key) {
    const { drawerSettings } = this.props;
    return compareObject(drawerSettings.colorPalette.changeTarget, this.changeColorTargets[key]);
  }

  withinLimit(value) {
    let updatedValue = value;
    if (value >= this.effects.max) {
      updatedValue = this.effects.max;
    } else if (value <= this.effects.min) {
      updatedValue = this.effects.min;
    }
    return updatedValue;
  }

  toggleSubDrawer(id, color = undefined, targetIdx, targetName) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      const nextDrawerSettings = { ...drawerSettings };
      if (drawerSettings.closed) { nextDrawerSettings.closed = false; }
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
      if (color) {
        this.selected = targetIdx;
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: config.colorTitle['縁'][targetName],
          changeTarget: this.changeColorTargets[targetIdx],
          colorType: 'unit',
          allowGradient: false,
        };
      }

      if (drawerSettings.colorPalette.changeTarget === this.changeColorTargets[targetIdx]) {
        if (drawerSettings.closed) {
          nextDrawerSettings.isSubDrawerOpen = true;
        } else {
          nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
        }
      } else if (color && drawerSettings.colorPalette.changeTarget !== this.changeColorTargets[targetIdx]) {
        // 色ドロワーかつ、色変更対象が変わった時
        nextDrawerSettings.isSubDrawerOpen = true;
      }
      nextDrawerSettings.subDrawer = id;
      nextDrawerSettings.decorationTarget = null;

      changeDrawer(nextDrawerSettings);
    };
  }

  render() {
    const { className, element } = this.props;
    const { border: borders } = element;
    return (
      <>
        <CustomPaper className={`${className} ${styles.borderDialogWrapper}`} right>
          <Typography variant="subtitle1" color="textSecondary">
            ボーダー
          </Typography>
          {
            Object.keys(borders).map((key) => {
              const border = borders[key];
              const option = { [`data-border-${key}-color`]: border.color };
              let colorIconButtonStyle = `${styles.borderIconButton} borderDialogBorderColor`;
              if (this.selected === key) { colorIconButtonStyle += ` ${styles.active}`; }
              return (
                <>
                  <div className={styles.borderWrapper} key={key}>
                    {/* { this.borders[key] } */}
                    <div className={styles.borderFlexRight}>
                      <div className={styles.borderFlexTop}>
                        <div className={styles.borderFlexLeft}>
                          <Typography variant="caption" color="textSecondary" className={styles.borderText}>
                            { border.text }
                          </Typography>
                        </div>
                        <div className={styles.borderFlexRight} {...option}>
                          <IconButton size="small" className={colorIconButtonStyle} onClick={this.toggleSubDrawer(this.colorPaletteDrawerIndex, border.color, key, border.text)}>
                            <SvgIcon>
                              <path d="M17.75 7L14 3.25l-10 10V17h3.75l10-10zm2.96-2.96c.39-.39.39-1.02 0-1.41L18.37.29a.9959.9959 0 0 0-1.41 0L15 2.25 18.75 6l1.96-1.96z" />
                              <path fillOpacity="1" d="M0 20h24v4H0z" fill={border.color} />
                            </SvgIcon>
                          </IconButton>
                          <IconButton size="small" className={styles.borderIconButton} onClick={this.changeBorderStyle(key)}>
                            <BorderStyle />
                          </IconButton>
                        </div>
                      </div>
                      <SliderAndInput
                        styles={styles}
                        key={key}
                        sliderConfig={this.sliderConfigs}
                        marks={this.marks}
                        value={border.weight}
                        unit="px"
                        onChangeInput={(e) => { this.onChangeBorder(key, e.target.value); }}
                        onChangeSlider={(e, newValue) => { this.onChangeBorder(key, newValue); }}
                      />
                    </div>
                  </div>
                </>
              );
            })
          }
        </CustomPaper>
      </>
    );
  }
}

export default BorderDialog;
