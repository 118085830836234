import {
  Button, Modal, Tooltip,
} from '@material-ui/core';
import {
  Crop, Opacity as Transparency, RoundedCorner, Brightness7, Tonality, DeleteForever,
} from '@material-ui/icons';
import { toastr } from 'react-redux-toastr';

import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';

// icons
import {
  HumanCutout, LogoCutout, ImageShadow,
} from '@components/icons';
import ActiveController from '@components/activeController/ActiveController';

import Toolbar from '@pages/editor/workArea/designTools/components/toolbar/Toolbar';
import RoundedCornerDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/roundedCornerDialog/RoundedCornerDialogContainer';
import TransparencyDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialogContainer';
import AlignmentContainer from '@pages/editor/workArea/designTools/components/alignment/AlignmentContainer';
import BrightnessDialogContainer from '@pages/editor/workArea/designTools/imageTools/components/dialogs/brightnessDialog/BrightnessDialogContainer';
import ContrastDialogContainer from '@pages/editor/workArea/designTools/imageTools/components/dialogs/contrastDialog/ContrastDialogContainer';
import ShadowDialogContainer from '@pages/editor/workArea/designTools/components/dialogs/shadowDialog/ShadowDialogContainer';

// import ImageShadowDialogContainer from '@pages/editor/workArea/designTools/imageTools/components/dialogs/imageShadowDialog/ImageShadowDialogContainer';
import styles from '@pages/editor/workArea/designTools/imageTools/ImageTools.module.scss';

import HumanCutModal from './components/modals/humanCutout/HumanCutModalContainer';
import TrimmingModal from './components/modals/trimming/TrimmingModalContainer';
import LogoCutModal from './components/modals/logoCutout/LogoCutModalContainer';

class ImageTools extends React.Component {
  constructor(props) {
    super(props);
    // this.closeModal = this.closeModal.bind(this);
    this.state = {
      dialogIsOpen: [
        false, // Brightness Dialog
        false, // Contrast Dialog
      ],
      prevDialogIsOpen: [],
      humanCutModal: false,
      logoCutModal: false,
      trimmingModal: false,
    };
    this.tmpToggleDialogs = this.tmpToggleDialogs.bind(this);
  }

  openHcutModal() {
    this.setState({ humanCutModal: true });
  }

  openLcutModal() {
    this.setState({ logoCutModal: true });
  }

  openTrimModal() {
    this.setState({ trimmingModal: true });
  }

  toggleDialog(dialogNum) {
    return () => {
      const { closeDialog, allowDialogs } = this.props;
      const { dialogIsOpen } = this.state;

      let openOrClose = !dialogIsOpen[dialogNum];
      // 上部のダイアログが空いていて、ボタンが押された時。
      if (dialogIsOpen[dialogNum] && !allowDialogs) {
        openOrClose = true;
      }
      closeDialog();

      // dialog判定の管理
      const updatedDialogIsOpen = dialogIsOpen.map(() => false);
      updatedDialogIsOpen[dialogNum] = openOrClose;
      this.setState({
        dialogIsOpen: updatedDialogIsOpen,
      });
    };
  }

  tmpToggleDialogs(open = false) {
    const { tmpToggleDialogs } = this.props;
    const { prevDialogIsOpen, dialogIsOpen } = this.state;
    if (open) {
      this.setState({
        dialogIsOpen: prevDialogIsOpen,
        prevDialogIsOpen: [],
      });
    } else {
      const allClose = dialogIsOpen.map((key) => false);
      this.setState({
        dialogIsOpen: allClose,
        prevDialogIsOpen: dialogIsOpen,
      });
    }
    tmpToggleDialogs(open);
  }

  deleteUrl(attr) {
    return async (e) => {
      try {
        const { element, receiveElement, zoom } = this.props;
        const { elementableAttributes: elementAttrs } = element;
        const updatedElementAttrs = {
          ...elementAttrs,
          [attr]: null,
        };

        if (attr !== 'crop') {
          const editedUrl = elementAttrs[attr];
          const img = await getImageDimensions(elementAttrs.url);
          const editedImg = await getImageDimensions(editedUrl);
          const multiple = editedImg.width / zoom / editedImg.naturalWidth;
          const imageRect = resizeImage({ height: img.naturalHeight * multiple, width: img.naturalWidth * multiple });
          updatedElementAttrs.width = imageRect.width;
          updatedElementAttrs.height = imageRect.height;
        }

        receiveElement({ ...element, elementableAttributes: updatedElementAttrs });
      } catch (e) {
        console.log(e);
        this.setState({ error: { message: '画像の復元に失敗しました。' } });
      }
    };
  }

  generateToolbarData() {
    const {
      iconClassName, element, zoom, designToolsStyles, isOpen, toggleDialog, allowDialogs, deleteElement,
    } = this.props;
    const { dialogIsOpen } = this.state;
    const { elementableAttributes: { hCutOutUrl, lCutOutUrl, crop } } = element;
    const mainColor = '#FFF';
    const buttonTextLarge = { textSizeLarge: styles.textSizeLarge };
    return {
      main: {
        content: [
          <Button
            className={(hCutOutUrl) ? `${styles.active} ${styles.button} ${styles.space}` : `${styles.space} ${styles.button}`}
            onClick={(hCutOutUrl) ? this.deleteUrl('hCutOutUrl') : () => { this.tmpToggleDialogs(); this.openHcutModal(); }}
            startIcon={<HumanCutout color={mainColor} />}
            size="large"
            classes={buttonTextLarge}
          >
            人物
            <br />
            切り抜き
          </Button>,
          <Button
            className={(lCutOutUrl) ? `${styles.active} ${styles.button} ${styles.space}` : `${styles.space} ${styles.button}`}
            onClick={(lCutOutUrl) ? this.deleteUrl('lCutOutUrl') : () => { this.tmpToggleDialogs(); this.openLcutModal(); }}
            startIcon={<LogoCutout color={mainColor} />}
            size="large"
            classes={buttonTextLarge}
          >
            ロゴ/イラスト
            <br />
            切り抜き
          </Button>,
          <div className={`${styles.mainWrapper} ${styles.fullWidth} ${designToolsStyles.dialogParent} ${styles.space}`}>
            <div className={`${styles.imageEffect} ${styles.space}`}>
              <div className={`${styles.imageEffectTop} ${styles.space} ${designToolsStyles.dialogParent}`}>
                <ActiveController
                  active={crop}
                  className={designToolsStyles.horizontalSpace}
                >
                  <Tooltip title="トリミング">
                    <div
                      onClick={(crop) ? this.deleteUrl('crop') : () => { this.tmpToggleDialogs(); this.openTrimModal(); }}
                    >
                      <Crop className={iconClassName} />
                    </div>
                  </Tooltip>
                </ActiveController>
                <ActiveController
                  active={isOpen[3]}
                  className={designToolsStyles.horizontalSpace}
                >
                  <Tooltip title="角丸">
                    <RoundedCorner
                      className={designToolsStyles.iconDefault}
                      onClick={toggleDialog(3)}
                    />
                  </Tooltip>
                </ActiveController>
                <RoundedCornerDialogContainer
                  className={(isOpen[3]) ? styles.dialogLocation : designToolsStyles.hidden}
                  zoom={zoom}
                  element={element}
                />
                <ActiveController
                  active={dialogIsOpen[0]}
                  className={designToolsStyles.horizontalSpace}
                >
                  <Tooltip title="明るさ">
                    <Brightness7
                      className={designToolsStyles.iconDefault}
                      onClick={this.toggleDialog(0)}
                    />
                  </Tooltip>
                </ActiveController>
                <BrightnessDialogContainer
                  className={(dialogIsOpen[0] && allowDialogs) ? '' : styles.hidden}
                  element={element}
                />
              </div>
              <div className={`${styles.imageEffectBottom} ${styles.space} ${designToolsStyles.dialogParent}`}>
                <ActiveController
                  active={dialogIsOpen[1]}
                  className={designToolsStyles.horizontalSpace}
                >
                  <Tooltip title="コントラスト">
                    <Tonality
                      className={designToolsStyles.iconDefault}
                      onClick={this.toggleDialog(1)}
                    />
                  </Tooltip>
                </ActiveController>
                <ContrastDialogContainer
                  className={(dialogIsOpen[1] && allowDialogs) ? '' : styles.hidden}
                  element={element}
                />
                <ActiveController
                  active={isOpen[4]}
                  className={designToolsStyles.horizontalSpace}
                >
                  <Tooltip title="不透明度">
                    <Transparency
                      className={designToolsStyles.iconDefault}
                      onClick={toggleDialog(4)}
                    />
                  </Tooltip>
                </ActiveController>
                <TransparencyDialogContainer
                  className={(isOpen[4]) ? '' : designToolsStyles.hidden}
                  element={element}
                />
              </div>
            </div>
          </div>,
        ],
      },
      alignment: {
        title: '配置',
        content: [
          <AlignmentContainer element={element} color={mainColor} />,
        ],
      },
      outerDesign: {
        title: '外枠装飾',
        content: [
          <div className={designToolsStyles.dialogParent}>
            <Button
              size="large"
              className={isOpen[0]
                ? `${styles.active}`
                : ''}
              onClick={toggleDialog(0)}
              startIcon={<ImageShadow className={designToolsStyles.iconDefault} color={mainColor} />}
              classes={{
                iconSizeLarge: styles.iconSizeLarge16,
                textSizeLarge: styles.textSizeLargePadding,
              }}
            >
              影
            </Button>
            <ShadowDialogContainer
              className={(isOpen[0]) ? '' : `${styles.hidden}`}
              element={element}
            />
          </div>,
        ],
      },
      delete: {
        content: [
          <Tooltip title="削除　Delete">
            <Button
              onClick={deleteElement}
              className={`${styles.space} ${styles.button}`}
              startIcon={<DeleteForever className={designToolsStyles.iconDefault} />}
            >
              削除
            </Button>
          </Tooltip>,
        ],
      },
    };
  }

  render() {
    const {
      humanCutModal, logoCutModal, trimmingModal, error,
    } = this.state;
    const {
      element, zoom,
    } = this.props;

    return (
      <>
        {error && toastr.error('error', error.message)}
        <Toolbar title="素材画像" data={this.generateToolbarData()} />
        {/* 画像影 (復活予定) */}
        {/* <article className={styles.imageShadowButton}>
          <ButtonBase className={styles.button} onClick={this.toggleDialog(1)}>
            画像影
          </ButtonBase>
          <ImageShadowDialogContainer className={(dialogIsOpen[1] && allowDialogs) ? '' : styles.hidden} iconClassName={iconClassName} element={element} />
        </article>
        <Divider orientation="vertical" flexItem /> */}
        {(humanCutModal)
          ? (
            <Modal
              open={humanCutModal}
              className={styles.modal}
            >
              <div className={styles.modalBody}>
                <HumanCutModal
                  element={element}
                  onRequestClose={() => { this.setState({ humanCutModal: false }); }}
                  tmpToggleDialogs={this.tmpToggleDialogs}
                />
              </div>
            </Modal>
          )
          : ''}
        {logoCutModal
          ? (
            <Modal
              open={logoCutModal}
              className={styles.modal}
            >
              <div className={styles.modalBody}>
                <LogoCutModal
                  element={element}
                  onRequestClose={() => { this.setState({ logoCutModal: false }); }}
                  tmpToggleDialogs={this.tmpToggleDialogs}
                />
              </div>
            </Modal>
          )
          : ''}
        {trimmingModal
          ? (
            <Modal
              open={trimmingModal}
              className={styles.modal}
            >
              <div className={styles.modalBody}>
                <TrimmingModal
                  element={element}
                  onRequestClose={() => { this.setState({ trimmingModal: false }); }}
                  zoom={zoom}
                  tmpToggleDialogs={this.tmpToggleDialogs}
                />
              </div>
            </Modal>
          )
          : ''}
      </>
    );
  }
}

export default ImageTools;
