import { connect } from 'react-redux';
import WorkArea from '@pages/editor/workArea/WorkArea';
import { receiveElement, undoElement } from '@common/components/entities/elements/ElementActions';
import { manipulateGridLine } from '@pages/editor/workArea/design/components/GridLineAction';
import { changeDrawer } from '@pages/editor/designDrawer/DesignDrawerAction';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  drawerSettings: state.ui.drawer,
  user: state.materials.accountMenu.user,
  grid: state.ui.grid,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};
  // const keys = Object.keys(Action);

  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  actions.undoElement = (...args) => dispatch(undoElement(...args));
  actions.manipulateGridLine = (...args) => dispatch(manipulateGridLine(...args));
  actions.changeDrawer = (...args) => dispatch(changeDrawer(...args));
  // for (const key of keys) {
  //   if (typeof Action[key] === 'function') {
  //     actions[key] = (...args) => dispatch(Action[key](...args));
  //   }
  // }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkArea);
