import Axios from '@common/apis/axios';
import axios from 'axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('text', 'FETCH'),
  COUNT: ActionCreatorFactory('text', 'COUNT'),
  SAVE: SaveActionCreatorFactory('text', 'SAVE'),
  PLUS_TEXT_CLICK_COUNT: ActionCreatorFactory('text', 'PLUS_TEXT_CLICK_COUNT'),
};

export const fetch = (queries) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetText: false,
      error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/recommend/text`, {
    params: { ...queries.tags },
  }).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetText: true,
        error: null,
      },
    });
    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        texts: res.data.items,
        textCount: res.data.totalCount,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetText: false, error: { message: '文言の取得に失敗しました。' },
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};

export const plusTextClickCount = (id) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.start, payload: { textLoading: false, didClicked: false, error: null } });
  Axios.put(`${config.apiServer.baseUrl}/recommend/text`, {id}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.success, payload: { textLoading: false, didClicked: true } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.fail, payload: { textLoading: false, didClicked: false, error: { message: '文言の更新に失敗しました' } } });
  });
}