import {
  FormControl, Fab, Grid, InputLabel, MenuItem, Select, Button,
} from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';
import LazyLoad from 'react-lazyload';
import path from 'path';
import BeatLoader from 'react-spinners/BeatLoader';
import EllipsisText from 'react-ellipsis-text';
import { toastr } from 'react-redux-toastr';

import config from '@assets/config';
import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';
import { calcPosMiddle } from '@common/utils/EditUtils';
import DoublyScrollObserver from '@components/doublyScrollObserver/DoublyScrollObserver';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import styles from '@pages/editor/designDrawer/assetImages/AssetImagesDrawer.module.scss';
import '@pages/editor/designDrawer/assetImages/AssetImagesDrawer.scss';

class AssetImagesDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;

    this.tagConfigs = {
      professional: { name: '職種カテゴリ', option: true },
      targetAttr: { name: 'ターゲット属性', option: true },
      imageKind: { name: '画像種別', option: true },
    };
    this.isUpdate = false;

    unmount();
  }

  componentDidMount() {
    const { count, mount } = this.props;
    mount();
    count();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { assetImages } = this.props;
    if (assetImages !== nextProps.assetImages && !this.isUpdate) {
      this.isUpdate = true;
    } else if (this.isUpdate) {
      this.isUpdate = false;
    }
    return !(this.state === nextState
      && this.props === nextProps);
  }

  componentDidUpdate() {
    const { uploadedImage, updateAssetImage } = this.props;
    if (uploadedImage !== undefined) {
      this.addElement({ url: uploadedImage, type: 'UploadImage' });
      updateAssetImage({ uploadedImage: undefined });
    }
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  /**
   * @param {string} tagName 英語のタグ名
   */
  onChangeTag(tagName) {
    const { tags, updateTag } = this.props;
    const { tagState } = tags;
    return (e) => {
      const updatedTag = { ...tagState };
      updatedTag[tagName] = e.target.value;
      updateTag(updatedTag);
    };
  }

  onClickResearch(limit) {
    return () => {
      const {
        fetch, tags, mount,
      } = this.props;
      const { tagState } = tags;
      mount();
      const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
        .filter(Boolean).join(',');
      const payload = { tags: tagListStr, limit: limit || 100 };
      fetch(payload);
    };
  }

  fetch() {
    return (pageCounter, unitOfPage, limit) => {
      const {
        fetch, tags, mount,
      } = this.props;
      const { tagState } = tags;
      mount();
      const tagListStr = Object.keys(this.tagConfigs).map((key) => tagState[key])
        .filter(Boolean).join(',');
      const offset = pageCounter * unitOfPage;
      const payload = { tags: tagListStr, limit, offset };
      fetch(payload);
    };
  }

  addElement({ url, type = 'AssetImage' }) {
    const { addElement } = this.props;
    gtag('event', 'assetImages_file_name', { assetImages_file_name: path.basename(url) });
    getImageDimensions(url).then(
      (image) => {
        const resizedImageDimensions = resizeImage({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
        const [posX, posY] = calcPosMiddle({
          width: resizedImageDimensions.width,
          height: resizedImageDimensions.height,
        });

        const element = {
          elementableType: type,
          transparency: 1,
          zIndex: 0,
          posX,
          posY,
          elementableAttributes: {
            url,
            width: resizedImageDimensions.width,
            height: resizedImageDimensions.height,
            naturalWidth: image.naturalWidth,
            naturalHeight: image.naturalHeight,
          },
        };
        addElement(element);
      },
    )
      .catch((error) => {
        console.log(error);
      });
  }

  uploadImage(file) {
    gtag('event', 'click_upload_image', {
      event_name: 'clickUploadImage',
    });
    // [時期開発]GAにアップロードされた枚数を送付
    const uploadImage = 1;
    gtag('event', 'assetImages_file_upload_total', { assetImages_file_upload_total: uploadImage });
    const { uploadFile } = this.props;
    if (file) {
      uploadFile(file);
    }
  }

  render() {
    const {
      assetImages, tags, assetImagesCount, offset, error,
    } = this.props;
    const currentOffset = offset || 0;
    const { allTags, tagState } = tags;
    return (
      <>
        <div className={styles.container}>
          {error && toastr.error('error', error.message)}
          <Grid container spacing={2}>
            {
              Object.keys(this.tagConfigs).map((tagName) => {
                const tagConfig = this.tagConfigs[tagName];
                return (
                  <Grid item xs={4} key={tagName}>
                    <FormControl variant="outlined" className={`${styles.formControl} AssetImagesDrawerSelect`}>
                      <InputLabel id={`${tagName}-label`}>
                        <EllipsisText
                          text={tagConfig.name}
                          length={5}
                        />
                      </InputLabel>
                      <Select
                        labelid={tagName}
                        id={tagName}
                        onChange={this.onChangeTag(tagName)}
                        className={`${styles.input} muiSelectIcon`}
                        value={tagState[tagName]}
                        placeholder={tagConfig.name}
                      >
                        <MenuItem value="">未選択</MenuItem>
                        {
                          (() => {
                            if (allTags !== undefined) {
                              if (allTags[tagConfig.name] !== undefined) {
                                return allTags[tagConfig.name].map((tag) => (
                                  <MenuItem
                                    key={tag.id}
                                    value={tag.id}
                                  >
                                    {tag.name}
                                  </MenuItem>
                                ));
                              }
                            }
                            return '';
                          })()
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })
            }
            <Grid item xs={8}>
              <Button
                variant="contained"
                aria-label="search"
                style={{ marginRight: 8, fontSize: 13 }}
                onClick={this.onClickResearch()}
              >
                検索
              </Button>
              <Fab
                size="small"
                aria-label="addImage"
                component="label"
              >
                <AddPhotoAlternate style={{ fontSize: 20 }} />
                <input
                  type="file"
                  hidden
                  onChange={
                    (e) => {
                      const reader = new FileReader();
                      const file = e.currentTarget.files[0];
                      reader.onloadend = () => {
                        const image = new Image();
                        image.onload = () => {
                          this.uploadImage(file);
                        };
                        image.src = reader.result;
                      };
                      if (file) {
                        reader.readAsDataURL(file);
                      }
                    }
                  }
                  accept="image/*"
                />
              </Fab>
            </Grid>
          </Grid>
        </div>
        <div className={scrollbar.customScroll} id={scrollbar.customScroll}>
          <div className={styles.assetImagesDrawer}>
            <DoublyScrollObserver
              className={styles.masonry}
              callBack={this.fetch()}
              observeTargetQueryName={`.${styles.observeTarget}`}
              rootQueryName={`.${scrollbar.customScroll}`}
              isUpdate={this.isUpdate}
              maxLength={assetImagesCount}
              offset={currentOffset}
            >
              {(assetImages !== undefined)
                ? (
                  <>
                    {(assetImages.length !== 0)
                      ? assetImages.map((imageObject, idx) => (
                        <LazyLoad
                          key={`assetImageDrawer-${currentOffset + idx}`}
                          className={`${styles.masonItem} ${styles.observeTarget}`}
                          scrollContainer={`.${scrollbar.customScroll}`}
                          style={{
                            height: config.thumbnail_images.height,
                            width: config.thumbnail_images.width,
                          }}
                          placeholder={<div className={`${styles.masonItem} ${styles.loading}`}><BeatLoader /></div>}
                        >
                          <i style={{ paddingBottom: `${(config.thumbnail_images.height / config.thumbnail_images.width) * 100.0}%` }} />
                          <img
                            src={imageObject.thumbnail_url}
                            alt={path.basename(imageObject.thumbnail_url).split('.')[0]}
                            onClick={() => this.addElement({ url: imageObject.url })}
                            className={styles.image}
                          />
                        </LazyLoad>
                      ))
                      : ''}
                    {(assetImages !== undefined && assetImages.length % 2 === 0)
                      ? ''
                      : (
                        <div
                          className={`${styles.masonItem} ${styles.observeTarget}`}
                          style={{
                            height: config.thumbnail_images.height,
                            width: config.thumbnail_images.width,
                          }}
                        />
                      )}
                  </>
                )
                : (
                  <>
                    {
                    [...Array(100)].map((value, idx) => (
                      <div
                        key={`assetImageDrawer-${currentOffset + idx}`}
                        style={{
                          height: config.thumbnail_images.height,
                          width: config.thumbnail_images.width,
                        }}
                        className={`${styles.masonItem} ${styles.observeTarget} ${styles.loading}`}
                      >
                        <BeatLoader />
                      </div>
                    ))
                  }
                  </>
                )}
            </DoublyScrollObserver>
          </div>
        </div>
      </>
    );
  }
}

export default AssetImagesDrawer;
