// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FontDecorationDrawer-module__textDrawer--2d_wK{width:312px;display:flex;flex-direction:column;padding-left:16px;padding-right:24px;color:white}.FontDecorationDrawer-module__textDrawer--2d_wK>*{margin-bottom:8px}.FontDecorationDrawer-module__itemList--3wYph{display:flex;flex-wrap:wrap;justify-content:space-between}.FontDecorationDrawer-module__item--FOxwm{background:#DDDDDD;width:90%;height:200px;border:1px solid #000;margin-bottom:15px;padding:1% 2%;display:flex;justify-content:center;align-items:center;transition:background-color 0.3s;font-size:4rem;cursor:pointer}.FontDecorationDrawer-module__item--FOxwm:hover{background:rgba(255,255,255,0.14);border:1px inset #000}.FontDecorationDrawer-module__highlights--1hZoP{display:flex;flex-direction:column;margin-bottom:20px}.FontDecorationDrawer-module__highlightItem--12b-3{background:rgba(255,255,255,0.07);border-radius:4px;margin-bottom:8px;transition:background-color 0.3s;cursor:pointer}.FontDecorationDrawer-module__highlightItem--12b-3:hover{background:rgba(255,255,255,0.14)}.FontDecorationDrawer-module__highlightItem--12b-3>p{padding:16px;line-height:1}.FontDecorationDrawer-module__heading--1mx7j{font-weight:800;font-size:28px}.FontDecorationDrawer-module__subheading--2eKKQ{font-weight:400;font-size:18px}.FontDecorationDrawer-module__body--1lFG4{font-weight:300;font-size:12px}.FontDecorationDrawer-module__selectColor--1TWbn{color:black;width:100%;display:flex;justify-content:space-between;padding:5px 0;padding-right:17px;font-size:1.5rem}.FontDecorationDrawer-module__selectColor--1TWbn .FontDecorationDrawer-module__colorPalette--3atMs{height:20px;width:30px}\n", ""]);
// Exports
exports.locals = {
	"textDrawer": "FontDecorationDrawer-module__textDrawer--2d_wK",
	"itemList": "FontDecorationDrawer-module__itemList--3wYph",
	"item": "FontDecorationDrawer-module__item--FOxwm",
	"highlights": "FontDecorationDrawer-module__highlights--1hZoP",
	"highlightItem": "FontDecorationDrawer-module__highlightItem--12b-3",
	"heading": "FontDecorationDrawer-module__heading--1mx7j",
	"subheading": "FontDecorationDrawer-module__subheading--2eKKQ",
	"body": "FontDecorationDrawer-module__body--1lFG4",
	"selectColor": "FontDecorationDrawer-module__selectColor--1TWbn",
	"colorPalette": "FontDecorationDrawer-module__colorPalette--3atMs"
};
module.exports = exports;
