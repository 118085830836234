// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ArchDialog-module__archDialogWrapper--39fUE{display:flex;flex-direction:column;align-items:center;padding:12.5px;width:100px;bottom:40px;top:unset;left:-12px}.ArchDialog-module__archDialogWrapper--39fUE .ArchDialog-module__titleAndColorSelection--2joE9{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.ArchDialog-module__archDialogWrapper--39fUE .ArchDialog-module__colorPalette--1nkbh{width:20px;height:20px;max-height:20px;max-width:20px;min-width:20px;min-height:20px}.ArchDialog-module__colorSecondary--3KUrA.ArchDialog-module__checked--3pjeS{color:#000 !important}.ArchDialog-module__label--_Ecll{color:#747474}.ArchDialog-module__label--_Ecll+.ArchDialog-module__checked--3pjeS{color:#000}\n", ""]);
// Exports
exports.locals = {
	"archDialogWrapper": "ArchDialog-module__archDialogWrapper--39fUE",
	"titleAndColorSelection": "ArchDialog-module__titleAndColorSelection--2joE9",
	"colorPalette": "ArchDialog-module__colorPalette--1nkbh",
	"colorSecondary": "ArchDialog-module__colorSecondary--3KUrA",
	"checked": "ArchDialog-module__checked--3pjeS",
	"label": "ArchDialog-module__label--_Ecll"
};
module.exports = exports;
