import { ACTION_TYPE } from './LoginAction';

const LoginReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.LOGIN.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.LOGIN.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.LOGIN.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.CHECK.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CHECK.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.CHECK.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.LOGOUT.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.LOGOUT.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.LOGOUT.fail:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export default LoginReducer;