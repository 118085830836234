import { connect } from 'react-redux';
import FontDecorationDrawer from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawer';
import { receiveElement } from '@common/components/entities/elements/ElementActions';
import { changeDrawer } from '@pages/editor/designDrawer/DesignDrawerAction';
import * as Action from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawerAction';


function mapStateToProps(state, ownProps) {
  return { 
    ...ownProps, 
    drawerSettings: state.ui.drawer,
    elements: state.entities.elements,
    ...state.materials.fontDecorations,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);

  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  actions.changeDrawer = (...args) => dispatch(changeDrawer(...args));
  
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(FontDecorationDrawer);
