import styles from '@pages/editor/designDrawer/shapes/ShapesDrawer.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import { getImageDimensions, resizeImage } from '@common/utils/ImageOperation';
import { calcPosMiddle } from '@common/utils/EditUtils';
import LazyLoad from 'react-lazyload';
import config from '@assets/config';
import path from 'path';
import { toastr } from 'react-redux-toastr';

class ShapesDrawer extends React.Component {

  componentDidMount() {
    const { fetch } = this.props;
    fetch();
  }

  addElement({ url, id }) {
    const { addElement, getSvg } = this.props;
    gtag('event', 'shapes_file_name', {'shapes_file_name': path.basename(url)});
    getImageDimensions(url).then(
      (image) => {

        const resizedImageDimensions = resizeImage({
          width: image.width,
          height: image.height,
        });

        if(path.basename(url) === 'circle.svg') {
          // circleでnaturalWidthがみたものと会っていないため特別対応
          resizedImageDimensions.height = resizedImageDimensions.width;
        }

        const [posX, posY] = calcPosMiddle({
          width: resizedImageDimensions.width,
          height: resizedImageDimensions.height,
        });
        const element = {
          id,
          elementableType: 'ShapeImage',
          transparency: 1,
          zIndex: 0,
          posX,
          posY,
          elementableAttributes: {
            color: '#919191',
            url,
            width: resizedImageDimensions.width,
            height: resizedImageDimensions.height,
            naturalWidth: image.naturalWidth,
            naturalHeight: image.naturalHeight,
          },
        };
        getSvg(url, (e) => {
          element.elementableAttributes.svg = e;
          addElement(element);
        })
      },
    ).catch((error) => {
        console.log("addElement error",error);
      });
  }

  render() {
    const { shapes, error } = this.props;
    return (
      <>
      {error && toastr.error('error', error.message)}
        <div className={scrollbar.customScroll}>
          <div className={styles.ShapesDrawer}>
            <div className={styles.itemList}>
              { shapes && shapes.map((item) => (
                <LazyLoad
                  key={item.id}
                  className={styles.item}
                  scrollContainer={`.${scrollbar.customScroll}`}
                  style={{
                    height: config.thumbnail_images.height,
                    width: config.thumbnail_images.width,
                  }}
                >
                  <i style={{ paddingBottom: `${(config.thumbnail_images.height / config.thumbnail_images.width) * 100.0}%` }} />
                  <img
                    src={item.url}
                    alt={path.basename(item.url).split('.')[0]}
                    onClick={() => this.addElement({ url: item.url })}
                    className={styles.imgObject}
                  />
                </LazyLoad>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ShapesDrawer;
