import {
  Typography, Input, InputAdornment, Slider,
} from '@material-ui/core';

import styles from '@components/utils/sliderAndInput/SliderAndInput.module.scss';

class SliderAndInput extends React.Component {
  unitOfNumber(unit) {
    return (value) => `${value}${unit}`;
  }

  render() {
    const {
      value, sliderConfig, marks, onChangeInput, onChangeSlider, unit, label, disabled, color,
    } = this.props;
    return (
      <div className={`${styles.wrapper} effectDialog-InputAdjust`}>
        <div className={styles.attrNameAndTextInput}>
          {(label) ? <Typography variant="body1" color={color}>{label}</Typography> : ''}
          <Input
            value={value}
            onChange={onChangeInput}
            type="number"
            className={styles.inputNumber}
            color={color}
            endAdornment={
              <InputAdornment position="end">{unit}</InputAdornment>
                }
            disabled={disabled}
          />
        </div>
        <Slider
          step={sliderConfig.step}
          min={sliderConfig.min}
          max={sliderConfig.max}
          marks={marks}
          onChange={onChangeSlider}
          aria-labelledby="discrete-slider-custom"
          getAriaValueText={this.unitOfNumber(unit)}
          valueLabelDisplay="auto"
          className={styles.sliders}
          color={color}
          value={value}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default SliderAndInput;
