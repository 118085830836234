import { connect } from 'react-redux';
import DesignDrawer from '@pages/editor/designDrawer/DesignDrawer';
import { changeDrawer, downloadImage, savingPreview } from '@pages/editor/designDrawer/DesignDrawerAction';

const mapStateToProps = (state, ownProps) => {
  const drawerSettings = state.ui.drawer;
  return {
    drawerSettings,
    elements: state.entities.elements,
    tagState: state.entities.tags.tagState,
    allTags: state.entities.tags.allTags,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeDrawer: (drawerSettings) => { dispatch(changeDrawer(drawerSettings)); },
  downloadImage: (elements, tagState, count, allTags) => { downloadImage(dispatch, elements, tagState, count, allTags); },
  savingPreview: () => { savingPreview(dispatch); }
});

export default connect(mapStateToProps, mapDispatchToProps)(DesignDrawer);
