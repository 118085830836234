import config from '@assets/config';

export const calcPosMiddle = ({ width, height }) => {
  const { width: canvasWidth } = config.canvas;
  const { height: canvasHeight } = config.canvas;
  const canvasCenterX = canvasWidth / 2;
  const elementCenterX = width / 2;
  const middlePosX = canvasCenterX - elementCenterX;

  const canvasCenterY = canvasHeight / 2;
  const elementCenterY = height / 2;
  const middlePosY = canvasCenterY - elementCenterY;

  return [middlePosX, middlePosY];
};
