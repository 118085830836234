/* eslint-disable no-restricted-syntax */
import { Typography } from '@material-ui/core';
import lodash from 'lodash';

import config from '@assets/config';
import { compareObject } from '@common/utils/ObjectUtils';

// components
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import SliderAndInput from '@components/utils/sliderAndInput/SliderAndInput';

// pages
import styles from '@pages/editor/workArea/designTools/textTools/components/dialogs/glowDialog/GlowDialog.module.scss';
import '@pages/editor/workArea/designTools/textTools/components/dialogs/glowDialog/GlowDialog.scss';

class GlowDialog extends React.Component {
  static valueText(value) {
    return `${value}px`;
  }

  constructor(props) {
    super(props);
    const { element } = this.props;
    this.colorPaletteDrawerIndex = 1;
    this.changeColorTargets = {};
    const setChangeColorTargets = (key, changeColorTargetsId) => {
      if (this.changeColorTargets[key] === undefined) { this.changeColorTargets[key] = []; }
      this.changeColorTargets[key].push(changeColorTargetsId);
    };
    ['border', 'glow', 'shadow'].forEach((attrName) => {
      setChangeColorTargets(attrName, `${element.id}-text-${attrName}`);
    });
    // effectの設定値
    this.effects = { max: 50, min: 0 };
    // スライダーの設定値
    this.sliderConfigs = { step: 1, min: this.effects.min, max: this.effects.max };
    // スライダーのマーク値
    this.marks = [
      { value: 0, label: '0px' },
      { value: 50, label: '50px' },
    ];
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  componentDidUpdate() {
    for (const key in this.changeColorTargets) {
      if (
        this.isColorDifferent(key) && this.isChangeColorTargetOwn(key)
      ) {
        this.changeColor(key);
      }
    }
  }

  // onChanges
  onChangeGlow(newValue) {
    const { receiveElement, element } = this.props;
    const { elementableAttributes } = element;
    const updatedAttr = lodash.cloneDeep(elementableAttributes);
    updatedAttr.glow.configs[0].weight = this.withinLimit(parseInt(newValue || 0, 10));
    receiveElement({ ...element, elementableAttributes: updatedAttr });
  }

  changeColor(targetKey) {
    const { element, receiveElement, drawerSettings } = this.props;
    const updateElementAttrs = lodash.cloneDeep(element.elementableAttributes);
    if (this.isChangeColorTargetOwn(targetKey)) {
      updateElementAttrs[targetKey].configs[0].color = drawerSettings.colorPalette.color;
      receiveElement({ ...element, elementableAttributes: updateElementAttrs });
    }
  }

  toggleSubDrawer(id, color = undefined, targetIdx) {
    return () => {
      const { drawerSettings, changeDrawer } = this.props;
      const nextDrawerSettings = { ...drawerSettings };
      if (drawerSettings.closed) { nextDrawerSettings.closed = false; }
      if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
      if (color) {
        nextDrawerSettings.colorPalette = {
          ...nextDrawerSettings.colorPalette,
          color,
          title: config.colorTitle['光彩色'],
          changeTarget: this.changeColorTargets[targetIdx],
          colorType: 'unit',
          allowGradient: false,
        };
      }
      if (!color) {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
        // 色ドロワーかつ、色変更対象が変わった時
      } else if (color && drawerSettings.colorPalette.changeTarget !== this.changeColorTargets[targetIdx]) {
        nextDrawerSettings.isSubDrawerOpen = true;
      }

      if (drawerSettings.closed) {
        nextDrawerSettings.isSubDrawerOpen = true;
      } else {
        nextDrawerSettings.isSubDrawerOpen = !nextDrawerSettings.isSubDrawerOpen;
      }
      nextDrawerSettings.subDrawer = id;
      nextDrawerSettings.decorationTarget = null;

      changeDrawer(nextDrawerSettings);
    };
  }

  isColorDifferent(key) {
    const { drawerSettings, element } = this.props;
    return element.elementableAttributes[key].configs[0].color !== drawerSettings.colorPalette.color;
  }

  isChangeColorTargetOwn(key) {
    const { drawerSettings } = this.props;
    return compareObject(drawerSettings.colorPalette.changeTarget, this.changeColorTargets[key]);
  }

  withinLimit(value) {
    let updatedValue = value;
    if (value >= this.effects.max) {
      updatedValue = this.effects.max;
    } else if (value <= this.effects.min) {
      updatedValue = this.effects.min;
    }
    return updatedValue;
  }

  render() {
    const { className, element } = this.props;
    const { glow } = element.elementableAttributes;
    return (
      <CustomPaper className={`${styles.glowDialogWrapper} ${styles.scrollable} ${className} glowDialog`}>
        <div className={styles.titleAndColorSelection}>
          <Typography variant="subtitle1" color="textSecondary">{glow.text}</Typography>
          <div
            id="glow-colorPalette"
            className={`btn-color ${styles.space} ${styles.colorPalette}`}
            style={{ backgroundColor: (Object.keys(glow.configs).length > 0) ? glow.configs[0].color : '#000' }}
            onClick={this.toggleSubDrawer(this.colorPaletteDrawerIndex, glow.configs[0].color, 'glow')}
          />
        </div>
        <SliderAndInput
          styles={styles}
          sliderConfig={this.sliderConfigs}
          marks={this.marks}
          value={glow.configs[0].weight}
          unit="px"
          onChangeInput={(e) => { this.onChangeGlow(e.target.value); }}
          onChangeSlider={(e, newValue) => { this.onChangeGlow(newValue); }}
        />
      </CustomPaper>
    );
  }
}

export default GlowDialog;
