// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LineHeightDialog-module__lineHeightWrapper--k80l5{padding:12.5px !important;width:100px;top:40px !important;left:-12px !important}.LineHeightDialog-module__lineHeightWrapper--k80l5 .LineHeightDialog-module__lineHeightDialog--3h47y{width:100%}.LineHeightDialog-module__lineHeightWrapper--k80l5 .LineHeightDialog-module__lineHeightDialog--3h47y .LineHeightDialog-module__sliders--lR0ej{width:80% !important;margin:0 10%}.LineHeightDialog-module__lineHeightWrapper--k80l5 .LineHeightDialog-module__lineHeightDialog--3h47y .LineHeightDialog-module__sliders--lR0ej .LineHeightDialog-module__sliderLabels--ctE14{display:flex;justify-content:flex-end}.LineHeightDialog-module__lineHeightWrapper--k80l5 .LineHeightDialog-module__lineHeightDialog--3h47y .LineHeightDialog-module__sliders--lR0ej .LineHeightDialog-module__sliderLabels--ctE14 .LineHeightDialog-module__inputNumber--VoCK1{width:45px}.LineHeightDialog-module__lineHeightWrapper--k80l5 .LineHeightDialog-module__lineHeightDialog--3h47y .LineHeightDialog-module__sliders--lR0ej .LineHeightDialog-module__sliderLabels--ctE14 .LineHeightDialog-module__labelWithIcon--YkanO{display:flex;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"lineHeightWrapper": "LineHeightDialog-module__lineHeightWrapper--k80l5",
	"lineHeightDialog": "LineHeightDialog-module__lineHeightDialog--3h47y",
	"sliders": "LineHeightDialog-module__sliders--lR0ej",
	"sliderLabels": "LineHeightDialog-module__sliderLabels--ctE14",
	"inputNumber": "LineHeightDialog-module__inputNumber--VoCK1",
	"labelWithIcon": "LineHeightDialog-module__labelWithIcon--YkanO"
};
module.exports = exports;
