import {
  ACTION_TYPE
} from '@pages/editor/designDrawer/designHistory/DesignHistoryAction';

const DesignHistoryReducer = (state={}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.GET_HISTORY.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.GET_HISTORY.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.GET_HISTORY.fail:
      return { ...state, ...action.payload };
    case ACTION_TYPE.UPDATE_KEEP_FLAG.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.UPDATE_KEEP_FLAG.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.UPDATE_KEEP_FLAG.fail:
      return { ...state, ...action.payload };
    case ACTION_TYPE.CLOSE_ALERT.success:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default DesignHistoryReducer;