import { toCanvas, getWebFontEmbedCss } from 'html-to-image';
import { DateTime } from 'luxon';
import config from '@assets/config';
import Axios from '@common/apis/axios';
import axios from 'axios';

export const CHANGE_DRAWER = 'CHANGE_DRAWER';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const DOWNLOAD_IMAGE_START = 'DOWNLOAD_IMAGE_START';
export const DOWNLOAD_IMAGE_DONE = 'DOWNLOAD_IMAGE_DONE';
export const DOWNLOAD_IMAGE_FAIL = 'DOWNLOAD_IMAGE_FAIL';

export const SAVING_PREVIEW_START = 'SAVING_PREVIEW_START';
export const SAVING_PREVIEW_DONE = 'SAVING_PREVIEW_DONE';
export const SAVING_PREVIEW_FAIL = 'SAVING_PREVIEW_FAIL';

let fontEmbedCss = '';
for (const key in [...Array(18)]) {
  Axios.get(`${config.apiServer.baseUrl}/fonts/webfont${parseInt(key)}.txt`).then((ret) => {
    fontEmbedCss += ret.data;
  });
}

const getTag = (category, id, allTags) => {
  const targetTag = allTags[category].filter((tag) => tag.id === id)[0];
  return targetTag;
};

const setDownloadSmallImageEvent = (fileName, tagState, allTags) => {
  const customDimensions = {
    event_name: 'smallImage_download_count',
    smallImage_downloaded_file_name: fileName,
    smallImage_created_at: DateTime.now().toFormat('yyyy/MM/dd/ HH:mm:ss'),
    smallImage_download_company_id: tagState.companyId,
    smallImage_download_professional: getTag('職種カテゴリ', tagState.professional, allTags).name,
    smallImage_download_targetAttr: getTag('ターゲット属性', tagState.targetAttr, allTags).name,
    smallImage_download_imageKind: getTag('画像種別', tagState.imageKind, allTags).name,
  };
  if (tagState.imageImagine) { customDimensions.smallImage_download_imageImagine = getTag('画像イメージ', tagState.imageImagine, allTags).name; }
  if (tagState.feature) { customDimensions.smallImage_download_feature = getTag('特徴', tagState.feature, allTags).name; }
  gtag('event', 'smallImage_download_count', customDimensions);
};

export const changeDrawer = (state) => ({
  type: CHANGE_DRAWER,
  payload: { ...state },
});
export const changeColor = (state) => ({
  type: CHANGE_COLOR,
  payload: { ...state },
});
export const downloadImage = async (dispatch, elements, tagState, count, allTags) => {
  dispatch({ type: DOWNLOAD_IMAGE_DONE, payload: { wait: true, error: null, networkErr: false } });

  setTimeout(async () => {
    const el = document.getElementById('noElement');
    try {
      const fileName = `gazo_${DateTime.now().toFormat('yyyyMMdd_HHmm_ss')}_${tagState.companyId}_${config.imageKindCode[tagState.imageKind] || ''}_${count}.jpg`;
      await srcToPresigner(el);
      const canvas = await toCanvas(el, {
        canvasWidth: 800, canvasHeight: 600, width: el.clientWidth, height: el.clientHeight, preferredFontFormat: 'opentype', fontEmbedCss, pixelRatio: 1,
      });
      const targetImgUri = canvas.toDataURL('image/jpeg');
      const res = await Axios.post(`${config.apiServer.baseUrl}/smallImage`, {
        companyId: tagState.companyId,
        tagState,
        file: targetImgUri.replace(/^.*,/, ''),
        fileName,
        partsInformations: elements,
      });
      if (res.status === 200) {
        setDownloadSmallImageEvent(fileName, tagState, allTags);
        const link = document.createElement('a');
        link.download = fileName;
        link.href = targetImgUri;
        link.click();
        dispatch({ type: DOWNLOAD_IMAGE_DONE, payload: { wait: false } });
      } else {
        dispatch({ type: DOWNLOAD_IMAGE_FAIL, payload: { wait: false, networkErr: true, error: { message: '小画像の保存に失敗しました' } } });
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: DOWNLOAD_IMAGE_FAIL, payload: { wait: false, networkErr: true  } });
    }
  }, 500);
};

const srcToPresigner = async (element) => {
  if (element.tagName === 'IMG') {
    const src = element.getAttribute('src');
    if (isValidHttpUrl(src)) {
      const res = await Axios.get(`${config.apiServer.baseUrl}/assetImage/url`, { params: { url: src } });
      const base64Img = await getBase64ImageFromUrl(res.data);

      element.setAttribute('src', base64Img);
    }
  } else if (element.tagName === 'SVG') {
    return element;
  } else {
    const elementChildren = element.children;

    if (elementChildren.length > 0) {
      for (const child of elementChildren) {
        element.replaceChild(await srcToPresigner(child), child);
      }
    }
  }
  return element;
};

const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const getBase64ImageFromUrl = async (imageUrl) => new Promise((resolve) => axios.get(imageUrl, { responseType: 'blob', headers: { 'Content-type': 'image/*' } }).then((res) => {
  const reader = new FileReader();
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(res.data);
}));

export const savingPreview = async(dispatch) => {
  dispatch({
    type: SAVING_PREVIEW_START,
    payload: {
      wait: true, 
      error: null,
      designedImg: '',
    },
  });
  try {
    const el = document.getElementById('noElement');
    await srcToPresigner(el);
    const canvas = await toCanvas(el, {
      canvasWidth: 800, canvasHeight: 600, width: el.clientWidth, height: el.clientHeight, preferredFontFormat: 'opentype', fontEmbedCss, pixelRatio: 1,
    });
    const imgUrl = canvas.toDataURL('image/jpeg');
    dispatch({ 
      type: SAVING_PREVIEW_DONE,
      payload: { 
        wait: false, 
        error: null, 
        designedImg: imgUrl,
        isModalOpen: true,
      }, 
    });
  } catch (error) {
    dispatch({ 
      type: SAVING_PREVIEW_FAIL,
      payload: { 
        wait: false, 
        error: error, 
        designedImg: '' 
      }, 
    });
  }
}