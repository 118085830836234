// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__loadImgList--3UAr6{width:150px;margin:5px 5px 5px 0;flex:1;display:flex;justify-content:center;align-items:center;background-color:#ccc}.styles-module__loadTextList--3qRXS{width:150px;margin:5px 5px 5px 0;flex:1;display:flex;justify-content:center;align-items:center}.styles-module__loadFontDesignPatternList--oBipp{width:195px;height:170px;margin:5px 5px 5px 0;flex:1;display:flex;justify-content:center;align-items:center}.styles-module__imgWrapper--17ORJ{margin:5px 5px 5px 0;flex:1}.styles-module__imgList--3d7DM{object-fit:contain}.styles-module__imgRow--2ByAU{display:flex;flex-direction:row}.styles-module__arrow--3dVWP{margin:8.5vh 0}.styles-module__arrow2--2OMYk{margin:8.5vh 0;color:#e0e0e0}.styles-module__textRecommendsPad--1L_Gu{width:90%;text-align:center;margin:5px 0 20px 0}.styles-module__textRecommendsPad--1L_Gu .styles-module__pad--m9F-A{font-size:1.08rem;margin:5px 10px 5px 10px}.styles-module__fontDesignPatternRow--1g2KL{display:flex;flex-direction:row;width:100%}.styles-module__fontDesignPatternRow--1g2KL .styles-module__fontDesignPatternPad--2y3vd{width:100%;text-align:center;display:flex;flex-direction:row;align-items:center;justify-content:space-evenly;flex:1}.styles-module__fontDesignPatternRow--1g2KL .styles-module__fontDesignPatternPad--2y3vd .styles-module__itemWrapper--1S_xh{height:100%;width:150px;flex:1;margin:5px 5px 5px 0;background-color:white}.styles-module__fontDesignPatternRow--1g2KL .styles-module__fontDesignPatternPad--2y3vd .styles-module__itemWrapper--1S_xh .styles-module__item--19AHc{display:flex;justify-content:center;align-items:center;background:#ddd;transition:background-color 0.3s;border:1px solid #000;font-size:30px;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"loadImgList": "styles-module__loadImgList--3UAr6",
	"loadTextList": "styles-module__loadTextList--3qRXS",
	"loadFontDesignPatternList": "styles-module__loadFontDesignPatternList--oBipp",
	"imgWrapper": "styles-module__imgWrapper--17ORJ",
	"imgList": "styles-module__imgList--3d7DM",
	"imgRow": "styles-module__imgRow--2ByAU",
	"arrow": "styles-module__arrow--3dVWP",
	"arrow2": "styles-module__arrow2--2OMYk",
	"textRecommendsPad": "styles-module__textRecommendsPad--1L_Gu",
	"pad": "styles-module__pad--m9F-A",
	"fontDesignPatternRow": "styles-module__fontDesignPatternRow--1g2KL",
	"fontDesignPatternPad": "styles-module__fontDesignPatternPad--2y3vd",
	"itemWrapper": "styles-module__itemWrapper--1S_xh",
	"item": "styles-module__item--19AHc"
};
module.exports = exports;
