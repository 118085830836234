import config from '@assets/config';
import styles from '@pages/editor/workArea/design/components/GridLine.module.scss';

class GridLine extends React.Component {
  constructor(props) {
    super(props);
  }

  generateGridPositions(latticeSpacing, middle, max) {
    const result = [middle];
    for (let i = middle + latticeSpacing; i < max; i += latticeSpacing) {
      result.push(i);
    }
    for (let i = middle - latticeSpacing; i > 0; i -= latticeSpacing) {
      result.push(i);
    }
    return result;
  }

  render() {
    const { zoom, open } = this.props;
    // 格子間隔
    const latticeSpacing = config.canvas.latticeSpacing * zoom;
    const height = config.canvas.height * zoom;
    const width = config.canvas.width * zoom;
    const middleWidth = width / 2;
    const middleHeight = height / 2;
    const gridPositions = {};
    gridPositions.width = this.generateGridPositions(latticeSpacing, middleWidth, width);
    gridPositions.height = this.generateGridPositions(latticeSpacing, middleHeight, height);
    return (
      (open)
        ? (
          <div
            className={styles.grid}
            style={{ width, height }}
          >
            {
            (Object.keys(gridPositions).map((direction) => gridPositions[direction].map((latticePlace, key) => {
              const color = (key === 0) ? '#000' : '#CCCCCC';
              if (direction === 'width') {
                return <div className={styles.line} style={{ left: latticePlace, borderLeft: `solid 1px ${color}`, height: '100%' }} />;
              }
              return <div className={styles.line} style={{ top: latticePlace, borderTop: `solid 1px ${color}`, width: '100%' }} />;
            }))).flat()
          }
          </div>
        )
        : ''
    );
  }
}

export default React.memo(GridLine);
