import {
  ACTION_TYPE, UPDATE_IMAGE_COUNT, CLOSE_MODAL, ADDED_TO_HISTORY, LARGE_JSON_NOTIFIED
} from '@pages/editor/designDrawer/saving/SavingAction';

const initState = {
  download: '',
  addToHistory: false,
  isJsonLarge: false,
  imgCount: {
    prevCompanyId: '',
    uploadCount: 0,
    downloadCount: 0
  }
};

const SavingReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.DOWNLOAD_IMAGE.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.DOWNLOAD_IMAGE.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.DOWNLOAD_IMAGE.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.SEARCH_COMPANY.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SEARCH_COMPANY.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SEARCH_COMPANY.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.UPLOAD_IMAGE.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.UPLOAD_IMAGE.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.UPLOAD_IMAGE.fail:
      return { ...state, ...action.payload };
    case UPDATE_IMAGE_COUNT:
      return { ...state, ...action.payload };
    case CLOSE_MODAL:
      return { ...state, ...action.payload };
    case ADDED_TO_HISTORY:
      return { ...state, ...action.payload };
    case LARGE_JSON_NOTIFIED:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default SavingReducer;

