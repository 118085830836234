import {
  Input, InputAdornment, Slider, Typography,
} from '@material-ui/core';
import { Tonality } from '@material-ui/icons';
import CustomPaper from '@components/utils/customPaper/CustomPaper';

import styles from '@pages/editor/workArea/designTools/imageTools/components/dialogs/contrastDialog/ContrastDialog.module.scss';

class ContrastDialog extends React.Component {
  constructor(props) {
    super(props);
    this.max = 300;
    this.min = 0;
    this.onChangeSliders = this.onChangeSliders.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onChangeSliders(filterName) {
    const { receiveElement, element } = this.props;
    return (e, newValue) => {
      const targetValue = newValue || 0;
      let updateValue = (parseInt(targetValue, 10) >= this.max) ? this.max : parseInt(targetValue, 10);
      updateValue = (parseInt(updateValue, 10) <= this.min) ? this.min : parseInt(updateValue, 10);
      element.elementableAttributes[filterName] = updateValue;
      receiveElement({ ...element });
    };
  }

  onChangeInput(filterName) {
    const { receiveElement, element } = this.props;
    return (e) => {
      const targetValue = e.target.value || 0;
      let updateValue = (parseInt(targetValue, 10) >= this.max) ? this.max : parseInt(targetValue, 10);
      updateValue = (parseInt(updateValue, 10) <= this.min) ? this.min : parseInt(updateValue, 10);
      element.elementableAttributes[filterName] = updateValue;
      receiveElement({ ...element });
    };
  }

  render() {
    const { className, iconClassName, element } = this.props;

    const { elementableAttributes: attr } = element;
    const marks = [
      {
        value: 0,
        label: '0%',
      },
      {
        value: 100,
        label: '100%',
      },
      {
        value: 200,
        label: '200%',
      },
      {
        value: 300,
        label: '300%',
      },
    ];

    const sliderConfig = {
      step: 0.25,
      min: this.min,
      max: this.max,
    };

    return (
      <CustomPaper className={`${className} ${styles.contrastPaper}`}>
        <Typography variant="caption" color="textSecondary">コントラスト</Typography>
        <div className={styles.contrastDialog}>
          <div className={`${styles.sliderLabels} contrastDialog-InputAdjust`}>
            <Input
              className={`input-attr ${styles.inputNumber}`}
              id="contrast"
              type="number"
              value={(attr.contrast !== undefined) ? attr.contrast : 100}
              onChange={this.onChangeInput('contrast')}
              endAdornment={
                <InputAdornment position="end">%</InputAdornment>
              }
            />
          </div>
          <Slider
            defaultValue={sliderConfig.defaultValue}
            step={sliderConfig.step}
            min={sliderConfig.min}
            max={sliderConfig.max}
            marks={marks}
            onChange={this.onChangeSliders('contrast')}
            value={(attr.contrast !== undefined) ? attr.contrast : 100}
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={(value) => (`${value}%`)}
            valueLabelDisplay="auto"
          />
        </div>
      </CustomPaper>
    );
  }
}

export default ContrastDialog;
