import { connect } from 'react-redux';
import WallPaperImageTools from '@pages/editor/workArea/designTools/wallPaperImageTools/WallPaperImageTools';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  receiveElement: (element) => dispatch(receiveElement(element)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WallPaperImageTools);
