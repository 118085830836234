import styles from '@components/icons/alignVerticalCenter/AlignVerticalCenter.module.scss';

export default function AlignVerticalCenter(props) {
  const { className, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <polygon fill={color} points="22,11 17,11 17,6 14,6 14,11 10,11 10,3 7,3 7,11 1.84,11 1.84,13 7,13 7,21 10,21 10,13 14,13 14,18 17,18 17,13 22,13" />
    </svg>
  );
}
