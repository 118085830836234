import '@common/scss/globalImports/Imports.scss';
import styles from '@pages/editor/designDrawer/subDrawer/fontDecoration/component/ParseStyleText.module.scss';
import { genGradientCss } from '@common/utils/TextOperation';

class ParseStyleText extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      style, children, onClick, content, className, beforeClassName, wrapStyle, full, onDoubleClick, devRef, zoom,
    } = this.props;
    let beforeStyle;
    let afterStyle;
    if (style.before) { 
      if (style.before.background && style.gradients) {
        beforeStyle = {
          ...style.before,
          fontSize: style.fontSize,
          textAlign: style.fontAlign,
          lineHeight: style.lineHeight,
          letterSpacing: style.letterSpacing,
          overflowWrap: style.overflowWrap,
          whiteSpace: style.whiteSpace,
          padding: style.padding,
          background: genGradientCss(style.gradients),
          color: 'transparent',
        }
        delete beforeStyle.top;
      } else {
        beforeStyle = { ...style.before }; 
      }
    }
    if (style.after) { afterStyle = { ...style.after }; }
    const domStyle = { ...style };

    delete domStyle.before;
    delete domStyle.after;

    const alignCenter = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    return (
      <div
        className={(className) ? `${className} ${styles.parsePseudoStyleText}` : styles.parsePseudoStyleText}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        ref={devRef}
        style={{ ...alignCenter }}
      >
        <div style={(!full) ? {
          position: 'relative', textAlign: 'center', ...wrapStyle, ...alignCenter,
        } : {
          position: 'relative', ...wrapStyle, textAlign: 'center', width: '100%', height: '100%', ...alignCenter,
        }}
        >
          {(style.before)
            ? (
              <div
                style={{
                  height: '100%', position: 'absolute', zIndex: 1, ...beforeStyle,
                }}
                className={(['未経験あいう', '"未経験あいう"', content].includes(beforeStyle.content))
                  ? `${styles.backgroundClipText} ${styles.before} ${beforeClassName}`
                  : `${beforeClassName} ${styles.before}`}
              >
                {(['未経験あいう', '"未経験あいう"', content].includes(beforeStyle.content)) ? content : ''}
              </div>
            )
            : ''}
          <div style={style.before && style.before.background && style.gradients ? 
            { ...domStyle, visibility: 'hidden' } : { ...domStyle }}>
            {children}
          </div>
          {(style.after)
            ? (
              <div
                style={(!full) ? { position: 'absolute', ...afterStyle } : {
                  width: '100%', height: '100%', position: 'absolute', ...afterStyle,
                }}
                className={styles.after}
              >
                {(style.after.content === '未経験あいう') ? content : ''}
              </div>
            )
            : ''}
        </div>
      </div>
    );
  }
}

export default ParseStyleText;
