import {
  Button, ButtonGroup, Dialog, DialogContent, Typography, Box
} from '@material-ui/core';
import { Clear } from '@material-ui/icons'
import TagDrawerContainer from '@pages/editor/designDrawer/tag/TagDrawerContainer';
import DesignHistoryContainer from '@pages/editor/designDrawer/designHistory/DesignHistoryContainer';
import styles from './TabSelector.module.scss';

class TabSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      startNew: false
    };
  }

  fromNew() {
    const { user } = this.props;
    if (this.state.tab === 0) {
      return null;
    }
    gtag('event', 'click_new_button', {
      event_name : 'click_new_button',
      user_name : user.name,
      user_email : user.mail,
    });
    const { elements } = this.props;
    const activeEle = Object.keys(elements).filter(el => !elements[el]._destroy)
    if (activeEle.length > 0) {
      this.setState({ startNew: true })
    } else {
      this.setState({ tab: 0 })
    }
  }

  async clearDesign() {
    this.props.setSelection(null);
    await this.props.deleteElements();
    const newTag = {
      professional: '',
      targetAttr: '',
      imageKind: '',
      imageImagine: '',
      feature: ''
    };
    await this.props.updateTag(newTag)
    this.cancelClear();
  }

  cancelClear() {
    this.setState({ 
      startNew: false, 
      tab: 0
    })
  }

  fromHistory() {
    const { user } = this.props;
    this.setState({ tab: 1 });
    gtag('event', 'click_history_button', {
      event_name : 'click_history_button',
      user_name : user.name,
      user_email : user.mail,
    });
  }

  render() {
    const { tab, startNew } = this.state;
    return (
      <div className={styles.tabBody}>
        <div className={styles.tabBtnBox}>
          <ButtonGroup fullWidth variant="contained">
            <Button 
              className={ tab === 0 ? styles.activeTab : styles.tabBtn }
              onClick={()=>this.fromNew()}
            >
              新規
            </Button>
            <Button 
              className={ tab === 1 ? styles.activeTab : styles.tabBtn }
              onClick={()=>this.fromHistory()}
            >
              履歴から
            </Button>
          </ButtonGroup>
        </div>
        { tab === 0 ? 
          <TagDrawerContainer/>
        : <div className={styles.historyContainer}>
          <DesignHistoryContainer/>
        </div> }

        <Dialog open={startNew} style={{ zIndex: 3000 }} PaperProps={{ style: { borderRadius: 15, padding: 15 }}}>
          <DialogContent className={styles.alertDialog}>
            <Clear className={styles.crossIcon} onClick={()=>this.setState({ startNew: false })}/>
            <Typography className={styles.dialogTitle}>デザイン削除の確認</Typography>
            <Box className={styles.alertTextBox}>
              <Typography className={styles.alertText}>キャンバスに編集中のデザインがあります。</Typography>
              <Typography className={styles.alertText}>現在のデザインをリセットして新規で作成しますか？</Typography>
            </Box>
            <Box className={styles.dialogAction}>
              <Button 
                variant='contained'
                className={styles.cancelButton}
                onClick={()=>this.cancelClear()} 
              >
                <Typography className={styles.buttonText}>キャンセル</Typography>
              </Button>
              <Button 
                variant='contained'
                className={styles.okButton}
                onClick={()=>this.clearDesign()} 
              >
                <Typography className={styles.buttonText}>はい</Typography>
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default TabSelector;
