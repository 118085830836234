import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('colorPalette', 'FETCH'),
  SAVE: SaveActionCreatorFactory('colorPalette', 'SAVE'),
};

export const fetch = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      wait: true, isGetColor: false, error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/color`).then((res) => {

    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        wait: false, isGetColor: true, error: null,
      },
    });

    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        colors: res.data.items,
        colorCount: res.data.totalCount,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        wait: false, isGetColor: false, error: { message: '色の取得に失敗しました。' },
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};
