import config from '@assets/config';

/**
 * 共通画像操作関数群
 * @since 1.0.0
*/

export const getImageDimensions = (url) => new Promise((resolve, reject) => {
  const img = new Image();

  img.addEventListener('load', () => {
    resolve(img);
  });

  img.addEventListener('error', (event) => {
    reject(`${event.type}: ${event.message}`);
  });

  img.src = url;
});

export const resizeImage = ({ height, width , maxHeight=null, maxWidth=null , strict=false}) => {
  let magnification = 1;
  const canvasHeight = maxHeight || config.canvas.height;
  const canvasWidth = maxWidth || config.canvas.width;
  if (height > canvasHeight) {
    magnification = height / canvasHeight;
  } else if (width > canvasWidth) {
    magnification = width / canvasWidth;
  }

  if(strict) {
    const heightMagnification = height / canvasHeight;
    const widthMagnification = width / canvasWidth;
    magnification = Math.max(heightMagnification, widthMagnification);
  }

  return { width: (width / magnification), height: (height / magnification) };
};
