import { connect } from 'react-redux';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

import Text from '@components/elements/text/Text'

function mapStateToProps(state, ownProps) {
  return { ...ownProps, ...state.materials.text, tags: state.entities.tags };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  actions.receiveElement = (...args) => dispatch(receiveElement(...args));

  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(Text);
