// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__overlay--3NtA5{background:rgba(0,0,0,0.3);z-index:9999;position:fixed;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"overlay": "styles-module__overlay--3NtA5"
};
module.exports = exports;
