import styles from '@components/icons/alignHorizontalRight/AlignHorizontalRight.module.scss';

export default function AlignHorizontalRight(props) {
  const { className, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      className={`${className} ${styles.defaultIconStyle}`}
    >
      <rect fill="none" height="24" width="24" />
      <path fill={color} d="M20,2h2v20h-2V2z M2,10h16V7H2V10z M8,17h10v-3H8V17z" />
    </svg>
  );
}
