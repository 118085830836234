// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContrastDialog-module__contrastPaper--1Jadu{top:40px;width:100px;padding:12.5px;left:-12px}.ContrastDialog-module__contrastPaper--1Jadu .ContrastDialog-module__contrastDialog--2lfna{width:100%}.ContrastDialog-module__contrastPaper--1Jadu .ContrastDialog-module__contrastDialog--2lfna .ContrastDialog-module__sliderLabels--4-GhR{display:flex;justify-content:flex-end}.ContrastDialog-module__contrastPaper--1Jadu .ContrastDialog-module__contrastDialog--2lfna .ContrastDialog-module__sliderLabels--4-GhR .ContrastDialog-module__labelWithIcon--2N79F{display:flex;justify-content:center;align-items:center}.ContrastDialog-module__contrastPaper--1Jadu .ContrastDialog-module__contrastDialog--2lfna .ContrastDialog-module__sliderLabels--4-GhR .ContrastDialog-module__inputNumber--8oGm1{width:60px}\n", ""]);
// Exports
exports.locals = {
	"contrastPaper": "ContrastDialog-module__contrastPaper--1Jadu",
	"contrastDialog": "ContrastDialog-module__contrastDialog--2lfna",
	"sliderLabels": "ContrastDialog-module__sliderLabels--4-GhR",
	"labelWithIcon": "ContrastDialog-module__labelWithIcon--2N79F",
	"inputNumber": "ContrastDialog-module__inputNumber--8oGm1"
};
module.exports = exports;
