import { connect } from 'react-redux';
import WallPaperImagesDrawer from '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawer';

import * as Action from '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawerAction';
import { update } from '@pages/editor/designDrawer/tag/TagDrawerAction';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

function mapStateToProps(state, ownProps) {
  return { ...ownProps, 
    ...state.materials.wallPaperImages, 
    elements: state.entities.elements,
    tags: state.entities.tags };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);

  actions.updateTag = (...args) => dispatch(update(...args));
  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(WallPaperImagesDrawer);
