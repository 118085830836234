function SvgHumanCutout(props, svgRef) {
  return (
    <svg
      {...props}
      id="humanCutout_svg__\u30EC\u30A4\u30E4\u30FC_7"
      data-name="\u30EC\u30A4\u30E4\u30FC 7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.74 30.75"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <defs>
        <style>{`.humanCutout_svg__cls-1{fill:${props.color}}`}</style>
      </defs>
      <path
        className="humanCutout_svg__cls-1"
        d="M783.75 470.68a.24.24 0 00-.31 0l-1.93 1.94a.24.24 0 000 .31l-7 7.37h.38v.08l.84-.27a60.37 60.37 0 006.46-2.29c.32-.28 1.55-1.53 2.11-2.12a.26.26 0 00.33 0l1.93-1.94a.26.26 0 000-.31zm-2.09 6.57c-.28.19-2.56 1-4.66 1.66l5.06-5.35 1.64 1.64c-.7.68-1.78 1.8-2.04 2.05zM766 497.36l-1-.31c.07-.23.15-.46.23-.68l-.46-.59.81-.59a8.7 8.7 0 00.59.74l.2.23-.1.28c-.06.3-.17.56-.27.92zM765.16 500.87l-1-.18.09-.51c.08-.44.18-.88.28-1.31l1 .22c-.1.42-.19.84-.26 1.27zM781.54 500.86l-.08-.48c-.06-.36-.15-.81-.26-1.31l1-.22c.11.52.2 1 .27 1.37l.08.47zM780.73 497.33c-.09-.31-.2-.62-.31-.93l-.11-.27.2-.23c.21-.24.4-.49.58-.74l.82.57a6.8 6.8 0 01-.46.6c.09.24.17.47.24.7z"
        transform="translate(-762.87 -470.64)"
      />
      <path
        className="humanCutout_svg__cls-1"
        d="M763.73 493.84a10.23 10.23 0 01-.65-2.08l1-.2a9.31 9.31 0 00.58 1.88zm19.25 0l-.92-.39a9.63 9.63 0 00.59-1.89l1 .2a9.86 9.86 0 01-.65 2.07zm-19.11-4.23h-1a10.91 10.91 0 01.19-1.89l.06-.28 1 .21-.05.25a11.13 11.13 0 00-.2 1.7zm19 0a9.89 9.89 0 00-.22-2l1-.21a10.37 10.37 0 01.25 2.16zm-18.13-3.83l-.91-.41a10.75 10.75 0 011.1-1.88l.8.59a9.66 9.66 0 00-1.02 1.68zm17.28 0a9.36 9.36 0 00-1-1.7l.81-.59a10.39 10.39 0 011.1 1.88zm-2.33-3.15a10 10 0 00-1.59-1.16l.49-.86a9.55 9.55 0 011.77 1.27zm-12.63 0l-.67-.74a10.48 10.48 0 011.77-1.28l.5.87a9 9 0 00-1.66 1.12zm9.23-2a9 9 0 00-1.93-.4l.11-1a11.06 11.06 0 012.13.45zm-5.83 0l-.31-.95a9.12 9.12 0 011.17-.3c.32-.06.64-.11 1-.14l.1 1a9.4 9.4 0 00-1.93.4zM777.76 495.82a7.67 7.67 0 10-8.8 0 19.5 19.5 0 00-1.83 5.57h12.45a17.3 17.3 0 00-1.82-5.57zM771.41 484a7.36 7.36 0 00-.86 3.06.5.5 0 01-.5.5.5.5 0 01-.5-.5 8.25 8.25 0 011-3.62.51.51 0 01.69-.13.5.5 0 01.17.69zm4.08.16a7.32 7.32 0 00-.86 3.06.5.5 0 01-.5.5.5.5 0 01-.5-.5 8.25 8.25 0 011-3.62.49.49 0 01.69-.13.5.5 0 01.17.64z"
        transform="translate(-762.87 -470.64)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHumanCutout);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
