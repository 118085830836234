import React from 'react';
import { toastr } from 'react-redux-toastr';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import ToolsNav from '@pages/editor/designDrawer/toolsNav/ToolsNav';

// main drawers
import TextDrawerContainer from '@pages/editor/designDrawer/text/TextDrawerContainer';
import FontDesignPatternDrawerContainer from '@pages/editor/designDrawer/fontDesignPatterns/FontDesignPatternDrawerContainer';
import AssetImagesDrawerContainer from '@pages/editor/designDrawer/assetImages/AssetImagesDrawerContainer';
import styles from '@pages/editor/designDrawer/DesignDrawer.module.scss';
import WallPaperImagesDrawerContainer from '@pages/editor/designDrawer/wallPaperImages/WallPaperImagesDrawerContainer';
import FrameImagesDrawerContainer from '@pages/editor/designDrawer/frameImages/FrameImagesDrawerContainer';
import TabSelectorContainer from './tabSelector/TabSelectorContainer';
import ShapesDrawerContainer from '@pages/editor/designDrawer/shapes/ShapesDrawerContainer';
import Saving from '@pages/editor/designDrawer/saving/SavingContainer';
import SubDrawer from '@pages/editor/designDrawer/subDrawer/SubDrawer';

// sub drawers
import FontDecorationDrawerContainer from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawerContainer';
import ColorPaletteContainer from '@pages/editor/designDrawer/subDrawer/colorPalette/ColorPaletteContainer';

import DrawerKnob from '@components/utils/drawerKnob/DrawerKnob';
import Loader from '../../../components/loader/Loader';

class DesignDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadDialogState: false,
      imageKindName: null,
      networkErr: true,
      savingModalIsOpen: false,
      waitModalIsOpen: false,
    };

    this.changeDrawer = this.changeDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.closeSavingModal = this.closeSavingModal.bind(this);
    // props.drawerSettings
    // {
    //   drawer: 1,
    //   closed: false,
    //   animate: true,
    //   isSubDrawerOpen: false,
    //   subDrawer: 0,
    //   colorPalette: object
    // }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
      && this.props === nextProps);
  }

  getTag(category, id) {
    const { allTags } = this.props;
    const tag = allTags[category].filter((tag) => tag.id === id)[0];
    return tag;
  }

  changeDrawer(id) {
    const { drawerSettings, changeDrawer } = this.props;
    const updateDrawerSettings = {
      ...drawerSettings,
      drawer: id,
      closed: false,
      isSubDrawerOpen: false,
      decorationTarget: null,
    };

    // サブドロワーが開いていない時に同じIDならドロワーを閉じる
    if (drawerSettings.drawer === id && drawerSettings.isSubDrawerOpen === false) {
      updateDrawerSettings.closed = !drawerSettings.closed;
    } else {
      updateDrawerSettings.closed = false;
    }

    if (updateDrawerSettings.closed) {
      updateDrawerSettings.animate = false;
    } else {
      // 開くとき
      updateDrawerSettings.animate = true;
    }
    changeDrawer(updateDrawerSettings);
  }

  closeDrawer() {
    const { drawerSettings, changeDrawer } = this.props;
    const updateDrawerSettings = { ...drawerSettings };
    updateDrawerSettings.closed = !drawerSettings.closed;
    changeDrawer(updateDrawerSettings);
  }

  checkDownload() {
    const { tagState } = this.props;
    const errors = [];
    if (!tagState.professional) {
      errors.push('職種カテゴリが選択されていません');
    }
    if (!tagState.targetAttr) {
      errors.push('ターゲット属性が選択されていません');
    }
    if (!tagState.imageKind) {
      errors.push('画像カテゴリが選択されていません');
    }
    if (errors.length) {
      toastr.error('error', {
        component: <p dangerouslySetInnerHTML={{ __html: errors.join('<br />') }} />,
      });
      return;
    }
    this.setState({ savingModalIsOpen: true });
  }

  closeDownloadDialog() {
    this.setState({
      downloadDialogState: false,
    });
  }

  closeSavingModal() {
    const { drawerSettings, changeDrawer } =  this.props;
    const temp = { ...drawerSettings };
    temp.isModalOpen = false;
    changeDrawer(temp);
    this.setState({ savingModalIsOpen: false });
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.drawerSettings.designedImg && nextProps.drawerSettings.isModalOpen) {
      this.setState({ waitModalIsOpen: false });
      this.setState({ savingModalIsOpen: true });
    }
  }

  render() {
    const {
      addElement, zoom, drawerSettings, elements, tagState, selection, allTags, setSelection
    } = this.props;
    const drawers = [
      '',
      // <TagDrawerContainer />,
      <TabSelectorContainer setSelection={setSelection}/>,
      <AssetImagesDrawerContainer addElement={addElement} />,
      <WallPaperImagesDrawerContainer addElement={addElement} />,
      <FrameImagesDrawerContainer addElement={addElement} />,
      <ShapesDrawerContainer addElement={addElement} zoom={zoom} />,
      <TextDrawerContainer addElement={addElement} zoom={zoom} />,
      <FontDesignPatternDrawerContainer addElement={addElement} zoom={zoom} isDesignPattern={false} element={elements[selection]} />,
    ];
    const subDrawers = [
      <FontDecorationDrawerContainer addElement={addElement} />,
      <ColorPaletteContainer addElement={addElement} element={elements[selection]}/>,
    ];

    return (
      <div className={styles.designDrawer}>
        <Modal
          open={this.state.waitModalIsOpen}
          className={styles.modal}>
          <div className={styles.modalBody}>
            <Loader active={true}/>
          </div>
        </Modal>
        <Modal
          open={this.state.savingModalIsOpen}
          className={styles.modal}
        >
          <div className={styles.modalBody}>
            <Saving
              onClose={this.closeSavingModal}
              elements={elements}
              tagState={tagState}
              designedImg={this.props.drawerSettings.designedImg}
            />
          </div>
        </Modal>
        <ToolsNav
          changeDrawer={this.changeDrawer}
          f
          drawerSettings={drawerSettings}
          downloadImage={async() => { 
            this.setState({ waitModalIsOpen: true });
            setTimeout(async() => {await this.props.savingPreview()}, 5000);
          }}
        />
        <div className={`${styles.drawer} ${drawerSettings.closed ? '' : styles.showDrawer}`}>
          {!allTags && drawerSettings.drawer===1 && <Loader leftDrawer={true} active={true}/>}
          {drawers[drawerSettings.drawer]}
          {(drawerSettings.isSubDrawerOpen)
            ? (
              <SubDrawer className={(drawerSettings.isSubDrawerOpen && !drawerSettings.closed) ? '' : styles.none}>
                {subDrawers[drawerSettings.subDrawer]}
              </SubDrawer>
            )
            : ''}
        </div>
        <div className={styles.handle}>
          <button type="button" className={`${styles.container} btn-none`} onClick={this.closeDrawer}>
            <DrawerKnob />
            <div className={styles.handleIcon}>
              { drawerSettings.closed ? <ArrowForwardIos /> : <ArrowBackIos /> }
            </div>
          </button>
        </div>
        {drawerSettings.networkErr && this.state.networkErr &&
        <Dialog
          open={drawerSettings.networkErr && this.state.networkErr}
          aria-labelledby="download-dialog-title"
        >
          <DialogTitle id="download-dialog-title">{"接続エラー"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="download-dialog-description">
              小画像の保存に失敗しました。<br />
              インターネット接続を確認して、もう一度お試しください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ networkErr: false }) }} color="secondary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
        }
      </div>
    );
  }
}

export default DesignDrawer;
