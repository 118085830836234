import { ACTION_TYPE } from './WizardModalAction';
import { Stack } from '@common/utils/Stack';

const initState = {
  selectedItems: Array(6),
  selectedUploadImages: new Stack(5),
};

const WizardModalReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case ACTION_TYPE.INIT.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.INIT.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.INIT.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.CHANGE_TAG.success:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.CHANGE_SELECTED_ITEMS.success:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.SMALL_IMAGE_RECOMMEND.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.ASSET_IMAGE.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.ASSET_IMAGE.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.ASSET_IMAGE.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.WALLPAPER_IMAGE.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.WALLPAPER_IMAGE.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.WALLPAPER_IMAGE.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.FRAME_IMAGE.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.FRAME_IMAGE.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.FRAME_IMAGE.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.RECOMMEND_TEXTS.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.RECOMMEND_TEXTS.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.RECOMMEND_TEXTS.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.GROUP_TEXT.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GROUP_TEXT.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GROUP_TEXT.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.GROUP_TEXT_IDS.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GROUP_TEXT_IDS.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.GROUP_TEXT_IDS.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.FONT_DESIGN_PATTERN.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.FONT_DESIGN_PATTERN.success:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.FONT_DESIGN_PATTERN.fail:
      return Object.assign({}, state, action.payload);
    
    case ACTION_TYPE.UPLOAD_FILE.start:
      return Object.assign({}, state, action.payload);
    case ACTION_TYPE.UPLOAD_FILE.success:
      const uploadImages = (state.uploadImages) ? [...state.uploadImages] : [];
      uploadImages[action.payload.key] = action.payload.uploadedImage;
      return Object.assign({}, state, {...action.payload, uploadImages});
    case ACTION_TYPE.UPLOAD_FILE.fail:
      return Object.assign({}, state, action.payload);

    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.fail:
      return { ...state, ...action.payload };

    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.start:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.success:
      return { ...state, ...action.payload };
    case ACTION_TYPE.PLUS_TEXT_CLICK_COUNT.fail:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default WizardModalReducer;