// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TransparencyDialog-module__transparencyPaper--_8xmy{top:30px;width:100px;padding:12.5px;left:-12px}.TransparencyDialog-module__transparencyPaper--_8xmy .TransparencyDialog-module__transparencyDialog--1bHLR{width:100%;display:flex;justify-content:center;align-items:flex-end;flex-direction:column}.TransparencyDialog-module__transparencyPaper--_8xmy .TransparencyDialog-module__transparencyDialog--1bHLR .TransparencyDialog-module__inputNumber--1DK_K{width:45px;margin-right:10%}.TransparencyDialog-module__sliders--13xAq{width:80% !important;margin:0 10%}\n", ""]);
// Exports
exports.locals = {
	"transparencyPaper": "TransparencyDialog-module__transparencyPaper--_8xmy",
	"transparencyDialog": "TransparencyDialog-module__transparencyDialog--1bHLR",
	"inputNumber": "TransparencyDialog-module__inputNumber--1DK_K",
	"sliders": "TransparencyDialog-module__sliders--13xAq"
};
module.exports = exports;
