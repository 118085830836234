import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  FETCH: ActionCreatorFactory('fontDecoration', 'FETCH'),
  SAVE: SaveActionCreatorFactory('fontDecoration', 'SAVE'),
  CHANGE_DECORATION: ActionCreatorFactory('fontDecoration', 'CHANGE_DECORATION'),
  REFLECT_DECORATION: ActionCreatorFactory('fontDecoration', 'REFLECT_DECORATION'),
  SET_CHANGEABLE: ActionCreatorFactory('fontDecoration', 'SET_CHANGEABLE'),
};

export const fetch = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.FETCH.start,
    payload: {
      isGetFontDecoration: false,
      error: null,
    },
  });
  Axios.get(`${config.apiServer.baseUrl}/fontDecoration`, {
  }).then((res) => {
    dispatch({
      type: ACTION_TYPE.FETCH.success,
      payload: {
        isGetFontDecoration: true,
        error: null,
      },
    });
    dispatch({
      type: ACTION_TYPE.SAVE.save,
      payload: {
        fontDecorations: res.data.items,
        fontDecorationCount: res.data.totalCount,
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTION_TYPE.FETCH.fail,
      payload: {
        isGetFontDecoration: false, error: { message: '文言装飾の取得に失敗しました。' },
      },
    });
  });
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};

export const changeDecoration = (decoration, decorationTarget, decorationInfo) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.CHANGE_DECORATION.success,
    payload: { decoration, decorationTarget, didReflectDecoration: false, ...decorationInfo }
  })
}

export const reflectDecoration = (decorationInfo) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.REFLECT_DECORATION.success,
    payload: { didReflectDecoration: true, ...decorationInfo }
  })
}

export const setChangeable = (textId, changeableInfo) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SET_CHANGEABLE.success,
    payload: { changeableInfo }
  })
}