import { connect } from 'react-redux';
import Component from './HumanCutModal';
import * as Action from './HumanCutModalAction';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state) => ({
  tagState: state.entities.tags,
  ...state.materials.humanCutModal,
});

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);
  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(Component);
