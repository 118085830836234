/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import config from '@assets/config';

/**
 * テキスト操作関数群
 * @since 1.0.0
*/

// 改行を<br>に変換
export const newLineToBreak = (text) => {
  const regexp = /(\n|\r|\r\n)/;
  const splitText = text.split(regexp);

  const reactTexts = splitText.map((value) => {
    if (regexp.test(value)) {
      return <br />;
    }
    return value;
  });
  return reactTexts;
};

// テキストの幅取得
const canvas = document.createElement('canvas');
export const getTextRect = ({
  text, font, height = null, width = null,
}) => {
  const result = {};
  const canvasConfig = config.canvas;
  const context = canvas.getContext('2d');
  const texts = text.split(/(\n|\r|\r\n)/).filter((splitText) => !['\n', '\r', '\r\n'].includes(splitText));
  context.font = font;
  let metrics;
  for (let i = 0; i <= (text.match(/(\n|\r|\r\n)/g) || []).length; i++) {
    const tmpMetrics = context.measureText(texts[i]);
    if (!metrics || tmpMetrics.width > metrics.width) metrics = tmpMetrics;
  }
  result.width = (canvasConfig.width > metrics.width) ? metrics.width : canvasConfig.width;
  if (width) {
    result.width = (result.width > width) ? result.width : width;
  }
  if (height) {
    const row = Math.ceil(metrics.width / canvasConfig.width) || 1;
    result.height = height * row;
  }
  return result;
};

export const getTextLineRect = ({ text, font }) => {
  const context = canvas.getContext('2d');
  const texts = text.split(/(\n|\r|\r\n)/).join('');
  context.font = font;

  const metrics = context.measureText(texts);
  return metrics.width;
};

/**
 * ArcTextの半径を求める。
 * @params angle 中心角
 * @params textWidth テキストの幅
 */
export const getArcRadius = (angle, textWidth) => {
  // 孤の求め方
  // 2 x 円周率 x 半径 * 中心角/360 = X
  // 直径 * 3.14 = 円周

  // x / 360 * angle = textWidth
  // x / 360 = textWidth / angle
  // x = textWidth / angle * 360 <- 円周

  const circumference = (textWidth / angle) * 360;
  const pi = 3.14;
  return parseFloat(circumference / pi / 2);
};

// テキスト影CSSの生成
const cache = {};
const setCache = (key, value) => {
  cache[key] = value;
};

const getCache = (key) => {
  if (key in cache) {
    return cache[key];
  }
  return null;
};
const cachePow = (num) => {
  if (!getCache(num)) {
    setCache(num, Math.pow(num, 2));
  }
  return getCache(num);
};

export const genShadowCss = ({ start = 0, end, color }) => {
  let css = '';
  // 縦**2 + 横**2 = 斜め**2
  for (let i = start; i <= end; i++) {
    for (let j = start; j <= end; j++) {
      if (cachePow(j) + cachePow(i) <= cachePow(end)) {
        css += (i === start && j === start) ? '' : ',';
        css += `${i}px ${j}px 0px ${color}`;
        if (j !== 0) {
          css += ',';
          css += `${i}px -${j}px 0px ${color}`;
        }
        if (i !== 0) {
          css += ',';
          css += `-${i}px ${j}px 0px ${color}`;
        }
        if (j !== 0 && i !== 0) {
          css += ',';
          css += `-${i}px -${j}px 0px ${color}`;
        }
      }
    }
  }
  return css;
};

export const genGradientCss = (gradients, direction = undefined) => {
  const originPercent = 100;

  const editDirection = direction || gradients.direction || 'to right';
  let cssValue = `linear-gradient(${editDirection},`;
  cssValue += 'transparent 0%,';
  for (const key in gradients.configs) {
    if (parseInt(key, 10) !== 0) { cssValue += ','; }
    const _gradient = gradients.configs[key];
    if ((typeof _gradient.percent === 'string') && _gradient.percent.includes('%')) {
      cssValue += `${_gradient.color} ${_gradient.percent}`;
    } else {
      const percent = parseInt((_gradient.percent / originPercent) * 100, 10);
      cssValue += `${_gradient.color} ${percent}%`;
    }
  }
  cssValue += ',transparent 100%)';
  return cssValue;
};

const pseudo = (id, css) => {
  id += '-pseudoStyle';
  const element = document.getElementById(id);
  if (element == null) {
    styleTag = document.createElement('style');
    styleTag.id = id;
    styleTag.innerHTML = css;
    document.getElementsByTagName('head')[0].appendChild(styleTag);
  } else {
    element.innerHTML = css;
  }
};

export const defaultDecoration = {
  fontWeight: 800,
  fontSize: 80,
  fontFamily: 'UDShinGoPr6N',
  color: '#000',
  letterSpacing: 0,
  lineHeight: 120,
  fontAlign: 'left',
  border: { text: '文字ボーダー', configs: [{ weight: 0, color: '#000' }] },
  glow: { text: '光彩', configs: [{ weight: 0, color: '#000' }] },
  shadow: {
    text: 'テキスト影',
    configs: [{
      vertical: 0, horizontal: 0, color: '#000', blur: 0,
    }],
  },
  arch: { text: '円形', direction: { value: 1, text: '方向' }, angle: { value: 0, text: '中心角' } },
};

// textElementの操作関数郡
export const templateToReactName = (template) => {
  const result = {};
  Object.keys(template).map((key) => {
    const replaced = key.replace(/-(.)/g, (match, p1) => (p1.toUpperCase()));
    if (template[key] && (typeof template[key]) === 'object') {
      result[replaced] = templateToReactName(template[key]);
    } else {
      result[replaced] = template[key];
    }
  });
  return result;
};

export const templateToStyle = (template) => {
  if (template.before) { template.before = templateToStyle(template.before); }
  if (template.after) { template.after = templateToStyle(template.after); }
  if (template.parent) { template.parent = templateToStyle(template.parent); }
  // gradation対応
  if (template.gradients && (template.gradients.configs.length !== 0 || Object.keys(template.gradients.configs).length !== 0)) {
    if (!template.before) { template.before = {}; }
    (template.content)
      ? template.background = genGradientCss(template.gradients)
      : template.before.background = genGradientCss(template.gradients);
    template.before.content = '未経験あいう';
    template.before.color = 'transparent';
    template.before.fontSize = template.fontSize;
    template.before.fontWeight = template.fontWeight;
    template.before.fontFamily = template.fontFamily;
  }

  if (template.border) {
    for (const key in template.border) {
      const upperKey = key.charAt(0).toUpperCase() + key.slice(1);
      const border = template.border[key];
      template[`border${upperKey}`] = `${border.style} ${border.width} ${border.color}`;
    }
  }

  if (template.fontShadows || template.fontBorders || template.glow) {
    template.textShadow = genTextShadowCss(template);
  }

  if (template.before && !template.before.bottom && !template.before.top) {
    template.before.top = 0;
    template.before.left = 0;
  }

  return template;
};

export const templateToTextElement = (template, defaultTextDecorate) => {
  const setParent = (parent, value, key) => {
    if (parent) {
      return { ...parent, [key]: value };
    }
    return { [key]: value };
  };

  const deepCopiedTemplate = { ...template };
  // borderの詰め直し
  if (deepCopiedTemplate.border) {
    deepCopiedTemplate.parent = setParent(deepCopiedTemplate.parent, deepCopiedTemplate.border, 'border');
    delete deepCopiedTemplate.border;
    delete deepCopiedTemplate.borderBottom;
    delete deepCopiedTemplate.borderLeft;
    delete deepCopiedTemplate.borderRight;
    delete deepCopiedTemplate.borderTop;
  }
  // borderRadiusの詰め直し
  if (deepCopiedTemplate.parent && deepCopiedTemplate.parent.borderRadius) {
    deepCopiedTemplate.parent = setParent(deepCopiedTemplate.parent, deepCopiedTemplate.borderRadius, 'roundedCorner');
    delete deepCopiedTemplate.borderRadius;
  }

  // colorの詰め直し(parent要素にいるやつだけ)
  if (deepCopiedTemplate.parent && deepCopiedTemplate.parent.color) {
    deepCopiedTemplate.color = deepCopiedTemplate.parent.color;
    delete deepCopiedTemplate.parent.color;
  }

  // fontSizeの詰め直し
  if (deepCopiedTemplate.fontSize) {
    deepCopiedTemplate.fontSize = deepCopiedTemplate.fontSize.match(/\d{2}/)[0];
  }

  // textShadowの詰め直し
  if (
    deepCopiedTemplate.fontShadows
    && deepCopiedTemplate.fontShadows.configs
    && Object.keys(deepCopiedTemplate.fontShadows.configs).length > 0
  ) {
    for (const key in deepCopiedTemplate.fontShadows.configs) {
      deepCopiedTemplate.fontShadows.configs[key].vertical = deepCopiedTemplate.fontShadows.configs[key].posY;
      deepCopiedTemplate.fontShadows.configs[key].horizontal = deepCopiedTemplate.fontShadows.configs[key].posX;
    }
    deepCopiedTemplate.shadow = { text: defaultDecoration.shadow.text, configs: { ...deepCopiedTemplate.fontShadows.configs } };
  }
  if (deepCopiedTemplate.fontBorders && Object.keys(deepCopiedTemplate.fontBorders).length > 0) {
    deepCopiedTemplate.border = { text: defaultDecoration.border.text, configs: { ...deepCopiedTemplate.fontBorders } };
  }
  if (deepCopiedTemplate.glow && Object.keys(deepCopiedTemplate.glow).length > 0) {
    deepCopiedTemplate.glow = { text: defaultDecoration.glow.text, configs: { ...deepCopiedTemplate.glow } };
  }　else {
    delete deepCopiedTemplate.glow;
  }

  if (template.before && template.before.content === '未経験あいう' && template.before.color === 'transparent') {
    if (!template.before) { template.before = {}; }
    template.before.fontSize = defaultTextDecorate.fontSize;
  }

  delete deepCopiedTemplate.fontBorders;
  delete deepCopiedTemplate.fontShadows;
  const elementAttrs = { ...deepCopiedTemplate };
  return elementAttrs;
};

export const genTextShadowCss = (template) => {
  let css = '';
  let processed = false;
  if (template.fontBorders) {
    const { fontBorders: border } = template;
    for (const idx in border) {
      if (processed) { css += ','; }
      css += genShadowCss({ end: border[idx].width, color: border[idx].color });
      processed = true;
    }
  }

  // glow
  if (template.glow) {
    const { glow } = template;
    for (const idx in glow) {
      if (processed) { css += ','; }
      css += `0px 0px ${glow[idx].weight}px ${glow[idx].color}`;
      processed = true;
    }
  }

  // shadow

  if (template.fontShadows && Object.keys(template.fontShadows.configs).length > 0) {
    const { fontShadows: shadow } = template;
    let blur = 0; let vertical = 0; let horizontal = 0; let
      color = '#000';
    if (shadow.configs[0].posY !== undefined) { vertical = shadow.configs[0].posY; }
    if (shadow.configs[0].posX !== undefined) { horizontal = shadow.configs[0].posX; }
    if (shadow.configs[0].blur !== undefined) { blur = shadow.configs[0].blur; }
    if (shadow.configs[0].color !== undefined) { color = shadow.configs[0].color; }
    if (vertical || horizontal || blur) {
      if (processed) { css += ','; }
      css += `${horizontal}px ${vertical}px ${blur}px ${color}`;
      processed = true;
    }
  }
  return css;
};
