export default (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
  },
  cardContent: {
    textAlign: 'center',
  },
  signinBtn: {
    marginTop: '15px',
    padding: '25px !important',
  }
});
