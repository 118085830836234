function SvgFontShadow(props, svgRef) {
  return (
    <svg
      {...props}
      id="fontShadow_svg__\u30EC\u30A4\u30E4\u30FC_5"
      data-name="\u30EC\u30A4\u30E4\u30FC 5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15"
      width="1em"
      height="1em"
      color={props.color}
      ref={svgRef}
    >
      <defs>
        <style>{`.fontShadow_svg__cls-1{fill:${props.color}}`}</style>
      </defs>
      <path
        className="fontShadow_svg__cls-1"
        d="M4.69 11L3 15h2.25l1.21-3h2.8l-.41-1H4.69zM10.5 2H9.43l5.08 12h-1.17l.41 1H16L10.5 2zM6.83 5.96L6.38 7h.87l-.42-1.04z"
      />
      <path
        className="fontShadow_svg__cls-1"
        d="M883 469.14l-5.5-13h-2l-5.5 13h2.25l1.21-3h6.06l1.23 3H883zm-8.73-5l2.23-5.53 2.23 5.53z"
        transform="translate(-870.04 -456.15)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFontShadow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
