import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  GET_PREV_DESIGN: ActionCreatorFactory('editor', 'GET_PREV_DESIGN'),
  SAVE_TEMP: ActionCreatorFactory('editor', 'SAVE_TEMP'),
};

export const getPreviousDesign = () => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.GET_PREV_DESIGN.start, 
    payload: { wait: true, error: null, prevDesign: '', tagInfo: '' }, 
  });
  try {
    const res = await Axios.get(`${config.apiServer.baseUrl}/smallImage/prevDesign`);
    if (res.status === 200) {
      dispatch({ 
        type: ACTION_TYPE.GET_PREV_DESIGN.success, 
        payload: { wait: false, 
          prevDesign: res.data.prevDesign, 
          tagInfo: res.data.tagInfo, 
          user: res.data.user
        } 
      });
    } else {
      dispatch({ 
        type: ACTION_TYPE.GET_PREV_DESIGN.fail, 
        payload: { wait: false, prevDesign: '', tagInfo: '', error: { message: '小画像取得に失敗しました' } } 
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ 
      type: ACTION_TYPE.GET_PREV_DESIGN.fail, 
      payload: { wait: false, prevDesign: '', tagInfo: '', error: { message: '小画像取得に失敗しました' }  } 
    });
  }
};

export const saveTemp = (body) => async (dispatch) => {
  dispatch({ 
    type: ACTION_TYPE.SAVE_TEMP.start, 
    payload: { wait: true, error: null, tempDesign: '' }, 
  });
  try {
    const res = await Axios.post(`${config.apiServer.baseUrl}/smallImage/saveTemp`, body);
    if (res.status === 200) {
      dispatch({ 
        type: ACTION_TYPE.SAVE_TEMP.success, 
        payload: { wait: false, tempDesign: res.data } 
      });
    } else {
      dispatch({ 
        type: ACTION_TYPE.SAVE_TEMP.fail, 
        payload: { wait: false, tempDesign: '', error: { message: '小画像の一時的に保存に失敗しました' } } 
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ 
      type: ACTION_TYPE.SAVE_TEMP.fail, 
      payload: { wait: false, tempDesign: '', error: { message: '小画像の一時的に保存に失敗しました' }  } 
    });
  }
};