import {
  Edit,
  Wallpaper,
  GetApp,
  CropFree,
  Widgets,
  PhotoLibrary,
  FontDownload,
} from '@material-ui/icons';
import styles from '@pages/editor/designDrawer/DesignDrawer.module.scss';
import toolsNavStyle from '@pages/editor/designDrawer/toolsNav/ToolsNav.module.scss';
import Logo from '@assets/images/logo-gazo.svg';

const ICON_SIZE = '4.3vh';

const ToolsNav = ({
  changeDrawer, drawerSettings, downloadImage,
}) => {
  const { drawer: current, closed, animate } = drawerSettings;
  const activeButton = (id) => (current === id && !closed ? 'active btn-tools' : 'btn-tools');
  return (
    <div className={styles.toolsNav}>
      <div className={animate ? styles.highlight : styles.highlightClosed} style={{ transform: `translate3d(0px, ${current * 10}vh, 0px)` }}>
        <div className={`${styles.background} ${(closed || current === 7 || drawerSettings.isSubDrawerOpen) ? styles.hidden : ''}`} />
      </div>
      <nav className={styles.buttonsNav}>
        <div className={`btn-tools ${styles.logo}`}>
          <img className={toolsNavStyle.logoImg} src={Logo} alt="" />
        </div>
        <button type="button" className={`${activeButton(1)} ${styles.btnTools}`} onClick={() => changeDrawer(1)}>
          <Edit style={{ fontSize: ICON_SIZE }} />
          <small>作成・編集</small>
        </button>
        <button type="button" className={`${activeButton(2)} ${styles.btnTools}`} onClick={() => changeDrawer(2)}>
          <PhotoLibrary style={{ fontSize: ICON_SIZE }} />
          <small>素材画像</small>
        </button>
        <button type="button" className={`${activeButton(3)} ${styles.btnTools}`} onClick={() => changeDrawer(3)}>
          <Wallpaper style={{ fontSize: ICON_SIZE }} />
          <small>背景</small>
        </button>
        <button type="button" className={`${activeButton(4)} ${styles.btnTools}`} onClick={() => changeDrawer(4)}>
          <CropFree style={{ fontSize: ICON_SIZE }} />
          <small>フレーム</small>
        </button>
        <button type="button" className={`${activeButton(5)} ${styles.btnTools}`} onClick={() => changeDrawer(5)}>
          <Widgets style={{ fontSize: ICON_SIZE }} />
          <small>図形</small>
        </button>
        <button type="button" className={`${activeButton(6)} ${styles.btnTools}`} onClick={() => changeDrawer(6)}>
          <FontDownload style={{ fontSize: ICON_SIZE }} />
          <small>テキスト</small>
        </button>
        {/* <button type="button" className={`${activeButton(7)} ${styles.btnTools}`} onClick={() => changeDrawer(7)}>
          <Palette style={{ fontSize: ICON_SIZE }} />
          <small>
            フォント
            <br />
            デザイン
          </small>
        </button> */}
        <div className={styles.buttonsNavBottom}>
          {/* <button type="button" className={`${activeButton(7)} ${styles.btnTools}`} onClick={() => changeDrawer(8)}>
            <Menu className={toolsNavStyle.icons} />
          </button> */}
          <button type="button" className={`${activeButton(99)} ${styles.btnTools}`} onClick={() => downloadImage()}>
            <GetApp style={{ fontSize: ICON_SIZE }} />
            <small>
              保存
              {/* ダウン
              <br />
              ロード */}
            </small>
          </button>
        </div>
      </nav>

    </div>
  );
};

export default ToolsNav;
