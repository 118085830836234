import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  SMALL_IMAGE_RECOMMEND: ActionCreatorFactory('smallImages', "SMALL_IMAGE_RECOMMEND"),
  PLUS_SMALL_IMAGE_CLICK_COUNT: ActionCreatorFactory('smallImages', 'PLUS_SMALL_IMAGE_CLICK_COUNT')
};

export const getSmallImage = (updatedTag = {}, offset = 0, limit = 30) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.start, payload: { smallImageLoading: true, smallImageRecommends: null, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/recommend/smallImage`, 
    {
      params: { offset, limit }, 
      tags: {...updatedTag},
  }).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.success, payload: { smallImageLoading: false, smallImageRecommend: res.data } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.SMALL_IMAGE_RECOMMEND.fail, payload: { smallImageLoading: false, error: { message: 'レコメンドの取得に失敗しました' } } });
  });
}

export const plusSmallImageClickCount = (id) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.start, payload: { smallImageLoading: false, didClicked: false, error: null } });
  Axios.put(`${config.apiServer.baseUrl}/smallImage/plus`, {id}).then((res) => {
    setTimeout(() => {
      dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.success, payload: { smallImageLoading: false, didClicked: true } });
    }, 500);
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.PLUS_SMALL_IMAGE_CLICK_COUNT.fail, payload: { smallImageLoading: false, didClicked: false, error: { message: 'レコメンドの更新に失敗しました' } } });
  });
}