import { combineReducers } from 'redux';
import {
  CHANGE_DRAWER, CHANGE_COLOR, DOWNLOAD_IMAGE_START, DOWNLOAD_IMAGE_DONE, DOWNLOAD_IMAGE_FAIL,
  SAVING_PREVIEW_START, SAVING_PREVIEW_DONE, SAVING_PREVIEW_FAIL, HANDLE_MODAL
} from '@pages/editor/designDrawer/DesignDrawerAction';
// import { SAVING_PREVIEW } from '@pages/editor/designDrawer/saving/SavingAction';

const initState = {
  drawer: 1,
  closed: false,
  animate: true,
  isSubDrawerOpen: false,
  isModalOpen: false,
  subDrawer: 0,
  colorPalette: {
    color: '#000',
    changeTarget: 'text',
  },
};

const designDrawerReducer = (state = initState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case CHANGE_DRAWER:
      return { ...state, ...action.payload };
    case CHANGE_COLOR:
      return { ...state, ...action.payload };
    case DOWNLOAD_IMAGE_START:
      return { ...state, ...action.payload };
    case DOWNLOAD_IMAGE_DONE:
      return { ...state, ...action.payload };
    case DOWNLOAD_IMAGE_FAIL:
      return { ...state, ...action.payload };
    case SAVING_PREVIEW_START:
      return { ...state, ...action.payload };
    case SAVING_PREVIEW_DONE:
      return { ...state, ...action.payload };
    case SAVING_PREVIEW_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default designDrawerReducer;
