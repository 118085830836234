import { connect } from 'react-redux';
import TransparencyDialog from '@pages/editor/workArea/designTools/components/dialogs/transparencyDialog/TransparencyDialog';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => {
  const actions = {};

  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(TransparencyDialog);
