import React from 'react';
import styles from './styles.module.scss';

export default class Loader extends React.Component {
  render() {
    return (
      <>
        { this.props.active
          ? (
            <div className={this.props.leftDrawer ? styles.skCircleBodyLeft : styles.skCircleBody}>
              <div className={this.props.leftDrawer ? styles.skCircleFadeLeft : styles.skCircleFade}>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
                <div className={styles.skCircleFadeDot}></div>
              </div>
            </div>
          )
          : null}
      </>
    );
  }
}
