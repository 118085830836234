import {
  Input, Slider, Typography, InputAdornment,
} from '@material-ui/core';
import styles from '@pages/editor/workArea/designTools/textTools/components/dialogs/lineHeightDialog/LineHeightDialog.module.scss';
import CustomPaper from '@components/utils/customPaper/CustomPaper';
import { getTextRect } from '@common/utils/TextOperation';

class LineHeightDialog extends React.Component {
  constructor(props) {
    super(props);
    this.lineSpace = { min: 0, max: 300 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  onChangeValue(newValue) {
    const { element, receiveElement } = this.props;
    const { elementableAttributes } = element;
    const updatedElementableAttributes = { ...elementableAttributes };
    const changedValue = this.withInRange(newValue || 0);
    updatedElementableAttributes.lineHeight = changedValue;

    const rect = this.genNewFrameWidthAndHeight(changedValue);
    updatedElementableAttributes.width = rect.width;
    updatedElementableAttributes.height = rect.height;

    receiveElement({ ...element, elementableAttributes: updatedElementableAttributes });
  }

  genNewFrameWidthAndHeight(lineHeight) {
    const { element } = this.props;
    const {
      fontWeight, fontSize, fontFamily, text, width,
    } = element.elementableAttributes;
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const newLineCount = (text.match(/(\n|\r|\rn)/g) || []).length;
    const calcedHeight = fontSize * (parseInt(lineHeight, 10) / 100) * (newLineCount + 1);
    const rect = getTextRect({
      text, font, height: calcedHeight, width,
    });
    return {
      height: rect.height,
      width: rect.width,
    };
  }

  withInRange(strInt) {
    let resultInt = parseInt(strInt, 10);
    if (this.max <= resultInt) {
      resultInt = this.max;
    } else if (this.min >= resultInt) {
      resultInt = this.min;
    }
    return resultInt;
  }

  render() {
    const { className, element } = this.props;
    const { lineHeight } = element.elementableAttributes;

    const marks = [
      { value: 0, label: '0%' },
      { value: 100, label: '100%' },
      { value: 200, label: '200%' },
      { value: 300, label: '300%' },
    ];

    const sliderConfig = {
      defaultValue: 100,
      step: 0.25,
      min: this.lineSpace.min,
      max: this.lineSpace.max,
    };

    return (
      <CustomPaper className={`${className} ${styles.lineHeightWrapper}`}>
        <div className={styles.lineHeightDialog}>
          <Typography variant="caption" color="textSecondary">行間</Typography>
          <div className={`${styles.sliders} lineHeightDialog-InputAdjust`}>
            <div className={styles.sliderLabels}>
              <Input
                value={lineHeight || 0}
                onChange={(e) => this.onChangeValue(e.target.value)}
                type="number"
                className={styles.inputNumber}
                endAdornment={
                  <InputAdornment position="end">%</InputAdornment>
                }
              />
            </div>
            <Slider
              defaultValue={sliderConfig.defaultValue}
              step={sliderConfig.step}
              min={sliderConfig.min}
              max={sliderConfig.max}
              marks={marks}
              onChange={(e, newValue) => this.onChangeValue(newValue)}
              value={lineHeight || 0}
              aria-labelledby="discrete-slider-custom"
              getAriaValueText={(value) => (`${value}%`)}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
      </CustomPaper>
    );
  }
}

export default LineHeightDialog;
