// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Design-module__design--1lg_g{background-color:white;border:1px solid #dddddd;box-shadow:0 2px 8px rgba(14,19,24,0.07);position:relative}.Design-module__elementsContainer--2Kixl{overflow:visible;width:100%;height:100%;position:relative}.Design-module__selected--18phZ{pointer-events:none;position:absolute;z-index:99;border:2px solid aqua}\n", ""]);
// Exports
exports.locals = {
	"design": "Design-module__design--1lg_g",
	"elementsContainer": "Design-module__elementsContainer--2Kixl",
	"selected": "Design-module__selected--18phZ"
};
module.exports = exports;
