// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FontShadowDialog-module__fontShadowWrapper--3yx-p{display:flex;flex-direction:column;align-items:center;padding:12.5px;width:100px;bottom:40px;top:unset;left:-12px}.FontShadowDialog-module__fontShadowWrapper--3yx-p .FontShadowDialog-module__titleAndColorSelection--1zIsZ{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.FontShadowDialog-module__fontShadowWrapper--3yx-p .FontShadowDialog-module__colorPalette--3PXuQ{width:20px;height:20px;max-height:20px;max-width:20px;min-width:20px;min-height:20px}\n", ""]);
// Exports
exports.locals = {
	"fontShadowWrapper": "FontShadowDialog-module__fontShadowWrapper--3yx-p",
	"titleAndColorSelection": "FontShadowDialog-module__titleAndColorSelection--1zIsZ",
	"colorPalette": "FontShadowDialog-module__colorPalette--3PXuQ"
};
module.exports = exports;
