import styles from '@pages/editor/designDrawer/subDrawer/SubDrawer.module.scss';

export default function SubDrawer(props) {
  const { children, className } = props;
  return (
    <div className={`${className} ${styles.subDrawer}`}>
      { children }
    </div>
  );
}
