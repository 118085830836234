import { connect } from 'react-redux';
import Component from './LoginComponent';
import * as Action from './LoginAction';

function mapStateToProps(state) {
  return { ...state.login };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  const keys = Object.keys(Action);
  for (const key of keys) {
    if (typeof Action[key] === 'function') {
      actions[key] = (...args) => dispatch(Action[key](...args));
    }
  }
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(Component);
