import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styles from './styles.module.scss';

export default class WaitComponent extends React.Component {
  render() {
    return (
      <>
        { this.props.active
          ? (
            <div className={styles.overlay}>
              <BeatLoader />
            </div>
          )
          : null}
      </>
    );
  }
}
