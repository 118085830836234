/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { IconButton, Typography } from '@material-ui/core';
import { Gradient } from '@material-ui/icons';
import MultiSlider, { Progress, Dot } from 'react-multi-bar-slider';

import { genGradientCss } from '@common/utils/TextOperation';
import styles from '@pages/editor/designDrawer/subDrawer/colorPalette/ColorPalette.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';

class ColorPalette extends React.Component {
  constructor(props) {
    super(props);
    const { unmount } = this.props;
    unmount();
    this.time = {};
    this.clicked;
  }

  componentDidMount() {
    const { fetch, mount } = this.props;
    mount();
    fetch();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  onClickGradient() {
    return () => {
      const { drawerSettings, changeColor } = this.props;
      const { colorPalette } = drawerSettings;
      let updatedColorPalette = { ...colorPalette };
      if (colorPalette.colorType !== 'gradient') {
        const gradients = {
          configs: [{ percent: 100, color: colorPalette.color }],
          direction: 'to top',
        };
        updatedColorPalette = {
          ...updatedColorPalette,
          color: undefined,
          colorType: 'gradient',
          gradients,
        };
      } else {
        updatedColorPalette = {
          ...updatedColorPalette,
          color: updatedColorPalette.gradients.configs[0].color,
          colorType: 'unit',
          gradients: [],
        };
      }
      changeColor({ ...drawerSettings, colorPalette: updatedColorPalette });
    };
  }

  onChangeGradient(number) {
    const { drawerSettings, changeColor } = this.props;
    const { colorPalette } = drawerSettings;
    const { gradients } = colorPalette;
    const updatedGradients = { ...gradients };
    if (this.clicked !== undefined) {
      updatedGradients.configs[this.clicked].percent = number;
      const updatedColorPalette = { ...colorPalette, gradients: updatedGradients };
      changeColor({ ...drawerSettings, colorPalette: updatedColorPalette });
    }
  }

  onDragStop() {
    const { drawerSettings, changeColor } = this.props;
    const { colorPalette } = drawerSettings;
    const { gradients } = colorPalette;
    const updatedGradients = this.sortGradient(gradients);
    const updatedColorPalette = { ...colorPalette, gradients: updatedGradients };
    changeColor({ ...drawerSettings, colorPalette: updatedColorPalette });
  }

  getSelectedColor(color, colorType = 'unit') {
    const { changeColor, drawerSettings, changeDrawer, element } = this.props;
    let nextDrawerSettings = { ...drawerSettings };
    if (!drawerSettings.closed) { nextDrawerSettings.closed = false; }
    if (!drawerSettings.animate) { nextDrawerSettings.animate = true; }
    const eleAttr = { ...element.elementableAttributes }
    let colorPalette;
    if (colorType === 'gradient') {
      const updatedGradients = nextDrawerSettings.colorPalette.gradients;
      if (this.clicked !== undefined) {
        updatedGradients.configs[this.clicked].color = color;
      }
      colorPalette = {
        gradients: updatedGradients,
        color: null,
        colorType,
        allowGradient: nextDrawerSettings.colorPalette.allowGradient,
        changeTarget: nextDrawerSettings.colorPalette.changeTarget,
      };
    } else if (eleAttr.before && eleAttr.before.background) {
      const tempGrad = {
        ...eleAttr.gradients
      }
      for(let key in Object.keys(tempGrad.configs)){
        tempGrad.configs[key]['color'] = color;
      }
      colorPalette = {
        color,
        gradients: tempGrad,
        colorType,
        allowGradient: nextDrawerSettings.colorPalette.allowGradient,
        changeTarget: nextDrawerSettings.colorPalette.changeTarget,
      };
    } else {
      colorPalette = {
        color,
        gradients: {
          configs: [],
          direction: null,
        },
        colorType,
        allowGradient: nextDrawerSettings.colorPalette.allowGradient,
        changeTarget: nextDrawerSettings.colorPalette.changeTarget,
      };
    }
    nextDrawerSettings = {
      ...nextDrawerSettings,
      isSubDrawerOpen: true,
      subDrawer: 1,
      colorPalette,
    };
    if (drawerSettings.onChange) { drawerSettings.onChange(colorPalette); }
    changeColor({ ...nextDrawerSettings });
    if (drawerSettings.colorPalette.returnToSubDrawer) {
      changeDrawer({ ...drawerSettings.prevDrawerSettings });
    }
  }

  // 自作ダブルクリック
  observeDoubleClick(timing) {
    const now = Date.now();
    if (timing === 'start') {
      if (!this.time.start || now - this.time.start >= 500) {
        this.time.start = now;
        this.time.count = 1;
      } else {
        this.time.count += 1;
      }
    } else if (this.time.count === 2 && now - this.time.start <= 500) {
      this.addGradient();
    }
  }

  // 選択中のdotを特定
  specifyChangeGradientTarget(number) {
    const { drawerSettings } = this.props;
    const { colorPalette } = drawerSettings;
    const { gradients } = colorPalette;
    for (let i = (gradients.configs.length - 1); i >= 0; i--) {
      if (
        gradients.configs[i].percent >= number - 5
        && gradients.configs[i].percent <= number + 5
      ) {
        this.clicked = i;
        return;
      }
    }
    this.clicked = undefined;
  }

  addGradient() {
    const { changeColor, drawerSettings } = this.props;
    const updatedColorPalette = { ...drawerSettings.colorPalette };
    updatedColorPalette.gradients.configs.push(
      { percent: this.lastSliderValue, color: 'transparent' },
    );
    updatedColorPalette.gradients = this.sortGradient(updatedColorPalette.gradients);
    changeColor({ ...drawerSettings, colorPalette: updatedColorPalette });
  }

  sortGradient(gradients) {
    gradients.configs.sort((gradientA, gradientB) => gradientA.percent - gradientB.percent);
    return gradients;
  }

  render() {
    const { colors, drawerSettings } = this.props;
    const { colorPalette } = drawerSettings;
    let sliderStyle;
    if (colorPalette.allowGradient && colorPalette.colorType === 'gradient') {
      sliderStyle = { background: genGradientCss(colorPalette.gradients, 'to right') };
    }
    return (
      <>
        <div className={`${scrollbar.customScroll} ${styles.relative}`}>
          <Typography variant="h4" classes={{ root: styles.typographyRoot, h4: styles.typographyH4 }}>
            {colorPalette.title}
          </Typography>
          <div className={styles.textDrawer}>
            <div className={styles.itemList}>
              {(colors !== undefined) ? colors.map((color) => (
                <div
                  key={color.id}
                  className={(drawerSettings.colorPalette.color === `#${color.hex}`) ? `${styles.item} ${styles.selected}` : styles.item}
                  style={{ backgroundColor: `#${color.hex}` }}
                  onClick={() => this.getSelectedColor(`#${color.hex}`, colorPalette.colorType)}
                />
              ))
                : ''}
            </div>
          </div>
          {(colorPalette.allowGradient)
            ? (
              <div className={styles.gradient}>
                <IconButton color="default" className={styles.iconButton} onClick={this.onClickGradient()}>
                  <Gradient className={styles.iconDefault} />
                </IconButton>
                {
            (colorPalette.colorType === 'gradient')
              ? (
                <div className={styles.MultiSliderWrapper}>
                  <MultiSlider
                    height={10}
                    onSlide={(percent) => {
                      this.onChangeGradient(percent);
                    }}
                    onDragStart={(percent) => {
                      this.specifyChangeGradientTarget(percent);
                      this.observeDoubleClick('start');
                    }}
                    onDragStop={(percent) => {
                      this.lastSliderValue = parseInt(percent, 10);
                      this.observeDoubleClick('end');
                      this.onDragStop();
                    }}
                    backgroundColor="#EEEEEE"
                    className={`${styles.multiSlider}`}
                    style={sliderStyle}
                  >
                    {
                    (() => {
                      const elements = [];
                      for (const key in colorPalette.gradients.configs) {
                        const gradient = colorPalette.gradients.configs[key];
                        elements.push(
                          <Progress
                            key={key}
                            progress={gradient.percent}
                          >
                            <Dot
                              color={gradient.color}
                              style={{ border: '1px solid #000' }}
                              onClickCapture={(e) => (this.clicked = key)}
                            />
                          </Progress>,
                        );
                      }
                      return elements;
                    })()
                  }
                  </MultiSlider>
                </div>
              )
              : ''
            }
              </div>
            )
            : ''}
        </div>
      </>
    );
  }
}

export default ColorPalette;
