import { connect } from 'react-redux';
import LetterSpacingDialog from '@pages/editor/workArea/designTools/textTools/components/dialogs/letterSpacingDialog/LetterSpacingDialog';
import { receiveElement } from '@common/components/entities/elements/ElementActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  receiveElement: (element) => dispatch(receiveElement(element)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LetterSpacingDialog);
