import { genGradientCss } from '@common/utils/TextOperation';

export const compareObject = (obj1, obj2) => {
  if((!obj1 && obj1 !== 0) || (!obj2 && obj2 !== 0)) { return (obj1 === obj2);}
  try{
    const indicatorObj = (Object.keys(obj1).length >= Object.keys(obj2).length) ? obj1 : obj2;
    for( const key in indicatorObj){
      let value1 = obj1[key];
      let value2 = obj2[key];

      if(typeof value1 === 'object' || typeof value2 === 'object') {
        if(!compareObject(value1, value2)) { return false; }
      } else {
        // カラーの省略記法の対応
        if(/^#(\w{3}|\w{6})/.test(value1)) { value1 = parseInt(value1.replace('#',''), 16); }
        if(/^#(\w{3}|\w{6})/.test(value2)) { value2 = parseInt(value2.replace('#',''), 16); }

        if(value1 !== value2) {
          return false;
        }
      }
    }
    return true;
  } catch(error){
    console.log(error);
    throw error;
  }
}

export const pickUpValue = (obj, selectedKey, withOut = []) => {
  const result = []
  if(obj == undefined) {
    return result;
  }
  Object.keys(obj).forEach((key) => {
    if(key === selectedKey) {
      result.push(obj[key]);
    }
    if(typeof obj[key] === 'object' && !withOut.includes(key)) {
      const belowHierarchy = pickUpValue(obj[key], selectedKey, withOut);
      if(belowHierarchy.length > 0) {
        result.push(belowHierarchy);
      }
    }
  });
  return result.flat();
}

export const setRecursiveObject = async (obj, placeArr, value, withOut = []) => {
  let result = {};
  await Object.keys(obj).forEach(async (key) => {
    if(key === placeArr[0] && placeArr.length > 1) {
      const object = await setRecursiveObject(obj[key], placeArr.slice(1, placeArr.length), value, withOut)
      result[key] = {...object}
    } else if(key === placeArr[0] && placeArr.length <= 1) {
      result[key] = value;
    } else if(typeof obj[key] === 'object' && !withOut.includes(key)){
      result[key] = await setRecursiveObject(obj[key], placeArr, value, withOut)
    } else {
      return result[key] = obj[key];
    }
  })
  return result;
}

export const hasKey = (obj, targetArr, withOut = []) => {
  for(const key in obj) {
    if(targetArr.includes(key)) {
      return true;
    }else if(typeof obj === 'object' && !withOut.includes(key)) {
      if(hasKey(obj[key], targetArr, withOut)) {
        return true;
      }
    }
  }
  return false;
}