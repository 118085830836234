import Axios from '@common/apis/axios';
import config from '@assets/config';
import { ActionCreatorFactory, SaveActionCreatorFactory } from '@common/ActionCreator';

export const ACTION_TYPE = {
  SAVE: SaveActionCreatorFactory('shapeTools', 'SAVE'),
};

export const mount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.mounted,
    payload: { mounted: true },
  });
};

export const unmount = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SAVE.unmount,
    payload: { mounted: false },
  });
};
