/* eslint-disable react/no-did-update-set-state */
import {
  IconButton, Divider, Tooltip,
} from '@material-ui/core';
import {
  AccountCircle, Undo, Redo,
} from '@material-ui/icons';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { elementCacher } from '@common/elements/ElementCacher';

// tools
import NoSelectedTools from '@pages/editor/workArea/designTools/noSelectedTools/NoSelectedTools';
import TextToolsContainer from '@pages/editor/workArea/designTools/textTools/TextToolsContainer';
import ShapeToolsContainer from '@pages/editor/workArea/designTools/shapeTools/ShapeToolsContainer';
import ImageToolsContainer from '@pages/editor/workArea/designTools/imageTools/ImageToolsContainer';
import WallPaperImageToolsContainer from '@pages/editor/workArea/designTools/wallPaperImageTools/WallPaperImageToolsContainer';
import FrameImageToolsContainer from '@pages/editor/workArea/designTools/frameImageTools/FrameImageToolsContainer';

// papers
import AccountMenuContainer from '@pages/editor/workArea/designTools/components/accountMenu/AccountMenuContainer';

import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';
import styles from '@pages/editor/workArea/designTools/DesignTools.module.scss';
import '@pages/editor/workArea/designTools/DesignTools.scss';

// Material ui theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#747474',
    },
    secondary: {
      main: '#FFF',
    },
    text: {
      primary: '#FFF',
      secondary: '#747474',
    },
    action: {
      active: '#747474',
    },
  },
  overrides: {
    MuiInput: {
      input: {
        color: '#333',
      },
    },
    MuiSlider: {
      active: {
        color: '#333',
      },
      markActive: {
        color: '#333',
      },
      markLabelActive: {
        color: '#333',
      },
    },
    MuiSelect: {
      icon: {
        color: '#FFF',
      },
    },
  },
});
class DesignTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowDialogs: true,
      isOpen: [
        // 上から順番
        false, // isOpenShadowDialogContainer
        false, // isOpenAlignmentDialog
        false, // isOpenBorderDialogContainer
        false, // isOpenRoundedDialogContainer
        false, // isOpenTransparencyMenu
        false, // isOpenAccountMenu
      ],
      prevIsOpen: [],
    };
    this.changeValueFromChildren = this.changeValueFromChildren.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.tmpToggleDialogs = this.tmpToggleDialogs.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  toggleDialog(targetNumber) {
    return () => {
      const { isOpen } = this.state;
      const openOrClose = !isOpen[targetNumber];
      const updateOpen = isOpen.map((key) => false);
      updateOpen[targetNumber] = openOrClose;
      this.setState({
        isOpen: updateOpen,
        allowDialogs: false,
      });
    };
  }

  closeDialog() {
    return () => {
      const { isOpen } = this.state;
      const updateOpen = isOpen.map((key) => false);
      this.setState({
        isOpen: updateOpen,
        allowDialogs: true,
      });
    };
  }

  changeInputValue(target) {
    return (e) => {
      const { receiveElement, element } = this.props;
      let { value } = e.target;
      if (/\d+/.test(value)) {
        if (target === 'transparency') {
          value = parseInt(value, 10) / 100;
        } else {
          value = parseInt(value, 10);
        }
      }
      const updatedElement = { ...element, [target]: value };

      receiveElement({ ...updatedElement });
    };
  }

  changeTransparencySlider() {
    return (e, newValue) => {
      const { receiveElement, element } = this.props;
      const changeValue = newValue / 100;
      const updatedElement = { ...element, transparency: changeValue };

      receiveElement({ ...updatedElement });
    };
  }

  changeTransparencyInput(e) {
    const { element, receiveElement } = this.props;
    const changeValue = e.target.value / 100;
    element.transparency = changeValue;
    receiveElement({ ...element });
  }

  changeValueFromChildren(element) {
    const { receiveElement } = this.props;
    receiveElement(...element);
  }

  deleteElement() {
    const { receiveElement, setSelection, element } = this.props;
    if (element.elementableType === 'WallPaperImage') {
      document.querySelector('#noElement').style.backgroundColor = 'white';
    }
    receiveElement({ ...element, _destroy: true });
    setSelection(null);
  }

  tmpToggleDialogs(open = false) {
    const { prevIsOpen, isOpen } = this.state;
    const { tmpToggleSmallImage } = this.props;
    if (open) {
      this.setState({
        isOpen: prevIsOpen,
        prevIsOpen: [],
      });
    } else {
      const allClosed = isOpen.map((key) => false);
      this.setState({
        isOpen: allClosed,
        prevIsOpen: isOpen,
      });
    }
    tmpToggleSmallImage(open);
  }

  Redo() {
    const { receiveElement, setSelection } = this.props;
    const undoneElements = elementCacher.popUndone();
    if (undoneElements) {
      if (undoneElements.prevElement) {
        receiveElement({ ...undoneElements.prevElement });
      } else {
        receiveElement({ ...undoneElements.changedElement, _destroy: true });
        setSelection(null);
      }
    }
  }

  Undo() {
    const { undoElement, setSelection } = this.props;
    const cachedElements = elementCacher.popCache();
    if (cachedElements) {
      if (cachedElements.prevElement) {
        undoElement({ ...cachedElements.prevElement });
      } else {
        undoElement({ ...cachedElements.changedElement, _destroy: true });
        setSelection(null);
      }
    }
  }

  designToolsHeader() {
    const { isOpen } = this.state;
    const { elements, receiveElement } = this.props;
    return (
      <div className={styles.designToolsHeader}>
        <Tooltip title="元に戻す ctrl + z">
          <IconButton
            color="default"
            onClick={() => { this.Undo(); }}
            className={elementCacher.cachedElements.stack.length === 0 ? styles.disabled : ''}
            disabled={elementCacher.cachedElements.stack.length === 0}
            size="small"
          >
            <Undo className={`${styles.iconDefault} ${styles.iconHeader}`} />
          </IconButton>
        </Tooltip>
        <Tooltip title="やり直し ctrl + shift + z">
          <IconButton
            color="default"
            onClick={() => { this.Redo(); }}
            className={elementCacher.undoneElements.stack.length === 0 ? styles.disabled : ''}
            disabled={elementCacher.undoneElements.stack.length === 0}
            size="small"
          >
            <Redo className={`${styles.iconDefault} ${styles.iconHeader}`} />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <div className={`${styles.space} ${styles.floatMenuParent}`}>
          <Tooltip title="アカウント情報">
            <IconButton className={(isOpen[5]) ? `${styles.active} ${styles.iconDefault}` : styles.iconDefault} size="small" onClick={this.toggleDialog(5)}>
              <AccountCircle className={`${styles.iconDefault} ${styles.iconHeader}`} />
            </IconButton>
          </Tooltip>
          <AccountMenuContainer
            className={(isOpen[5]) ? '' : styles.hidden}
            receiveElement={receiveElement}
            elements={elements}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      isOpen, allowDialogs,
    } = this.state;
    const {
      element, zoom,
    } = this.props;

    if (!element) {
      return (
        <div className={styles.designTools}>
          {this.designToolsHeader()}
          <div className={styles.designForm}>
            <NoSelectedTools />
          </div>
        </div>
      );
    }

    const isImage = (element.elementableType === 'AssetImage' || element.elementableType === 'UploadImage');
    const className = isImage ? `${styles.designForm} ${scrollbar.customScrollNoMargin} ${styles.imageForm}` : `${styles.designForm} ${scrollbar.customScrollNoMargin}`
    return (
      <div className={styles.designTools}>
        {this.designToolsHeader()}
        <ThemeProvider theme={theme}>
          <form
            className={className}
            onChange={(e) => e.preventDefault()}
          >
            {(element.elementableType === 'ShapeImage') && (
            <ShapeToolsContainer
              iconClassName={styles.iconDefault}
              element={element}
              allowDialogs={allowDialogs}
              closeDialog={this.closeDialog()}
              zoom={zoom}
              isOpen={isOpen}
              toggleDialog={this.toggleDialog}
              designToolsStyles={styles}
              deleteElement={this.deleteElement}
            />
            )}
            {(element.elementableType === 'Text')
              && (
                <TextToolsContainer
                  element={element}
                  iconClassName={styles.iconDefault}
                  allowDialogs={allowDialogs}
                  closeDialog={this.closeDialog()}
                  zoom={zoom}
                  isOpen={isOpen}
                  toggleDialog={this.toggleDialog}
                  designToolsStyles={styles}
                  deleteElement={this.deleteElement}
                />
              )}
            {isImage && (
            <ImageToolsContainer
              iconClassName={styles.iconDefault}
              element={element}
              allowDialogs={allowDialogs}
              closeDialog={this.closeDialog()}
              zoom={zoom}
              tmpToggleDialogs={this.tmpToggleDialogs}
              isOpen={isOpen}
              toggleDialog={this.toggleDialog}
              designToolsStyles={styles}
              deleteElement={this.deleteElement}
            />
            )}
            {(element.elementableType === 'WallPaperImage') && (
            <WallPaperImageToolsContainer
              iconClassName={styles.iconDefault}
              element={element}
              isOpen={isOpen}
              toggleDialog={this.toggleDialog}
              designToolsStyles={styles}
              deleteElement={this.deleteElement}
            />
            )}
            {(element.elementableType === 'FrameImage') && (
            <FrameImageToolsContainer
              iconClassName={styles.iconDefault}
              element={element}
              isOpen={isOpen}
              toggleDialog={this.toggleDialog}
              designToolsStyles={styles}
              deleteElement={this.deleteElement}
            />
            )}
          </form>
        </ThemeProvider>
      </div>
    );
  }
}

export default DesignTools;
