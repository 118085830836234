// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FontDesignPatternDrawer-module__container--2ajbX{flex-grow:1;margin:5%}.FontDesignPatternDrawer-module__formControl--1-UUT{width:100%}.FontDesignPatternDrawer-module__formControl--1-UUT .FontDesignPatternDrawer-module__input--1Q7Ur{color:#FFF;font-size:1.1rem;border-color:#FFF}.FontDesignPatternDrawer-module__textDrawer--1PBya{width:312px;display:flex;flex-direction:column;padding:0 16px 72px 24px;color:white;margin-top:10px}.FontDesignPatternDrawer-module__textDrawer--1PBya>*{margin-bottom:8px}.FontDesignPatternDrawer-module__itemList--2LeLV{display:flex;flex-wrap:wrap;justify-content:space-between}.FontDesignPatternDrawer-module__item--29Ckf{background:#fff;width:312px;height:52px;margin-bottom:10px;display:flex;justify-content:center;align-items:center;transition:background-color 0.3s;font-size:30px;cursor:pointer}.FontDesignPatternDrawer-module__item--29Ckf:hover{background:rgba(255,255,255,0.712)}.FontDesignPatternDrawer-module__highlights--gYupu{display:flex;flex-direction:column;margin-bottom:20px}.FontDesignPatternDrawer-module__highlightItem---flnJ{background:rgba(255,255,255,0.07);border-radius:4px;margin-bottom:8px;transition:background-color 0.3s;cursor:pointer}.FontDesignPatternDrawer-module__highlightItem---flnJ:hover{background:rgba(255,255,255,0.14)}.FontDesignPatternDrawer-module__highlightItem---flnJ>p{padding:16px;line-height:1}.FontDesignPatternDrawer-module__heading--2s_We{font-weight:800;font-size:28px}.FontDesignPatternDrawer-module__subheading--N7A22{font-weight:400;font-size:18px}.FontDesignPatternDrawer-module__body--BefQk{font-weight:300;font-size:12px}\n", ""]);
// Exports
exports.locals = {
	"container": "FontDesignPatternDrawer-module__container--2ajbX",
	"formControl": "FontDesignPatternDrawer-module__formControl--1-UUT",
	"input": "FontDesignPatternDrawer-module__input--1Q7Ur",
	"textDrawer": "FontDesignPatternDrawer-module__textDrawer--1PBya",
	"itemList": "FontDesignPatternDrawer-module__itemList--2LeLV",
	"item": "FontDesignPatternDrawer-module__item--29Ckf",
	"highlights": "FontDesignPatternDrawer-module__highlights--gYupu",
	"highlightItem": "FontDesignPatternDrawer-module__highlightItem---flnJ",
	"heading": "FontDesignPatternDrawer-module__heading--2s_We",
	"subheading": "FontDesignPatternDrawer-module__subheading--N7A22",
	"body": "FontDesignPatternDrawer-module__body--BefQk"
};
module.exports = exports;
