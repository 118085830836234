/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
import { FormControlLabel, Switch } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

import { elementCacher } from '@common/elements/ElementCacher';

import Wizard from '@pages/editor/workArea/modal/WizardModalContainer';
import SmallImagesContainer from '@pages/editor/workArea/smallImages/SmallImagesContainer';
import SmallImage from '@pages/editor/workArea/smallImage/SmallImage';
import DesignContainer from '@pages/editor/workArea/design/DesignContainer';
import DesignToolsContainer from '@pages/editor/workArea/designTools/DesignToolsContainer';

import styles from '@pages/editor/workArea/WorkArea.module.scss';
import scrollbar from '@pages/editor/designDrawer/components/Scrollbar.module.scss';

class WorkArea extends React.Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setActivate = this.setActivate.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleBottomDrawer = this.toggleBottomDrawer.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.setKey = this.setKey.bind(this);
    this.execShortCut = this.execShortCut.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.tmpToggleSmallImage = this.tmpToggleSmallImage.bind(this);
    this.wrapperMin = 50;
    // ショートカットイベントの発生フラグ
    this.pressingKeys = [];
    this.state = {
      recommendImages: false,
      recommendImage: false,
      prevRecommendImage: null,
      recommendImageUrl: null,
      modalIsOpen: true,
      noElementShadow: {
        width: 400,
        height: 300,
      },
      maxHeight: 0,
      maxWidth: 0,
      gotNoElementShadow: false,
    };
  }

  setObserver() {
    const resizeObserver = new ResizeObserver((entries) => {
      const noElementShadowSize = document.querySelector('#noElementShadow');
      const {
        marginTop, marginBottom, marginLeft, marginRight,
      } = window.getComputedStyle(noElementShadowSize);
      const width = noElementShadowSize.offsetWidth + parseInt(marginLeft, 10) + parseInt(marginRight, 10);
      const height = noElementShadowSize.offsetHeight + parseInt(marginTop, 10) + parseInt(marginBottom, 10);
      const rect = document.querySelector('#noElementGrey').getBoundingClientRect();
      this.setState({
        gotNoElementShadow: true,
        noElementShadow: {
          width: (width > rect.width) ? width : rect.width,
          height: (height > rect.height) ? height : rect.height,
        },
      });
    });
    const elementToObserve = document.querySelector('#noElement');

    const observer = new MutationObserver((e) => {
      const { design, zoom } = this.props;
      const { noElementShadow } = this.state;

      let maxHeight = window.innerHeight;
      let maxWidth = noElementShadow.width;
      if (elementToObserve.children.length > 0) {
        const designHeight = design.height * zoom;
        const designWidth = design.width * zoom;
        // 高さ
        // トップラッパーの高さを求める。
        let topWrapperHeight = (window.innerHeight - designHeight) / 2 - 8;
        topWrapperHeight = (topWrapperHeight > this.wrapperMin) ? topWrapperHeight : this.wrapperMin;

        // 幅
        let sideWrapperWidth = (noElementShadow.width - designWidth) / 2 - 4;
        sideWrapperWidth = (sideWrapperWidth > this.wrapperMin) ? sideWrapperWidth : this.wrapperMin;

        // レイヤーから一番高いものを見つけてくる。
        for (const child of elementToObserve.children) {
          let tmpHeight = parseInt(child.style.top) + child.offsetHeight;
          tmpHeight += (child.style.marginTop) ? parseInt(child.style.marginTop) : 0;
          tmpHeight += (child.style.marginBottom) ? parseInt(child.style.marginBottom) : 0;

          maxHeight = (maxHeight > topWrapperHeight + tmpHeight) ? maxHeight : topWrapperHeight + tmpHeight;

          let tmpWidth = parseInt(child.style.left) + child.offsetWidth;
          tmpWidth += (child.style.marginLeft) ? parseInt(child.style.marginLeft) : 0;
          tmpWidth += (child.style.marginRight) ? parseInt(child.style.marginRight) : 0;
          maxWidth = (maxWidth > sideWrapperWidth + tmpWidth) ? maxWidth : sideWrapperWidth + tmpWidth;
        }

        this.setState({ maxHeight, maxWidth });
      }
    });

    observer.observe(elementToObserve, { subtree: true, childList: true, attributes: true });
    resizeObserver.observe(document.querySelector('#noElementShadow'));
    resizeObserver.observe(document.querySelector('#noElementGrey'));
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.setKey);
    document.addEventListener('keyup', this.execShortCut);
    window.addEventListener('DOMContentLoaded', (event) => {
      this.setObserver();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state === nextState
            && this.props === nextProps);
  }

  componentDidUpdate() {
    const { user } = this.props;
    const { modalIsOpen, gotNoElementShadow } = this.state;
    if (user && !user.allowWizard && modalIsOpen) { this.handleModal(); }
    if (!gotNoElementShadow) {
      this.setObserver();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { setSelection, changeDrawer, drawerSettings } = this.props;
    if (this.wrapperRef
        && this.wrapperRef.contains(event.target)
        && (event.target.id === 'noElement' || event.target.id === 'noElementGrey' || event.target.id === 'noElementShadow')) {
      setSelection(null);
      changeDrawer({
        ...drawerSettings,
        isSubDrawerOpen: false,
      });
    } else if (event.target.id !== 'noElement' || event.target.id !== 'noElementGrey' || event.target.id !== 'noElementShadow') {
      this.activate = false;
    }
  }

  handleModal() {
    this.setState({ modalIsOpen: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setActivate() {
    this.activate = true;
  }

  setKey(event) {
    this.pressingKeys.push(event.key);
  }

  toggleBottomDrawer() {
    const { recommendImages } = this.state;
    this.setState({ recommendImages: !recommendImages });
  }

  toggleImageModal(recommendImage, recommendImageUrl = null) {
    this.setState({
      recommendImage,
      recommendImageUrl,
    });
  }

  tmpToggleSmallImage(open) {
    const { prevRecommendImage, recommendImage } = this.state;
    if (open) {
      this.setState({
        recommendImage: prevRecommendImage,
        prevRecommendImage: null,
      });
    } else {
      this.setState({
        recommendImage: false,
        prevRecommendImage: recommendImage,
      });
    }
  }

  execShortCut(event) {
    const {
      elements, selection, receiveElement, addElement,
    } = this.props;

    if(!this.activate) { 
      this.deleteKey();
      return 
    }
    if (this.pressingKeys.includes('Meta') || this.pressingKeys.includes('Control')) {
      if ((this.pressingKeys.includes('z') || this.pressingKeys.includes('Z'))) {
        // zとctrl(cmd)が押されてる
        this.undoOrRedo(event);
      } else if (this.pressingKeys.includes('c') || this.pressingKeys.includes('C')) {
        this.copyElement(event);
      } else if (this.pressingKeys.includes('x') || this.pressingKeys.includes('X')) {
        this.copyElement(event, true);
      } else if (this.pressingKeys.includes('v') || this.pressingKeys.includes('V')) {
        if (this.copy) {
          this.copy = { ...this.copy, posX: this.copy.posX + 10, posY: this.copy.posY + 10 };
          addElement({ ...this.copy });
        }
      }
    } else if (this.pressingKeys.includes('Delete')) {
      this.deleteElement();
    }
    this.deleteKey();
  }

  deleteKey(event) { this.pressingKeys = []; }

  undoOrRedo(event) {
    const { receiveElement, undoElement, setSelection } = this.props;

    if (this.pressingKeys.includes('Shift')) {
      // Redo
      const undoneElements = elementCacher.popUndone();
      if (undoneElements) {
        if (undoneElements.prevElement) {
          receiveElement({ ...undoneElements.prevElement });
        } else {
          receiveElement({ ...undoneElements.changedElement, _destroy: true });
          setSelection(null);
        }
      }
    } else {
      // Undo
      const cachedElements = elementCacher.popCache();
      if (cachedElements) {
        if (cachedElements.prevElement) {
          undoElement({ ...cachedElements.prevElement });
        } else {
          undoElement({ ...cachedElements.changedElement, _destroy: true });
          setSelection(null);
        }
      }
    }
  }

  copyElement(event, isCut = false) {
    const {
      elements, selection, receiveElement, setSelection,
    } = this.props;
    const element = { ...elements[selection] };
    if (!['WallPaperImage', 'FrameImage'].includes(element.elementableType)) {
      this.copy = { ...element };
      if (isCut) {
        receiveElement({ ...element, _destroy: true });
        setSelection(null);
      }
    }
  }

  deleteElement() {
    const {
      receiveElement, setSelection, elements, selection,
    } = this.props;
    const element = { ...elements[selection] };
    if (element.elementableType === 'WallPaperImage') {
      document.querySelector('#noElement').style.backgroundColor = 'white';
    }
    receiveElement({ ...element, _destroy: true });
    setSelection(null);
  }

  render() {
    const {
      design,
      zoom,
      updateElement,
      selection,
      setSelection,
      addElement,
      user,
      grid,
      manipulateGridLine,
    } = this.props;
    const {
      modalIsOpen, recommendImages, recommendImage, recommendImageUrl, noElementShadow, maxHeight, maxWidth,
    } = this.state;

    if (Object.keys(design).length === 0) return null;

    const designHeight = design.height * zoom;
    const designWidth = design.width * zoom;

    // 上
    let topWrapperHeight = (window.innerHeight - designHeight) / 2 - 8;
    topWrapperHeight = (topWrapperHeight > this.wrapperMin) ? topWrapperHeight : this.wrapperMin;

    // 下
    const fullHeight = (window.innerHeight < maxHeight) ? maxHeight : window.innerHeight;
    const bottomWrapperHeight = fullHeight - topWrapperHeight - designHeight;

    // 左
    let sideWrapperWidth = (noElementShadow.width - designWidth) / 2 - 4;
    sideWrapperWidth = (sideWrapperWidth > this.wrapperMin) ? sideWrapperWidth : this.wrapperMin;

    // 右
    const fullWidth = (noElementShadow.width < maxWidth) ? maxWidth : noElementShadow.width;
    const rightWrapperWidth = fullWidth - sideWrapperWidth - designWidth;

    return (
      <div className={styles.workContainer}>
        {/* Wizard modal */}
        <div>
          {
            (user && user.allowWizard)
              ? (
                <>
                  <Modal
                    open={modalIsOpen}
                    onClose={this.handleClose}
                    className={styles.modal}
                  >
                    <div className={styles.modalBody}>
                      <Wizard
                        onClose={this.handleModal}
                        toggleImageModal={this.toggleImageModal}
                        addElement={addElement}
                      />
                    </div>
                  </Modal>
                </>
              )
              : ''
          }
        </div>
        <div className={styles.horizontalAdjustor}>
          <div className={styles.workAreaAndSmallImages}>
            <div className={`${styles.workArea} ${scrollbar.customScrollOwnColor}`} ref={this.setWrapperRef} onClick={this.setActivate} id="noElementGrey">
              <div
                className={styles.noElementGreyTopWrapper}
                style={{
                  height: topWrapperHeight,
                  minHeight: this.wrapperMin,
                  width: noElementShadow.width + rightWrapperWidth,
                }}
              />
              <div
                className={styles.noElementGreyLeftWrapper}
                style={{
                  width: sideWrapperWidth,
                  height: designHeight,
                  top: topWrapperHeight,
                  left: 0,
                }}
              />
              <div className={styles.designContainer} id="noElementShadow">
                <DesignContainer
                  zoom={zoom}
                  updateElement={updateElement}
                  setSelection={setSelection}
                  selection={selection}
                />
              </div>
              <div
                className={styles.noElementGreyRightWrapper}
                style={{
                  width: rightWrapperWidth,
                  height: designHeight,
                  top: topWrapperHeight,
                  left: designWidth + sideWrapperWidth,
                }}
              />
              <div
                className={styles.noElementGreyBottomWrapper}
                id="underWrapper"
                style={{
                  height: bottomWrapperHeight,
                  width: noElementShadow.width + rightWrapperWidth,
                  top: topWrapperHeight + designHeight,
                  bottom: 0,
                }}
              />
              <FormControlLabel
                className={styles.gridSwitch}
                control={
                  <Switch value={grid.open} onChange={() => manipulateGridLine({ open: !grid.open })} classes={{ checked: styles.gridCheckedColor, track: styles.track }} />
                }
                label="グリッド表示"
              />
            </div>
            {/* 小画像レコメンド一覧 */}
            <SmallImagesContainer
              display={recommendImages}
              toggleImageModal={this.toggleImageModal}
              toggleBottomDrawer={this.toggleBottomDrawer}
              url={recommendImageUrl}
            />
            <SmallImage isOpen={recommendImage} url={recommendImageUrl} toggleImageModal={this.toggleImageModal} />
          </div>
          <DesignToolsContainer
            updateElement={updateElement}
            selection={selection}
            setSelection={setSelection}
            zoom={zoom}
            tmpToggleSmallImage={this.tmpToggleSmallImage}
          />

        </div>
      </div>
    );
  }
}

export default WorkArea;
