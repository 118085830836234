import config from '@assets/config';
import { ActionCreatorFactory } from '@common/ActionCreator';
import Axios from '@common/apis/axios';
import { DateTime } from 'luxon';

export const ACTION_TYPE = {
  LOGIN: ActionCreatorFactory('login', 'LOGIN'),
  CHECK: ActionCreatorFactory('login', 'CHECK'),
  LOGOUT: ActionCreatorFactory('logout', 'CHECK'),
};

const setUserProperties = (data) => {
  if (!sessionStorage.getItem('user_id')) {
    sessionStorage.setItem('user_id', data.id);
    const gaId = (process.env.NODE_ENV === 'production') ? 'G-N4DGPC61SL' : 'G-HGF30RVT16';
    gtag('config', gaId, { user_id: sessionStorage.getItem('user_id') });
    gtag('set', 'user_properties', {
      user_timestamp: DateTime.now().toFormat('yyyy/MM/dd/ HH:mm:ss'),
      user_name: data.name,
      user_email: data.email,
    });
  }
};

export const login = (e) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.LOGIN.start, payload: { wait: true, isAuthenticated: false, error: null } });
  Axios.post(`${config.apiServer.baseUrl}/auth/google`, { idToken: e.credential, accessToken: '' }).then((res) => {
    // GA対応
    if (res.data.id) { setUserProperties(res.data.user); }
    if (res.data.user_registered) { gtag('event', 'user_create_count', { event_name: 'user_create_count' }); }

    if (res.status === 200) {
      localStorage.setItem(config.accessTokenKey, res.data.access_token);
      dispatch({ type: ACTION_TYPE.LOGIN.success, payload: { wait: false, isAuthenticated: true, error: null } });
    } else {
      dispatch({ type: ACTION_TYPE.LOGIN.fail, payload: { wait: false, error: { message: '認証に失敗しました' } } });
    }
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTION_TYPE.LOGIN.fail, payload: { wait: false, error: { message: '認証に失敗しました' } } });
  });
};

export const check = (e) => (dispatch) => {
  dispatch({ type: ACTION_TYPE.CHECK.start, payload: { wait: true, isAuthenticated: false, isChecked: true } });
  const token = localStorage.getItem(config.accessTokenKey);
  if (token) {
    Axios.get(`${config.apiServer.baseUrl}/user/me`).then((res) => {
      if (res.data) {
        if (DateTime.fromISO(res.data.expires_at).diffNow('days').days < 0) {
          localStorage.removeItem(config.accessTokenKey);
          sessionStorage.removeItem('user_id');
          dispatch({ type: ACTION_TYPE.CHECK.fail, payload: { wait: false, isAuthenticated: false } });
        } else {
          // GAにユーザープロパティとしてユーザIDを保存。
          if (res.data.id) { setUserProperties(res.data); }
          dispatch({ type: ACTION_TYPE.CHECK.success, payload: { wait: false, isAuthenticated: true } });
        }
      }
    }).catch((error) => {
      console.log(error);
      dispatch({ type: ACTION_TYPE.CHECK.fail, payload: { wait: false, isAuthenticated: false } });
    });
  } else {
    dispatch({ type: ACTION_TYPE.CHECK.fail, payload: { wait: false, isAuthenticated: false } });
  }
};

export const logout = (history) => (dispatch) => {
  localStorage.removeItem(config.accessTokenKey);
  sessionStorage.removeItem('user_id');
  dispatch({ type: ACTION_TYPE.LOGIN.success, payload: { wait: false, isAuthenticated: false, error: null } });
  history.push('/login');
};
