import { connect } from 'react-redux';

import Element from '@components/elements/Element'
import { reflectDecoration } from '@pages/editor/designDrawer/subDrawer/fontDecoration/FontDecorationDrawerAction'
import { receiveElement } from '@common/components/entities/elements/ElementActions';

function mapStateToProps(state, ownProps) {
  return { ...ownProps, fontDecorations: state.materials.fontDecorations };
}

function mapDispatchToProps(dispatch) {
  const actions = {};
  actions.reflectDecoration = (...args) => dispatch(reflectDecoration(...args));
  actions.receiveElement = (...args) => dispatch(receiveElement(...args));
  return actions;
}
export default connect(mapStateToProps, mapDispatchToProps)(Element);
